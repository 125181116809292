import * as $ from "jquery";
import * as ko from "knockout";
import {getElementViewportPosition} from "../../lib/utils";
import {getTranslation} from "../../lib/localize";


class KnockoutDialog {

    private dialogIsOpen: boolean;
    private readonly jq: JQueryStatic;
    private readonly $el: JQuery;
    private readonly dialog: JQuery;
    private readonly dialogCss: string[];
    private readonly dialogUiCss: { [key: string]: string | number };
    private readonly dialogCloseOthers: boolean;
    private readonly dialogEscapeViewport: boolean;
    private readonly dialogDisableModalOnDrag: boolean;
    private readonly dialogModalOnDrag: boolean;
    private readonly dialogVisible: ko.MaybeObservable<boolean>;
    private readonly dialogTitle: ko.MaybeObservable<string>;
    private readonly dialogPosition: ko.MaybeObservable<HTMLElement>;
    private readonly dialogPositionReference: string;

    constructor(element: HTMLTextAreaElement,
                valueAccessor: () => JQueryUI.DialogOptions,
                allBindings: ko.AllBindings) {

        // @ts-expect-error: We always need the top jquery for this.
        this.jq = window.top.jQuery || window.jQuery;

        const options = {
            "appendTo": window.top.document.body,
            "autoOpen": false,
            "closeOnEscape": true,
            "closeText": "",
            "title": "",
            "height": 200,
            "width": 300,
            "resizable": false,
            ...(valueAccessor() || {}),
        };

        // values to subscribe
        this.dialogVisible = allBindings().dialogVisible;
        this.dialogTitle = allBindings().dialogTitle || ko.observable(options.title);
        this.dialogPosition = allBindings().dialogPosition;

        // values to get once (unwrap)
        this.dialogCss = ko.unwrap(allBindings().dialogCss);
        this.dialogUiCss = ko.unwrap(allBindings().dialogUiCss);
        this.dialogCloseOthers = ko.unwrap(allBindings().dialogCloseOthers);
        this.dialogEscapeViewport = ko.unwrap(allBindings().dialogEscapeViewport);
        this.dialogDisableModalOnDrag = ko.unwrap(allBindings().dialogDisableModalOnDrag);
        this.dialogPositionReference = ko.unwrap(allBindings().dialogPositionReference);

        // show modal overlay only when dialog is moved and hide when dialog loses focus (default = true)
        this.dialogModalOnDrag = !(this.dialogDisableModalOnDrag || options.modal);

        // the dialog
        this.$el = this.jq(element);
        this.dialogIsOpen = false;

        // create the initial dialog element
        this.dialog = this.$el.dialog(options as JQueryUI.DialogOptions);
        this.dialog.on("dialogclose", () => {
            this.dialogIsOpen = false;
            if (ko.isWriteableObservable(this.dialogVisible)) {
                this.dialogVisible(false);
            } else {
                allBindings().dialogVisible = false;
            }
        });
        this.dialog.on("dialogopen", () => {
            this.dialogIsOpen = true;

            if (ko.isWriteableObservable(this.dialogVisible)) {
                this.dialogVisible(true);
            } else {
                allBindings().dialogVisible = true;
            }

            // Initially hide modal overlay when dialog is open and 'dialogModalOnDrag' is true
            // (only show when dragging).
            if (this.dialogModalOnDrag) {
                this.jq(".ui-widget-overlay").css("background-image", "none").hide();
            }
        });


        // set aria label for the close button
        this.$el
            .closest(".ui-dialog")
            .find(".ui-dialog-titlebar-close")
            .attr("title", getTranslation("Close"))
            .attr("aria-label", getTranslation("Close"));

        if (ko.isObservable(this.dialogTitle)) {
            this.dialogTitle.subscribe(this.setDialogTitle);
        }

        // handle unload events hard
        $(window).one("unload", () => {
            // check if element has a dialog instance
            if (this.$el.hasClass("ui-dialog-content")) {
                this.$el.dialog("close")
                    .dialog("destroy");
            }

            this.$el.remove();
        });

        if (ko.isObservable(this.dialogPosition)) {
            this.dialogPosition.subscribe(this.setDialogPosition);
        }

        if (ko.isObservable(this.dialogVisible)) {
            this.dialogVisible.subscribe(this.setDialogVisible);
        }
        this.setDialogVisible(ko.utils.unwrapObservable(this.dialogVisible));

        // handle disposal
        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if (this.$el.hasClass("ui-dialog-content")) {
                this.$el.dialog("destroy");
            }
            this.$el.remove();
        });

    }

    // handle the dynamic dialog title
    private setDialogTitle = (value: string) => {
        this.$el.dialog("option", "title", value);
    };

    // handle the dynamic positioning
    private setDialogPosition = (value: JQuery<HTMLElement> | HTMLElement) => {
        if (value) {
            const singularElement: HTMLElement = $(value).get(0);

            // create a temporary element for dialog positioning
            const positioningElement = this.jq("<div>").css("position", "absolute");
            positioningElement.css({...getElementViewportPosition(singularElement)});
            this.jq("body").append(positioningElement);
            this.dialog.closest(".ui-dialog").position({
                of: positioningElement,
                my: this.dialogPositionReference,
                collision: "fit",
            });
            positioningElement.remove();
        }
    };

    private setSizeLimit = () => {
        if (this.dialog.parent().offset()) {
            const parentOffset = this.dialog.parent().offset().top;
            const thisOffset = this.dialog.offset().top;
            const titleHeight = thisOffset - parentOffset;
            const bottomDistance = parentOffset + titleHeight + 10;
            this.$el.css("max-height", $(window.top).height() - bottomDistance);
            this.$el.css("overflow-y", "auto");
        }
    };

    // handle dialog to be modal only on drag/move
    private showModalOnDrag = () => {
        this.$el
            .on("dialogdragstart", () => {
                this.jq(".ui-widget-overlay").show();
            })
            .on("dialogdragstop", () => {
                this.jq(".ui-widget-overlay").hide();
            })
            .dialog("option", "modal", true);
    };

    // handle dialog visibility
    private setDialogVisible = (value: boolean) => {
        if (value) {
            // should be open
            if (this.dialogIsOpen !== true) {

                // close other dialogs in top context
                if (this.dialogCloseOthers) {
                    this.jq(".ui-dialog-content").each((index: number, object: HTMLElement) => {
                        const $object = this.jq(object);
                        if ($object.get(0) !== this.$el.get(0)) {
                            $object.dialog("close");
                        }
                    });
                }

                // show modal overlay only when dialog is dragged and hide it when dialog loses focus
                if (this.dialogModalOnDrag) {
                    this.showModalOnDrag();
                }

                // open the dialog
                this.$el.dialog("open");

                // load css files
                if (this.dialogCss) {
                    // @ts-expect-error: TODO: eventually we should remove cssa completely
                    this.dialog.one("dialogclose", window.top.cssa.require(this.dialogCss));
                }

                // set the initial style
                this.$el.css("min-height", "");
                if (this.dialogUiCss) {
                    this.dialog.parent().css(this.dialogUiCss);
                }
                if (!this.dialogEscapeViewport) {
                    $(window.top).on("resize", this.setSizeLimit);
                    this.setSizeLimit();
                }

                // initially set dynamic values
                this.setDialogTitle(ko.unwrap(this.dialogTitle));
                this.setDialogPosition(ko.unwrap(this.dialogPosition));
            }

        } else {
            // should be closed
            if (this.dialogIsOpen === true) {
                this.$el.dialog("close");
            }
        }
    };
}


declare module "knockout" {
    export interface BindingHandlers {
        dialog?: {
            init(element: HTMLTextAreaElement, valueAccessor: () => JQueryUI.DialogOptions, allBindings: AllBindings): void;
        };
    }

}

/**
 * The dialog binding
 *
 * Integrates jQuery.ui.dialog with KO.
 *
 * options:
 *    dialog:  plain object (no observable!) of jquery ui dialog options such as title, width, height, ... (required)
 *    dialogVisible:  observable boolean, toggles popup open/close state (required)
 *    dialogPosition: observable element, event or jquery-selector the opened popup is placed nearby (optional)
 *    dialogPositionReference: string to describe where the reference for positioning is (default: 'left top')
 *    dialogEscapeViewport: don't keep the height of the dialog smaller then the viewport (default: false)
 *    dialogCss: string or array of strings, with css files that are required by this dialog
 *    dialogUiCss: object with css-options to apply on ui-dialog-instance on open (like {top: '5px'}
 *                 or {top: '20px', right: '20px', bottom: 'auto', left: 'auto'} to position in top right corner)
 *    dialogTitle: observable or string to set the dialog title attribute
 *    dialogDisableModalOnDrag: By default modal overlay is shown when dialog is dragged and hidden when dialog
 loses focus. Deactivate it by setting to true or use modal = true via valueAccessor
 (normal modal behaviour, where overlay is present while dialog is open)
 *    dialogCloseOthers: close all other dialogs in top context on open (default: false)
 *
 * see also: http://stackoverflow.com/questions/8611327/integrating-jquery-ui-dialog-with-knockoutjs
 */
ko.bindingHandlers.dialog = {
    init: (element: HTMLTextAreaElement, valueAccessor: () => JQueryUI.DialogOptions, allBindings: ko.AllBindings): void => {
        new KnockoutDialog(element, valueAccessor, allBindings);
    },
};
