import {Observable} from "knockout";
import {notifications} from "../../lib/pyratTop";
import {getFormData} from "../../lib/utils";
import {reloadWindow} from "../../lib/utils";
import {getTranslation} from "../../lib/localize";
import * as ko from "knockout";


interface UsageTrend {
    [isoDatetime: string]: number;
}

export interface StatusRecord {
    start_date_time: string;  // isoDatetime
    formatted_start_date_time: string;
    finish_date_time: string;  // isoDatetime
    formatted_finish_date_time: string;
    duration: number;
    formatted_duration: string;
    age: number;  // seconds
    id: number;
    cpu_count: number;
    memory_total: number;
    memory_used: number;
    memory_free: number;
    memory_free_in_percent: number;
    size_of_uploads: number;
    number_of_uploads: number;
    number_of_animals: number;
    number_of_live_animals: number;
    number_of_pups: number;
    number_of_live_pups: number;
    number_of_cages: number;
    number_of_open_cages: number;
    number_of_tanks: number;
    number_of_open_tanks: number;
    number_of_strains: number;
    number_of_licenses: number;
    number_of_license_classifications: number;
    number_of_severity_assessment_items: number;
    logs_disk_space_total: number;
    logs_disk_space_used: number;
    logs_disk_space_free: number;
    logs_disk_space_free_percent: number;
    logs_disk_path: string;
    mysql_disk_space_total: number;
    mysql_disk_space_used: number;
    mysql_disk_space_free: number;
    mysql_disk_space_free_percent: number;
    mysql_disk_path: string;
    system_disk_space_total: number;
    system_disk_space_used: number;
    system_disk_space_free: number;
    system_disk_space_free_percent: number;
    system_disk_path: string;
    uploads_disk_space_total: number;
    uploads_disk_space_used: number;
    uploads_disk_space_free: number;
    uploads_disk_space_free_percent: number;
    uploads_disk_path: string;
    max_size_of_uploads: number;  // bytes
    max_number_of_live_animals: number;
    max_number_of_open_tanks: number;
}

interface StatusRecordWithTrends extends StatusRecord {
    memory_used_trend: UsageTrend;
    number_of_uploads_trend: UsageTrend;
    number_of_animals_trend: UsageTrend;
    number_of_pups_trend: UsageTrend;
    number_of_cages_trend: UsageTrend;
    number_of_tanks_trend: UsageTrend;
    number_of_strains_trend: UsageTrend;
    number_of_licenses_trend: UsageTrend;
    number_of_severity_assessment_items_trend: UsageTrend;
    logs_disk_space_used_trend: UsageTrend;
    mysql_disk_space_used_trend: UsageTrend;
    system_disk_space_used_trend: UsageTrend;
    uploads_disk_space_used_trend: UsageTrend;
}

class PyratStatusReport {

    /* state */
    public createInProgress: Observable<boolean>;
    public statusRecord: StatusRecordWithTrends;

    constructor(statusRecord: StatusRecordWithTrends) {
        /* observables */
        this.createInProgress = ko.observable(false);
        this.statusRecord = statusRecord;
    }

    /**
     * Create new status record
     */
    public create = () => {
        this.createInProgress(true);
        fetch("pyrat_status_report.py", {
            method: "POST",
            body: getFormData({
                action: "create_status_record",
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                this.createInProgress(false);

                if (response.success === true) {
                    reloadWindow(window);
                }
                notifications.showNotification(response.message, response.success === true ? "success" : "error");
            })
            .catch(() => {
                this.createInProgress(false);
                notifications.showNotification(getTranslation("General unexpected error occurred."), "error");
            });
    };
}

export const initPyratStatusReport = (statusRecord?: StatusRecordWithTrends): void => {
    ko.applyBindings(new PyratStatusReport(statusRecord));
};
