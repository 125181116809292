/**
 * Show a pop-up to set project for given subject id.
 *
 * @param animalId: Database ID of the animal for which to set project(s).
 *
 * @param pupId: Database ID of the pup for which to set project(s).
 *
 * @param cageId: Database ID of the cage for which to set project(s).
 *
 * @param incidentId: Database ID of the worjk request for which to set project.
 *
 * @param selectType: multiple or single selection - default is "single"
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Computed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {IndexSignature} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setProjects.html";

interface SetProjectsParams {
    animalId?: number;
    pupId?: number;
    cageId?: number;
    incidentId?: number;
    selectType?: "single" | "multiple";
    eventTarget?: HTMLElement;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface RequestData {
    animal_id?: number;
    pup_id?: number;
    cage_id?: number;
    incident_id?: number;
}

interface Seed {
    subject_name: string;
    current_project_ids: number[];
    possible_project_options: {id: number; name: string}[];
}

class SetProjectsViewModel {
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public readonly selectedProjectIds: Observable<number | number[]>;
    public readonly possibleProjectOptions: ObservableArray<{id: number; name: string}>;
    public readonly allowEdit: boolean;
    public readonly canApply: PureComputed<boolean>;

    private readonly animalId: number;
    private readonly pupId: number;
    private readonly cageId: number;
    private readonly incidentId: number;
    private readonly selectType: "single" | "multiple";
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly dialog: KnockoutPopup;
    private readonly applyInProgress: Observable<boolean>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly currentProjectIds: ObservableArray<number>;
    private readonly addProjectIds: Computed<number[]>;
    private readonly removeProjectIds: Computed<number[]>;


    constructor(params: SetProjectsParams, dialog: KnockoutPopup) {
        this.dialog = dialog;

        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.cageId = params.cageId;
        this.incidentId = params.incidentId;
        this.selectType = params.selectType || "single";
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);
        this.selectedProjectIds = ko.observable();
        this.currentProjectIds = ko.observableArray();
        this.possibleProjectOptions = ko.observableArray();

        this.allowEdit = Boolean(!this.animalId || session.userPermissions.animal_set_project)
            && Boolean(!this.pupId || session.userPermissions.animal_set_project)
            && Boolean(!this.cageId || session.userPermissions.cage_set_project)
            && Boolean(!this.incidentId || session.userPermissions.workrequest_edit_project);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const params: IndexSignature<RequestData> = {};

                if (this.animalId) {
                    params.animal_id = this.animalId;
                } else if (this.pupId) {
                    params.pup_id = this.pupId;
                } else if (this.cageId) {
                    params.cage_id = this.cageId;
                } else if (this.incidentId) {
                    params.incident_id = this.incidentId;
                }

                return fetch(getUrl("set_project.py", params), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            let subjectName = "";

            if (seed?.success) {
                if (seed.subject_name) {
                    subjectName = seed.subject_name;
                }
                this.currentProjectIds(seed.current_project_ids);
                this.selectedProjectIds(seed.current_project_ids);
                this.possibleProjectOptions(seed.possible_project_options);
            }

            if (this.selectType === "multiple") {
                if (this.allowEdit) {
                    this.dialog.setTitle(getTranslation("Set projects for %s").replace("%s", subjectName));
                } else {
                    this.dialog.setTitle(getTranslation("View projects of %s").replace("%s", subjectName));
                }
            } else {
                if (this.allowEdit) {
                    this.dialog.setTitle(getTranslation("Set project for %s").replace("%s", subjectName));
                } else {
                    this.dialog.setTitle(getTranslation("View project of %s").replace("%s", subjectName));
                }
            }
        });

        this.addProjectIds = ko.pureComputed(() => {
            let selectedProjectIds: number[] = [];

            if (Array.isArray(this.selectedProjectIds())) {
                selectedProjectIds = this.selectedProjectIds() as number[];
            } else if (this.selectedProjectIds() && parseInt(this.selectedProjectIds().toString(), 10)) {
                selectedProjectIds = [this.selectedProjectIds() as number];
            }

            return selectedProjectIds.filter((project_id) => {
                return this.currentProjectIds().includes(project_id) === false;
            });
        });

        this.removeProjectIds = ko.pureComputed(() => {
            let selectedProjectIds: number[] = [];

            if (Array.isArray(this.selectedProjectIds())) {
                selectedProjectIds = this.selectedProjectIds() as number[];
            } else if (this.selectedProjectIds() && typeof this.selectedProjectIds() === "number") {
                selectedProjectIds = [this.selectedProjectIds() as number];
            }

            return this.currentProjectIds().filter((project_id) => {
                return selectedProjectIds.includes(project_id) === false;
            });
        });

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && (this.addProjectIds().length > 0 || this.removeProjectIds().length > 0);
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        fetch("set_project.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };

    private getFormData = () => {
        const formData = getFormData({
            add_project_ids: JSON.stringify(this.addProjectIds()),
            remove_project_ids: JSON.stringify(this.removeProjectIds()),
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        } else if (this.cageId) {
            formData.append("cage_id", this.cageId.toString());
        } else if (this.incidentId) {
            formData.append("incident_id", this.incidentId.toString());
        }
        return formData;
    };
}

export const showSetProjects = dialogStarter(SetProjectsViewModel, template, params => ({
    name: "SetProjects",
    width: 450,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
}));
