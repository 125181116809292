/**
 * Show a pop-up to set status for given request (id).
 *
 * @param incidentId: Database ID of the request for which to set status.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed.
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not.
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PureComputed} from "knockout";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setRequestStatus.html";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {AjaxResponse} from "../lib/utils";

interface Params {
    incidentId: number;
    eventTarget?: HTMLElement;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface StatusOption  {
    status_id: number;
    status_name: string;
    status_label: string;
    status_status: string;
}

interface Seed {
    current_status_id: number;
    status_options: StatusOption[];
}

class ViewModel {
    public currentStatusId: number;
    public readonly dialog: KnockoutPopup;
    public readonly canApply: PureComputed<boolean>;
    public readonly selectedStatusId: Observable<number>;
    public readonly statusOptions: ObservableArray<StatusOption>;
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;

    private readonly incidentId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly reloadRequired: Observable<boolean>;
    private readonly submitInProgress: Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;

        this.incidentId = params.incidentId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.currentStatusId = undefined;
        this.selectedStatusId = ko.observable();
        this.statusOptions = ko.observableArray();
        this.reloadRequired = ko.observable(false);
        this.submitInProgress = ko.observable(false);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("requestdetail.py", {
                    action: "get_status_dialog_options",
                    incidentid: this.incidentId,
                }), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.currentStatusId = seed.current_status_id;
                this.statusOptions(seed.status_options);
                this.selectedStatusId(seed.current_status_id);
            }
        });

        this.canApply = ko.pureComputed(() => {
            return !this.submitInProgress()
                && !this.seed.fetchInProgress()
                && this.currentStatusId !== this.selectedStatusId();
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.reloadRequired(true);
        this.submitInProgress(true);

        fetch("requestdetail.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success == true) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };

    private getFormData = () => {
        return getFormData({
            incidentid: this.incidentId.toString(),
            status_id: this.selectedStatusId().toString(),
        });
    };
}

export const showSetRequestStatus = dialogStarter(ViewModel, template, params => ({
    name: "SetRequestStatus",
    width: 300,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":popup.css"],
    title: getTranslation("Change status"),
}));
