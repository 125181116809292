/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExportableWellplate = {
    id: number;
    code: string;
    reference?: string;
    mode?: ExportableWellplate.mode;
};

export namespace ExportableWellplate {

    export enum mode {
        REPRO = 'repro',
    }


}

