/**
 * Show a pop-up to set medical conditions of one subject
 *
 * @param animalId
 *        database ID of the animal for which to set medical conditions
 *
 * @param pupId
 *        database ID of the pup for which to set medical conditions
 *
 * @param tankId
 *        database ID of the tank for which to set medical conditions
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback
 *        function to call when data has been applied and pop-up is closed,
 *        e.g. to reload a list or detail page to display the new data
 *
 * @param closeCallback
 *        function to call whenever the pop-up is closed, whther data was
 *        applied or not, e.g. to unhighlight a row in the listview table
 */

import * as _ from "lodash";
import * as ko from "knockout";
import {Observable, ObservableArray, PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse, getFormattedCurrentDate, getFormData, getUrl} from "../lib/utils";
import template from "./setMedicalConditions.html";

interface Params {
    animalId?: number;
    pupId?: number;
    tankId?: number;
    eventTarget?: HTMLElement;
    title: string;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface MedicalConditionAssignment {
    assignId?: Observable<number>;
    medicalConditionId: Observable<number>;
    medicalConditionName: Observable<string>;
    startDate: Observable<string>;
    endDate: Observable<string>;
    ended: Observable<boolean>;
    deleted: Observable<boolean>;
}


interface Seed {
    medical_conditions: {
        id: number;
        name: string;
    }[];
    medical_condition_assignments: {
        assign_id: number;
        medical_condition_id: number;
        medical_condition_name: string;
        start_date: string;
        end_date: string;
    }[];
    tank_status?: "open" | "closed" | "exported" | "joined";
}

class SetMedicalConditionsViewModel {

    private readonly dialog: KnockoutPopup;
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly tankId: number;

    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public readonly medicalConditionAssignmentsCurrent: ObservableArray<MedicalConditionAssignment>;
    public readonly medicalConditionAssignmentsHistory: ObservableArray<MedicalConditionAssignment>;
    public readonly availableMedicalConditions: PureComputed<Seed["medical_conditions"]>;
    public readonly selectedMedicalConditionIds: CheckExtended<ObservableArray<number>>;
    public readonly showMedicalConditionAssignmentsHistory: Observable<boolean>;

    public readonly allowEdit: PureComputed<boolean>;
    public readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly reloadCallback: () => void;
    private readonly closeCallback: () => void;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.tankId = params.tankId;
        this.reloadCallback = params.reloadCallback;
        this.closeCallback = params.closeCallback;

        this.medicalConditionAssignmentsCurrent = ko.observableArray();
        this.medicalConditionAssignmentsHistory = ko.observableArray();

        this.availableMedicalConditions = ko.pureComputed(() => {
            // dynamically remove the already assigned current medical conditions
            return _.filter(this.seed().medical_conditions, (row) => {
                return !_.find(this.medicalConditionAssignmentsCurrent(), (item) => {
                    return item.medicalConditionId() === row.id;
                });
            });
        });
        this.selectedMedicalConditionIds = ko.observableArray().extend({
            invalid: (v) => {
                return !(v && v.length);
            },
        });

        this.showMedicalConditionAssignmentsHistory = ko.observable(false);


        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const data: {
                        animal_id?: number;
                        pup_id?: number;
                        tank_id?: number;
                    } = {};

                if (this.animalId) {
                    data.animal_id = this.animalId;
                }

                if (this.pupId) {
                    data.pup_id = this.pupId;
                }

                if (this.tankId) {
                    data.tank_id = this.tankId;
                }

                return fetch(getUrl("set_medical_conditions.py", data), {signal});
            },
        });
        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.medicalConditionAssignmentsCurrent(_.map(
                    _.filter(seed.medical_condition_assignments, (row) => { return !row.end_date; }
                    ), (row) => { return this.getRowModel(row); }
                ));

                this.medicalConditionAssignmentsHistory(_.map(
                    _.filter(seed.medical_condition_assignments, (row) => { return !!row.end_date; }
                    ), (row) => { return this.getRowModel(row); }
                ));
            }
        });

        /**
         * Allow to edit medical conditions based on user permissions
         * (=> for tanks: conditions are only editable if tank status is "open")
         */
        this.allowEdit = ko.pureComputed(() => {
            return (!this.animalId || session.userPermissions.animal_set_condition)
                && (!this.pupId || session.userPermissions.animal_set_condition)
                && (!this.tankId || session.userPermissions.tank_update && this.seed()?.tank_status === "open");
        });


        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadCallback && this.reloadRequired()) {
                this.reloadCallback();
            }
        });

        this.submitInProgress = ko.observable(false);
        this.canSubmit = ko.pureComputed(() => {
            const anyChange = _.some(this.medicalConditionAssignmentsCurrent(), function(row) {
                return !row.assignId() || row.ended() || row.deleted();
            }) || _.some(this.medicalConditionAssignmentsHistory(), function(row) {
                return row.deleted();
            });

            return !this.submitInProgress()
                && anyChange;
        });
        this.reloadRequired = ko.observable(false);
    }

    private getRowModel = (row: Seed["medical_condition_assignments"][0]) => {
        const rowModel: MedicalConditionAssignment = {
            assignId: ko.observable(row.assign_id),
            medicalConditionId: ko.observable(row.medical_condition_id),
            medicalConditionName: ko.observable(row.medical_condition_name),
            startDate: ko.observable(row.start_date),
            endDate: ko.observable(row.end_date),
            ended: ko.observable(false),
            deleted: ko.observable(false),
        };

        return rowModel;
    };


    public canAddMedicalCondition = ko.pureComputed(() => {
        return !this.selectedMedicalConditionIds.isInvalid();
    });

    public addMedicalCondition = () => {
        _.each(this.selectedMedicalConditionIds(), (conditionId) => {
            this.medicalConditionAssignmentsCurrent.push(this.getRowModel({
                assign_id: undefined,
                medical_condition_id: conditionId,
                medical_condition_name: _.find(this.seed().medical_conditions, {id: conditionId})?.name,
                start_date: getFormattedCurrentDate(),  // today
                end_date: undefined,
            }));
        });
    };

    /**
     * Set the end date of medical condition to today.
     */
    public endMedicalCondition = (item: MedicalConditionAssignment) => {
        if (item.assignId && !item.endDate()) {
            item.endDate(getFormattedCurrentDate());
            item.ended(true);
        }
    };

    /**
     * Delete medical condition assignment
     * (=> Existing assignments can be reassigned and therefore are not removed from DOM (strikeout row),
     *     whereas new added assignments will be removed in general.)
     */
    public deleteMedicalCondition = (item: MedicalConditionAssignment) => {
        if (item.assignId() && !item.deleted()) {
            item.deleted(true);
        } else if (item.assignId() && item.deleted()) {
            item.deleted(false);
        } else {
            this.medicalConditionAssignmentsCurrent.remove(item);
        }
    };

    /**
     * Reactivate medical condition assignment
     * (=> Available only for existing assignments (strikeout row))
     */
    public reactivateMedicalCondition = (item: MedicalConditionAssignment) => {
        item.deleted(false);
    };


    public toggleShowMedicalConditionAssignmentsHistory = () => {
        this.showMedicalConditionAssignmentsHistory(!this.showMedicalConditionAssignmentsHistory());
    };


    private getFormData = () => {
        const medicalConditions: any = [];

        this.medicalConditionAssignmentsCurrent().forEach((row) => {
            if (!row.assignId()) {
                medicalConditions.push({
                     action: "add_medical_condition",
                     medical_condition_id: row.medicalConditionId(),
                });
            } else if (row.ended()) {
                medicalConditions.push({
                     action: "remove_medical_condition",
                     medical_condition_id: row.medicalConditionId(),
                });
            } else if (row.deleted()) {
                medicalConditions.push({
                     action: "delete_medical_condition",
                     assign_id: row.assignId(),
                });
            }
        });

        this.medicalConditionAssignmentsHistory().forEach((row) => {
            if (row.deleted()) {
                medicalConditions.push({
                     action: "delete_medical_condition",
                     assign_id: row.assignId(),
                });
            }
        });

        const formData = getFormData({
            medical_condition_assignments: JSON.stringify(medicalConditions),
        });

        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        } else if (this.tankId) {
            formData.append("tank_id", this.tankId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.reloadRequired(true);
        fetch("set_medical_conditions.py", {
            method: "POST",
            body: this.getFormData(),
        }).then(response => response.json()).then((response: AjaxResponse<any>) => {
            this.submitInProgress(false);
            if (response.success) {
                this.dialog.close();
                notifications.showNotification(response.message, "success");
            } else {
                notifications.showNotification(response.message, "error");
            }
        }).catch(() => {
            this.submitInProgress(false);
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
        });
    };
}

export const showSetMedicalConditions = dialogStarter(SetMedicalConditionsViewModel, template, params => ({
    name: "SetMedicalConditions",
    width: 480,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":editable_assignment.css"],
    title: params.title,
}));
