import {PureComputed} from "knockout";
import * as ko from "knockout";
import {ObservableArray} from "knockout";
import {Observable} from "knockout";
import {getTranslation} from "../../lib/localize";

interface SelectableObject {
    [key: string]: any;

    id: any;
    name: string;
}

interface ClickToSelectParams {
    value: string | Observable<string>;
    caption: string | Observable<string>;
    list: (string | SelectableObject)[] | ObservableArray<string | SelectableObject>;
    title: string | Observable<string>;
    editClass: string | Observable<string>;
}


/**
 * Register click-to-select component for a select list.
 *
 * The behavior, should be the same as the editable tables in PyRATs admin section.
 * You'll see just a text value (the span element) and get an select element to
 * choose the value from a list if you click on the text.
 *
 * @param value: The (current) value. Can be String (static) or an ko.observable from
 * your model (highly dynamic). If your params.list consists of strings, value has to
 * be one of them. If params.list consists of dicts (Objects) having id and name keys,
 * value has to be one of the ids. To have no preselection, pass null.
 *
 * @param caption: The caption/placeholder text. This is displayed, as replacement for
 * null (nothing) is selected, Can be String (static) or an ko.observable from your
 * model (highly dynamic). If caption is not passed (or undefined), translation of
 * 'None' will be used. If you do not want to have a caption, pass null not ''.
 *
 * @param list: The list of entries, where the user should select from. Can be an
 * Array (static) or an ko.observableArray (highly dynamic). Either way, the Array can
 * hold Strings or dicts (Objects) having id and name as keys. Do not mix both. In case
 * of dict (Object), the id will be the value and name will be displayed.
 *
 * @param title: The title attribute for the elements. Can be String (static) or an
 * ko.observable from your model (highly dynamic).
 *
 * @param editClass: Style Class name to assign to edit element (<select/>).
 * Can be String (static) or an ko.observable from your model (highly dynamic).
 *
 * @example:
 *      <ko-click-to-select params="value: myValue, caption: myCaption, list: myList"></ko-click-to-select>
 */
class ClickToSelectViewModel {

    public preEditValue: string;
    public value: Observable<string>;
    public caption: Observable<string>;
    public title: Observable<string>;
    public name: PureComputed<string>;
    public editClass: Observable<string>;
    public objectList: PureComputed<SelectableObject[]>;
    public editing: Observable<boolean>;

    public edit = (model: ClickToSelectViewModel, event: UIEvent) => {
        const selectEl = (event.target as HTMLElement).nextSibling;

        if (selectEl) {
            this.preEditValue = selectEl.textContent;
            this.editing(true);
            return true;
        }
    };

    public blurSelect = (model: ClickToSelectViewModel, event: MouseEvent) => {
        const eventTarget = event.target as HTMLElement;
        const blurEvent = document.createEvent("HTMLEvents");
        blurEvent.initEvent("blur", true, false);
        eventTarget.dispatchEvent(blurEvent);
    };

    public keyDown = (model: ClickToSelectViewModel, event: KeyboardEvent) => {

        const eventTarget = event.target as HTMLElement;

        // restore select elements value and leave editing mode when hit ESC
        if (event.key === "Esc" || event.key === "Escape") {

            eventTarget.textContent = this.preEditValue;

            const changeEvent = document.createEvent("HTMLEvents");
            changeEvent.initEvent("change", true, false);
            eventTarget.dispatchEvent(changeEvent);

            const blurEvent = document.createEvent("HTMLEvents");
            blurEvent.initEvent("blur", true, false);
            eventTarget.dispatchEvent(blurEvent);

            // Returning false, causes knockout to prevent event's default
            // action. This could be, closing our popup if we are part of one.
            return false;
        }
        return true;
    };
    /**
     * Callback to apply some changes to individual options in the select list
     */
    public optionsAfterRenderCallback = (htmlElem: HTMLOptionElement, itemData: SelectableObject) => {
        if (itemData && itemData.isDelimiter) {
            htmlElem.disabled = true;
            htmlElem.classList.add("delimiter");
        }
    };

    constructor(params: ClickToSelectParams) {

        // Make sure, our value (this.value) is observable.
        this.value = ko.isObservable(params.value) ? params.value : ko.observable(params.value);

        // Also caption. If caption is not given, we use translation of 'None'.
        this.caption = ko.isObservable(params.caption) ? params.caption : ko.observable(undefined !== params.caption ? params.caption : getTranslation("None"));

        this.title = ko.isObservable(params.title) ? params.title : ko.observable(params.title);
        this.editClass = ko.isObservable(params.editClass) ? params.editClass : ko.observable(params.editClass);

        this.editing = ko.observable(false);

        // Prepare list to select from (will be option elements).
        // Make sure, the list (Array) consists of dicts (Objects) having id and name keys.
        this.objectList = ko.pureComputed(() => {
            const list: (string | SelectableObject)[] = ko.unwrap(params.list) || [];
            return ko.utils.arrayMap(list, function (item) {
                if (typeof item === "string") {
                    return {
                        id: item,
                        name: item,
                    };
                } else {
                    return item;
                }
            });
        });

        // Compute the value to show. We want to see the name - not the id of select elements value.
        this.name = ko.pureComputed(() => {
            const val = this.value();

            if (val !== undefined) {
                const entry = ko.utils.arrayFilter(this.objectList(), x => x.id === val);
                return entry[0].name;
            }
            return this.caption();
        });
    }
}


export class ClickToSelectComponent {

    constructor() {

        return {
            viewModel: ClickToSelectViewModel,
            // language=HTML
            template: `
                <span style="position:relative">
                    <span style="overflow:hidden; width:100%; height:100%; top:0"
                          data-bind="style: {position: editing()? 'relative' : 'absolute'}">
                        <select style="cursor:pointer;"
                                data-bind="value: value,
                                           options: objectList,
                                           optionsValue:'id',
                                           optionsText:'name',
                                           optionsCaption: caption,
                                           optionsAfterRender: optionsAfterRenderCallback,
                                           style: {opacity: editing()? 1 : 0, filter: editing()? 'alpha(opacity=100)' : 'alpha(opacity=0)'},
                                           attr: {
                                               title: title(),
                                               'class': editClass()
                                           },
                                           hasFocus: editing,
                                           event: {keydown: keyDown, mousedown: edit, change: blurSelect},
                                           keydownBubble: false"></select>
                    </span>
                    <span data-bind="text: name()+' &#9660;',
                                     visible: !editing()"></span>
                </span>`,
        };
    }
}
