import * as ko from "knockout";

type NormalizeFunction<T> = (arg0: T) => T;

declare module "knockout" {

    export interface ExtendersOptions<T> {
        normalize?: NormalizeFunction<T>;
    }
}

/**
 * Call fn before writing a value to target.
 */
ko.extenders.normalize = (target, fn) => ko.computed({
    read: target,
    write: function (newValue) {
        const current = target();
        const normalizedValue = fn(newValue);
        if (normalizedValue !== current) {
            target(normalizedValue);
        } else {
            // normalizedValue has not changed, but the input
            // (newValue) was different, notify subscribers so that
            // they update their views with the new normalizedValue
            if (newValue !== current) {
                target.notifySubscribers(normalizedValue);
            }
        }
    },
});
