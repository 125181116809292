declare global {

    interface HTMLFrameElement {
        frameHistory: FrameHistory;
    }

}

export class FrameHistory {

    private readonly urlList: string[];
    private frameElement: HTMLFrameElement;

    constructor(frameElement: HTMLFrameElement) {
        this.frameElement = frameElement;
        this.urlList = [];
    }

    public addUrl = (url: string): void => {
        if (!url) {
            url = this.frameElement.contentWindow.location.href;
        }
        if (url !== this.urlList[this.urlList.length - 1]) {
            this.urlList.push(url);
        }
    };

    public goBack = (steps: number): void => {
        if (!steps) {
            steps = 1;
        }
        steps += 1;
        if (this.urlList.length >= steps) {
            this.frameElement.contentWindow.location.href = this.urlList.splice(-1 * steps)[0];
        }
    };

    public isEmpty = (): boolean => {
        return !this.urlList.length;
    };

}

export const registerFrameHistoryTracker = (w: Window): void => {
    if (w.frames) {
        for (let i = 0; i < w.frames.length; i++) {
            const frameElement = w.frames[i].frameElement as HTMLFrameElement;
            frameElement.frameHistory = new FrameHistory(frameElement);
        }
    }
};
