/* eslint-disable @typescript-eslint/no-unused-vars */

import * as ko from "knockout";
import {Subscription} from "knockout";

type SubscriptionChangedCallback<T, TTarget> = (this: TTarget, val: T, oldVal: T) => void;

declare module "knockout" {

    export interface SubscribableFunctions {
        subscribeChanged?<T, TTarget = void>(callback: SubscriptionChangedCallback<T, TTarget>, callbackTarget: TTarget, event: string): Subscription;

    }

    export interface Subscribable<T> {
        subscribeChanged(callback: (newValue: T, oldValue: T) => void, target?: any, event?: "change"): Subscription;
    }
}

// extension to get old and new value for subscriptions
// see https://github.com/knockout/knockout/issues/914#issuecomment-139111515
ko.subscribable.fn.subscribeChanged = function <T, TTarget>(callback: SubscriptionChangedCallback<T, TTarget>, callbackTarget: TTarget, event: string): Subscription {
    let savedValue = this.peek();
    return this.subscribe(function (latestValue: T) {
        const oldValue = savedValue;
        savedValue = latestValue;
        callback.call(callbackTarget, latestValue, oldValue);
    });
};
