import {session} from "./pyratSession";

function capitalize_first_character(text: string): string {
    return text.charAt(0).toUpperCase() + (text.slice(1) || "");
}

type TranslationTemplateResolverArguments = { cap?: boolean; plural?: boolean; trans?: boolean };

const translationTemplateDelimiter = "@";

export const TranslationTemplates: { [key: string]: (args: TranslationTemplateResolverArguments) => string } = {

    license: ({cap = false, plural = false, trans = false}): string => {

        let text: string = session.pyratConf?.LICENCE_NAME?.toLowerCase() || "license";

        if (plural) {
            text = session.pyratConf?.LICENCE_NAME_PLURAL?.toLowerCase() || "licenses";
        }

        if (trans) {
            if (text == "license") {
                text = getTranslation("license");
            } else if (text == "licenses") {
                text = getTranslation("licenses");
            } else if (text == "protocol") {
                text = getTranslation("protocol");
            } else if (text == "protocols") {
                text = getTranslation("protocols");
            } else if (text == "ethical permit") {
                text = getTranslation("ethical permit");
            } else if (text == "ethical permits") {
                text = getTranslation("ethical permits");
            }
        }

        if (cap) {
            text = capitalize_first_character(text);
        }

        return text;
    },

    severity_level: ({cap = false, plural = false}): string => {

        let text: string = session.pyratConf?.SEVERITY_LEVEL_NAME?.toLowerCase() || "severity_level";

        if (plural) {
            if (text == "pain category") {
                text = "pain categories";
            } else {
                text = "severity levels";
            }
        }

        if (cap) {
            text = capitalize_first_character(text);
        }

        return text;
    },

    cage_project: ({cap = false, plural = true}): string => {

        let name: string = session.pyratConf?.CAGE_PROJECT_NAME?.toLowerCase() || "cage_project";

        if (name == "billing project") {
            if (plural) {
                name = getTranslation("billing projects");
            } else {
                name = getTranslation("billing project");
            }
        } else if (name == "cage project") {
            if (plural) {
                name = getTranslation("cage projects");
            } else {
                name = getTranslation("cage project");
            }
        } else {
            if (plural) {
                name = getTranslation("projects");
            } else {
                name = getTranslation("project");
            }
        }

        if (cap) {
            name = capitalize_first_character(name);
        }

        return name;
    },
};


/** Parse and apply variables in translated strings. **/
function apply_templates(text: string, delimiter: string = translationTemplateDelimiter): string {

    // check first if there is something to parse
    if (text.indexOf(delimiter) > -1) {
        for (const [key, fn] of Object.entries(TranslationTemplates)) {

            const pattern = new RegExp(`${delimiter}(${key})(|([a-z|]*))?${delimiter}`, "g");
            for (const match of text.matchAll(pattern)) {
                if (match) {
                    const options: TranslationTemplateResolverArguments = {
                        "cap": false,
                        "plural": false,
                        "trans": false,
                    };
                    if (match[3] !== undefined) {
                        for (const m of match[3].split("|")) {
                            if (m in options) {
                                options[m as keyof TranslationTemplateResolverArguments] = true;
                            }
                        }
                    }
                    text = text.replace(match[0], fn(options));
                }
            }
        }
    }

    return text;
}


export const getTranslation = (text: string): string => {
    const pyratTranslation = window.top?.pyratTranslation;
    return apply_templates(pyratTranslation?.[text as keyof typeof pyratTranslation] || text);
};

/**
 * Try to parse the given string to a decimal number.
 *
 * @param str: The value to parse.
 * @returns: A floating point number parsed from the given string.
 *           Return NaN when the string cannot be converted to a number.
 */
export function parseDecimal(str: string): number {
    return parseFloat(str.replace(session.localesConf.decimalSymbol, ".").replace(" ", ""));
}

/**
 * Format the given number as a string.
 *
 * @param num: The value to format.
 * @param precision: If precision is given, round d accordingly (0.5->1), before formatting.
 * @param maxPrecision: If max_precision is given, return up to max precision digits without trailing zeros.
 * @returns: A string representing the number.
 *           Return undefined when n is not a number type.
 */
export function formatDecimal(num: number,
                              precision?: number,
                              maxPrecision?: number): string | undefined {
    let result;
    if (precision) {
        result = Number(Math.round(parseFloat(num + "e" + precision)) + "e-" + precision);
    } else if (maxPrecision) {
        result = Number(Math.round(parseFloat(num + "e" + maxPrecision)) + "e-" + maxPrecision);
        result = result.toFixed(maxPrecision).replace(new RegExp("[.0]{0," + (maxPrecision + 1) + "}$"), "");
    }

    return result.toString().replace(".", session.localesConf.decimalSymbol);
}
