import {Computed} from "knockout";
import {PureComputed} from "knockout";
import * as ko from "knockout";
import {ObservableArray} from "knockout";
import {Observable} from "knockout";
import * as _ from "lodash";
import {KnockoutPopup} from "../lib/popups";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {session} from "./../lib/pyratSession";
import template from "./setNumberOfTankAnimals.html";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {isDateLowerThanDate} from "../lib/utils";
import {getFormattedCurrentDate} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import "./tankDetails.scss";


interface Params {
    tankId: number;
    numberOfDead: number | null;
    sacrificeReasonId: number | null;
    reloadCallback: (tankId: number) => void;
}


interface ContextTank {
    tank_id: number;
    number_of_male: number;
    number_of_female: number;
    number_of_unknown: number;
    status: string;
    tank_position: string;
    location_rack_name: string;
}

interface Seed {
    context: ContextTank[];
}

class SetNumberOfTankAnimalsViewModel {

    private dialog: KnockoutPopup;

    // params
    public tankId: number;
    public numberOfDead: Observable<number>;
    public sacrificeReasonId: CheckExtended<Observable<number>>;
    public reloadCallback: (tankId: number) => void;

    // state
    public selectedAction: Observable<"kill" | "sexing" | "revive">;
    public availableActions: ({ name: string; label: string })[];
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    public reloadRequired: Observable<boolean>;
    public sacrificeMethodId: CheckExtended<Observable<number>>;
    public dateOfDeath: CheckExtended<Observable<string>>;
    public comment: CheckExtended<Observable<string>>;
    public newNumberOfMale: CheckExtended<Observable<number>>;
    public newNumberOfFemale: CheckExtended<Observable<number>>;
    public newNumberOfUnknown: CheckExtended<Observable<number>>;
    public selectedDifferenceOfMale: CheckExtended<Observable<number>>;
    public selectedDifferenceOfFemale: CheckExtended<Observable<number>>;
    public selectedDifferenceOfUnknown: CheckExtended<Observable<number>>;
    public selectedAmount: Computed<number>;
    public remainingAmount: Computed<number>;
    public dialogTitle: PureComputed<string>;
    public seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public context: PureComputed<ContextTank[] | undefined>;
    public selectedContext: Observable<ContextTank | undefined>;
    public contextChangeInProgress: Observable<boolean>;
    public newNumberOfUnknownAutoChanged: Observable<boolean>;
    public canApply: Computed<boolean>;
    public scenery: PureComputed<{ loading: boolean } | { context: SetNumberOfTankAnimalsViewModel } | { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {


        /* params */
        this.dialog = dialog;
        this.tankId = params.tankId;
        this.numberOfDead = ko.observable(params.numberOfDead);
        this.sacrificeReasonId = ko.observable(params.sacrificeReasonId);
        this.reloadCallback = params.reloadCallback;


        /* event */
        this.selectedAction = ko.observable("kill");
        this.availableActions = [
            {name: "kill", label: getTranslation("Record dead animals.")},
            {name: "sexing", label: getTranslation("Redistribute animal sexes.")},
        ];

        if (session.userPermissions.tank_revive) {
            this.availableActions.push({name: "revive", label: getTranslation("Revive dead animals.")});
        }


        /* internals */
        this.applyInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
        this.reloadRequired = ko.observable(false);

        /* state */
        this.sacrificeMethodId = ko.observable();

        this.dateOfDeath = ko.observable().extend({
            invalid: v => !(v && !isInvalidCalendarDate(v)
                && !isDateLowerThanDate(getFormattedCurrentDate(), v) || _.isUndefined(v)),
        });

        this.comment = ko.observable().extend({
            normalize: _.trim,
            invalid: v => !(!v || !v.length || v.length > 2),
        });

        this.selectedDifferenceOfMale = ko.observable(undefined).extend({
            invalid: (v) => {

                // require a positive or negative number
                if ((!_.isNumber(v) || String(v).match(/^[-]?(\d+)$/) === null) && !_.isUndefined(v)) {
                    return true;
                } else if (this.selectedAction() === "kill") {
                    return !((this.newNumberOfMale() || 0) <= this.selectedContext().number_of_male);
                } else if (this.selectedAction() === "sexing") {
                    return !(this.selectedAmount() === 0);
                } else if (this.selectedAction() === "revive") {
                    return !((this.newNumberOfMale() || 0) >= this.selectedContext().number_of_male);
                }

                return false;
            },
        });

        this.selectedDifferenceOfFemale = ko.observable(undefined).extend({
            invalid: (v) => {

                // require a positive or negative number
                if ((!_.isNumber(v) || String(v).match(/^[-]?(\d+)$/) === null) && !_.isUndefined(v)) {
                    return true;
                } else if (this.selectedAction() === "kill") {
                    return !((this.newNumberOfFemale() || 0) <= this.selectedContext().number_of_female);
                } else if (this.selectedAction() === "sexing") {
                    return !(this.selectedAmount() === 0);
                } else if (this.selectedAction() === "revive") {
                    return !((this.newNumberOfFemale() || 0) >= this.selectedContext().number_of_female);
                }

                return false;
            },
        });

        this.selectedDifferenceOfUnknown = ko.observable(undefined).extend({
            invalid: (v) => {

                // require a positive or negative number
                if ((!_.isNumber(v) || String(v).match(/^[-]?(\d+)$/) === null) && !_.isUndefined(v)) {
                    return true;
                } else if (this.selectedAction() === "kill") {
                    return !((this.newNumberOfUnknown() || 0) <= this.selectedContext()?.number_of_unknown);
                } else if (this.selectedAction() === "sexing") {
                    return !(this.selectedAmount() === 0);
                } else if (this.selectedAction() === "revive") {
                    return !((this.newNumberOfUnknown() || 0) >= this.selectedContext()?.number_of_unknown);
                }

                return false;
            },
        });

        this.newNumberOfMale = ko.observable().extend({
            invalid: (v) => {
                return !(_.isNumber((v || 0)) && (v || 0) >= 0);
            },
        });

        this.newNumberOfFemale = ko.observable().extend({
            invalid: (v) => {
                return !(_.isNumber((v || 0)) && (v || 0) >= 0);
            },
        });

        this.newNumberOfUnknown = ko.observable().extend({
            invalid: (v) => {
                return !(_.isNumber((v || 0)) && (v || 0) >= 0);
            },
        });

        this.sacrificeReasonId.extend({
            invalid: (v) => {
                return !(session.pyratConf.MANDATORY_SACRIFICE_REASON ? !_.isUndefined(v) : true);
            },
        });

        this.sacrificeMethodId.extend({
            invalid: (v) => {
                return !(session.pyratConf.MANDATORY_SACRIFICE_METHOD ? !_.isUndefined(v) : true);
            },
        });

        /* initial data */
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("quickselect_tank.py", {
                    data: JSON.stringify({
                        actions: ["set_number_of_animals_action"],
                        context: {tank_id: [this.tankId]},
                    }),
                }), {signal});
            },
        });

        this.selectedContext = ko.observable();
        this.selectedContext.subscribe(() => {
            this.comment(undefined);
            this.dateOfDeath(undefined);
            this.sacrificeMethodId(undefined);
            this.sacrificeReasonId(undefined);
        });

        this.selectedContext.subscribe((selectedContext) => {

            const template = _.template(
                getTranslation("Adjust the number of animals in <%- location_rack_name %> > <%- tank_position %>")
            );

            if (selectedContext?.tank_position && selectedContext?.location_rack_name) {
                this.dialog.setTitle(template(selectedContext));
            } else {
                this.dialog.setTitle(getTranslation("Adjust the number of animals"));
            }
        });

        this.context = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed.context;
            }
            return [];
        });

        this.context.subscribe((context) => {
            this.selectedContext(_.find(context, {selected: true}) as ContextTank || context?.[0]);
        });

        // visual helpers for auto distribution
        this.newNumberOfUnknownAutoChanged = ko.observable(false);

        // if only one animal count is set, assume the user wants to change this
        this.contextChangeInProgress = ko.observable(false);
        ko.computed(() => {
            const context = this.selectedContext();
            this.contextChangeInProgress(true);
            this.selectedDifferenceOfMale(undefined);
            this.selectedDifferenceOfFemale(undefined);
            this.selectedDifferenceOfUnknown(undefined);
            this.newNumberOfUnknown(context?.number_of_unknown);
            this.newNumberOfMale(context?.number_of_male);
            this.newNumberOfFemale(context?.number_of_female);
            if (context && this.numberOfDead()) {
                if (context.number_of_male > 0 && context.number_of_female === 0 && context.number_of_unknown === 0) {
                    this.selectedDifferenceOfMale(this.numberOfDead() * -1);
                } else if (context.number_of_male === 0 && context.number_of_female > 0 && context.number_of_unknown === 0) {
                    this.selectedDifferenceOfFemale(this.numberOfDead() * -1);
                } else if (context.number_of_male === 0 && context.number_of_female === 0 && context.number_of_unknown > 0) {
                    this.selectedDifferenceOfUnknown(this.numberOfDead() * -1);
                }
            }
            this.newNumberOfUnknownAutoChanged(false);
            this.contextChangeInProgress(false);
        });

        // cross update of value inputs
        this.selectedDifferenceOfUnknown.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_unknown)) {
                this.newNumberOfUnknown(this.selectedContext().number_of_unknown + (v || 0) || undefined);
            }
        });
        this.selectedDifferenceOfMale.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_male)) {
                this.newNumberOfMale(this.selectedContext().number_of_male + (v || 0) || undefined);
            }
        });
        this.selectedDifferenceOfFemale.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_female)) {
                this.newNumberOfFemale(this.selectedContext().number_of_female + (v || 0) || undefined);
            }
        });
        this.newNumberOfUnknown.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_unknown)) {
                this.selectedDifferenceOfUnknown((this.selectedContext().number_of_unknown - (v || 0)) * -1 || undefined);
            }
        });
        this.newNumberOfMale.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_male)) {
                this.selectedDifferenceOfMale((this.selectedContext().number_of_male - (v || 0)) * -1 || undefined);
            }
        });
        this.newNumberOfFemale.subscribe((v) => {
            if (_.isNumber(this.selectedContext()?.number_of_female)) {
                this.selectedDifferenceOfFemale((this.selectedContext().number_of_female - (v || 0)) * -1 || undefined);
            }
        });


        // animal redistribution helper
        this.newNumberOfMale.subscribeChanged(this.updateUnknownsAfterRedistribution);
        this.newNumberOfFemale.subscribeChanged(this.updateUnknownsAfterRedistribution);
        this.newNumberOfUnknown.subscribe(() => {
            this.newNumberOfUnknownAutoChanged(false);
        });


        // validation helper
        this.selectedAmount = ko.pureComputed(() => {
            return (this.selectedDifferenceOfMale() || 0)
                + (this.selectedDifferenceOfFemale() || 0)
                + (this.selectedDifferenceOfUnknown() || 0);
        });
        this.remainingAmount = ko.pureComputed(() => {
            return (this.selectedContext().number_of_unknown
                + this.selectedContext().number_of_male
                + this.selectedContext().number_of_female
                + this.selectedAmount());
        });

        // validation for applying changes
        this.canApply = ko.computed(() => {

            if (this.applyInProgress()) {
                return false;
            } else if (this.selectedAction() === "kill" && this.sacrificeReasonId.isInvalid()) {
                return false;
            } else if (this.selectedAction() === "kill" && this.sacrificeMethodId.isInvalid()) {
                return false;
            } else if (this.selectedAction() === "kill" && this.selectedAmount() >= 0) {
                return false;
            } else if (this.selectedAction() === "sexing" && this.selectedAmount() !== 0) {
                return false;
            } else if (this.selectedAction() === "revive" && this.selectedAmount() <= 0) {
                return false;
            } else if (this.remainingAmount() <= 0
                && this.selectedContext().status !== "closed"
                && session.userPermissions.tank_close === false
            ) {
                return false;
            } else if (this.selectedDifferenceOfMale.isInvalid()) {
                return false;
            } else if (this.selectedDifferenceOfFemale.isInvalid()) {
                return false;
            } else if (this.selectedDifferenceOfUnknown.isInvalid()) {
                return false;
            } else if (this.newNumberOfMale.isInvalid()) {
                return false;
            } else if (this.newNumberOfFemale.isInvalid()) {
                return false;
            } else if (this.newNumberOfUnknown.isInvalid()) {
                return false;
            } else if (this.comment.isInvalid()) {
                return false;
            } else if (this.dateOfDeath.isInvalid()) {
                return false;
            }

            return true;
        });

        // routing
        this.scenery = ko.pureComputed(() => {
            if (this.seed.fetchInProgress()) {
                return {loading: true};
            }

            const seed = this.seed();
            if (seed && seed.success) {
                return {context: this};
            }

            return {error: true};
        });

    }

    public reset = () => {
        this.selectedContext.valueHasMutated();
    };

    public apply = () => {

        this.applyInProgress(true);
        this.reloadRequired(true);
        this.errors([]);

        const form = getFormData({
            data: JSON.stringify({
                content: {
                    set_number_of_animals_action: {
                        action: this.selectedAction(),
                        sacrifice_reason_id: this.sacrificeReasonId(),
                        sacrifice_method_id: this.sacrificeMethodId(),
                        comment: this.comment() || undefined,
                        date_of_death: this.dateOfDeath() || undefined,
                        counts: [{
                            number_of_male: (this.newNumberOfMale() || 0),
                            number_of_female: (this.newNumberOfFemale() || 0),
                            number_of_unknown: (this.newNumberOfUnknown() || 0),
                        }],
                    },
                },
                context: {tank_id: [this.selectedContext().tank_id]},
            }),
        });
        fetch("quickselect_tank.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback(this.selectedContext().tank_id);
                    }
                } else {
                    _.forEach(_.get(response, ["content", "set_number_of_animals_action"]), (e) => {
                        this.errors.push(e);
                    });
                    if (this.errors().length === 0) {
                        this.errors.push(response.message);
                    }
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                this.errors.push(getTranslation("General error."));
            });
    };

    private updateUnknownsAfterRedistribution = (newValue: number, oldValue: number) => {
        if (this.selectedAction() === "sexing" && !this.contextChangeInProgress()) {
            this.newNumberOfUnknownAutoChanged(false);
            if ((newValue || 0) > (oldValue || 0) && (this.newNumberOfUnknown() || 0) > 0) {
                this.selectedDifferenceOfUnknown(((this.selectedDifferenceOfMale() || 0) +
                    (this.selectedDifferenceOfFemale() || 0)) * -1);
                this.newNumberOfUnknownAutoChanged(true);
            }
        }
    };
}

/**
 * Pop dialog for mark animals as dead in tank list
 */
export const showSetNumberOfTankAnimals = dialogStarter(SetNumberOfTankAnimalsViewModel, template, {
    name: "SetNumberOfTankAnimals",
    width: 450,
    height: "auto",
    modal: true,
    cssARequire: [":table.css", ":quick_select.css"],
    anchor: {top: 120, left: undefined},
    closeOthers: true,
});
