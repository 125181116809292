import * as $ from "jquery";
import {assert} from "./assert";
import {getTranslation} from "./localize";
import {runScriptTags} from "./runScriptTags";
import {getFormData} from "./utils";
import {getUrl} from "./utils";
import {getElementWindow} from "./utils";
import {getElementViewportPosition} from "./utils";
import {frames} from "./pyratTop";

export type PopupCallback = () => void;

interface AbsolutePosition {
    left?: number | "auto" | undefined;
    right?: number | "auto" | undefined;
    top?: number | "auto" | undefined;
    bottom?: number | "auto" | undefined;
}

interface PopupParams {
    name?: string;
    width?: number | "auto";
    height?: number | "auto";
    title?: string;
    anchor?: HTMLElement | AbsolutePosition;  // former "clickElement" and "absPos" option
    handle?: string;  // former "my" property of "position" option
    modal?: boolean;
    modalOnDrag?: boolean;
    escalate?: boolean;
    closeOthers?: boolean;
    closeOnEscape?: boolean;
    destroyOnClose?: boolean;
    onOpen?: PopupCallback;  // former "open" option
    onClose?: PopupCallback;  // former "close" option
    reloadCallback?: PopupCallback; // former "reload" option
}

export interface AjaxPopupParams extends PopupParams {
    method: "GET" | "POST";
    url: string;
    data: {[key: string]: string};
}

export interface KnockoutPopupParams extends PopupParams {
    cssARequire?: string[];  // long name is chosen to make it grepable
}

interface ElementPopupParams extends PopupParams {
    element: HTMLElement;
}

/** Check if the given object is a jQuery object. **/
function isJQueryObject(obj: any): obj is typeof $ {
    return typeof obj === "object" && typeof obj.jquery !== "undefined";
}

/** Check if the given object is a AbsolutePosition object. **/
function isAbsolutePosition(anchor: HTMLElement | AbsolutePosition): anchor is AbsolutePosition {
    return typeof anchor === "object" && (
        ("left" in anchor)
        || ("right" in anchor)
        || ("top" in anchor)
        || ("bottom" in anchor)
    );
}

function isHTMLElement(anchor: any): anchor is HTMLElement {
    return anchor instanceof HTMLElement
        || (typeof anchor === "object" && Object.getPrototypeOf(anchor.constructor).name === "HTMLElement");
}

/** Create a new positioning object in top context.
 *
 * This is a temporary element for dialog positioning, to make sure positioning of
 * top-dialogs to elements in frame works as expected.
 *
 * @param anchor - Element to position at.
 */
function createPositioningElement(anchor: HTMLElement) {
    const positioningElement = document.createElement("div");
    window.top.document.body.append(positioningElement);
    positioningElement.style.position = "absolute";
    const elementViewPortPosition = getElementViewportPosition(anchor);
    if (typeof elementViewPortPosition.left === "number") {
        positioningElement.style.left = `${elementViewPortPosition.left}px`;
    }
    if (typeof elementViewPortPosition.top === "number") {
        positioningElement.style.top = `${elementViewPortPosition.top}px`;
    }
    if (typeof elementViewPortPosition.width === "number") {
        positioningElement.style.width = `${elementViewPortPosition.width}px`;
    }
    if (typeof elementViewPortPosition.height === "number") {
        positioningElement.style.height = `${elementViewPortPosition.height}px`;
    }
    return  positioningElement;
}


/** Generator to create a new positioning object
 *
 * @param anchor - Element to position at.
 */
function* withPositioningElement(anchor: HTMLElement) {
    const positioningElement = createPositioningElement(anchor);
    yield positioningElement;
    positioningElement.remove();
}


class Popup {

    public triggerReloadCallbackOnClose = false;
    public readonly params: PopupParams;

    private readonly isTop: boolean;
    private readonly jQuery: JQueryStatic;
    private readonly element: HTMLElement;
    private readonly $element: JQuery;
    private readonly onOpen: PopupCallback[];
    private readonly onClose: PopupCallback[];

    /** Create a new popup
     *
     * @param element: HTML to convert to a popup.
     *
     * @param params - Popup parameters.
     *
     * @param params.title - The title of the popup.
     *
     * @param params.name - Name of the popup. If given, the popup is registered
     * in the PopupRegistry and it's ensures the is only a single popup with this name.
     * This allows to retrieve the popup instance later from the registry.
     *
     * @param params.width - The width (px) of the popup or "auto". Default: "auto"
     *
     * @param params.height - The height (px) of the popup or "auto". Default: "auto"
     *
     * @param params.onOpen - Function that gets called when the popup is opened.
     *
     * @param params.onClose - Function that gets called when the popup is closed.
     *
     * @param params.reloadCallback - A public function that can be called from the popup content when the
     * form is submitted. This is not triggered manually but via the `triggerReloadCallback` method.
     *
     * @param params.anchor - HTMLElement or AbsolutePosition object where the new dialog should be positioned.
     *
     * @param params.handle - String description of where the point of the popup what is
     * positioned over the `anchor`. Default: "left top"
     *
     * @param params.modal - Prevent uses from clicking behind the popup, by drawing a semitransparent
     * overlay behind it.
     *
     * @param params.modalOnDrag: If true (default) the modal overlay is shown but invisible, when the popup
     * is dragged and hidden when the popup loses focus.
     *
     * @param params.escalate - Always show the Popup in the top frame.
     *
     * @param params.closeOthers - Automatically close other popups with the same name. Default: true
     *
     * @param params.destroyOnClose -  Automatically destroy the popup when it's closed. Destroying means,
     * the original HTMLElement is restored to it's initial state and to unregister the popup from the
     * PopupRegistry. Default: true.
     *
     * @param params.closeOnEscape - Close the dialog when the Esc key was pressed.
     *
     */
    constructor(element: HTMLElement, params: PopupParams) {

        this.params = {
            width: "auto",
            height: "auto",
            handle: "left top",
            modal: false,
            modalOnDrag: !params.modal,
            escalate: false,
            closeOthers: true,
            closeOnEscape: true,
            destroyOnClose: true,
            ...params,
        };

        if (params.escalate) {
            // @ts-expect-error: We need top jQuery to calculate positions when escalating the dialog top the top frame.
            this.jQuery = window.top.jQuery;
        } else {
            this.jQuery = $;
        }

        this.isTop = window == window.top;
        this.$element = this.jQuery(element);
        this.element = element;

        this.onOpen = [];
        if (this.params.onOpen) {
            this.addOnOpen(this.params.onOpen);
        }
        this.onClose = [];
        if (this.params.onClose) {
            this.addOnClose(this.params.onClose);
        }

        if (this.params.closeOthers && this.params.name) {
            registry.close(this.params.name);
        }

        this.open();

    }

    /** Add a new callback, called after the popup was opened. **/
    public addOnOpen = (callback: PopupCallback) => {
        this.onOpen.push(callback);
    };

    /** Add a new callback, called after the popup was closed. **/
    public addOnClose = (callback: PopupCallback) => {
        this.onClose.push(callback);
    };

    /** Adjust the position and size of the popup
     *
     * @param anchor - HTMLElement or AbsolutePosition object to position the popup over.
     * @param handle - String describing the point on the popup placed over the `anchor`.
     */
    public immediateReposition = (anchor = this.params.anchor, handle = this.params.handle) => {

        const dialogElement = this.$element.closest(".ui-dialog");

        if (isJQueryObject(anchor)) {
            // Somebody illegally gave a jQuery element as anchor.
            anchor = $(anchor).get(0);
        }

        if (isHTMLElement(anchor)) {
            try {
                if (this.isTop && getElementWindow(anchor) != window) {
                    // A top-frame popup instance was created from within a frame.
                    // We need to a virtual element to position the dialog against.
                    for (const positioningElement of withPositioningElement(anchor)) {
                        dialogElement.position({of: positioningElement, my: handle, collision: "fit"});
                    }
                } else {
                    dialogElement.position({of: anchor, my: handle, collision: "fit"});
                }
            } catch (e) {
                // Catch "Permission denied" by positioning
                // against an element which might be gone in case
                // dialog is opened while list iframe was reloaded.
            }
        } else if (isAbsolutePosition(anchor)) {
            dialogElement.css({
                left: "auto",
                right: "auto",
                top: "auto",
                bottom: "auto",
                ...anchor,
            });

            // When moving the dialog element, the left and top offset will be set accordingly.
            // But if the dialog is already pinned to right or/and bottom, moving the dialog
            // will end up changing its width and height - you then have an accordion.
            if (anchor.right || anchor.bottom) {
                dialogElement.css({right: "auto", bottom: "auto", ...dialogElement.position()});
            }
        } else {
            dialogElement.position({my: "center", at: "center", of: window});
        }

        // TODO: Maybe make this behavior optional?
        // Set max height and scroll bars, if content is higher than the window.
        this.setHeightLimit();
    };

    /** Same as `immediateReposition` but covered in a setTimeout, to allow other code to render. **/
    public reposition = (anchor = this.params.anchor, handle = this.params.handle) => {
        window.setTimeout(() => {
            this.immediateReposition(anchor, handle);
        }, 0);
    };

    public setHeightLimit = () => {
        try {
            // set max-height property (innerHeight - "bottomDistance")
            if (this.params.escalate || this.isTop) {
                this.$element.css("max-height", window.top.innerHeight - this.$element.offset().top + 10);
            } else {
                this.$element.css("max-height", window.innerHeight - this.$element.offset().top + 10);
            }
            this.$element.css("overflow-y", "auto");
        } catch {
            // pass (ignore exception)
        }
    };

    public setTitle = (text: string) => {
        // check if element has a dialog instance
        if (this.$element.hasClass("ui-dialog-content")) {
            this.$element.dialog("option", "title", text);
        }
    };

    /** Instantiate (if required) and open the popup. **/
    public open = () => {
        if (this.$element.dialog("instance")) {
            this.$element.dialog("open");
        } else {
            this.$element.dialog({
                resizable: false,
                closeText: "",
                modal: this.params.modal || this.params.modalOnDrag,
                // @ts-expect-error: DOM-nodes are also accepted.
                appendTo: this.params.escalate ? window.top.document.body : window.document.body,
                dialogClass: "ajax-popup-dialog",
                stack: false, //  don't move dialog to top of dialog stack (increase z-index)
                width: this.params.width,
                height: this.params.height,
                title: this.params.title,
                closeOnEscape: this.params.closeOnEscape,
                open: () => {
                    this.onOpen.forEach(callback => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    });
                },
                close: () => {
                    this.onClose.forEach(callback => {
                        if (typeof callback === "function") {
                            callback();
                        }
                    });
                    if (this.triggerReloadCallbackOnClose) {
                        this.triggerReloadCallback();
                    }
                    if (this.params.destroyOnClose) {
                        this.destroy();
                    }
                },
                drag: () => {
                    // TODO: Maybe make this behavior optional?
                    this.setHeightLimit();
                },
            });

            // set aria label for the close button
            this.$element
                .closest(".ui-dialog")
                .find(".ui-dialog-titlebar-close")
                .attr("title", getTranslation("Close"))
                .attr("aria-label", getTranslation("Close"));

            // Remove minHeight to allow smaller dialogs.
            this.$element.css("min-height", "");


            if (this.params.modalOnDrag) {
                const overlay = this.element.parentNode.nextSibling as HTMLElement;

                if (!this.params.modal) {
                    overlay.style.backgroundImage = "none";
                    overlay.style.display = "none";
                }
                this.$element
                    .on("dialogdragstart", () => {
                        overlay.style.display = "";
                    })
                    .on("dialogdragstop", () => {
                        overlay.style.display = "none";
                    });
            }

            // position the dialog
            this.immediateReposition();

            // self register this popup
            if (this.params.name) {
                registry.register(this, this.params.name);
            }

        }
    };

    /** Close the popup **/
    public close = () => {
        if (this.$element.dialog("instance")) {
            this.$element.dialog("close");
        }
    };

    /** Trigger the reloadCallback function, given as parameter to the popup during creation **/
    public triggerReloadCallback = () => {
        if (typeof this.params.reloadCallback === "function") {
            this.params.reloadCallback();
        }
    };

    /** Destroy the popup.
     *
     * The original HTMLElement is restored to it's initial state and popup is removed from the
     * PopupRegistry.
     */
    private destroy = () => {
        try {
            this.$element.dialog("destroy");
        } catch {
            // pass
        }
        registry.remove(this);
    };

}


export class AjaxPopup extends Popup {

    private readonly method: "GET" | "POST";
    private readonly url: string;
    private readonly data: { [key: string]: string };
    private readonly loadingBox: HTMLDivElement;
    private readonly errorMessage: HTMLDivElement;
    private readonly contentElement: HTMLDivElement;

    /* Create a new popup and load the content vai a GET or POST request.
    *
    * The name "AjaxPopup" is used for the registry by default.
    * .
    * @param params.method: The method for sending data to the server (GET or POST). Default: "GET"
    *
    * @param params.url: The url to load the popup content from.
    *
    * @param params.data: Data to send as FormData (for POST) or query string (for GET).
    *
    * All params from the parent `Popup` class are also accepted.
    */
    constructor(params: AjaxPopupParams) {

        const root = document.createElement("div");
        const {method, url, data, ...popupParams} = params;
        super(root, {
            name: "AjaxPopup",
            ...popupParams,
        });

        const loadingBox = document.createElement("div");
        loadingBox.style.padding = "30px 70px";
        loadingBox.id = "ajax-popup-loading-box";
        const loadingCirce = document.createElement("div");
        loadingCirce.style.marginTop = "0";
        loadingCirce.classList.add("loading_circle");
        loadingBox.appendChild(loadingCirce);
        root.appendChild(loadingBox);

        const errorMessage = document.createElement("div");
        errorMessage.id = "ajax-popup-error-message";
        const errorParagraph = document.createElement("p");
        errorParagraph.classList.add("error");
        errorParagraph.classList.add("txt-center");
        errorParagraph.innerText = getTranslation("Error while loading the data. Please try again.");
        errorMessage.appendChild(errorParagraph);
        root.appendChild(errorMessage);

        const contentElement = document.createElement("div");
        root.appendChild(contentElement);

        // params
        this.method = method || "GET";
        this.url = url;
        this.data = data;

        // elements
        this.loadingBox = loadingBox;
        this.errorMessage = errorMessage;
        this.contentElement = contentElement;

        // actually "first" load
        this.reload();
    }

    /** (Re-)Load the data for the popup. **/
    public reload = (): void => {
        this.errorMessage.style.display = "none";
        this.contentElement.innerHTML = "";
        this.loadingBox.style.display = "";
        this.immediateReposition();

        let request;
        if (this.method === "GET") {
            request = fetch(getUrl(this.url, this.data));
        } else if (this.method === "POST") {
            request = fetch(this.url, {method: "POST", body: getFormData(this.data)});
        }

        request
            .then(response => {
                assert(response.ok, `Request to ${response.url} failed.`);
                return response;
            })
            .then(response => response.text())
            .then((body) => {
                this.loadingBox.style.display = "none";
                this.contentElement.innerHTML = body;
                runScriptTags(this.contentElement);

                // bind event handler on 'Cancel' button
                this.contentElement.querySelectorAll(".ajax-popup-cancel-btn").forEach(button => {
                    button.addEventListener("click", (event) => {
                        event.preventDefault();
                        this.close();
                    });
                });

            })
            .catch(() => {
                this.loadingBox.style.display = "none";
                this.errorMessage.style.display = "";
            })
            .then(() => {
                this.reposition();
            });
    };

    /**
     * Submit a HTMLFormElement as GET request in the ListIframe and reload the list.
     *
     * @deprecated: Do no use! Better use fetch requests and reload manually.
     *
     * @param submittedForm: Form element to submit.
     */
    public applyFilterForm = (submittedForm: HTMLFormElement): false => {

        const data = $(submittedForm).serialize();
        const url = $(submittedForm).attr("action");
        const newHref = url + "?" + data;

        // close filter window and reload the list
        frames.reloadListIframe({newHref: newHref});
        this.close();

        // return false to prevent default submit action
        return false;
    };
}

/* Create a new popup from a HtmlElement.
 *
 * Bind click on all child elements with class popup-close-btn to close the
 * popup (to imitate the old `popup.js -> DivPopup` behavior).
 */
export class ElementPopup extends Popup {

    constructor(params: ElementPopupParams) {
        const {element, ...popupParams} = params;
        super(element, popupParams);

        // bind event handler on 'Cancel' button
        element.querySelectorAll(".popup-close-btn").forEach(button => {
            button.addEventListener("click", (event) => {
                event.preventDefault();
                this.close();
            });
        });

    }
}


// TODO: implement and export
export class KnockoutPopup extends Popup {

    constructor(element: HTMLElement, params: KnockoutPopupParams) {
        const {cssARequire, ...superParams} = {
            escalate: true,
            ...params,
        };
        super(element, superParams);
        if (cssARequire?.length) {
            // @ts-expect-error: TODO: eventually we should remove cssa completely
            this.addOnClose(window.top.cssa.require(cssARequire));
        }
        window.addEventListener("unload", () => this.close());
    }
}


class PopupRegistry {

    private readonly registry: {
        [key: string]: Popup;
    };

    constructor() {
        this.registry = {};
    }

    /** Get the named popup instance from the registry. **/
    public get = (name: string): Popup | AjaxPopup | ElementPopup | KnockoutPopup | undefined => {
        return this.registry?.[name];
    };

    /** Close the named popup instance. **/
    public close = (name: string) => {
        this.get(name)?.close();
    };

    /** Close all known named popup instances. **/
    public closeAll = () => {
        for (const name in this.registry) {
            this.close(name);
        }
    };

    /** Reposition the named popup instance. **/
    public reposition = (name: string) => {
        this.get(name)?.reposition();
    };

    /** Trigger the reloadCallback function of the named popup instance. **/
    public triggerReloadCallback = (name: string) => {
        this.get(name)?.triggerReloadCallback();
    };

    /** Remove the given popup instance from the registry. **/
    public remove = (popup: Popup): void => {
        for (const key in this.registry) {
            if (this.registry[key] === popup) {
                delete this.registry[key];
            }
        }
    };

    /** Add the given popup instance to the registry if the name is not yet used. */
    public register = (popup: Popup, name: string): void => {
        if (name in this.registry) {
            throw Error(`Popup name ${JSON.stringify(name)} already in use. ` +
                        "Choose a different name, no name, or close the other popup before.");
        } else {
            this.registry[name] = popup;
        }
    };

}

const registry = new PopupRegistry();
export const getPopup = registry.get;
export const closePopup = registry.close;
export const closeAllPopups = registry.closeAll;
export const repositionPopup = registry.reposition;
export const triggerReloadCallback = registry.triggerReloadCallback;

interface DropdownPopupOptions {
    onOpen?: () => void;
    popupPosition: "left" | "right";
    hideOnMouseLeave?: boolean;
}

export class DropdownPopup {

    combo;
    popup;
    options;
    popupInitialBoundingRect: DOMRect;
    hideTimeout: NodeJS.Timeout;

    /** Create a DropdownPopup
     *
     * The element is required to container two div elements.
     * First is the click element, second is the popup.
     *
     * @example
     *   myPopup = new pyratFrontend.popups.DropdownPopup(document.getElementById("myElement"));
     *
     * @param wrapperElement Selector for the wrapper div.
     * @param options Options for the dropdown.
     * @param options.onOpen Function that gets called after the popup is opened.
     * @param options.popupPosition Align popup. Possible values: 'left', 'right'.
     * @param options.hideOnMouseLeave whether hide popup on mouse leave or only on click
     */
    constructor(wrapperElement: HTMLElement, options: DropdownPopupOptions = {
        popupPosition: "right",
        hideOnMouseLeave: true,
    }) {
        const [combo, popup, ...otherElements] = wrapperElement.children;
        assert(otherElements.length == 0, "Element has to many children for DropdownPopup.");

        wrapperElement.classList.add("dropdown-popup-wrapper");
        combo.classList.add("dropdown-combo");
        popup.classList.add("dropdown-popup");

        wrapperElement.addEventListener("mouseenter", () => {
            clearTimeout(this.hideTimeout);
        });

        combo.addEventListener("mouseenter", () => {
            clearTimeout(this.hideTimeout);
            this.combo.classList.add("mouse_over");
        });

        combo.addEventListener("click", () => {
            if (this.isOpen()) {
                this.hide();
            } else {
                this.show();
            }
        });

        wrapperElement.addEventListener("mouseleave", (e) => {
            if (options.hideOnMouseLeave) {
                if((e.target as Element).tagName.toLowerCase() === "iframe"){
                    // if mouse left popup because you selected an alias in choose_alias iframe
                    // we wait 3 sec for you to come back before hiding again
                    this.hideTimeout = setTimeout(this.hide, 3000);
                } else {
                    // all others hide after 0.3 sec
                    this.hideTimeout = setTimeout(this.hide, 300);
                }

            } else {
                if (!this.isOpen()) {
                    combo.classList.remove("popup_visible");
                    combo.classList.remove("mouse_over");
                }
            }
        });

        this.popup = popup as HTMLDivElement;
        this.combo = combo as HTMLDivElement;
        this.options = options;
    }

    public show = () => {
        this.combo.classList.add("mouse_over");
        this.combo.classList.add("popup_visible");
        if (this.options.popupPosition === "left") {
            this.popup.classList.add("align-left");
        }

        // stick with the initial top offset
        this.popup.style.display = "block";
        this.popup.style.opacity = "0.01";
        if (this.popupInitialBoundingRect) {
            // restore to initial offset
            this.popup.style.top = `${this.popupInitialBoundingRect.top}px`;
        } else {
            // remember initial offset
            this.popupInitialBoundingRect = this.popup.getBoundingClientRect();
        }
        this.popup.style.opacity = "1";

        if (typeof this.options.onOpen === "function") {
            this.options.onOpen();
        }
    };

    public hide = () => {
        this.combo.classList.remove("mouse_over");
        this.combo.classList.remove("popup_visible");
        this.popup.style.opacity = "0";
        this.popup.style.display = "none";
    };

    public isOpen = () => {
        return window.getComputedStyle(this.popup).display !== "none";
    };

}
