import * as $ from "jquery";
import {session} from "./pyratSession";
import {getTranslation} from "./localize";
import "./sessionPoll.scss";
import {getFormData} from "./utils";
import {getUrl} from "./utils";
import {escapeHtml} from "./utils";
import {htmlToNode} from "./utils";


export default class SessionPoll {

    private readonly minPollSeconds = 60; // seconds
    private readonly maxPollSeconds = 60 * 60 * 24 * 21; // seconds
    private readonly pollBeforeTimeIsUp = 10; // seconds
    private readonly timeGrantedOnAjaxFail = 60; // seconds
    private readonly sessionTimeout;  // seconds
    private readonly passwordInput: HTMLInputElement;
    private readonly passwordSubmit: HTMLInputElement;
    private readonly dialogElement;
    private failedPolls = 0;
    private windowTimeout: number;

    constructor(sessionTimeout: number) {

        this.sessionTimeout = sessionTimeout;

        // TODO: Convert to JSX, once this is available.
        const dialogContent = htmlToNode(`
            <div id="session_poll_wrap" title="${getTranslation("Session expired")}">
                <div id="session_poll_container">
                    <p class="padding_b_5">${getTranslation("Please enter your password to reactivate your session")}</p>
                    <div class="grid flexible_container flex_center_aligned">
                        <div class="grid_column col-2-5 padding_5 txt-right">
                            <label for="session_password_name"
                                   class="txt-right">${getTranslation("Username")}:</label>
                        </div>
                        <div class="grid_column col-3-5 padding_5 txt-left">
                            <input name="username"
                                   class="width_200"
                                   type="text"
                                   id="session_password_name"
                                   value="${escapeHtml(session.userName)}"
                                   disabled="disabled"
                                   title="${getTranslation("You can't reactivate a session with another username")}" />
                        </div>
                    </div>
                    <div class="grid flexible_container flex_center_aligned">
                        <div class="grid_column col-2-5 padding_5 txt-right">
                            <label for="session_password_input"
                                   class="txt-right">${getTranslation("Password")}:</label>
                        </div>
                        <div class="grid_column col-3-5 padding_5 txt-left">
                            <input name="password"
                                   class="width_200"
                                   id="session_password_input"
                                   type="password" />
                        </div>
                    </div>
                    <div class="forgot_password txt-center">
                        <p class="padding_b_10">${getTranslation("You are not %(username)s?").replace("%(username)s", escapeHtml(session.userName))}</p>
                        <a href="${getUrl("logout.py")}"
                           class="subtle-link">${getTranslation("Back to Login")}</a>
                    </div>
                    <p class="error"></p>
                    <input type="button" id="session_submit" value="${getTranslation("Reactivate session")}" />
                </div>
            </div>
        `) as HTMLDivElement;

        this.passwordInput = dialogContent.querySelector("#session_password_input");
        this.passwordSubmit = dialogContent.querySelector("#session_submit");

        this.passwordSubmit.addEventListener("click", this.reactivate);
        this.passwordInput.addEventListener("keyup", (event) => {
            if (event.key === "Enter") {
                this.reactivate();
            }
        });

        document.body.append(dialogContent);
        this.dialogElement = $(dialogContent).dialog({
            autoOpen: false,
            resizable: false,
            closeText: "",
            modal: true,
            closeOnEscape: false,
            width: "auto",
            dialogClass: "session-poll-dialog",
        });

        this.setPollTimeout(this.getRepollSeconds(sessionTimeout));

    }

    private showOverlay = (): void => {
        this.dialogElement.dialog("open");
        this.passwordSubmit.disabled = false;
        this.passwordInput.disabled = false;
        this.passwordInput.focus();
    };

    private hideOverlay = (): void => {
        this.dialogElement.dialog("close");
    };

    private getRepollSeconds = (seconds: number) => {
        const res = seconds - this.pollBeforeTimeIsUp;
        if (res > this.maxPollSeconds) {
            return this.maxPollSeconds;
        } if (res < this.minPollSeconds) {
            return this.minPollSeconds;
        } else {
            return res;
        }
    };

    private poll = () => {
        // ask login.py how much time is left for the current session.
        fetch("login.py", { method: "POST", body: getFormData({ get_session_time: JSON.stringify(true) }) })
            .then((response) => response.json())
            .then((data) => {
                // callback function for login.py invoked with get_session_time
                // data.session_time_left should be the amount of time left
                // for the session.
                this.failedPolls = 0;

                if (data.session_time_left > this.pollBeforeTimeIsUp) {
                    this.setPollTimeout(this.getRepollSeconds(data.session_time_left));
                } else if (data.finished_login_chain) {
                    this.showOverlay();
                } else {
                    // else: still on choose_alias page
                    // session timed out without having an alias selected
                    // go back to login
                    window.top.location.href = getUrl("logout.py");
                }
            })
            .catch(() => {
                // try to poll again, later
                this.failedPolls++;
                if (this.failedPolls < 4) {
                    this.setPollTimeout(this.timeGrantedOnAjaxFail);
                }
                // else: permanent problem: quit trying to poll
                return false;
            });

    };

    private setPollTimeout = (seconds: number) => {
        if (this.windowTimeout) {
            window.clearTimeout(this.windowTimeout);
        }
        if (seconds > 0 && seconds <= 2147483.647) {
            // Maximum value for setTimeout is limited to 24.855 days.
            // see: https://web.archive.org/web/20210310183717/https://catonmat.net/settimeout-setinterval
            this.windowTimeout = window.setTimeout(this.poll, seconds * 1000);
        }
    };

    private reactivate = () => {
        if (this.passwordInput.value) {
            this.passwordSubmit.disabled = true;
            this.passwordInput.disabled = true;
            this.passwordInput.blur();

            const form = getFormData({
                reactivate_session: session.sessionId,
                password: this.passwordInput.value,
            }, {addSession: false});
            this.passwordInput.value = "";

            fetch("login.py", {method: "POST", body: form})
                .then(response => response.json())
                .then((response) => {
                    if (response.resurrected === true) {
                        this.setPollTimeout(this.getRepollSeconds(this.sessionTimeout));
                        this.hideOverlay();
                    } else {
                        // the reactivation failed, let's try again
                        this.passwordSubmit.disabled = false;
                        this.passwordInput.disabled = false;
                        this.passwordInput.focus();
                    }
                })
                .catch(() => {
                    // redirect to root page
                    window.location.assign("login.py");
                    return false;
                });
        } else {
            this.passwordInput.focus();
        }
    };
}
