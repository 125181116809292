import * as ko from "knockout";
import {KnockoutPopupParams} from "../lib/popups";
import {KnockoutPopup} from "../lib/popups";


/** Create a dialog starter function from the given model and template.
 *
 * Warning: Do not give Observables as parameters to dialogs, they should act
 * only on initially the given data or callback. It works, but it will make a
 * transition away from knockout harder.
 *
 * Basically, this creates virtual dom element, instantiates the given model
 * and ko-binds it to the element.
 *
 * @param viewModel: View model class.
 * @param template: Template of the dialog.
 * @param config: Configuration the dialog.
 * @returns: The model instance.
 */
export const dialogStarter = <Model, Params>(
    viewModel: { new(params: Params, dialog: KnockoutPopup): Model },
    template: string,
    config: KnockoutPopupParams | ((params: Params) => KnockoutPopupParams) = {}
): (params: Params) => Model => {

    return (params): Model => {
        const container = document.createElement("div");

        let dialog: KnockoutPopup;
        if (typeof config === "function") {
            dialog = new KnockoutPopup(container, config(params));
        } else {
            dialog = new KnockoutPopup(container, config);
        }
        const model = new viewModel(params, dialog);
        container.innerHTML = template;
        ko.applyBindings(model, container);
        dialog.addOnClose(() => {
            if (dialog.params.destroyOnClose) {
                ko.cleanNode(container);
            }
        });
        return model;
    };

};
