/**
 * Show select fields for generation, validate combination of selected values
 *
 * @param subGeneration1
 *        to serialize and deserialize the first sub generation
 *
 * @param newGeneration1
 *        to serialize and deserialize the first new generation
 *
 * @param subGeneration2
 *        to serialize and deserialize the second sub generation
 *        (when pyrat_conf.MULTIPLE_SUBGEN is True)
 *
 * @param newGeneration2
 *        to serialize and deserialize the second new generation
 *        (when pyrat_conf.MULTIPLE_SUBGEN is True)
 *
 * @param additionalGeneration
 *        to serialize and deserialize the additional generation
 *        (when pyrat_conf.MULTIPLE_SUBGEN is False)
 *
 * @param valid
 *        pass the information if the combination of selected generation values
 *        is valid to the outside
 *
 * @param errorMessages
 *        pass error messages to the outside
 *
 * @param idSuffix
 *        in case there is more than one generation widget on the page then
 *        the `id` attributes need to be made unique between all the widgets,
 *        therefore specify here some data that is unique for each widget to
 *        append it to the `id` or `for` attribute of select and label elements
 *
 * @param skipValidation
 *        whether to skip the validation and not to mark fields as invalid at all,
 *        e.g. do not mark them in a QS if the option is not selected
 *
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PureComputed} from "knockout";
import {Subscribable} from "knockout";
import * as _ from "lodash";
import {CheckExtended} from "../extensions/invalid";
import {getTranslation} from "../../lib/localize";
import template from "./generationWidget.html";

interface GenerationWidgetParams {
    subGeneration1?: Observable<string>;
    newGeneration1?: Observable<string>;
    subGeneration2?: Observable<string>;
    newGeneration2?: Observable<string>;
    additionalGeneration?: Observable<string>;
    valid?: (value: boolean) => void;
    errorMessages?: ObservableArray<string>;
    idSuffix: string | number;
    skipValidation: Subscribable<boolean>;
}

class GenerationWidgetViewModel {

    public subGeneration1: CheckExtended<Observable<string>>;
    public newGeneration1: CheckExtended<Observable<string>>;
    public subGeneration2: CheckExtended<Observable<string>>;
    public newGeneration2: Observable<string>;
    public additionalGeneration: Observable<string>;
    public idSuffix: string | number;
    private valid: PureComputed<boolean>;
    private errorMessages: PureComputed<string[]>;
    private skipValidation: Subscribable<boolean>;

    constructor(params: GenerationWidgetParams) {

        this.subGeneration1 = ko.observable().extend({
            invalid: (v) => {
                if (this.skipValidation()) {
                    return false;
                }
                if (this.newGeneration2() && v !== "N") {
                    return getTranslation("Field must be N");
                }
                return false;
            },
        });
        this.newGeneration1 = ko.observable().extend({
            invalid: (v) => {
                if (this.skipValidation()) {
                    return false;
                }
                // don't allow single letter mutation (e.g. "F" or "a")
                if (!v && (this.additionalGeneration() || this.subGeneration1() || this.subGeneration2())) {
                    return getTranslation("Field not selected");
                }
                return false;
            },
        });
        this.subGeneration2 = ko.observable().extend({
            invalid: (v) => {
                if (this.skipValidation()) {
                    return false;
                }
                if (!v && this.newGeneration2()) {
                    return getTranslation("Field not selected");
                }
                return false;
            },
        });

        this.newGeneration2 = ko.observable();
        this.additionalGeneration = ko.observable();

        this.idSuffix = params.idSuffix;

        if (ko.isObservable(params.skipValidation)) {
            this.skipValidation = params.skipValidation;
        } else {
            this.skipValidation = ko.observable(false);
        }

        if (ko.isObservable(params.subGeneration1)) {
            params.subGeneration1.subscribe((v) => {
                this.subGeneration1(v);
            });
            params.subGeneration1.notifySubscribers(params.subGeneration1());

            this.subGeneration1.subscribe((v) => {
                params.subGeneration1(v);
            });
        }

        if (ko.isObservable(params.newGeneration1)) {
            params.newGeneration1.subscribe((v) => {
                this.newGeneration1(v);
            });
            params.newGeneration1.notifySubscribers(params.newGeneration1());

            this.newGeneration1.subscribe((v) => {
                params.newGeneration1(v);
            });
        }

        if (ko.isObservable(params.subGeneration2)) {
            params.subGeneration2.subscribe((v) => {
                this.subGeneration2(v);
            });
            params.subGeneration2.notifySubscribers(params.subGeneration2());

            this.subGeneration2.subscribe((v) => {
                params.subGeneration2(v);
            });
        }

        if (ko.isObservable(params.newGeneration2)) {
            params.newGeneration2.subscribe((v) => {
                this.newGeneration2(v);
            });
            params.newGeneration2.notifySubscribers(params.newGeneration2());

            this.newGeneration2.subscribe((v) => {
                params.newGeneration2(v);
            });
        }

        if (ko.isObservable(params.additionalGeneration)) {
            params.additionalGeneration.subscribe((v) => {
                this.additionalGeneration(v);
            });
            params.additionalGeneration.notifySubscribers(params.additionalGeneration());

            this.additionalGeneration.subscribe((v) => {
                params.additionalGeneration(v);
            });
        }

        if (typeof params.valid === "function") {
            this.valid = ko.pureComputed(() => !(
                this.subGeneration1.isInvalid() ||
                this.newGeneration1.isInvalid() ||
                this.subGeneration2.isInvalid()
            ));

            this.valid.subscribe((v) => {
                params.valid(v);
            });
        }

        if (typeof params.errorMessages === "function") {
            this.errorMessages = ko.pureComputed(() => _.compact([
                this.subGeneration1.errorMessage(),
                this.newGeneration1.errorMessage(),
                this.subGeneration2.errorMessage(),
            ]));

            this.errorMessages.subscribe((v) => {
                params.errorMessages(v);
            });
        }
    }

}

export class GenerationWidgetComponent {

    constructor() {
        return {
            viewModel: GenerationWidgetViewModel,
            template,
        };
    }
}
