import * as _ from "lodash";
import * as ko from "knockout";
import {Observable} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setTankLabel.html";

interface Params {
    tankId: number;
    eventTarget?: HTMLElement;
    reloadCallback?: (tankId: number) => void;
}

class SetTankLabelViewModel {

    private readonly dialog: KnockoutPopup;

    // params
    public tankId: number;
    public reloadCallback: (tankId: number) => void;

    // state
    public tankLabel: Observable<string>;
    public updateInProgress: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<{ get_tank_label_details: { tank_label: string } }>>>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.tankId = params.tankId;
        this.reloadCallback = params.reloadCallback;

        this.tankLabel = ko.observable("");
        this.updateInProgress = ko.observable(false);
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch("tank_list.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({get_tank_label_details: this.tankId}),
                    }),
                    signal,
                });
            },
        });

        this.seed.subscribe((v) => {
            this.tankLabel(_.get(v, ["get_tank_label_details", "tank_label"]));
        });

        this.dialog.setTitle(getTranslation("Set tank label for Tank ID %s.").replace("%s", String(this.tankId)));
    }

    public applyLabel = () => {
        this.updateInProgress(true);
        const form = getFormData({
            request: JSON.stringify({
                set_tank_label: {
                    tank_id: this.tankId,
                    tank_label: this.tankLabel(),
                },
            }),
        });
        return fetch("tank_list.py", {method: "POST", body: form})
            .then(() => {
                this.updateInProgress(false);
                this.dialog.close();
                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback(this.tankId);
                }
            })
            .catch(() => {
                this.updateInProgress(false);
                this.dialog.close();
            });
    };

}

export const showSetTankLabel = dialogStarter(SetTankLabelViewModel, template, params => ({
    name: "SetTankLabel",
    width: 300,
    anchor: params.eventTarget,
    cssARequire: [":popup.css"],
    closeOthers: true,
}));
