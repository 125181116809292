/**
 * Provide functions to store session specific data in the
 * browsers session storage objects.
 *
 * TODO: add set/getServerStorage (for PyRat Session storage)
 */
import {session} from "./pyratSession";

type Space = "internal" | "user";


/**
 * Calculate the key to use to store the data for the active PyRat session and version.
 *
 * This is based on window variables for PyRAT version dna userId (what requires it to be a promise).
 *
 * @param key A custom string to describe what to store.
 * @param space The storage space to use.
 */
function getSessionStorageKey(key: string, space: Space = "user"): Promise<string> {

    const resolver = () => [
        "pyrat",
        encodeURIComponent(session && session.pyratVersion?.long || "no-version"),
        encodeURIComponent(session && session.sessionId || "no-session"),
        encodeURIComponent(space || "user"),
        encodeURIComponent(key || "no-key"),
    ].join("_");

    return new Promise((resolve) => {
        if (window.document.readyState === "complete") {
            resolve(resolver());
        } else {
            window.addEventListener("load", () => {
                resolve(resolver());
            }, {once: true});
        }
    });
}

/**
 * Calculate the key to use to store the data for the active PyRat user and version.
 *
 * This is based on window variables for PyRAT version dna userId (what requires it to be async / a promise).
 *
 * @param key A custom string to describe what to store.
 * @param space The storage space to use.
 */
function getLocalStorageKey(key: string, space: Space = "user"): Promise<string> {

    const resolver = () => [
        "pyrat",
        encodeURIComponent(session && session.pyratVersion?.long || "no-version"),
        encodeURIComponent(session && session.userId || "user-id"),
        encodeURIComponent(space || "user"),
        encodeURIComponent(key || "no-key"),
    ].join("_");

    return new Promise((resolve) => {
        if (window.document.readyState === "complete") {
            resolve(resolver());
        } else {
            window.addEventListener("load", () => {
                resolve(resolver());
            }, {once: true});
        }
    });
}

/**
 * Check if the browser supports sessionStorage and localStorage.
 * @returns  True if the browser supports it.
 */
export function isSupported(): boolean {
    const mod = "TEST.29HQLCBmn33JpoMso4O4";  // random string

    try {
        const key = Math.random().toString(36).substring(7);
        sessionStorage.setItem(key, mod);
        sessionStorage.removeItem(key);
    } catch (e) {
        return false;
    }

    try {
        const key = Math.random().toString(36).substring(7);
        localStorage.setItem(key, mod);
        localStorage.removeItem(key);
    } catch (e) {
        return false;
    }

    return true;
}

/**
 * Get a list of all sessionStorage keys in use.
 */
export async function getSessionStorageKeys(): Promise<string[]> {
    const keysKey = await getSessionStorageKey("keys", "internal");
    return JSON.parse(sessionStorage.getItem(keysKey) || "[]");
}

/**
 * Get a list of all getLocalKeys keys in use.
 */
export async function getLocalStorageKeys(): Promise<string[]> {
    const keysKey = await getLocalStorageKey("keys", "internal");
    return JSON.parse(localStorage.getItem(keysKey) || "[]");
}

/**
 * Write an object into the sessionStorage.
 *
 * @param key The key to access the object later. Use dots ('.') to
 *      simulate namespaces like `animal_list.filter_popup.size`.
 * @param obj The object to store.
 * @returns True if the value was stored.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function setSessionItem(key: string, obj: any): Promise<boolean> {

    try {

        if (typeof obj === "undefined") {
            return removeSessionItem(key);
        }

        const storageKey = await getSessionStorageKey(key);
        sessionStorage.setItem(storageKey, JSON.stringify(obj));

        const keysKey = await getSessionStorageKey("keys", "internal");
        const keys = await getSessionStorageKeys();

        // add any new key to the internal key list
        if (keys.indexOf(key) < 0) {
            keys.push(key);
            sessionStorage.setItem(keysKey, JSON.stringify(keys));
        }

        return true;

    } catch (e) {
        return false;
    }
}

/**
 * Write an object into the localStorage.
 *
 * @param key The key to access the object later.
 * @param obj The object to store.
 * @returns True if the value was stored.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function setLocalItem(key: string, obj: any): Promise<boolean> {

    try {

        if (typeof obj === "undefined") {
            return removeLocalItem(key);
        }

        const storageKey = await getLocalStorageKey(key);
        localStorage.setItem(storageKey, JSON.stringify(obj));

        const keysKey = await getLocalStorageKey("keys", "internal");
        const keys = await getSessionStorageKeys();

        // add any new key to the internal key list
        if (keys.indexOf(key) < 0) {
            keys.push(key);
            localStorage.setItem(keysKey, JSON.stringify(keys));
        }

        return true;

    } catch (e) {
        return false;
    }

}

/**
 * Remove an object from the sessionStorage.
 *
 * @param key The key, used to store the object.
 * @returns True if the value was removed (or it was unnecessary).
 */
export async function removeSessionItem(key: string): Promise<boolean> {

    try {

        const storageKey = await getSessionStorageKey(key);
        sessionStorage.removeItem(storageKey);

        const keysKey = await getSessionStorageKey("keys", "internal");
        const keys = await getSessionStorageKeys();

        // object is unset, so remove it from the key list
        keys.forEach((value, index) => {
            if (value === key) {
                keys.splice(index, 1);
                return false;
            }
        });

        sessionStorage.setItem(keysKey, JSON.stringify(keys));

        return true;

    } catch (e) {
        return false;
    }
}

/**
 * Remove an object from the localStorage.
 *
 * @param key The key, used to store the object.
 * @returns True if the value was removed (or it was unnecessary).
 */
export async function removeLocalItem(key: string): Promise<boolean> {

    try {

        const storageKey = await getLocalStorageKey(key);
        localStorage.removeItem(storageKey);

        const keysKey = await getLocalStorageKey("keys", "internal");
        const keys = await getLocalStorageKeys();

        // object is unset, so remove it from the key list
        keys.forEach((value, index) => {
            if (value === key) {
                keys.splice(index, 1);
                return false;
            }
        });

        localStorage.setItem(keysKey, JSON.stringify(keys));

        return true;

    } catch (e) {
        return false;
    }
}

/**
 * Read an object from the sessionStorage
 *
 * @param  key The key, used to store the object.
 * @param fallback Object to return if the key is unset or undefined.
 * @returns The saved object or fallback if it does not exist.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getSessionItem(key: string, fallback?: any): Promise<any> {
    const storageKey = await getSessionStorageKey(key);
    if (sessionStorage.getItem(storageKey) === null) {
        return fallback;
    } else {
        try {
            return JSON.parse(sessionStorage.getItem(storageKey));
        } catch {
            return fallback;
        }
    }
}

/**
 * Read an object from the localStorage
 *
 * @param key -he key, used to store the object.
 * @param fallback Object to return if the key is unset or undefined.
 * @returns The saved object or fallback if it does not exist.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function getLocalItem(key: string, fallback?: any): Promise<any> {
    const storageKey = await getLocalStorageKey(key);
    if (localStorage.getItem(storageKey) === null) {
        return fallback;
    } else {
        try {
            return JSON.parse(localStorage.getItem(storageKey));
        } catch {
            return fallback;
        }
    }
}

/**
 * Remove all known entries from the sessionStorage.
 *
 * @returns True if the values were removed (or it was unnecessary).
 */
export async function cleanupSessionStorage(): Promise<boolean> {
    try {

        // remove all known keys
        const storageKeys = await getSessionStorageKeys();
        await Promise.all(storageKeys.map(key => removeSessionItem(key)));

        // remove the key list itself
        const keysKey = await getSessionStorageKey("keys", "internal");
        sessionStorage.removeItem(keysKey);
        return true;

    } catch (e) {
        return false;
    }
}

/**
 * Remove all known entries from the localStorage.
 *
 * @returns True if the values were removed (or it was unnecessary).
 */
export async function cleanupLocalStorage(): Promise<boolean> {
    try {

        // remove all known keys
        const storageKeys = await getLocalStorageKeys();
        await Promise.all(storageKeys.map(key => removeLocalItem(key)));

        // remove the key list itself
        const keysKey = await getLocalStorageKey("keys", "internal");
        localStorage.removeItem(keysKey);
        return true;

    } catch (e) {
        return false;
    }
}

/**
 * Remove all known entries from the all client site storage.
 *
 * @returns True if everything was removed (or it was unnecessary).
 */
export async function cleanup(): Promise<boolean> {
    return await cleanupSessionStorage() && await cleanupLocalStorage();
}
