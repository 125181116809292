import {ObservableArray} from "knockout";
import * as ko from "knockout";
import {Observable} from "knockout";
import {KnockoutPopup} from "../lib/popups";
import template from "./setTankAgeLevel.html";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import "./tankDetails.scss";


interface TankAgeLevelParams {
    tankId: number;
    eventTarget?: HTMLElement;
    reloadCallback?: (tankId: number) => void;
}

interface TankAgeLevelSeed {
    tank_age_levels: {
        label: string;
        name: string;
    }[];
}

class SetTankAgeLevelViewModel {

    private readonly dialog: KnockoutPopup;

    // params
    public tankId: number;
    public reloadCallback: (tankId: number) => void;

    // state
    public submitInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    public ageLevel: Observable<string>;
    public seed: FetchExtended<Observable<TankAgeLevelSeed>>;

    public submit = () => {
        this.errors([]);
        this.submitInProgress(true);

        const form = getFormData({
            request: JSON.stringify({
                "set_tank_age_level": {
                    "tank_id": this.tankId,
                    "age_level": this.ageLevel() || null,
                },
            }),
        });
        fetch("tank_list.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);

                if (response.success == true) {
                    this.dialog.close();

                    // reload tank list after submit
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback(this.tankId);
                    }
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.errors.push(getTranslation("General error."));
            });
    };

    constructor(params: TankAgeLevelParams, dialog: KnockoutPopup) {

        /* params */
        this.dialog = dialog;
        this.tankId = params.tankId;
        this.reloadCallback = params.reloadCallback;

        /* dialog */
        this.dialog.setTitle(getTranslation("Set age level"));

        /* observables */
        this.ageLevel = ko.observable();
        this.errors = ko.observableArray([]);
        this.submitInProgress = ko.observable(false);

        // load filter content
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch("tank_list.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify(["get_tank_age_levels"]),
                    }),
                    signal,
                });
            },
        });

    }
}


/**
 * Pop dialog for setting the age level ('adult', 'juvenile', 'larva') of all animals in the given cage
 */
export const showSetTankAgeLevel = dialogStarter(SetTankAgeLevelViewModel, template, params => ({
    name: "SetTankAgeLevel",
    width: 400,
    anchor: params.eventTarget,
    closeOthers: true,
    cssARequire: [
        ":table.css",
        ":popup.css",
        ":tab_menu.css",
    ],
}));
