import * as ko from "knockout";
import * as _ from "lodash";

declare module "knockout" {
    export interface ExtendersOptions {
        trim?: boolean;
    }
}

/**
 * Trim the value of target.
 *
 * Same as `.extend({normalize: function(v) { return v && $.trim(v); }})`
 * Takes no argument, use as follows:
 *     target.extend({trim: true});
 */
ko.extenders.trim = function (target) {
    return target.extend({
        normalize: function (v) {
            return v && _.trim(v);
        },
    });
};
