/**
 * Show a pop-up to pick positions on a microwell plate.
 *
 * @param onSubmit: Function to call with the selection as first parameter, for when it is applied.
 *
 */

import { ObservableArray } from "knockout";
import { PureComputed } from "knockout";
import * as ko from "knockout";
import { Observable } from "knockout";
import * as _ from "lodash";
import { dialogStarter } from "../knockout/dialogStarter";
import { AnimalOrPup } from "../lib/subjectCollection";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { getFormData } from "../lib/utils";
import { AjaxResponse } from "../lib/utils";
import template from "./microwellPlatePicker.html";

interface MicrowellPlatePostionPickerParams {
    wellplateId: number;
    subjects: AnimalOrPup[];
    onSubmit?: (positions: ({ position: string } & AnimalOrPup)[]) => void;
}

interface Seed {
    id: number;
    code: string;
    reference: string;
    columns: number;
    rows: number;
    all_positions: string[];
    ordered_positions: string[];
    available_positions: string[];
    subjects: ({ eartag_or_id: string } & AnimalOrPup)[];
}

interface PositionAssignment {
    subject: { eartag_or_id: string } & AnimalOrPup;
    position: Observable<string>;
}

class MicrowellPlatePostionPickerViewModel {
    private params: MicrowellPlatePostionPickerParams;
    private dialog: KnockoutPopup;
    private fetchInProgress: Observable<boolean>;
    private seed: Observable<Seed | undefined>;
    private assignment: ObservableArray<PositionAssignment>;
    private nextCandidate: PureComputed<PositionAssignment | null>;
    private error: Observable<string>;

    constructor(params: MicrowellPlatePostionPickerParams, dialog: KnockoutPopup) {
        this.params = params;
        this.dialog = dialog;
        this.error = ko.observable("");

        this.assignment = ko.observableArray();
        this.nextCandidate = ko.pureComputed(() => {
            for (const row of this.assignment()) {
                if (!row.position()) {
                    return row;
                }
            }
            return null;
        });
        this.seed = ko.observable();
        this.fetchInProgress = ko.observable(true);
        fetch("microwell_plate_list.py", {
            method: "POST",
            body: getFormData({
                action: "get_picker_options",
                wellplate_id: params.wellplateId,
                subjects: JSON.stringify(params.subjects),
            }),
        })
            .then((response) => response.json())
            .then((response: AjaxResponse<Seed>) => {
                if (response.success === true) {
                    this.seed(response);
                } else {
                    this.error(response.message);
                }
            })
            .finally(() => this.fetchInProgress(false));

        this.seed.subscribe((v) => {
            this.assignment(
                v.subjects.map((subject) => ({
                    subject: subject,
                    position: ko.observable(""),
                }))
            );
            if (v.reference) {
                dialog.setTitle(_.template(getTranslation("Select position on <%- code %> (<%- reference %>)"))(v));
            } else {
                dialog.setTitle(_.template(getTranslation("Select position on <%- code %>"))(v));
            }
            dialog.reposition();
        });
    }

    private isInUse = (position: string) => {
        for (const row of this.assignment()) {
            if (row.position() == position) {
                return true;
            }
        }
        return !this.seed().available_positions.includes(position);
    };

    private usedPositions = () => {
        return this.seed()?.all_positions?.filter((position) => this.isInUse(position));
    };

    private isNextCandidate = (positionAssignment: PositionAssignment) => {
        const next = this.nextCandidate();
        if (next) {
            return next.subject == positionAssignment.subject;
        }
    };

    private onClickPosition = (clickedPosition: string) => {
        const next = this.nextCandidate();
        if (next) {
            next.position(clickedPosition);
        }
    };

    /** Fill in the remaining positions with the next available positions.
     *
     * After the "next available positions" definition is a bit complicated,
     * we apply kind of magic here.
     *
     * The idea is to always fill in the positions in the order they are
     * defined in the wellplate attributes. The question is, where to start?
     *
     * 1. If the user assigned any position already, we assume that it
     *    has a priority order, and we start with the next position after
     *    highest assigned one.
     *
     * 2. Otherwise, we start with the next position after any used or
     *    omitted position. This fills the wellplate as densely as possible.
     *
     **/
    private fill = (omit = 0) => {

        let remainingPositions: string[] = [];

        // get all positions assigned already
        const usedPositions = this.usedPositions();

        // are any positions already assigned?
        const anyAssigned = this.assignment().some((a) => a.position());

        // if so, we start there
        if (anyAssigned) {

            // get the maximum index the used assigned positions have in the available positions
            const maxAssignedIndex = usedPositions.reduce((max, p) => {
                const index = this.seed().available_positions.indexOf(p) + 1;
                return index > max ? index : max;
            }, 0);

            // use all positions after the assigned positions
            remainingPositions = this.seed().available_positions.slice(maxAssignedIndex);

        // if not, we start at the position not used or omitted
        } else {

            // get the maximum index the used positions have in the ordered positions
            const maxOrderedIndex = usedPositions.reduce((max, p) => {
                const index = this.seed().ordered_positions.indexOf(p) + 1;
                return index > max ? index : max;
            }, 0);

            // find positions that are used or omitted
            const wastedPositions = this.seed().ordered_positions.slice(0, maxOrderedIndex);

            // use all available positions after used and omitted positions
            remainingPositions = this.seed().available_positions.filter((p) => !wastedPositions.includes(p));
        }

        // remove the first skip positions
        for (let i = 0; i < omit; i++) {
            remainingPositions.shift();
        }

        // fill in the remaining positions
        for (const row of this.assignment()) {
            if (!row.position() && remainingPositions.length) {
                row.position(remainingPositions.shift());
            }
        }
    };

    private canApply = () => {
        return (
            this.assignment().length &&
            this.assignment().every((assignment) => this.seed().available_positions.includes(assignment.position()))
        );
    };

    private apply = () => {
        const postions = this.assignment().map((a) => ({ position: a.position(), ...a.subject }));
        if (typeof this.params.onSubmit === "function") {
            this.params.onSubmit(postions);
        }
        this.dialog.close();
    };
}

export const showMicrowellPlatePositionPicker = dialogStarter(MicrowellPlatePostionPickerViewModel, template, {
    name: "MicrowellPlatePicker",
    width: 450,
    closeOthers: true,
    escalate: false,
    title: getTranslation("Select position"),
});
