import * as ko from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import {frames} from "../lib/pyratTop";
import pupupTemplate from "./scheduledReportDetails.html";
import {defer, result, size, template} from "lodash";

interface Params {
    reportId: number | null;
}

interface Report {
    module_name: string;
    class_name: string;
    description: string;
}

interface Seed {
    report_name: string;
    repeat: string;
    scheduled_run: string;
    available_reports: Report[];
    available_repetitions: string[];
}

class ScheduledReportDetailViewModel {

    public reportId: number;
    private readonly dialog: KnockoutPopup;

    private seed: FetchExtended<ko.Observable<Seed>>;
    private errors: ko.ObservableArray<string>;
    private reportName: ko.Observable<string>;
    private selectedReport: ko.Observable<Report>;
    private reportRepetition: ko.Observable<string>;
    private scheduledRun: CheckExtended<ko.Observable<string>>;
    private deleteReport: ko.Observable<boolean>;
    private inProgress: ko.Observable<boolean>;

    constructor({reportId}: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.reportId = reportId;

        this.inProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
        this.reportName = ko.observable();
        this.selectedReport = ko.observable();
        this.reportRepetition = ko.observable();
        this.scheduledRun = ko.observable().extend({
            invalid: (v) => !!(size(v) && isInvalidCalendarDate(v)),
        });
        this.deleteReport = ko.observable(false);

        if (reportId === null) {
            dialog.setTitle(getTranslation("New scheduled report"));
        } else {
            dialog.setTitle(template(getTranslation("Scheduled report #<%- reportId %>"))(this));
        }

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch("scheduled_reports.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            report_details: reportId,
                            available_reports: true,
                            available_repetitions: true,
                        }),
                    }),
                    signal,
                });
            },
        });

        // set default values
        this.seed.subscribe((seed) => {
            const details = result(seed, "report_details");
            if (details) {
                defer(() => {
                    this.reportName(result(details, "report_name"));
                    this.reportRepetition(result(details, "repeat"));
                    this.scheduledRun(result(details, "scheduled_run"));
                });
            }
        });



    }

    private canSubmit = () => {
        if (this.reportId === null) {
            if (!this.selectedReport()) {
                return false;
            }
        }
        if (this.scheduledRun.isInvalid()) {
            return false;
        }
        return true;
    };

    private submitForm = () => {

        this.errors.removeAll();
        this.inProgress(true);

        let request: Promise<Response>;
        if (this.reportId === null) {
            request = fetch("scheduled_reports.py", {
                method: "POST",
                body: getFormData({
                    request: JSON.stringify({
                        schedule_report: {
                            report_module: this.selectedReport().module_name,
                            report_class: this.selectedReport().class_name,
                            scheduled_run: this.scheduledRun() || null,
                            repeat: this.reportRepetition() || null,
                        },
                    }),
                }),
            });
        } else if (this.deleteReport()) {
            request = fetch("scheduled_reports.py", {
                method: "POST",
                body: getFormData({
                    request: JSON.stringify({
                        delete_report: {
                            report_id: this.reportId,
                        },
                    }),
                }),
            });
        } else if (this.reportId) {
            request = fetch("scheduled_reports.py", {
                method: "POST",
                body: getFormData({
                    request: JSON.stringify({
                        update_report: {
                            report_id: this.reportId,
                            report_name: this.reportName(),
                            scheduled_run: this.scheduledRun() || null,
                            repeat: this.reportRepetition() || null,
                        },
                    }),
                }),
            });
        }

        if (request) {
            request
                .then((response) => response.json())
                .then((response: AjaxResponse<{schedule_report: number; update_report: boolean; delete_report: boolean}>) => {
                    if (response.success == true) {
                        this.dialog.close();
                        frames.reloadListIframe();
                    } else {
                        this.errors.push(response.message);
                    }
                })
                .catch(() => {
                    this.errors.push(getTranslation("General unexpected error occurred."));
                })
                .finally(() => {
                    this.inProgress(false);
                });
        }
    };
}

export const showScheduledReportDetails = dialogStarter(ScheduledReportDetailViewModel, pupupTemplate, {
    name: "ScheduledReportDetails",
    width: 400,
    cssARequire: [":popup.css"],
});
