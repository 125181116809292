import {fromError, StackFrame} from "stacktrace-js";
import * as URI from "urijs";
import {setSessionItem} from "./browserStorage";
import {getSessionItem} from "./browserStorage";
import {session} from "./pyratSession";
import {getFormData} from "./utils";

let reportCounter = 0;

export const raiseTestException = () => {
    throw new Error("Test exception");
};

export class ExceptHook {

    /**
     * Get the current date time in ISO 8601 format.
     *
     * Try to to use the browser function for that, with accurate time zone
     * information, but fall back to a simplified version.
     *
     * @returns The current date and time as string.
     */
    private static getISODateString(): string {

        const date = new Date();

        function padZero(n: number) {
            return n < 10 ? "0" + n : n;
        }

        try {
            return date.toISOString();
        } catch (e) {
            return date.getUTCFullYear() + "-"
                + padZero(date.getUTCMonth() + 1) + "-"
                + padZero(date.getUTCDate()) + "T"
                + padZero(date.getUTCHours()) + ":"
                + padZero(date.getUTCMinutes()) + ":"
                + padZero(date.getUTCSeconds()) + "Z";
        }

    }

    /**
     * Get all attributes of the dom element as object.
     *
     * Example:
     *      > attributesObject($('<td colspan=2>Foo</td>').get(0))
     *      < Object {colspan: "2"}
     *
     * @param element The element to inspect.
     * @returns An object, containing the elements attributes.
     */
    private static attributesObject(element: Element) {
        const obj: { [key: string]: any } = {};
        const attributes = element.attributes;

        for (const index in attributes) {
            if (Object.prototype.hasOwnProperty.call(attributes, index)) {
                const nodeName = attributes[index].nodeName;
                obj[nodeName] = attributes[index].nodeValue;
            }
        }
        return obj;
    }

    /**
     * Split url GET parameters into an object.
     *
     * @param w Window to the the parameter from.
     * @returns Found GET parameters.
     */
    private static locationSearchParameters(w: Window) {
        const parametersItems = w.location.search.substr(1).split("&");
        const parameters: { [key: string]: any } = {};

        for (let index = 0; index < parametersItems.length; index++) {
            const tuple = parametersItems[index].split("=");
            const key = decodeURIComponent(tuple[0]);
            const value = decodeURIComponent(tuple.slice(1).join("="));
            if (key.length) {
                parameters[key] = value;
            }
        }

        return parameters;
    }

    /**
     * Get all input/select element information from window.document.
     *
     * @param w Window to the the parameter from.
     * @returns Fount inputs an their content.
     */
    private static formsDetails(w: Window) {

        const inputAttributesByName = function (w: Window, elements: HTMLCollectionOf<HTMLButtonElement | HTMLInputElement | HTMLSelectElement>) {
            const forms: { [key: string]: { [key: string]: any }[] } = {};

            function formName(e: HTMLButtonElement | HTMLInputElement | HTMLSelectElement) {
                if (e && e.form) {
                    return e.form.getAttribute("action") + " (" + e.form.getAttribute("name") + ")";
                }
            }

            function selectedOption(e: HTMLSelectElement) {
                return e.options[e.selectedIndex].value || e.options[e.selectedIndex].text;
            }

            for (let index = 0; index < elements.length; index++) {
                const e = elements[index];
                const name = formName(e);
                if (!forms[name]) {
                    forms[name] = [];
                }
                forms[name].push({
                    node: e.nodeName,
                    attributes: ExceptHook.attributesObject(e),
                    selected: e instanceof HTMLSelectElement ? selectedOption(e) : undefined,
                });
            }

            return forms;
        };

        return Object.assign({},
            inputAttributesByName(w, w.document.getElementsByTagName("input")),
            inputAttributesByName(w, w.document.getElementsByTagName("select")),
            inputAttributesByName(w, w.document.getElementsByTagName("button")));
    }

    /**
     * Get all possible frame details from window (including sub frames).
     *
     * @param w Window to the the parameter from.
     * @returns Found frames and their details.
     */
    private static inspectFrames(w: Window) {
        const frameList: { [key: number]: any } = {};

        const frameDetails = (w: Window) => ({
            name: w.name,
            href: w.location && w.location.href,
            parameters: w.location && ExceptHook.locationSearchParameters(w),
            forms: w.document && ExceptHook.formsDetails(w),
            frames: w.frames && w.frames.length ? ExceptHook.inspectFrames(w) : [],
        });

        for (let i = 0; i < w.frames.length; i++) {
            if (w.frames[i].location) {
                if (!(w.frames[i].location.pathname.endsWith("/static/empty.html"))
                    && !(w.frames[i].location.href === "about:blank")) {
                    try {
                        frameList[i] = frameDetails(w.frames[i]);
                    } catch (e) {
                        // pass
                    }
                }
            }
        }
        return frameList;
    }

    /**
     * Get all possible information from the current browser window.
     *
     * @returns Object, with all possible information.
     */
    private static sampleEnvironment() {
        return {
            // @ts-expect-error: The value of frameInTop is set through the templater.
            frameInTop: Boolean(window.frameInTop),
            frames: ExceptHook.inspectFrames(window.top),
        };
    }

    /**
     * Event handler to write user interactions to the sessionStorage
     * @param event Default Javascript event object.
     */
    private static eventHandler(event: MouseEvent | KeyboardEvent) {

        const target = event.target as HTMLInputElement;
        const action = {
            event: event.type,
            time: ExceptHook.getISODateString(),
            node: target.nodeName,
            text: String(target.textContent || target.value).trim(),
            attributes: ExceptHook.attributesObject(target),
        };

        getSessionItem("history", []).then(value => {
            setSessionItem("history", value.slice(-9).concat(action));
        });

    }

    /**
     * Register event handlers to write user interactions to a local session storage object.
     */
    public registerInteractionTracker(): void {
        document.addEventListener("click", (event) => {
            return ExceptHook.eventHandler(event);
        }, true);
        document.addEventListener("keydown", (event) => {
            if (event.key === "Enter") return ExceptHook.eventHandler(event);
        }, true);
    }

    /**
     * Register event handlers to send exceptions to the server.
     */
    public registerOnErrorHandler(): void {
        // post the js errors to cgi script where it will be logged.
        window.onerror = (msg,
                          url,
                          line,
                          col,
                          err) => {

            // do not kill the server with too many messages
            if (reportCounter >= 3) return undefined;

            if (!url || !URI(url).is("absolute")) {
                // Exception thrown in global scope
                return undefined;
            }

            if (!err || !err.stack) {
                // Exceptions without stack are almost impossible
                // to resolve and actually exist only more in Internet Explorer.
                return undefined;
            }

            // resolve .map files
            fromError(err).then((stack: StackFrame[]) => {
                const rootUrl = URI("../").absoluteTo(window.location.href).toString();
                getSessionItem("history").then(history => {
                    // noinspection JSIgnoredPromiseFromCall
                    fetch("exceptapi.py", {method: "POST", body: getFormData({
                        data: JSON.stringify({
                            JavaScriptError: {
                                msg: msg,
                                url: url,
                                line: line,
                                col: col,
                                err: err,
                                stack: stack
                                    .join("\n")
                                    .replaceAll(/(\/[^/]*)_cache\/[^/]+/g, "$1")
                                    .replaceAll(rootUrl, "")
                                    .replaceAll(session?.sessionId, "X"),
                                relative_path: URI(url.replaceAll(/(\/[^/]*)_cache\/[^/]+/g, "$1")).relativeTo(rootUrl).toString(),
                                sessionid: session?.sessionId,
                                location: location.href,
                                environment: ExceptHook.sampleEnvironment(),
                                history: history,
                                agent: window.navigator.userAgent,
                            },
                        }),
                    })});
                });

            });

            reportCounter++;

        };
    }

}
