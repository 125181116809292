/**
 * Show a popup to set the strain for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import * as _ from "lodash";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setStrain.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    strain_id: number;
    species_name: string;
    show_set_species_to_strain: boolean;
    father_with_pups: boolean;
    strains: {
        id: number;
        name_with_id: string;
        species_id: number;
    }[];
}

class SetStrainViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly strainId: CheckExtended<Observable<number>>;
    private readonly showSpeciesToStrain: ko.PureComputed<boolean>;
    private readonly setSpeciesToStrain: Observable<boolean>;
    private readonly keepMutation: Observable<boolean>;
    private readonly changePupStrain: Observable<boolean>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorMessages: ko.PureComputed<string[]>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.strainId = ko.observable().extend({
            invalid: (v) => {
                return !v;
            },
        });

        // show 'assign species to strain' checkbox if a strain without species was selected
        this.showSpeciesToStrain = ko.pureComputed(() => {
            const strain = _.find(this.seed().strains, {id: this.strainId()});
            return this.seed().show_set_species_to_strain && strain && !strain.species_id;
        });

        this.setSpeciesToStrain = ko.observable();
        this.keepMutation = ko.observable();
        this.changePupStrain= ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl("set_strain.py", {animal_id: this.animalId}), {signal});
                } else if (this.pupId) {
                    return fetch(getUrl("set_strain.py", {pup_id: this.pupId}), {signal});
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                setTimeout(() => {
                    this.strainId(seed.strain_id);
                }, 0);
            }
        });

        this.errorMessages = ko.pureComputed(() => {
            const res = _.compact([
                this.strainId.errorMessage(),
            ]);

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress() ||
                     this.seed().strains.length === 0 ||
                     this.strainId.isInvalid());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            strain_id: this.strainId(),
            set_species_to_strain: this.setSpeciesToStrain() ? 1 : 0,
            keep_mutation: this.keepMutation() ? 1 : 0,
            change_pup_strain: this.changePupStrain() ? 1 : 0,
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch("set_strain.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetStrain = dialogStarter(SetStrainViewModel, template, params => ({
    name: "SetStrain",
    width: 420,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: params.title,
}));
