/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Version } from '../models/Version';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SystemService {

    /**
     * Get Version
     * Get the current PyRAT version.
     * @returns Version Successful Response
     * @throws ApiError
     */
    public static getVersion(): CancelablePromise<Version> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/system/version',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
