// import flatpickr
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "./flatpickr.scss";
import {FlatpickrFn} from "flatpickr/dist/types/instance";
import {key as FlatpickrLocaleKey} from "flatpickr/dist/types/locale";
import {Options as FlatpickrOptions} from "flatpickr/dist/types/options";

// import flatpickr languages
import "flatpickr/dist/l10n/default.js"; // = en
import "flatpickr/dist/l10n/de.js";
import "flatpickr/dist/l10n/es.js";
import "flatpickr/dist/l10n/fr.js";
import "flatpickr/dist/l10n/it.js";
import "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/l10n/sv.js";
import "flatpickr/dist/l10n/he.js";

// @ts-expect-error: The plugin has a bad module structure.
import * as ShortcutButtonsPlugin from "shortcut-buttons-flatpickr/dist/shortcut-buttons-flatpickr";
import "shortcut-buttons-flatpickr/dist/themes/light.css";

import {getTranslation} from "./localize";
import {ArrayElement} from "./utils";
import {getElementWindow} from "./utils";

export type LocaleKey = ("en" | "de" | "es" | "fr" | "it" | "pt" | "sv") & FlatpickrLocaleKey;

// Obtain defaults from top
if (window !== window.top) {
    // @ts-expect-error: added with loading the flatpickr module
    const topFlatpickr = window.top?.flatpickr as FlatpickrFn;
    if (topFlatpickr) {
        flatpickr.setDefaults(topFlatpickr.defaultConfig as FlatpickrOptions);
    }
}

export const localize = (localeKey: LocaleKey, dateFormat: string, firstDayOfWeek: number) => {
    const locale = flatpickr.l10ns[localeKey];
    locale.firstDayOfWeek = firstDayOfWeek;
    flatpickr.setDefaults({
        locale,
        dateFormat,
        weekNumbers: true,
    });
};

export interface DatepickerOptions {

    // PyRAT custom options
    buttons?: {
        label: string | (() => string);
        onClick: (fp: flatpickr.Instance) => void;
        attributes?: {
            class?: string;
            "aria-label"?: string;
        };
    }[];
    isoFormat?: boolean;

    // Passthrough option from the picker
    allowInput?: FlatpickrOptions["allowInput"];
    defaultDate?: FlatpickrOptions["defaultDate"];
    enableTime?: FlatpickrOptions["enableTime"];
    maxDate?: FlatpickrOptions["maxDate"];
    minDate?: FlatpickrOptions["minDate"];
    onValueUpdate?: FlatpickrOptions["onValueUpdate"];
    positionElement?: FlatpickrOptions["positionElement"];

}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace buttons {

    export const today: ArrayElement<DatepickerOptions["buttons"]> = {
        label: () => getTranslation("Today"),
        onClick: (fp) => {
            fp.setDate((new Date()).toISOString(), true);
            fp.close();
        },
    };

    export const close: ArrayElement<DatepickerOptions["buttons"]> = {
        label: () => getTranslation("Close"),
        onClick: (fp) => fp.close(),
        attributes: {
            class: "flatpickr-button-right",
        },
    };

    export const constant: (value: string) => ArrayElement<DatepickerOptions["buttons"]> = (v) => ({
        label: v,
        onClick: (fp) => {
            fp.setDate(v, true);
            fp.close();
        },
    });
}

/** Workaround for limitations by frames.
 *
 * Normally, flatpickr would establish similar (probably more precise) event
 * listeners. The frames in PyRAT requre us to append the flatpickr HTML to a
 * frame window sometimes - and in this case the event listeners would be in
 * the wrong window.
 */
const addCloseOnDocumentClick = (dates: Date[], currentDateString: string, picker: flatpickr.Instance) => {

    const hidePicker = (e: MouseEvent) => {
        if (picker.isOpen && !picker.config.inline) {
            const eventTarget = e.target as HTMLElement;
            if (eventTarget !== picker.input &&
                eventTarget !== picker.element &&
                !picker.calendarContainer.contains(eventTarget) &&
                !picker.element.contains(eventTarget)) {
                picker.close();
            }
        }
    };

    const elementWindow = getElementWindow(picker.element);
    elementWindow.document.addEventListener("focus", hidePicker);
    elementWindow.document.addEventListener("touchstart", hidePicker);
    elementWindow.document.addEventListener("mousedown", hidePicker);
    elementWindow.document.addEventListener("keyup", hidePicker);
};

export const datepicker = (element: HTMLInputElement, options: DatepickerOptions = {}): flatpickr.Instance => {

    const {buttons, isoFormat, ...flatpickrOptions}: DatepickerOptions & Partial<FlatpickrOptions> = options;

    flatpickrOptions["plugins"] = [];
    flatpickrOptions["appendTo"] = getElementWindow(element).document.body;
    flatpickrOptions["onReady"] = addCloseOnDocumentClick;

    if (buttons) {
        const ButtonPluginOptions = {
            button: buttons.map((b) => ({
                label: typeof b.label === "function" ? b.label() : b.label,
                attributes: b.attributes,
            })),
            onClick: (index: number, fp: flatpickr.Instance) => {
               buttons[index].onClick(fp);
            },
        };
        flatpickrOptions["plugins"].push(ShortcutButtonsPlugin(ButtonPluginOptions));
    }

    if (isoFormat) {
        flatpickrOptions["altInput"] = true;
        flatpickrOptions["altFormat"] = flatpickr.defaultConfig.dateFormat;
        flatpickrOptions["dateFormat"] = "Y-m-d\\TH:i:S";  // "Z" would also work, but is time-zone aware
    }

    let picker = flatpickr(element, flatpickrOptions);
    if (Array.isArray(picker) && picker.length == 0) {
        // @ts-expect-error: flatpickr returns an empty array if the element is not found / not accessible
        picker = getElementWindow(element).flatpickr(element, flatpickrOptions);
    }
    return picker;
};

/**
 * Parse a date string, matching the locale settings date format, to a real Date object.
 */
export const parseDate = (value: string): Date => {
    return flatpickr.parseDate(value, flatpickr.defaultConfig.dateFormat);
};

/**
 * Format a Date object to a string, matching the locale settings date format.
 */
export const formatDate = (value: Date): string => {
    return flatpickr.formatDate(value, flatpickr.defaultConfig.dateFormat);
};

/**
 * Get global date format converted for MomentJs.
 */
export const getMomentJsDateFormat = (): string => {
    return flatpickr.defaultConfig.dateFormat
        .replace("Y", "YYYY")
        .replace("y", "YY")
        .replace("m", "MM")
        .replace("d", "DD")
        .replace("H", "HH")
        .replace("i", "mm")
    ;
};
