import * as ko from "knockout";
import {PureComputed} from "knockout";
import {Subscribable} from "knockout";
import {Computed} from "knockout";
import {ObservableArray} from "knockout";
import {components} from "knockout";
import {Observable} from "knockout";
import {getUrl} from "../../lib/utils";
import {getFormData} from "../../lib/utils";
import template from "./documents.html";
import {getTranslation} from "../../lib/localize";
import {AjaxResponse} from "../../lib/utils";
import {getReadableByteSizeObject, ReadableByteSize} from "../../lib/utils";
import "./documents.scss";

interface Subjects {
    [key: string]: number[];
}

export interface DocumentsWidgetSeed {
    subjects: Subjects;
    documents: {
        id: number;
        name: string;
        size: number;
        can_delete: boolean;
        locale_upload_date_str: string;
        locale_upload_datetime_str: string;
    }[];
}

type FileStates = "unsent" | "uploading" | "uploaded" | "aborted" | "error" | "removed";

interface DocumentFile {
    file: File;
    name: typeof File.name;
    isNewDocument: boolean;
    pendingAction: Observable<boolean>;
    documentId: Observable<number>;
    humanSize: ReadableByteSize;
    state: Observable<FileStates>;
    stateText: ko.Observable<string>;
    uploadProgress: ko.Observable<number>;
    uploadAbort?: () => void;
    canAbortUpload?: Subscribable<boolean>;
    canDeleteFile?: Subscribable<boolean>;
    downloadHref?: Subscribable<string>;
}

type reSeedCallback = () => void;

interface DocumentsParams {
    enableUploading: Observable<boolean>;
    enableDeleting: Observable<boolean>;
    multipleFiles: Observable<boolean>;
    hideExistingDocuments: Observable<boolean>;
    onSubjectsGetSeed: Observable<boolean>;
    seed: DocumentsWidgetSeed;
    reSeed: reSeedCallback;
    resizeTrigger: Observable;
    existingDocumentIds: Observable<number[]>;
    newDocumentIds: Observable<number[]>;
    documentIds: Observable<number[]>;
    uploadsInProgress: Observable<number>;
    pendingAction: Observable<boolean>;
}


/**
 * ViewModel
 *
 * 'in' and 'out' are indicating the direction the passed observables are bound. 'out' ones can't be
 * changed from outside. see gluedObservable in knockout_custom_extend
 *
 * @param params.enableUploading
 *      in, default: true, enable or disable the possibility to upload new files
 * @param.enableDeleting
 *      in, default: true, enable or disable the possibility to delete documents
 * @param.multipleFiles
 *      in, default: true, upload multiple files vs. one file
 *      TODO: if disabled, it is still possible to upload a single file multiple times
 * @param.hideExistingDocuments
 *      in, default: false, if true, the widget does not show existing documents fur passed subjects - only newly uploaded ones
 * @param.seed
 *      in, the seed for te component (from lib.document.get_widget_seed),
 *      to upload documents that are not attached to anything, pass undefined or do not pass seed at all
 * @param.reSeed
 *      in, default: null, if a function is passed in, it will be called every time a document was successful added/deleted
 * @param.onSubjectsGetSeed
 *      in, default: true, when subjects changes, fetch new seed
 * @param.resizeTrigger
 *      out, default: <triggerOnly>, that observable will be triggered when a new files was added and
 *      the outer frame/window should update the height
 * @param.existingDocumentIds
 *      out, default: [], observable with a list of documentIds that where already assigned to subjects
 *      when the widget was loaded
 * @param.newDocumentIds
 *      out, default: [], observable with a list of documentIds that where newly uploaded by this widget
 * @param.documentIds
 *      out, default: [], observable with a list of documentIds of existing and new documentIds
 * @param.uploadsInProgress
 *      out, default: 0, int of how many uploads are currently in progress
 * @param.pendingAction
 *      out, default: true, indication if the widget is currently doing something (like uploading/deleting documents)
 *      this should be used to disable the outer form
 */
class DocumentsViewModel {

    private onUploadFileUploadProgress = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        if (ev.lengthComputable) {
            file.uploadProgress(ev.loaded / ev.total);
        }
    };

    private onUploadFileUploadAbort = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("aborted");
        file.stateText(getTranslation("aborted"));
        file.pendingAction(false);
        this.queuedUpload();
    };

    private onUploadFileUploadError = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("error");
        file.stateText(getTranslation("Error while uploading document"));
        file.pendingAction(false);
        this.queuedUpload();
    };

    private onUploadFileUploadLoad = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("uploaded");
        this.queuedUpload();
    };

    private onUploadFileAbort = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("aborted");
        file.stateText(getTranslation("aborted"));
        file.pendingAction(false);
    };

    private onUploadFileError = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("error");
        file.stateText(getTranslation("Error while uploading document"));
        file.pendingAction(false);
    };

    private onUploadFileLoad = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        const xhr = ev.target as XMLHttpRequest;
        let data: AjaxResponse<{
            document_id: number;
        }>;

        file.pendingAction(false);

        try {
            data = JSON.parse(xhr.responseText);
        } catch (e) {
            // parsing error
            file.state("error");
            file.stateText(getTranslation("Error while uploading document"));
            return;
        }

        if (data.success === true) {
            file.documentId(data.document_id);
            file.stateText(getTranslation("OK"));
            this.callReSeed();
        } else {
            file.state("error");
            file.stateText(data.message);
        }
    };

    private onRemoveError = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        file.state("error");
        file.stateText(getTranslation("Error while deleting document"));
        file.pendingAction(false);
    };

    private onRemoveLoad = (ev: ProgressEvent<XMLHttpRequestEventTarget>, file: DocumentFile) => {
        const xhr = ev.target as XMLHttpRequest;
        let data: AjaxResponse<{
            document_id: number;
        }>;

        file.pendingAction(false);

        try {
            data = JSON.parse(xhr.responseText);
        } catch (e) {
            // parsing error
            file.state("error");
            file.stateText(getTranslation("Error while deleting document"));
            return;
        }

        if (data.success === true) {
            file.state("removed");
            file.stateText(getTranslation("removed"));
            file.documentId(null);
            this.callReSeed();
        } else {
            file.state("error");
            file.stateText(data.message);
        }
    };

    private uploadFile = (fileData: DocumentFile) => {
        const formData = getFormData({});
        const xhr = new XMLHttpRequest();

        fileData.state("uploading");
        fileData.pendingAction(true);
        fileData.uploadAbort = (() => {
            xhr.abort();
        });

        if (Object.keys(this.subjects()).length) {
            formData.append("subjects", JSON.stringify(this.subjects()));
        }
        formData.append("file", fileData.file);
        // transmitting file name separately because of IE11 and Edge.
        // Here in JS it is just the files basename. But on the Python end, it will be
        // the full path including "C:\"
        formData.append("name", fileData.name);

        // important: bind event listener before call xhr.open
        xhr.upload.addEventListener("progress", (ev) => this.onUploadFileUploadProgress(ev, fileData), false);
        xhr.upload.addEventListener("abort", (ev) => this.onUploadFileUploadAbort(ev, fileData), false);
        xhr.upload.addEventListener("error", (ev) => this.onUploadFileUploadError(ev, fileData), false);
        xhr.upload.addEventListener("load", (ev) => this.onUploadFileUploadLoad(ev, fileData), false);
        xhr.addEventListener("abort", (ev) => this.onUploadFileAbort(ev, fileData), false);
        xhr.addEventListener("error", (ev) => this.onUploadFileError(ev, fileData), false);
        xhr.addEventListener("load", (ev) => this.onUploadFileLoad(ev, fileData), false);
        xhr.open("POST", "document.py", true);
        xhr.send(formData);
    };

    private queuedUpload = () => {
        let i;
        let fileData;
        for (i = 0; i < this.files().length && this.uploadsInProgress() < 3; i++) {
            fileData = this.files()[i];
            if (fileData.state() === "unsent") {
                this.uploadFile(fileData);
            }
        }
    };

    private onFileDataStateChange = (newValue: FileStates) => {
        if (["unsent", "uploading", "uploaded", "aborted", "error", "removed"].indexOf(newValue) === -1) {
            throw Error("unknown state");
        }
    };

    private getHrefComputed = (fileData: DocumentFile): Computed | null => {
        return ko.pureComputed(() => {
            if (fileData.documentId()) {
                return getUrl("document.py", {id: fileData.documentId()});
            }
            return null;
        });
    };

    private addFile = (file: File) => {
        const fileData: DocumentFile = {
            file: file,
            name: file.name,
            isNewDocument: true,
            pendingAction: ko.observable(true),
            documentId: ko.observable(null),
            humanSize: getReadableByteSizeObject(file.size),
            state: ko.observable("unsent"), // possible values: unsent, uploading, uploaded, aborted, error, removed
            stateText: ko.observable(""),
            uploadProgress: ko.observable(0),
            uploadAbort: null,
        };

        fileData.state.subscribe(this.onFileDataStateChange);

        fileData.canAbortUpload = ko.pureComputed(() => {
            return fileData.state() === "uploading";
        });

        fileData.canDeleteFile = ko.pureComputed(() => {
            return Boolean(this.enableDeleting()
                && fileData.state() === "uploaded"
                && fileData.documentId() !== null);
        });

        fileData.downloadHref = this.getHrefComputed(fileData);
        this.files.push(fileData);
        this.sortFiles();
        this.queuedUpload();
        this.resizeTrigger.valueHasMutated();
    };

    private digestSeed = () => {
        this.files([]);
        if (this.seed().subjects) {
            this.subjects(this.seed().subjects);
        }
        if (this.seed().documents) {
            this.seed().documents.forEach((entry) => {
                const fileData: DocumentFile = {
                    file: null,
                    name: entry.name,
                    isNewDocument: false,
                    pendingAction: ko.observable(false),
                    documentId: ko.observable(entry.id),
                    humanSize: getReadableByteSizeObject(entry.size),
                    state: ko.observable("uploaded"),
                    stateText: ko.observable(entry.locale_upload_datetime_str),
                    uploadProgress: ko.observable(0),
                    uploadAbort: null,
                    canAbortUpload: ko.observable(false),
                };

                fileData.canDeleteFile = ko.pureComputed(() => {
                    return Boolean(this.enableDeleting()
                        && fileData.state() === "uploaded"
                        && entry.can_delete
                        && fileData.documentId() !== null);
                });

                fileData.state.subscribe(this.onFileDataStateChange);
                fileData.downloadHref = this.getHrefComputed(fileData);
                this.files.push(fileData);
            });
            this.sortFiles();
            this.resizeTrigger.valueHasMutated();
        }
    };

    private handleEventFiles = (files: FileList) => {
        for (let i = 0; i < files.length; i++) {
            this.addFile(files[i]);
        }
    };

    private stopEvent = (ev: JQuery.TriggeredEvent | Event) => {
        ev.stopPropagation();
        ev.preventDefault();
        if ("originalEvent" in ev) {
            this.stopEvent(ev.originalEvent);
        }
    };

    private sortFiles = () => {
        this.files.sort((a, b) => {
            const lowerA = a.name.toLowerCase();
            const lowerB = b.name.toLowerCase();

            return lowerA < lowerB ? -1 :
                lowerA > lowerB ? 1 :
                    0;
        });
    };

    private callReSeed = () => {
        if (typeof (this.reSeed()) === "function") {
            this.reSeed()();
        }
    };

    public subjects: Observable<Subjects>;
    public enableUploading: Observable<boolean>;
    public enableDeleting: Observable<boolean>;
    public multipleFiles: Observable<boolean>;
    public hideExistingDocuments: Observable<boolean>;
    public onSubjectsGetSeed: Observable<boolean>;
    public resizeTrigger: Observable<any>;
    public seed: Observable<DocumentsWidgetSeed>;
    public reSeed: Observable<reSeedCallback>;
    public files: ObservableArray<DocumentFile>;
    public filesToShow: PureComputed<DocumentFile[]>;
    public existingDocumentIds: PureComputed<number[]>;
    public newDocumentIds: PureComputed<number[]>;
    public documentIds: PureComputed<number[]>;
    public mouseOverDropZone: Observable<boolean>;
    public uploadsInProgress: PureComputed<number>;
    public pendingAction: PureComputed<boolean>;

    public onFileInputChange = (model: DocumentsViewModel, ev: JQuery.ChangeEvent) => {
        this.handleEventFiles(ev.target.files);
        // reset input element
        ev.target.value = "";
    };

    public onDragEnter = (model: DocumentsViewModel, ev: JQuery.DragEnterEvent) => {
        this.stopEvent(ev);
        this.mouseOverDropZone(true);
    };

    public onDragOver = (model: DocumentsViewModel, ev: JQuery.DragOverEvent) => {
        this.stopEvent(ev);
        this.mouseOverDropZone(true);
    };

    public onDragLeave = (model: DocumentsViewModel, ev: JQuery.DragLeaveEvent) => {
        this.stopEvent(ev);
        this.mouseOverDropZone(false);
    };

    public onDrop = (model: DocumentFile, ev: JQuery.DropEvent) => {
        this.stopEvent(ev);
        this.mouseOverDropZone(false);
        this.handleEventFiles(ev.originalEvent.dataTransfer.files);
    };

    public onRemove = (fileData: DocumentFile) => {
        if (fileData.canAbortUpload() && (typeof (fileData.uploadAbort) === "function")) {
            fileData.uploadAbort();
        } else if (fileData.canDeleteFile()) {
            const xhr = new XMLHttpRequest();

            const fd = getFormData({
                subjects: JSON.stringify(this.subjects()),
                id: String(fileData.documentId()),
                remove: "true",
            });

            xhr.addEventListener("error", (ev) => this.onRemoveError(ev, fileData), false);
            xhr.addEventListener("load", (ev) => this.onRemoveLoad(ev, fileData), false);

            // unset documentId to prevent double clicking on delete button
            fileData.documentId(null);
            fileData.pendingAction(true);

            xhr.open("POST", "document.py", true);
            xhr.send(fd);
        }
    };

    constructor(params: DocumentsParams) {

        this.subjects = ko.observable({});
        this.enableUploading = ko.utils.gluedObservable(params.enableUploading, true, "in");
        this.enableDeleting = ko.utils.gluedObservable(params.enableDeleting, true, "in");
        this.multipleFiles = ko.utils.gluedObservable(params.multipleFiles, true, "in");
        this.hideExistingDocuments = ko.utils.gluedObservable(params.hideExistingDocuments, false, "in");
        this.onSubjectsGetSeed = ko.utils.gluedObservable(params.onSubjectsGetSeed, true, "in");
        this.seed = ko.utils.gluedObservable(params.seed, null, "in");
        this.seed.subscribe(this.digestSeed);
        this.resizeTrigger = ko.utils.gluedObservable(params.resizeTrigger, null, "out", true);
        this.reSeed = ko.utils.gluedObservable(params.reSeed, null, "in");
        this.files = ko.observableArray();
        this.filesToShow = ko.pureComputed(() => {
            return this.files().filter((fileData) => {
                return this.hideExistingDocuments() === false || fileData.isNewDocument === true;
            });
        }).extend({deferred: true});
        this.existingDocumentIds = ko.utils.gluedObservable(params.existingDocumentIds, [], "out", false,
            ko.pureComputed(() => {
                return this.files().reduce((acc, fileData) => {
                    if (fileData.isNewDocument === false && fileData.documentId()) acc.push(fileData.documentId());
                    return acc;
                }, []);
            })
        );
        this.newDocumentIds = ko.utils.gluedObservable(params.newDocumentIds, [], "out", false,
            ko.pureComputed(() => {
                return this.files().reduce((acc, fileData) => {
                    if (fileData.isNewDocument === true && fileData.documentId()) acc.push(fileData.documentId());
                    return acc;
                }, []);
            })
        );
        this.documentIds = ko.utils.gluedObservable(params.documentIds, undefined, "out", false,
            ko.pureComputed(() => {
                return this.existingDocumentIds().concat(this.newDocumentIds());
            })
        );
        this.mouseOverDropZone = ko.observable(false);
        this.uploadsInProgress = ko.utils.gluedObservable(params.uploadsInProgress, 0, "out", false,
            ko.pureComputed(() => {
                return this.files().reduce((acc, file) => {
                    if (file.state() === "uploading") {
                        acc++;
                    }
                    return acc;
                }, 0);
            })
        );
        this.pendingAction = ko.utils.gluedObservable(params.pendingAction, true, "out", false,
            ko.pureComputed(() => {
                return this.files().some(fileData => fileData.pendingAction() === true);
            })
        );

        // init
        if (this.seed()) {
            this.digestSeed();
        }
    }
}


export class DocumentsComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: DocumentsParams, componentInfo: components.ComponentInfo) => {

                    // ensure the component is used through <ko-documents/> HTML element so we can use scoped styles
                    if (componentInfo.element.nodeName.toLowerCase() !== "ko-documents") {
                        throw new Error("Only use the documents component through <ko-documents/> HTML tag.");
                    }

                    return new DocumentsViewModel(params);
                },
            },
            template,
        };
    }
}
