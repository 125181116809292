// runs an array of async functions in sequential order
function seq(arr: any[], callback: () => void, index = 0) {
    // first call, without an index
    arr[index](() => {
        index++;
        if (index === arr.length) {
            callback();
        } else {
            seq(arr, callback, index);
        }
    });
}

function scriptsDone() {
    const DOMContentLoadedEvent = document.createEvent("Event");
    DOMContentLoadedEvent.initEvent("DOMContentLoaded", true, true);
    document.dispatchEvent(DOMContentLoadedEvent);
}

function insertScript(scriptElement: HTMLScriptElement, callback: () => void) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    if (scriptElement.src) {
        s.onload = callback;
        s.onerror = callback;
        s.src = scriptElement.src;
    } else {
        s.textContent = scriptElement.innerText;
    }

    // re-insert the script tag so it executes.
    document.head.appendChild(s);

    // clean-up
    scriptElement.parentNode.removeChild(scriptElement);

    // run the callback immediately for inline scripts
    if (!scriptElement.src) {
        callback();
    }
}


/** Execute the script tags in a htmlElement after inserting with innerHTML.
 *
 * This is required, because the if the content of an htmlElement is set via innerHTML,
 * the script tags are not executed for security reasons.
 *
 * This is roughly documented in the HTML5 spec:
 * > Note: script elements inserted using innerHTML do not execute when they are inserted.
 * (https://web.archive.org/web/20200821141651/https://www.w3.org/TR/2008/WD-html5-20080610/dom.html#innerhtml0)
 *
 * It is required to pick up all the script tags, virtually create them again as fully
 * qualified dom nodes, and insert them while waiting until they are executed.
 *
 * The alternative here is to use `jQuery().html()` what requires jQuery and also uses
 * deprecated synchronous requests to ensure the downloads of src= script tags are in
 * order (what throws a warning ever time in the browser).
 *
 * Details can be found here:
 *  - https://web.archive.org/web/20200821140024/https://ghinda.net/article/script-tags/
 *
 * @param htmlElement
 */
export function runScriptTags(htmlElement: HTMLElement): void {

// https://html.spec.whatwg.org/multipage/scripting.html
    const runScriptTypes = [
        "application/javascript",
        "application/ecmascript",
        "application/x-ecmascript",
        "application/x-javascript",
        "text/ecmascript",
        "text/javascript",
        "text/javascript1.0",
        "text/javascript1.1",
        "text/javascript1.2",
        "text/javascript1.3",
        "text/javascript1.4",
        "text/javascript1.5",
        "text/jscript",
        "text/livescript",
        "text/x-ecmascript",
        "text/x-javascript",
    ];

    // get scripts tags from a node
    const $scripts = htmlElement.querySelectorAll("script");
    const runList: ((callback: any) => void)[] = [];
    let typeAttr;

    [].forEach.call($scripts, (scriptElement: HTMLScriptElement) => {
        typeAttr = scriptElement.getAttribute("type");

        // only run script tags without the type attribute
        // or with a javascript mime attribute value
        if (!typeAttr || runScriptTypes.indexOf(typeAttr) !== -1) {
            runList.push((callback) => {
                insertScript(scriptElement, callback);
            });
        }
    });

    // insert the script tags sequentially
    // to preserve execution order
    seq(runList, scriptsDone);
}
