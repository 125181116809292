/**
 * Show a pop-up to export a microwellplate.
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import { observable, Observable, observableArray, ObservableArray, pureComputed, PureComputed } from "knockout";
import { dialogStarter } from "../knockout/dialogStarter";
import { getTranslation } from "../lib/localize";
import { KnockoutPopup } from "../lib/popups";
import { WellplateExportFormat, WellplateExportSeed, WellplatesService } from "../backend/v1";
import { FetchBackendExtended } from "../knockout/extensions/fetchBackend";
import template from "./microwellPlateExport.html";
import { getUrl } from "../lib/utils";

interface MicrowellPlateExportParams {
    wellplateId?: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

class MicrowellPlateExportViewModel {
    public readonly seed: FetchBackendExtended<Observable<WellplateExportSeed>>;
    public readonly canExport: PureComputed<boolean>;
    public WellplateExportFormat = WellplateExportFormat;
    public availableExportFormats: ObservableArray<WellplateExportFormat>;
    private readonly dialog: KnockoutPopup;
    private readonly setAsExported: Observable<boolean>;
    private readonly exportFormat: Observable<WellplateExportFormat>;
    private readonly exportInProgress: Observable<boolean>;
    private readonly reloadRequired: Observable<boolean>;

    private exportableWellplates: ObservableArray<
        WellplateExportSeed["exportable_wellplates"][0] & {
            selected: Observable<boolean>;
        }
    >;

    constructor(params: MicrowellPlateExportParams, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.reloadRequired = observable(false);
        this.exportInProgress = observable(false);

        this.setAsExported = observable(false);
        this.exportFormat = observable(WellplateExportFormat.STANDARD_CSV);
        this.exportFormat.extend({ localStorage: "well_plate_export_format" });
        this.exportableWellplates = observableArray([]);
        this.availableExportFormats = observableArray([]);

        /**
         * Get options for dialog (popup).
         */
        this.seed = observable().extend({
            fetchBackend: WellplatesService.getWellplatesExportSeed,
        });

        this.seed.subscribe((seed) => {
            this.availableExportFormats(seed.export_formats);
            this.exportableWellplates([]);
            for (const wellplate of seed.exportable_wellplates) {
                this.exportableWellplates.push({
                    selected: observable(true),
                    ...wellplate,
                });
            }
        });

        this.canExport = pureComputed(() => {
            if (!this.exportFormat()) {
                return false;
            }
            if (!this.exportableWellplates().some((w) => w.selected())) {
                return false;
            }
            return true;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });
    }

    public runExport = () => {
        this.exportInProgress(true);
        if (this.setAsExported()) {
            this.reloadRequired(true);
        }

        const selectedWellplateIds = this.exportableWellplates()
            .filter((w) => w.selected())
            .map((w) => w.id);

        if (this.exportFormat() === WellplateExportFormat.STANDARD_CSV) {
            window.open(
                getUrl("../frontend/wellplates/export_as_standard_csv", {
                    wellplate_id: selectedWellplateIds,
                    set_exported: this.setAsExported() ? "true" : "false",
                }),
            );
        } else if (this.exportFormat() === WellplateExportFormat.TRANSNETYX_XLSX) {
            window.open(
                getUrl("../frontend/wellplates/export_as_transnetyx_xlsx", {
                    wellplate_id: selectedWellplateIds,
                    set_exported: this.setAsExported() ? "true" : "false",
                }),
            );
        } else {
            throw new Error("Unknown export format");
        }
        this.exportInProgress(false);
        this.dialog.close();
    };
}

export const showMicrowellPlateExport = dialogStarter(MicrowellPlateExportViewModel, template, (params) => ({
    name: "MicrowellPlateExport",
    width: 450,
    closeOthers: true,
    cssARequire: [":table.css"],
    anchor: { top: 120, left: undefined },
    title: params.wellplateId ? getTranslation("Wellplate") : getTranslation("Export wellplate"),
}));
