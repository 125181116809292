/**
 * Show a pop-up to create new microwell plate.
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";import * as _ from "lodash";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import { ArrayElement, getFormData } from "../lib/utils";
import {IndexSignature} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./microwellPlateDetails.html";

interface MicrowellPlateDetailParams {
    wellplateId?: number;
    reloadCallback?: () => void;
    closeCallback?: () => void;
}

interface RequestParams {
    action: "get_dialog_options";
    wellplate_id?: number;
}

interface Seed {
    status: "active" | "closed" | "exported" | "deleted";
    format_id?: number;
    code?: string;
    reference?: string;
    rows?: number;
    columns?: number;
    fill_direction?: "horizontal" | "vertical";
    mode?: "REPRO" | null;
    responsible_id: number;
    used_positions?: {
        [position: string]: {
            eartag_or_id: string;
            labid: string | undefined;
            strain_name_with_id: string | undefined;
        };
    };
    possible_formats: {id: number; label: string}[];
    possible_fill_directions: {value: string; label: string}[];
    possible_modes: {value: string; label: string}[];
    possible_responsibles: Array<{ id: number; name: string }>;
}


class MicrowellPlateDetailsViewModel {
    private readonly dialog: KnockoutPopup;
    private readonly params: MicrowellPlateDetailParams;

    public readonly formatId: Observable<number>;
    public readonly code: Observable<string>;
    public readonly referenceText: Observable<string>;
    public readonly fillDirection: Observable<"horizontal" | "vertical">;
    public readonly responsibleId: CheckExtended<Observable<number>>;
    public readonly responsibleCaption: Observable<string>;
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public readonly possibleFormats: ObservableArray<{ id: number; label: string }>;
    public readonly possibleFillDirections: ObservableArray<{ value: string; label: string }>;
    public readonly possibleModes: ObservableArray<{ value: string; label: string }>;
    public readonly possibleResponsibles: ObservableArray<{ id: number; name: string }>;
    public readonly canApply: PureComputed<boolean>;

    private readonly editable: Observable<boolean>;
    private readonly applyInProgress: Observable<boolean>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly displayFormat: Observable<"overview" | "listing">;
    private readonly mode: Observable<"REPRO" | null>;
    private readonly repro: Observable<ArrayElement<Seed["possible_modes"]>["value"]>;
    private readonly selectedPosition: Observable<string | null>;

    constructor(params: MicrowellPlateDetailParams, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.params = params;

        this.code = ko.observable();
        this.formatId = ko.observable();
        this.fillDirection = ko.observable();
        this.referenceText = ko.observable();
        this.responsibleId = ko.observable().extend({
            invalid: (v) => {
                if (!v) {
                    return getTranslation("Please select a responsible");
                }

                return false;
            },
        });
        this.responsibleCaption = ko.observable();
        this.editable = ko.observable(session.userPermissions.microwell_plate_edit);
        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);
        this.possibleFormats = ko.observableArray();
        this.possibleFillDirections = ko.observableArray();
        this.possibleModes = ko.observableArray();
        this.possibleResponsibles = ko.observableArray();
        this.displayFormat = ko.observable("overview");
        this.mode = ko.observable();
        this.selectedPosition = ko.observable(null);

        /**
         * Get options for dialog (popup).
         */
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const params: IndexSignature<RequestParams> = {action: "get_dialog_options"};

                if (this.params.wellplateId) {
                    params.wellplate_id = this.params.wellplateId;
                }
                return fetch(getUrl("microwell_plate_list.py", params), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.possibleFormats(seed.possible_formats);
                this.possibleFillDirections(seed.possible_fill_directions);
                this.possibleModes(seed.possible_modes);
                this.possibleResponsibles(seed.possible_responsibles);
                this.fillDirection("vertical"); //preselect "vertical" as default

                if (!this.params.wellplateId) {
                    this.responsibleId(session.userId);
                } else {
                    this.code(seed.code);
                    this.formatId(seed.format_id);
                    this.referenceText(seed.reference);
                    this.fillDirection(seed.fill_direction);
                    this.responsibleId(seed.responsible_id);
                    this.mode(seed.mode);

                    if (!seed.responsible_id) {
                        this.responsibleCaption(getTranslation("None"));
                    }

                    // if wellplate is “in use” set the editable flag to 'false' to restrict editing of fields
                    if (Object.keys(seed.used_positions).length) {
                        this.editable(false);
                    }

                    // wellplate is “exported” or closed “exported” or "deleted"
                    if (seed.status !== "active") {
                        this.editable(false);
                    }
                }
            }
        });

        this.canApply = ko.pureComputed(() => {
            return this.code() && this.formatId() && this.responsibleId.isValid() && this.applyInProgress() === false;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (params.closeCallback) {
                params.closeCallback();
            }

            if (this.reloadRequired() && typeof params.reloadCallback === "function") {
                params.reloadCallback();
            }
        });
    }

    private getFormData = () => {
        const formData = getFormData({
            responsible_id: this.responsibleId(),
        });

        if (!this.params.wellplateId) {
            formData.append("action", "add_wellplate");
        } else {
            formData.append("wellplate_id", this.params.wellplateId.toString());
            if (this.editable()) {
                formData.append("action", "update_wellplate");
            } else {
                formData.append("action", "update_used_wellplate");
            }
        }

        if (this.editable()) {
            formData.append("code", this.code());
            formData.append("format_id", this.formatId().toString());
            formData.append("fill_direction", this.fillDirection());

            if (this.mode()) {
                formData.append("mode", this.mode());
            }

            if (this.referenceText()) {
                formData.append("reference", this.referenceText());
            }
        }

        return formData;
    };

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        fetch("microwell_plate_list.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };

    public invalidatePosition = (position: string) => {
        notifications.showConfirm(
            _.template(getTranslation("Do you want to invalidate position <%- position %>?"))({ position }),
            () => {
                this.applyInProgress(true);
                this.reloadRequired(true);

                fetch("microwell_plate_list.py", {
                    method: "POST",
                    body: getFormData({
                        action: "invalidate_position",
                        wellplate_id: this.params.wellplateId,
                        wellplate_position: position,
                    }),
                })
                    .then(response => response.json()).then(() => {
                    this.applyInProgress(false);
                    this.seed.fetchForceReload();
                })
                    .catch(() => {
                        this.applyInProgress(false);
                    });
            });
    };
}

export const showMicrowellPlateDetails = dialogStarter(MicrowellPlateDetailsViewModel, template, params => ({
    name: "MicrowellPlateDetails",
    width: 450,
    closeOthers: true,
    cssARequire: [":table.css"],
    anchor: {top: 120, left: undefined},
    title: params.wellplateId ? getTranslation("Wellplate") : getTranslation("Create wellplate"),
}));
