import * as ko from "knockout";

interface TextareaAutoHeightOptions {
    initialHeight: string;
}

declare module "knockout" {

    export interface BindingHandlers {
        textareaAutoHeight?: {
            init(element: HTMLElement, valueAccessor: () => TextareaAutoHeightOptions): void;
        };
    }

}

// Binding to resize the content of a textarea automatically by its input.
//
// usage:
//    <textarea data-bind="textInput: foo, textareaAutoHeight"></textarea>
//    <textarea data-bind="textInput: foo, textareaAutoHeight: {initialHeight: '22px'}"></textarea>
ko.bindingHandlers.textareaAutoHeight = {
    init: function (element, valueAccessor) {
        const params = {
            initialHeight: "auto",
            ...(ko.unwrap(valueAccessor()) || {}),
        };

        let minHeight = 0;

        function resizeTextArea() {
            const currentHeight = parseFloat(getComputedStyle(element, null).height.replace("px", ""));
            if (currentHeight > 0) {
                minHeight = minHeight || currentHeight;
                if (element.scrollHeight >= minHeight) {
                    element.style.height = element.scrollHeight + "px";
                }
            }
        }

        element.style.height = params.initialHeight;
        element.style.overflowY = "hidden";
        element.style.resize = "none";

        resizeTextArea();
        element.addEventListener("input", () => {
            element.style.height = "auto";
            resizeTextArea();
        });
    },
};
