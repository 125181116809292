import * as ko from "knockout";
import {Computed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";

type CheckFunction<T> = (args0: T) => boolean | string;

declare module "knockout" {

    export interface ExtendersOptions<T> {
        invalid?: CheckFunction<T> | CheckFunction<T>[];
    }
}

interface ExtenderObservables {
    isValid?: Computed<boolean>;
    isInvalid?: Computed<boolean>;
    errorMessage?: Computed<string>;
}

export type CheckExtended<T> = T & ExtenderObservables;


/**
 * Knockout extension to (in)validate the content of an observable.
 *
 * You pass a check function or an Array of check functions that indicate if the value is invalid.
 * To do so, check functions must return a (non-empty) string with a usefull error message or true
 * to mark the value invalid. Only false is interpret as "the value is valid".
 *
 * Notice that the initial value will also be checked.
 *
 * Three computed observables will be added to the target observable.
 *   .isValid - Is always boolean. Indicates if the value is valid or not. (true means valid)
 *   .isInvalid - Is always boolean. Indicates if the value id invalid or not. (true means invalid)
 *   .errorMessage - Is always string (so you can always pass it's value to an only string consuming consumer).
 *                   Be careful: errorMessage of empty string could mean value is valid or value is valid but
 *                   check function did not provide a message. Do not rely on the value of errorMessage to decide
 *                   if value is valid or invalid.
 *
 * Example:
 *   ko.observable().extend({ invalid: function (v: number) { return v === 42 ? false : 'wrong answer'; } });
 *
 */
ko.extenders.invalid = <T>(target: CheckExtended<Observable> | CheckExtended<ObservableArray>,
                           fn: CheckFunction<T> | CheckFunction<T>[]) => {

    const checks = !Array.isArray(fn) ? [fn] : fn;

    if (!Array.isArray(checks) || !checks.every(function (check) { return typeof check === "function"; })) {
        throw new TypeError("wrong type passed - must be function or array of functions");
    }

    if (target.isValid) {
        throw new Error("target already has an isValid property");
    }

    if (target.isInvalid) {
        throw new Error("target already has an isInvalid property");
    }

    if (target.errorMessage) {
        throw new Error("target already has an errorMessage property");
    }

    const isInvalid = ko.pureComputed(function (){
        const targetValue = target();
        for (let i = 0, l = checks.length; i < l; i++) {
            const checkResult = checks[i](targetValue);
            if (checkResult !== false) {
                // = value is invalid
                if (typeof checkResult === "string") {
                    if (checkResult.length === 0) {
                        throw new Error("invalid return value for check function - return true if you do not want to provide a message");
                    }
                    return checkResult;

                } else if (typeof checkResult === "boolean") {
                    return checkResult;
                }

                throw new TypeError(`invalid return type for check function - must be boolean or string not ${typeof checkResult}`);
            }
        }

        // no check evaluated this as invalid
        return false;
    });

    target.isValid = ko.pureComputed(function () {
        return isInvalid() === false;
    });

    target.isInvalid = ko.pureComputed(function () {
        return !target.isValid();
    });

    target.errorMessage = ko.pureComputed(function () {
        const checkedValue = isInvalid();
        return typeof checkedValue === "string" ? checkedValue : "";
    });

    return target;
};
