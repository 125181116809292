/**
 * Show a pop-up to set RFID for given subject (id).
 *
 * @param pupId: Database ID of the pup for which to set RFID.
 +
 * @param animalId: Database ID of the animal for which to set RFID.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setRFID.html";
import {CheckExtended} from "../knockout/extensions/invalid";

interface Params {
    pupId?: number;
    animalId?: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    eartag_or_id: string | number;
    rfid: string;
}

class SetRFIDViewModel {
    public readonly allowEdit: boolean;
    public readonly canApply: PureComputed<boolean>;
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;

    private readonly pupId: number;
    private readonly animalId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly dialog: KnockoutPopup;
    private readonly reloadRequired: Observable<boolean>;
    private readonly applyInProgress: Observable<boolean>;
    private readonly rfID: CheckExtended<Observable<string>>;


    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;

        this.pupId = params.pupId;
        this.animalId = params.animalId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.allowEdit = session.userPermissions.animal_set_rfid
            && Boolean(this.pupId || this.animalId);

        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);

        this.rfID = ko.observable().extend({
            invalid: (value) => {
                return !!value && session.pyratConf.RFID_REGEX && !value.match(new RegExp(session.pyratConf.RFID_REGEX))
                    ? getTranslation("No valid RFID")
                    : false;
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.pupId) {
                    return fetch(getUrl("set_rfid.py", {pup_id: this.pupId}), {signal});
                } else if (this.animalId) {
                    return fetch(getUrl("set_rfid.py", {animal_id: this.animalId}), {signal});
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.rfID(seed.rfid);
                this.dialog.setTitle(getTranslation("Set RFID for %s").replace("%s", String(seed.eartag_or_id)));
            }
        });

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && !this.seed.fetchInProgress()
                && this.rfID.isValid()
                && this.rfID() !== this.seed()?.rfid;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        let formData;
        if (this.pupId) {
            formData = getFormData({pup_id: this.pupId, rfid: this.rfID()});
        } else if (this.animalId){
            formData = getFormData({animal_id: this.animalId, rfid: this.rfID()});
        }

        fetch("set_rfid.py", {method: "POST", body: formData})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetRFID = dialogStarter(SetRFIDViewModel, template, params => ({
    name: "SetRFID",
    width: 270,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: getTranslation("Set RFID"),
}));
