import * as ko from "knockout";
import {CagePickerComponent} from "./cagePicker";
import {LocationCagePickerComponent} from "./locationCagePicker";
import {LocationCagePickerPopupComponent} from "./locationCagePickerPopup";
import {LocationPickerComponent} from "./locationPicker";
import {LocationPickerPopupComponent} from "./locationPickerPopup";
import {TankPickerComponent} from "./tankPicker";

ko.components.register("ko-location-picker", new LocationPickerComponent());
ko.components.register("location-picker-popup", new LocationPickerPopupComponent());
ko.components.register("ko-cage-picker", new CagePickerComponent());
ko.components.register("ko-tank-picker", new TankPickerComponent());
ko.components.register("ko-location-cage-picker", new LocationCagePickerComponent());
ko.components.register("location-cage-picker-popup", new LocationCagePickerPopupComponent());
