import * as ko from "knockout";
import {MaybeSubscribable} from "knockout";

declare module "knockout" {
    export interface BindingHandlers {
        element?: {
            update(element: HTMLElement, valueAccessor: () => MaybeSubscribable<HTMLElement>): void;
        };
    }

}

/* KnockoutJS binding to set any jquery object as content or replace existing content with it. */
ko.bindingHandlers.element = {
    update: function (element, valueAccessor) {
        if (element.children.length) {
            element.children[0].replaceWith(ko.unwrap(valueAccessor()));
        } else {
            element.append(ko.unwrap(valueAccessor()));
        }
    },
};
