import {Observable} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";
import {getTranslation} from "../../lib/localize";
import {getFormData} from "../../lib/utils";
import {naturalCompare} from "../../lib/utils";
import {AjaxResponse} from "../../lib/utils";
import {FetchExtended} from "../extensions/fetch";
import template from "./cageHardwareWidget.html";
import "./cageHardwareWidget.scss";
import {notifications} from "../../lib/pyratTop";
import {frames} from "../../lib/pyratTop";

interface CageHardwareWidgetParams {
    cageNumber: string;  // CageNumber of the cage.
    cageId: number;  // Database ID of the cage.
}

interface Seed {
    provider: {
        tecniplast?: {
            known: boolean;
            label: {
                amsCageId: string;
                amsCageName: string;
                amsResearcherId: number;
                amsProtocolId: string;
                type: string;
                mice: {
                    mouseId: string;
                    amsAnimalName: string;
                    sex: string;
                    strain: string;
                    birthDate: string;
                }[];
            };
            position?: {
                amsRackId: number;
                position: string;
                rackName: string;
            };
            led: string | boolean;
            history: {
                local_date: string;
                description: string;
            }[];
        };
        galilei?: {
            label_id: string;
            status: string;
            label_type: string;
            sub_cell: string;
            enrolled: boolean;
            update_date_string: string;
            link_date_string: string;
            last_image: {
                page_1: string | null;
                page_2: string | null;
                page_3: string | null;
            };
        };
    };
}

interface UnknownCagePositions {
    facility: string;
    building: string;
    floor: string;
    room: string;
    rack: string;
    amsRackId: number;
    positions: string[];
}


class TecniplastModel {

    public widget: CageHardwareWidgetViewModel;
    public cage: Seed["provider"]["tecniplast"];
    public obtainTecniplastDVCPositionInProgress: Observable<boolean>;
    public updateTecniplastDVCCageInProgress: Observable<boolean>;
    public disconnectCageInProgress: Observable<boolean>;
    public connectCageInProgress: Observable<boolean>;
    public turnLabelLEDOnInProgress: Observable<boolean>;
    public turnLabelLEDOffInProgress: Observable<boolean>;
    public connectCageVisible: Observable<boolean>;
    public connectCagePositions: FetchExtended<Observable<AjaxResponse<{ unknown_cages: UnknownCagePositions[] }>>>;
    public connectCageDialogPosition: Observable<HTMLElement | undefined>;

    constructor(widget: CageHardwareWidgetViewModel, seed: Seed["provider"]["tecniplast"]) {
        this.widget = widget;
        this.cage = seed;

        this.obtainTecniplastDVCPositionInProgress = ko.observable(false);
        this.updateTecniplastDVCCageInProgress = ko.observable(false);
        this.disconnectCageInProgress = ko.observable(false);
        this.connectCageInProgress = ko.observable(false);
        this.turnLabelLEDOnInProgress = ko.observable(false);
        this.turnLabelLEDOffInProgress = ko.observable(false);

        this.connectCageVisible = ko.observable(false);
        this.widget.seed.subscribe(() => {
            this.connectCageVisible(false);
        });

        this.connectCagePositions = ko.observable().extend({
            fetch: {
                disable: ko.computed(() => !this.connectCageVisible()),
                fn: (signal) => {
                    return fetch("cage_hardware_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "tecniplast_get_unknown_cages",
                        }),
                        signal,
                    });
                },
            },
        });

        this.connectCageDialogPosition = ko.observable();
        this.connectCagePositions.subscribe(() => {
            const timeout = 30000;
            setTimeout(() => {
                // never automatic refresh more often then timeout says
                if (this.connectCagePositions.fetchLastSuccess() < new Date(new Date().getTime() - timeout)) {
                    this.connectCagePositions.fetchForceReload();
                }
            }, timeout);
        });
    }

    public collectPositionGroups = (positions: UnknownCagePositions["positions"]) => {
        const columns = _.uniq(_.map(positions, (p) => {
            return p.slice(1);
        })).sort(naturalCompare).reverse();
        const rows = _.uniq(_.map(positions, (p) => {
            return p.slice(0, 1);
        })).sort(naturalCompare);

        return _.map(columns, (c) => {
            return _.map(rows, (r) => {
                if (_.includes(positions, r + c)) return r + c;
                return undefined;
            });
        });
    };

    public connectCageShow = (item: CageHardwareWidgetViewModel, event: MouseEvent) => {
        const target = event.target as HTMLElement;
        this.connectCageVisible(true);
        this.connectCageDialogPosition(target);
        this.connectCagePositions.subscribeOnce(_.partial(_.defer, this.connectCageDialogPosition, target));
    };

    public connectCage = (location: UnknownCagePositions, position: string) => {
        this.connectCageInProgress(true);
        this.connectCageVisible(false);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_connect_dvc_cage",
                cage_id: String(this.widget.cageId()),
                rack_id: String(location.amsRackId),
                position: String(position),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const message = getTranslation("Connected cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    const message = getTranslation("Connecting cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.connectCageInProgress(false);
                this.widget.completeReload();
            });
    };

    public obtainTecniplastDVCPosition = () => {
        this.obtainTecniplastDVCPositionInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_obtain_dvc_position",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let message;
                if (data.success) {
                    message = getTranslation("Obtained the position of cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    message = getTranslation("Obtaining the position of cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.obtainTecniplastDVCPositionInProgress(false);
                this.widget.completeReload();
            });
    };

    public updateTecniplastDVCCage = () => {
        this.updateTecniplastDVCCageInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_update_dvc_cage",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let message;
                if (data.success) {
                    message = getTranslation("Updated cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    message = getTranslation("Updating cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.updateTecniplastDVCCageInProgress(false);
            });
    };

    public disconnectCage = () => {
        this.disconnectCageInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_disconnect_dvc_cage",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                let message;
                if (data.success) {
                    message = getTranslation("Disconnected cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    message = getTranslation("Disconnecting cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.disconnectCageInProgress(false);
                this.widget.seed.fetchForceReload();
            });
    };

    public turnLabelLEDOn = () => {
        this.turnLabelLEDOnInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_turn_cage_led_on",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then(() => {
                this.widget.seed.fetchForceReload();
            })
            .finally(() => {
                this.turnLabelLEDOnInProgress(false);
            });
    };

    public turnLabelLEDOff = () => {
        this.turnLabelLEDOffInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "tecniplast_turn_cage_led_off",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then(() => {
                this.widget.seed.fetchForceReload();
            })
            .finally(() => {
                this.turnLabelLEDOffInProgress(false);
            });
    };
}


class GalileiModel {

    public widget: CageHardwareWidgetViewModel;
    public cage: Seed["provider"]["galilei"];
    public freeLabelId: Observable<string>;
    public pairLabelInProgress: Observable<boolean>;
    public unpairLabelInProgress: Observable<boolean>;
    public turnLabelLEDOnInProgress: Observable<boolean>;
    public turnLabelLEDOffInProgress: Observable<boolean>;
    public updateLabelInProgress: Observable<boolean>;

    constructor(widget: CageHardwareWidgetViewModel, seed: Seed["provider"]["galilei"]) {
        this.widget = widget;
        this.cage = seed;
        this.freeLabelId = ko.observable("");
        this.pairLabelInProgress = ko.observable(false);
        this.unpairLabelInProgress = ko.observable(false);
        this.turnLabelLEDOnInProgress = ko.observable(false);
        this.turnLabelLEDOffInProgress = ko.observable(false);
        this.updateLabelInProgress = ko.observable(false);
    }

    public pairLabel = () => {
        this.pairLabelInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "galilei_pair_cage",
                cage_id: String(this.widget.cageId()),
                label_id: String(this.freeLabelId()),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const message = getTranslation("Paired cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    const message = getTranslation("Pairing cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.widget.seed.fetchForceReload();
            }).finally(() => {
            this.pairLabelInProgress(false);
        });
    };

    public unpairLabel = () => {
        this.unpairLabelInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "galilei_pair_cage",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const message = getTranslation("Unpaired cage <%- cageNumber %>.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "success");
                } else {
                    const message = getTranslation("Unpairing cage <%- cageNumber %> failed.");
                    notifications.showNotification(
                        _.template(message)({cageNumber: this.widget.cageNumber()}),
                        "error");
                }
                this.widget.seed.fetchForceReload();
            })
            .finally(() => {
                this.unpairLabelInProgress(false);
            });
    };

    public turnLabelLEDOn = () => {
        this.turnLabelLEDOnInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "galilei_turn_label_led_on",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then(() => {
                this.widget.seed.fetchForceReload();
            })
            .finally(() => {
                this.turnLabelLEDOnInProgress(false);
            });
    };

    public turnLabelLEDOff = () => {
        this.turnLabelLEDOffInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "galilei_turn_label_led_off",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .then(() => {
                this.widget.seed.fetchForceReload();
            })
            .finally(() => {
                this.turnLabelLEDOffInProgress(false);
            });
    };

    public updateLabel = () => {
        this.updateLabelInProgress(true);
        fetch("cage_hardware_service.py", {
            method: "POST",
            body: getFormData({
                function: "galilei_update_cage_label",
                cage_id: String(this.widget.cageId()),
            }),
        })
            .then((response) => response.json())
            .finally(() => {
                this.updateLabelInProgress(false);
            });
    };
}

class CageHardwareWidgetViewModel {

    public cageNumber: Observable<string>;
    public cageId: Observable<number>;
    public tecniplast: Observable<TecniplastModel | undefined>;
    public galilei: Observable<GalileiModel | undefined>;
    public seed: FetchExtended<Observable<AjaxResponse<Seed> | undefined>>;

    constructor(params: CageHardwareWidgetParams) {
        this.cageNumber = ko.observable(params.cageNumber);
        this.cageId = ko.observable(params.cageId);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.cageNumber()) {
                    return fetch("cage_hardware_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_cage_hardware_status",
                            cage_id: String(this.cageId()),
                        }),
                        signal,
                    });
                }
            },
        });

        // initialize the configured hardware models
        this.tecniplast = ko.observable(undefined);
        this.galilei = ko.observable(undefined);
        this.seed.subscribe((seed) => {

            // tecniplast dvc
            if (seed?.success && seed?.provider?.tecniplast) {
                this.tecniplast(new TecniplastModel(this, seed.provider.tecniplast));
            } else {
                this.tecniplast(undefined);
            }

            // galilei cage talker
            if (seed?.success && seed?.provider?.galilei) {
                this.galilei(new GalileiModel(this, seed.provider.galilei));
            } else {
                this.galilei(undefined);
            }

        });

    }

    public completeReload = () => {
        window.location.reload();
        frames.reloadListIframe();
    };


}

export class CageHardwareWidgetComponent {

    constructor() {

        return {
            viewModel: CageHardwareWidgetViewModel,
            template,
        };
    }
}
