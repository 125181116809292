/**
 * Show a tiny pop-up to change numbers of born or live pups of a birth/litter.
 *
 * @param cageId
 *        Database ID of the cage in which the birth/litter / the pups are.
 *
 * @param birthId
 *        Database ID of the birth/litter.
 *
 * @param popupType
 *        If number of born pups or of live pups will be modified.
 *
 * @param numberOfMales
 *        Initial number of males.
 *
 * @param numberOfFemales
 *        Initial number of females.
 *
 * @param numberOfUnknwon:
 *        Initial number of unknown.
 *
 * @param reloadCallback
 *        Function to call when the data has been applied, e.g. reload detail
 *        page.
 */

import * as ko from "knockout";
import * as _ from "lodash";
import {dialogStarter} from "../knockout/dialogStarter";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setBirthNumbers.html";

interface Params {
    cageId: number;
    birthId: number;
    popupType: "born" | "live";
    numberOfMales: number;
    numberOfFemales: number;
    numberOfUnknown: number;
    zeroPupsConfirmMessage?: string;
    reloadCallback?: () => void;
}

class SetBirthNumbersViewModel {

    public dialog: KnockoutPopup;
    public numberOfMales: CheckExtended<ko.Observable<number>>;
    public numberOfFemales: CheckExtended<ko.Observable<number>>;
    public numberOfUnknown: CheckExtended<ko.Observable<number>>;
    public minNumberOfMales: number;
    public minNumberOfFemales: number;
    public minNumberOfUnknown: number;
    public calculatedNumberOfUnknown: ko.Observable<boolean>;
    public applyInProgress: ko.Observable<boolean>;
    public isInvalid: ko.PureComputed<boolean>;

    public readonly licenseOveruseLimitMessage = ko.observable("");
    public readonly confirmLicenseOveruseLimit = ko.observable(false);

    private readonly cageId: number;
    private readonly birthId: number;
    private readonly popupType: "born" | "live";
    private readonly initialNumberOfMales: number;
    private readonly initialNumberOfFemales: number;
    private readonly initialNumberOfUnknown: number;
    private readonly zeroPupsConfirmMessage: string;
    private readonly reloadCallback: () => void;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        if (params.popupType === "live") {
            this.dialog.setTitle(getTranslation("Change live"));
        } else if (params.popupType === "born") {
            this.dialog.setTitle(getTranslation("Change born"));
        }

        this.numberOfMales = ko.observable(params.numberOfMales).extend({
            invalid: _.partial(this.isInvalidPupNumber, _, this.minNumberOfMales),
        });
        this.numberOfFemales = ko.observable(params.numberOfFemales).extend({
            invalid: _.partial(this.isInvalidPupNumber, _, this.minNumberOfFemales),
        });
        this.numberOfUnknown = ko.observable(params.numberOfUnknown).extend({
            invalid: _.partial(this.isInvalidPupNumber, _, this.minNumberOfUnknown),
        });

        this.minNumberOfMales = session.pyratConf.ALLOW_DECREASE_BIRTH_NUMBERS ? 0 : params.numberOfMales;
        this.minNumberOfFemales = session.pyratConf.ALLOW_DECREASE_BIRTH_NUMBERS ? 0 : params.numberOfFemales;
        this.minNumberOfUnknown = session.pyratConf.ALLOW_DECREASE_BIRTH_NUMBERS ? 0 : params.numberOfUnknown;

        this.calculatedNumberOfUnknown = ko.observable(false);

        this.cageId = params.cageId;
        this.birthId = params.birthId;
        this.popupType = params.popupType;

        this.initialNumberOfMales = params.numberOfMales;
        this.initialNumberOfFemales = params.numberOfFemales;
        this.initialNumberOfUnknown = params.numberOfUnknown;

        this.zeroPupsConfirmMessage = params.zeroPupsConfirmMessage;

        this.numberOfMales.subscribe(_.partial(this.recalculateNumberOfUnknown, _, this.initialNumberOfMales, this.numberOfFemales, this.initialNumberOfFemales));
        this.numberOfFemales.subscribe(_.partial(this.recalculateNumberOfUnknown, _, this.initialNumberOfFemales, this.numberOfMales, this.initialNumberOfMales));

        this.reloadCallback = params.reloadCallback;

        this.applyInProgress = ko.observable(false);
        this.isInvalid = ko.pureComputed(() => {
            return this.numberOfMales.isInvalid() ||
                    this.numberOfFemales.isInvalid() ||
                    this.numberOfUnknown.isInvalid() ||
                    false;
        });
    }

    private isInvalidPupNumber = (v: any, minNumber: number) => {
        if (!_.isNumber(v) || _.isUndefined(v) || !String(v).match(/^\d+$/)) {
            return getTranslation("Invalid number");
        }

        if (v < minNumber) {
            return getTranslation("Number must be at least %d").replace("%d", minNumber.toString());
        }

        return false;
    };

    private recalculateNumberOfUnknown = (numberOfThisSex: number, initialNumberOfThisSex: number, numberOfOtherSex: ko.Observable<number>, initialNumberOfOtherSex: number) => {
        const newOtherSex = numberOfOtherSex() - initialNumberOfOtherSex;
        let newThisSex = numberOfThisSex - initialNumberOfThisSex;

        if (newOtherSex > 0) {
            newThisSex += newOtherSex;
        }

        if (newThisSex > 0 && this.initialNumberOfUnknown) {
            // increased pups with defined sex
            if (this.initialNumberOfUnknown >= newThisSex) {
                this.numberOfUnknown(this.initialNumberOfUnknown - newThisSex);
            } else {
                this.numberOfUnknown(0);
            }
            this.calculatedNumberOfUnknown(true);
        } else if (newThisSex <= 0) {
            // decreased pups with defined sex or same amount
            if (newOtherSex > 0) {
                // increased only the sex from the other input field
                this.numberOfUnknown(this.initialNumberOfUnknown - newOtherSex);
            } else {
                // no increase of pups with defined sex in either input field (male or female)
                this.numberOfUnknown(this.initialNumberOfUnknown);
                this.calculatedNumberOfUnknown(false);
            }
        }
    };

    private sendData = () => {
        this.applyInProgress(true);
        this.licenseOveruseLimitMessage("");

        fetch("cagedetail.py", {
            method: "POST",
            body: getFormData({
                cageid: this.cageId.toString(),
                action: "set_birth_numbers",
                popup_type: this.popupType,
                birth_id: this.birthId.toString(),
                number_of_males: this.numberOfMales().toString(),
                number_of_females: this.numberOfFemales().toString(),
                number_of_unknown: this.numberOfUnknown().toString(),
                confirmed_license_overuse: this.confirmLicenseOveruseLimit() ? 1 : 0,
            }),
        }).then(response => response.json()).then((response: AjaxResponse<any>) => {
            this.applyInProgress(false);
            if (response.success) {
                notifications.showNotification(getTranslation("Litter details updated"), "success");

                if (typeof this.reloadCallback === "function") {
                    this.reloadCallback();
                }

                this.dialog.close();
            } else if (response.confirm === "confirm_license_overuse") {
                this.licenseOveruseLimitMessage(response.message);
            } else {
                notifications.showNotification(response.message, "error");
            }
        }).catch(() => {
            this.applyInProgress(false);
            notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
        });
    };

    public submit = () => {
        if (!this.isInvalid()) {
            if (this.zeroPupsConfirmMessage && !this.numberOfMales() && !this.numberOfFemales() && !this.numberOfUnknown()) {
                notifications.showConfirm(this.zeroPupsConfirmMessage, this.sendData);
            } else {
                this.sendData();
            }
        }
    };
}

export const showSetBirthNumbers = dialogStarter(SetBirthNumbersViewModel, template, {
    name: "SetBirthNumbers",
    width: 280,
    height: "auto",
    anchor: {
        top: 400,
        right: 400,
    },
    closeOthers: true,
    cssARequire: [
        ":popup.css",
    ],
});
