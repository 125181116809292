/**
 * Open a page in the current window, using POST in a virtual form element.
 *
 * @param url - The URL to open.
 * @param data - The Data to send in the POST request.
 */
export const virtualPostRequest = (url: string, data: { [key: string]: string }): void => {
    const form = document.createElement("form");

    for (const name in data) {
        if (Object.prototype.hasOwnProperty.call(data, name)) {
            const input = document.createElement("input");
            input.setAttribute("name", name);
            input.value = data[name];
            form.append(input);
        }
    }

    form.setAttribute("action", url);
    form.setAttribute("method", "post");
    form.style.display = "none";

    // old browsers require the form to be inside the body
    document.querySelector("body").append(form);

    form.submit();
    form.remove();
};

/**
 * Offer data for saving as a file download.
 *
 * @param data - The content of the file.
 * @param fileName - The fileName to use for the file.
 * @param mimeType - The mimeType to use for the file. Ignored if data is a Blob.
 */
export const virtualSaveData = (data: string | Blob, fileName?: string, mimeType = "octet/stream"): void => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let url: string;
    if (typeof data === "string") {
        const blob = new Blob([data], {type: mimeType});
        url = window.URL.createObjectURL(blob);
    } else {
        url = URL.createObjectURL(data);
    }
    a.href = url;
    if (fileName) {
        a.download = fileName;
    }
    a.click();
    window.URL.revokeObjectURL(url);
};


/** Offer saving the data returned from a fetch() call as file download.
 *
 * The returned promise continues not before the the file was actually offered. So it can
 * perfectly be used to handle loading progress animations.
 *
 * @param promise - The promise returned by a fetch() call.
 * @param fallbackFileName - File name to use if reading the content-disposition header fails.
 * @returns - The original Response as a Promise.
 */
export const virtualSaveFromFetch = (promise: Promise<Response>, fallbackFileName = "file"): Promise<Response> => {
    let originalResponse: Response;
    let fileName: string = fallbackFileName;
    return promise
        .then((response) => {
            originalResponse = response;
            if (response.ok) {
                const contentDisposition = response.headers.get("content-disposition");
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                    if (fileNameMatch?.length) {
                        fileName = fileNameMatch[1];
                    }
                }
            }
            return response;
        })
        .then((response) => response.blob())
        .then((body) => {
            virtualSaveData(body, fileName);
            return originalResponse;
        });
};


/* post link generator
*
* Loading this Javascript file, allows you to send html a-links with post.
* The syntax is similar to form elements.
*
* Examples:
*
*   <a method="POST"
*      action="url.py"
*      data="{my_data: 'as json dictionary'}">
*
* Setting the method to POST activates this module. You can also set the
* attribute "base" to another json string just like data, what allows you to
* provide some data from javascript, that is extended by the data from the link.
*
* Example base attribute:
*
*   $('td a[method=POST]').each(function () {
*       $(this).attr('base', JSON.stringify(options.filter_args));
*   });
*
*   <a method="POST"
*      action="url.py"
*      base="{basic_data: 'gets extended by the other data'}"
*      data="{my_data: 'as json dictionary'}">
*
* */
export const registerPostLinkGenerator = (w: Window): void => {
    // install the click handlers
    w.document.querySelectorAll("a[method=POST]").forEach((element) => {
        element.addEventListener("click", () => {
            if (element.getAttribute("base")) {
                virtualPostRequest(
                    element.getAttribute("action"),
                    Object.assign({},
                        JSON.parse(element.getAttribute("base") || "{}"),
                        JSON.parse(element.getAttribute("data"))
                    )
                );
            } else {
                virtualPostRequest(
                    element.getAttribute("action"),
                    JSON.parse(element.getAttribute("data")));
            }
        });
    });
};
