/**
 * Show a popup to set the responsible for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setAnimalResponsible.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    responsible_id: number;
    responsibles: {
        userid: number;
        fullname: string;
    }[];
}

class SetAnimalResponsibleViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly responsibleId: Observable<number>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.responsibleId = ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl("set_animal_responsible.py", {animal_id: this.animalId}), {signal});
                } else if (this.pupId) {
                    return fetch(getUrl("set_animal_responsible.py", {pup_id: this.pupId}), {signal});
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.responsibleId(seed.responsible_id);
            }
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            responsible_id: this.responsibleId() || "",
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch("set_animal_responsible.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetAnimalResponsible = dialogStarter(SetAnimalResponsibleViewModel, template, params => ({
    name: "SetAnimalResponsible",
    width: 330,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [],
    title: params.title,
}));
