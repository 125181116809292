/**
 * Show a popup for entering a birth event.
 *
 * @param cageId: Database ID of the cage.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import * as _ from "lodash";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {parseDate} from "../lib/flatpickr";
import {getTranslation, TranslationTemplates} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import template from "./addBirth.html";

interface Params {
    cageId: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Animal {
    animalid: number;
    eartag: string;
}

interface Strain {
    id: number | "no_strain";
    name: string;
    name_id: string;
    name_name: string;
    breeding_setup_id?: number;
    name_too_long_warning: string;
}

interface Project {
    id: number;
    project_label: string;
    owner_fullname: string;
    project_label_with_owner: string;
}

interface BreedingSetup {
    breeding_setup_id: number;
    strain_id: number;
    responsible_id: number;
    license_id: number;
    classification_id: number;
    project_id: number;
    full_pedigree_line: string;
    newgen: string;
    subgen: string;
    addgen: string;
    newgen2: string;
    subgen2: string;
}

interface Seed {
    mothers: Animal[];
    fathers_in_cage: Animal[];
    father_guessed: Animal;
    show: string[];
    next_strain_name_id: string;
    strain_name_id_delimiter: string;
    available_strains: Strain[];
    cage_responsible_id: number;
    parent_species_id: number;
    breeding_setups: BreedingSetup[];
    mothers_embryo_transfer_generations: {
        [mother_id: number]: {
            subgen1: string;
            newgen1: string;
            subgen2: string;
            newgen2: string;
            addgen: string;
        };
    };
    birth_date: string;
}

class AddBirthViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly cageId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly mothers: ObservableArray<Animal>;
    private readonly selectedMothers: ObservableArray<number | "all">;
    private readonly fathersInCage: ObservableArray<Animal>;
    private readonly fatherGuessed: Observable<Animal>;
    private readonly fatherId: Observable<number | "">;
    private readonly availableStrains: ObservableArray<Strain>;
    private readonly deserializeStrain: ObservableArray;
    private readonly selectedStrainOptions: ObservableArray<Strain>;
    private readonly customStrainNameId: Observable<string>;
    private readonly customStrainName: CheckExtended<Observable<string>>;
    private readonly strainNameCutWarning: PureComputed<string>;
    private readonly responsibleId: Observable<number>;
    private readonly licenseDenom: string;
    private readonly parentSpeciesId: Observable<number>;
    private readonly licenses: FetchExtended<ObservableArray<{
        id: number;
        name: string;
    }>>;
    private readonly classifications: FetchExtended<ObservableArray<{
        id: number;
        name: string;
    }>>;
    private readonly licenseId: Observable<number>;
    private readonly classificationId: CheckExtended<Observable<number>>;
    private readonly deserializeProject: ObservableArray;
    private readonly selectedProjectOptions: CheckExtended<ObservableArray<Project>>;
    private readonly fullPedigreeLine: Observable<string>;
    private readonly subGen: Observable<string>;
    private readonly newGen: Observable<string>;
    private readonly subGen2: Observable<string>;
    private readonly newGen2: Observable<string>;
    private readonly addGen: Observable<string>;
    private readonly generationValid: Observable<boolean>;
    private readonly breedingDefaults: ObservableArray<BreedingSetup>;
    private readonly quantityMales: CheckExtended<Observable<number>>;
    private readonly quantityFemales: CheckExtended<Observable<number>>;
    private readonly quantityUnknown: CheckExtended<Observable<number>>;
    private readonly quantityFilled: CheckExtended<PureComputed<boolean>>;
    private readonly isFetus: Observable<boolean>;
    private readonly birthDate: CheckExtended<Observable<string>>;
    private readonly comment: Observable<string>;

    private readonly errors: ObservableArray<string>;
    private readonly errorMessages: PureComputed<string[]>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;

    private readonly showLicenseOveruseLimit = ko.observable(false);
    private readonly confirmLicenseOveruseLimit = ko.observable(false);


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.cageId = params.cageId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.mothers = ko.observableArray();
        this.selectedMothers = ko.observableArray();
        this.fathersInCage = ko.observableArray();
        this.fatherGuessed = ko.observable();
        this.fatherId = ko.observable();

        // mutually exclusive All checkbox with single mothers checkboxes
        this.selectedMothers.subscribe((changes) => {
            changes.forEach((change) => {
                if (change.status === "added") {
                    if (change.value === "all") {
                        // uncheck single mother checkboxes
                        this.selectedMothers.remove((item) => { return item !== "all"; });
                    } else {
                        // uncheck 'All mothers' checkbox
                        this.selectedMothers.remove((item) => { return item === "all"; });
                    }
                }
            });
        }, null, "arrayChange");

        this.availableStrains = ko.observableArray();
        this.deserializeStrain = ko.observableArray([]);
        this.selectedStrainOptions = ko.observableArray();
        this.customStrainNameId = ko.observable("");
        this.customStrainName = ko.observable("");

        // copy the selected strain into 'custom strain' inputs
        this.selectedStrainOptions.subscribe((options) => {
            if (options.length > 0) {
                const name = "name_name" in options[0] ?  // STRAIN_NAME_WITH_ID
                           options[0].name_name :
                           options[0].name;

                this.customStrainName(name);
                this.customStrainNameId(options[0].name_id);

                // fill in breeding defaults depending on the selected strain
                if (options[0].breeding_setup_id) {
                    this.setBreedingDefaults(options[0].breeding_setup_id);
                }
            }
        });

        // reset the selected strain when the user edits the custom strain input
        this.customStrainNameId.subscribe((v) => {
            if (this.selectedStrainOptions().length > 0) {
                if (this.selectedStrainOptions()[0].name_id !== v) {
                    this.deserializeStrain([]);
                }
            }
        });

        // reset the selected strain when the user edits the custom strain input
        this.customStrainName.subscribe((v) => {
            if (this.selectedStrainOptions().length > 0) {
                const name = "name_name" in this.selectedStrainOptions()[0] ?  // STRAIN_NAME_WITH_ID
                           this.selectedStrainOptions()[0].name_name :
                           this.selectedStrainOptions()[0].name;

                if (name !== v) {
                    this.deserializeStrain([]);

                    // suggest the next strain name id
                    this.customStrainNameId(this.seed().next_strain_name_id);
                }
            } else {
                if (!this.customStrainNameId()) {
                    // suggest the next strain name id
                    this.customStrainNameId(this.seed().next_strain_name_id);
                }
            }
        });

        // strain name field should be always filled (also if strain is selected in dropdown)
        this.customStrainName.extend({
            invalid: (v) => {
                const selectedStrainId = this.selectedStrainOptions().length ? this.selectedStrainOptions()[0].id : "";
                return !(v || selectedStrainId === "no_strain");
            },
        });

        // message to inform that strain name was longer than 255 characters
        // and was automatically cut
        this.strainNameCutWarning = ko.pureComputed(() => {
            const selectedStrain = this.selectedStrainOptions() && this.selectedStrainOptions()[0];

            if (selectedStrain && selectedStrain.name_too_long_warning) {
                return selectedStrain.name_too_long_warning;
            }
        });

        this.responsibleId = ko.observable();

        this.licenseDenom = TranslationTemplates.license({cap: true, trans: true});
        this.parentSpeciesId = ko.observable();

        // load licenses and classifications depending on selected strain
        this.licenses = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.parentSpeciesId()),
                fn: (signal) => {
                    return fetch("ajax_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_licence_list_json",
                            species_id: String(this.parentSpeciesId()),
                            strain_id: String(this.selectedStrainOptions().map((strain) => { return strain.id; }).filter(Number)),
                        }),
                        signal,
                    });
                },
            },
        });
        this.licenseId = ko.observable();

        this.classifications = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.parentSpeciesId() || !this.licenseId()),
                fn: (signal) => {
                    return fetch("ajax_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_licence_classifications_json",
                            licence_id: String(this.licenseId()),
                            species_id: String(this.parentSpeciesId()),
                            strain_id: String(this.selectedStrainOptions().map((strain) => { return strain.id; }).filter(Number)),
                        }),
                        signal,
                    });
                },
            },
        });

        this.classificationId = ko.observable();

        // classification is mandatory when licence gets selected
        this.classificationId.extend({
            invalid: (v) => {
                return !!(this.licenseId() && !v && !this.classifications.fetchInProgress());
            },
        });

        this.deserializeProject = ko.observableArray([]);
        this.selectedProjectOptions = ko.observableArray().extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_BIRTH_PROJECT && !v.length);
            },
        });

        this.fullPedigreeLine = ko.observable();
        this.subGen = ko.observable();
        this.newGen = ko.observable();
        this.subGen2 = ko.observable();
        this.newGen2 = ko.observable();
        this.addGen = ko.observable();
        this.generationValid = ko.observable(true);

        // breeding setups (a list because animals can have more than one)
        this.breedingDefaults = ko.observableArray();

        this.quantityMales = ko.observable().extend({
            invalid: (v) => {
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        this.quantityFemales = ko.observable().extend({
            invalid: (v) => {
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        this.quantityUnknown = ko.observable().extend({
            invalid: (v) => {
                if (!((_.isNumber(v) && String(v).match(/^\d+$/) && v >= 0) || _.isUndefined(v))) {
                    return getTranslation("Invalid number");
                }
                return (this.quantityFilled.isInvalid());
            },
        });

        // calculated fields to check with "at least one" is filled
        this.quantityFilled = ko.pureComputed(() => {
            return this.quantityMales() > 0 ||
                   this.quantityFemales() > 0 ||
                   this.quantityUnknown() > 0;
        }).extend({
            invalid: (v) => {
                return !v;
            },
        });

        this.isFetus = ko.observable(false);

        this.birthDate = ko.observable().extend({
            invalid: (v) => {
                return (isInvalidCalendarDate(v) && getTranslation("Invalid date"));
            },
        });

        this.birthDate.subscribeChanged((newValue, oldValue) => {
            const today = new Date().setHours(0,0,0,0);
            let parsedDate;

            if (newValue && !isInvalidCalendarDate(newValue)) {
                parsedDate = parseDate(newValue).setHours(0,0,0,0);

                if (parsedDate > today && !this.isFetus()) {
                    notifications.showConfirm(
                        getTranslation("Date of birth in the future. Proceed anyway?"),
                        function () {
                            return true;
                        },
                        {
                            onCancel: () => {
                                if (oldValue && parseDate(oldValue).setHours(0, 0, 0, 0) > today) {
                                    this.birthDate(this.seed().birth_date);
                                } else {
                                    this.birthDate(oldValue);
                                }
                            },
                        }
                    );
                }
            }
        });

        this.comment = ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("add_birth.py", {cage_id: this.cageId}), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                setTimeout(() => {
                    let embryoTransferGeneration;

                    this.mothers(seed.mothers);
                    this.selectedMothers(seed.mothers.length === 1 ? [seed.mothers[0].animalid] :
                                         (session.pyratConf.BIRTH_PRESELECT_ALL_MOTHERS ? ["all"] : []));

                    this.fathersInCage(seed.fathers_in_cage);
                    this.fatherGuessed(seed.father_guessed);
                    if (seed.fathers_in_cage.length === 1) {
                        this.fatherId(seed.fathers_in_cage[0].animalid);
                    } else if (seed.father_guessed) {
                        this.fatherId(seed.father_guessed.animalid);
                    } else {
                        this.fatherId("");
                    }
                    // the cage responsible is the default value, but can be overwritten by breeding defaults
                    this.responsibleId(seed.cage_responsible_id);
                    this.parentSpeciesId(seed.parent_species_id);

                    // breeding setup
                    this.breedingDefaults(seed.breeding_setups);
                    if (this.breedingDefaults().length > 0) {
                        this.setBreedingDefaults(this.breedingDefaults()[0].breeding_setup_id);
                    }

                    if (!this.subGen() && !this.newGen() && !this.subGen2() && !this.newGen2() && !this.addGen() &&
                            seed.mothers_embryo_transfer_generations && this.selectedMothers().length) {
                        embryoTransferGeneration = _.values(seed.mothers_embryo_transfer_generations)[0];
                        if (embryoTransferGeneration) {
                            this.subGen(embryoTransferGeneration.subgen1);
                            this.newGen(embryoTransferGeneration.newgen1);
                            this.subGen2(embryoTransferGeneration.subgen2);
                            this.newGen2(embryoTransferGeneration.newgen2);
                            this.addGen(embryoTransferGeneration.addgen);
                        }
                    }

                    this.birthDate(seed.birth_date);
                }, 0);
            }
        });

        this.errorMessages = ko.pureComputed(() => {
            const res = _.compact([
                this.customStrainName.errorMessage(),
                this.classificationId.errorMessage(),
                this.selectedProjectOptions.errorMessage(),
                this.quantityMales.errorMessage(),
                this.quantityFemales.errorMessage(),
                this.quantityUnknown.errorMessage(),
                this.quantityFilled.errorMessage(),
                this.birthDate.errorMessage(),
                this.generationValid() ? undefined : getTranslation("Invalid generation"),
            ]);

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress() ||
                     this.licenses.fetchInProgress() ||
                     this.classifications.fetchInProgress() ||
                     this.customStrainName.isInvalid() ||
                     this.classificationId.isInvalid() ||
                     this.selectedProjectOptions.isInvalid() ||
                     this.quantityMales.isInvalid() ||
                     this.quantityFemales.isInvalid() ||
                     this.quantityUnknown.isInvalid() ||
                     this.quantityFilled.isInvalid() ||
                     this.birthDate.isInvalid() ||
                     !this.generationValid() ||
                     !this.mothers().length);
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    private setBreedingDefaults = (breedingDefaultId: number) => {
        const breedingDefaultData =_.find(this.breedingDefaults(), (breedingDefault) => {
            return breedingDefault.breeding_setup_id === breedingDefaultId;
        });

        if (breedingDefaultData) {
            // selectem lists
            this.deserializeProject([breedingDefaultData.breeding_setup_id]);

            // ajax loaded lists
            this.licenses.subscribeOnce((licences) => {
                setTimeout(() => {
                    const defaultLicense = _.find(licences, {id: breedingDefaultData.license_id});
                    if (defaultLicense) {
                        this.licenseId(defaultLicense.id);
                    }
                }, 0);
            });

            this.classifications.subscribeOnce((classifications) => {
                setTimeout(() => {
                    const defaultClassification = _.find(classifications, {id: breedingDefaultData.classification_id});
                    if (defaultClassification) {
                        this.classificationId(defaultClassification.id);
                    }
                }, 0);
            });

            // other fields
            if (breedingDefaultData.responsible_id) {
                this.responsibleId(breedingDefaultData.responsible_id);
            }
            this.fullPedigreeLine(breedingDefaultData.full_pedigree_line);
            this.subGen(breedingDefaultData.subgen);
            this.newGen(breedingDefaultData.newgen);
            this.subGen2(breedingDefaultData.subgen2);
            this.newGen2(breedingDefaultData.newgen2);
            this.addGen(breedingDefaultData.addgen);
        }
    };

    // style separator in dropdowns

    public styleLicenseOptions = (opt: HTMLOptionElement, item: {id: number; name: string; disabled: boolean}) => {
        if (item && item.id === -1) {
             opt.className = "delimiter";
             opt.disabled = true;
         }
    };

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.errors([]);
        this.submitInProgress(true);
        this.showLicenseOveruseLimit(false);

        const formData = getFormData({
            cage_id: this.cageId.toString(),
            mothers: JSON.stringify(this.selectedMothers()),
            fatherid: this.fatherId() || "",
            is_fetus: this.isFetus() ? 1 : 0,
            birthdate: this.birthDate() || "",
            nummales: this.quantityMales() || 0,
            numfemales: this.quantityFemales() || 0,
            numunknown: this.quantityUnknown() || 0,
            comments: this.comment() || "",
            pup_strain_id: this.selectedStrainOptions().length ? this.selectedStrainOptions()[0].id : "",
            pup_strain_name: this.customStrainName() || "",
            strain_name_id: this.customStrainNameId() || "",
            responsible_id: this.responsibleId() || "",
            licence_id: this.licenseId() || "",
            classification_id: this.classificationId() || "",
            project_ids: JSON.stringify(_.uniq(_.map(this.selectedProjectOptions(), "id"))),
            full_pedigree_line: this.fullPedigreeLine() || "",
            subgen: this.subGen() || "",
            newgen: this.newGen() || "",
            subgen2: this.subGen2() || "",
            newgen2: this.newGen2() || "",
            addgen: this.addGen() || "",
            confirmed_license_overuse: this.confirmLicenseOveruseLimit() ? 1 : 0,
        });

        fetch("add_birth.py", {method: "POST", body: formData})
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                    if (response.confirm === "confirm_license_overuse") {
                        this.showLicenseOveruseLimit(true);
                    }
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };

    /**
     * Add keypress event to detect if user hits "Enter" on input fields to submit form
     * where native behavior is blocked due to <ko-selectem>-Elements (for whatever reason?!).
     * (=> 19806: save birth event / change in number of pups using enter key)
     */
    public onEnterSubmitForm = (model: AddBirthViewModel, event: KeyboardEvent) => {
        if (event.key == "Enter" && this.canSubmit()) {
            this.submit();
        }

        return true;
    };
}

export const showAddBirth = dialogStarter(AddBirthViewModel, template, params => ({
    name: "AddBirth",
    width: 450,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [],
    title: getTranslation("Enter new birth event"),
}));
