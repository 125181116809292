/**
 * CommentWidget Knockout Component
 *
 * @param seed
 *        Optional. An initial seed, generated by lib.comment.get_widget_seed,
 *        to reduce the number of requests. If this is NOT given, the widget
 *        tries to get the data itself, based on the given subjects.
 *
 * @param subjects
 *        Optional if seed is given. The Subjects, new comments can relate to
 *        and existing comments should be shown for. Will be overwritten by seed.
 *
 * @param enabled
 *        Optional. An Observable to enable or disable all controls.
 *
 * @param blind
 *        Optional. Start the widget in "blind" mode. In this mode, no existent
 *        comments are shown and the submit / rest buttons are hidden.
 *
 * @param maxHeight
 *        Optional. Maximum height of the element in pixels.
 *
 * @param reSeedCallback
 *        Optional. Callback that is called if the seed` data becomes invalid
 *        through changes, with the new `seed` object as first argument. After
 *        this callback was called, the old `seed` can no longer be used.
 *
 * @param afterRenderCallback
 *        Optional. Callback that is called after the comment widget rendering
 *        is completed. Can be used for example for resizeDetailWindow.
 *
 * @param commentWriteBack
 *        Optional. An Observable to write the current new comment object in.
 *        This is useful for quickselect and in blind mode.
 *
 */

import * as ko from "knockout";
import {components, Computed, Observable, ObservableArray} from "knockout";
import * as _ from "lodash";
import {getTranslation} from "../../lib/localize";
import {getFormData} from "../../lib/utils";
import {getUrl} from "../../lib/utils";
import template from "./commentWidget.html";
import "./commentWidget.scss";


interface PossibleAttribute {
    attribute_id: number;
    incoherent: boolean;
    label: string;
    permitted_subjects: string[];
    permitted_users: {
        user_id: number;
        user_fullname: string;
    }[];
}

interface CommentAttribute {
    incoherent: boolean;
    label: string;
    content: string | null;
}

interface Comment {
    comment_id: number;
    reply_pending: boolean;
    reply_to_id: number | null;
    created_string: string;
    origin: string;
    origin_label: string;
    content: string | null;
    creator_fullname: string;
    attributes: CommentAttribute[];
    subjects_summary: string;
}

export interface NewComment {
    reply_pending: boolean;
    origin: string;
    subjects: Subjects;
    comment: string;
    attributes: unknown[];
    reply_to_id: number;
}

type Subjects = { [key: string]: number | number[] };

export interface CommentWidgetSeed {
    blind: boolean;
    subjects: Subjects;
    comments?: Comment[];
    attributes: PossibleAttribute[];
}

type reSeedCallback = (arg0: CommentWidgetSeed) => void;
type afterRenderCallback = () => void;
type commentWriteBack = (arg0: Comment) => void;

export interface CommentWidgetParams {
    origin: string;
    seed: CommentWidgetSeed;
    subjects: Subjects;
    enabled?: Observable<boolean> | boolean;
    blind?: boolean;
    maxHeight?: number;
    reSeedCallback?: reSeedCallback;
    afterRenderCallback?: afterRenderCallback;
    commentWriteBack?: commentWriteBack;
}

class CommentWidgetViewModel {

    // constants
    CREATOR_MIN_HEIGHT = 70;
    URL_SEARCH_EXPRESSION = new RegExp(
        "(\\b(https?):(&#x2F;&#x2F;)[-A-Z0-9+&@#%?=~_|!:,.;/]*[-A-Z0-9+&@#%=~_|;])",
        "ig");

    // component management
    private componentInfo: components.ComponentInfo;
    private reSeedCallback: reSeedCallback;
    private afterRenderCallback: afterRenderCallback;
    private commentWriteBack: commentWriteBack;
    public enabled: Observable<boolean>;
    public blind: Observable<boolean>;
    public maxHeight: number;
    public seedInProgress = ko.observable(false);

    // existing comments
    public origin: string;
    public subjects: Observable<Subjects>;
    public seed: Observable<CommentWidgetSeed>;

    // new comment
    private replyToComment: Computed<Comment>;
    private newCommentData: Computed<NewComment>;
    public selectedAttribute: Observable<CommentAttribute | undefined>;
    public newComment: Observable<string>;
    public newCommentAttributes: ObservableArray<CommentAttribute>;
    public newCommentAttributeValue: Observable<string>;
    public newCommentPlaceholder: Computed<string>;
    public replyPending: Observable<boolean>;
    public replyToId: Observable<number | undefined>;


    // actions

    public addAttribute = () => {
        const newAttribute = _.clone(this.selectedAttribute());

        if (newAttribute.incoherent) {
            newAttribute.content = _.trim(this.newCommentAttributeValue());
        }

        this.newCommentAttributes.push(newAttribute);
        this.newCommentAttributeValue("");
    };

    public removeAttribute = (item: CommentAttribute) => {
        this.newCommentAttributes.remove(item);
    };

    public sendComment = () => {
        this.seedInProgress(true);
        fetch("comment.py", {
            method: "POST",
            body: getFormData({
                add_comment: JSON.stringify(this.newCommentData()),
            }),
        })
            .then((response) => response.json())
            .then((data: { success: boolean; seed?: CommentWidgetSeed }) => {
                if (data.success) {
                    this.seed(data.seed);
                    if (typeof this.reSeedCallback == "function")
                        {this.reSeedCallback(this.seed());}
                }
                this.seedInProgress(false);
                this.clearComment();
            })
            .catch(() => {
                this.seedInProgress(false);
            });
    };

    public clearComment = () => {
        this.newComment("");
        this.newCommentAttributes([]);
        this.newCommentAttributeValue("");
        this.replyPending(false);
        this.replyToId(undefined);
    };

    /** Convert possible url(s) in comment.
     *
     * Url(s) that match URL_SEARCH_PATTERN will be replaced by its  <a>-element representation (hyperlink),
     * having an additional rel attribute ("noopener noreferrer") to prevent partial access to
     * the linking page via the window.opener object.
     * (see https://www.jitbit.com/alexblog/256-targetblank---the-most-underestimated-vulnerability-ever/)
     *
     * @param text - Content being parsed for possible url replacements.
     * @returns Comment text with url(s) converted to hyperlink(s).
     */
    public convertCommentURL = (text: string): string => {
        return text.replace(
            this.URL_SEARCH_EXPRESSION, "<a rel=\"noopener noreferrer\" href=\"$1\" target=\"_blank\">$1</a>"
        );
    };

    // user focus
    public isFocused = (comment: Comment) => {
        if (!this.replyToId()) {
            return true;
        } else if (comment.comment_id === this.replyToId() || comment.reply_to_id === this.replyToId()) {
            return true;
        }
        return false;
    };

    // assist auto sizing of the whole component
    private afterRender = () => {

        _.defer(() => {

            const widget = this.componentInfo.element as HTMLElement;
            const commentCreator = widget?.querySelector(".comment_creator") as HTMLElement;

            // make the comment list scrolling
            if (commentCreator) {
                commentCreator.style.minHeight = `${this.CREATOR_MIN_HEIGHT}px`;
            }

            if (_.isFunction(this.afterRenderCallback)) {
                this.afterRenderCallback();
            }
        });

    };

    constructor({
                    origin,
                    seed,
                    subjects,
                    enabled,
                    blind,
                    maxHeight,
                    reSeedCallback,
                    afterRenderCallback,
                    commentWriteBack,
                }: CommentWidgetParams, componentInfo: components.ComponentInfo) {

        // component management
        this.componentInfo = componentInfo;
        this.blind = ko.observable(seed?.blind || blind);
        this.maxHeight = maxHeight;
        this.enabled = ko.isObservable(enabled) ? enabled : ko.observable(enabled !== false);
        this.reSeedCallback = reSeedCallback;
        this.afterRenderCallback = afterRenderCallback;
        this.commentWriteBack = commentWriteBack;

        // existing comments
        this.origin = origin;
        this.subjects = ko.observable(seed?.subjects || subjects);
        this.seed = ko.observable(seed);

        // new comments
        this.selectedAttribute = ko.observable(undefined);
        this.newComment = ko.observable("");
        this.newCommentAttributes = ko.observableArray([]);
        this.newCommentAttributeValue = ko.observable("");
        this.replyPending = ko.observable(false);
        this.replyToId = ko.observable(undefined);


        this.seed.subscribe((seed) => {
            if (this.subjects() && typeof seed === "undefined") {
                this.seedInProgress(true);
                fetch(getUrl("comment.py", {
                    widget_seed: JSON.stringify({
                        subjects: this.subjects(),
                        blind: this.blind(),
                    }),
                }))
                    .then(response => response.json())
                    .then((data: CommentWidgetSeed) => {
                        this.seed(data);
                        if (data.subjects)
                            {this.subjects(data.subjects);}
                        this.seedInProgress(false);
                    }).catch(() => {
                    this.seedInProgress(false);
                });
            }
        });

        this.replyToComment = ko.pureComputed(() => {
            if (this.seed()) {
                return _.find(this.seed().comments, {comment_id: this.replyToId()});
            }
        });

        this.newCommentPlaceholder = ko.pureComputed(() => {
            if (this.replyToComment()) {
                return _.template(getTranslation("Comment in reply to the comment from <%- created_string %> by <%- creator_fullname %>"))(this.replyToComment());
            }
            return getTranslation("Comment");
        });

        this.newCommentData = ko.pureComputed(() => {
            return {
                origin: this.origin || null,
                reply_pending: this.replyPending(),
                reply_to_id: this.replyToId(),
                subjects: this.subjects(),
                comment: _.trim(this.newComment()) || null,
                attributes: _.map(this.newCommentAttributes(),
                    (a: CommentAttribute) => {
                        return _.pick(a, "attribute_id", "content");
                    }),
            };
        });

        this.newCommentData.subscribe(function (v) {
            if (ko.isObservable(commentWriteBack)) commentWriteBack(v);
        });

        this.seed.valueHasMutated();
        _.defer(this.afterRender);
    }

}

export class CommentWidgetComponent {

    constructor() {

        return {
            viewModel: {
                createViewModel: (params: any, componentInfo: components.ComponentInfo) => {
                    return new CommentWidgetViewModel(params, componentInfo);
                },
            },
            template,
        };
    }
}
