import {Subscribable} from "knockout";
import * as _ from "lodash";
import * as ko from "knockout";
import {Computed} from "knockout";
import {PureComputed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PreselectLocationItem} from "../knockout/components/locationPicker/locationPicker";
import {LocationItem} from "../knockout/components/locationPicker/locationPicker";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import {isInvalidEartagPrefix} from "../lib/utils";
import {isInvalidEartagSuffix} from "../lib/utils";
import {isInvalidCagePrefix} from "../lib/utils";
import {isInvalidCageSuffix} from "../lib/utils";
import {frames} from "../lib/pyratTop";
import template from "./quickselectExport.html";


// The definition of Seed is quite complex here, so we use a namespace knowing it is not recommended.
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Seed {

    interface Context {
        animals: {
            animalid: number[];
            eartag: string;
            cagetype: "Breeding" | "Stock" | "Experiment";
            species_id: number | undefined;
            strain_id: number | undefined;
            state: string;
        }[];
        pups: {
            pupid: number[];
            eartag_or_id: string;
            cagetype: "Breeding" | "Stock" | "Experiment";
            species_id: number | undefined;
            strain_id: number | undefined;
            state: string;
        }[];
        pup_list_was_extended: boolean;
    }

    interface Content {
        export_to_scientist_action?: ExportToScientistAction;
        export_to_institution_action?: ExportToInstitutionAction;
        set_license_action?: SetLicenseAction;
        perform_workrequest_action?: PerformWorkrequestAction;
    }

    interface WorkRequestDetails {
        behavior_name: string;
        new_owner_id: number | undefined;
        change_responsible: boolean | undefined;
        new_responsible_id: number | undefined;
        new_location: PreselectLocationItem | undefined;
        license_id: number | undefined;
        classification_id: number | undefined;
        facility_id: number;
        export_comment: string;
    }

    interface ExportToScientistAction {
        subactions: string[];
        owners: {
            id: number;
            label: string;
            prefixes: string[];
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface ExportToInstitutionAction {
        institutions: {
            id: number;
            name: string;
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface SetLicenseAction {
        licenses: {
            id: number;
            name: string;
        }[];
        workrequest_details?: WorkRequestDetails;
    }

    interface PerformWorkrequestAction {
        workrequest_handling: {
            workrequest_id: number;
            close_workrequest: boolean;
        };
    }
}

interface Response {
    success: boolean;
    context: {
        animals: {
            animal_id: number[];
            eartag: string;
        };
        pups: {
            pup_id: number[];
            eartag: string;
        };
    };
    content: {
        export_to_scientist_action?: {
            not_updated_pup_cage_ids?: number[];
        };
        export_to_institution_action?: any;
        set_license_action?: any;
    };
}

interface Params {
    animalIds?: number[];
    pupIds?: number[];
    actions?: string[];
    reloadCallback?: () => void;
    workrequestId?: number;
    closeWorkrequest?: boolean;
}

abstract class Action {

    public qs: QuickselectExportViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: QuickselectExportViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);
    }

    public serialize = () => {
        return {};
    };
}


// TODO: Waiting for "inherit" from in TypeScript to replace "Action" return value with implementation.
// noinspection JSPotentiallyInvalidUsageOfThis
const actionModels: { [key in keyof Seed.Content]: (qs: QuickselectExportViewModel, seed: Seed.Content[key]) => Action } = {

    export_to_scientist_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        // subactions (checkboxes)
        public subactionAnimalAssignId: Observable<boolean>;
        public subactionAnimalSetResponsible: Observable<boolean>;
        public subactionCageSetLocation: Observable<boolean>;
        public subactionCageAssignId: Observable<boolean>;
        public subactionCageSetResponsible: Observable<boolean>;
        public subactionNewCageMoveAnimals: Observable<boolean>;
        public subactionExportStrains: Observable<boolean>;
        public subactionExportProjects: Observable<boolean>;

        public ownerId: CheckExtended<Observable<number>>;
        public comment: Observable<string>;
        public prefixes: Computed<string[]>;
        public availableResponsibles: FetchExtended<Observable<{
            userid: number;
            fullname: string;
        }>>;

        // animal assign id
        public animalPrefixFromList: CheckExtended<Observable<string>>;
        public animalPrefix: CheckExtended<Observable<string>>;
        public animalKeepSuffix: Observable<boolean>;
        public animalSuffix: CheckExtended<Observable<string>>;
        public animalNextFreeEartag: FetchExtended<Observable<AjaxResponse<{eartag: string}>>>;
        public animalNextFreeEartagError: Observable<string>;

        // animal set responsible:
        public animalResponsibleId: Observable<number>;

        // cage assign id
        public cagePrefixFromList: CheckExtended<Observable<string>>;
        public cagePrefix: CheckExtended<Observable<string>>;
        public cageSuffix: CheckExtended<Observable<string>>;
        public cageNextFreeSuffix: FetchExtended<Observable<{Breeding?: string; Stock?: string; Experiment?: string}>>;

        // cage set location
        public cageSelectedLocation: CheckExtended<Observable<LocationItem>>;
        public cageUnselectLocation: Observable<string>;
        public cagePreselectLocation: Observable<PreselectLocationItem>;
        public cageRackId: Computed<number>;
        public cagePositions: Observable<string>;
        public cageConfirmSanitaryStatus: Observable<string>;

        // move to new cage
        public newCageSelectedLocation: CheckExtended<Observable<LocationItem>>;
        public newCageUnselectLocation: Observable<string>;
        public newCagePreselectLocation: Observable<PreselectLocationItem>;
        public newCageRackId: Computed<number>;
        public newCagePrefix: CheckExtended<Observable<string>>;
        public newCageType: Observable<string>;
        public newCageCategoryId: CheckExtended<Observable<number>>;
        public newCageOpenManyNewCages: Observable<boolean>;
        public newCagePerCage: CheckExtended<Observable<number>>;
        public newCageKeepAnimalsTogether: Observable<boolean>;
        public newCageFillMethod: Observable<string>;
        public newCagePositions: Observable<string>;
        public newCageConfirmSanitaryStatus: Observable<string>;

        // cage set responsible
        public cageResponsibleId: Observable<number>;
        public enableCageSetResponsible: Computed<boolean>;

        constructor() {
            super(qs);

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            // show prefixes depending on the selected owner
            this.prefixes = ko.pureComputed(() => {
                if (this.ownerId()) {
                    const owner = _.find(seed.owners, {id: this.ownerId()});
                    return owner.prefixes;
                }
                return [];
            });

            // load responsibles according to the selected owner
            this.availableResponsibles = ko.observable().extend({
                fetch: {
                    undefined: [],
                    disable: ko.pureComputed(() => {
                        return !this.ownerId()
                                 || this.ownerId.isInvalid();
                    }),
                    fn: (signal) => {
                        return fetch("ajax/user_service.py", {
                            method: "POST",
                            body: getFormData({
                                function: "get_responsibles_of_user",
                                userid: JSON.stringify(this.ownerId()),
                            }),
                            signal,
                        });
                    },
                },
            });

            /* animal assign id */

            this.subactionAnimalAssignId = ko.observable(false);
            this.subactionAnimalAssignId.subscribe(() => {
               this.selected(true);
            });

            this.animalPrefixFromList = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionAnimalAssignId()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.animalPrefix = ko.observable()
                .extend({normalize: _.trim})
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionAnimalAssignId()) {
                            return !v || isInvalidEartagPrefix(v);
                        }
                        return false;
                    },
                });

            this.animalPrefix.subscribe(() => {
                this.subactionAnimalAssignId(true);
            });

            this.animalKeepSuffix = ko.observable(true);
            this.animalSuffix = ko.observable()
                .extend({normalize: _.trim})
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionAnimalAssignId() && !this.animalKeepSuffix()) {
                            return !v || isInvalidEartagSuffix(v);
                        }
                        return false;
                    },
                });

            // copy over the selected prefix from dropdown to text field
            this.animalPrefixFromList.subscribe(() => {
                this.animalPrefix(this.animalPrefixFromList());
            });

            this.animalNextFreeEartagError = ko.observable();

            // load next free suffix according to selected prefix
            // do not send the ajax call until config.PREFEARTAGSIZE characters are given
            this.animalNextFreeEartag = ko.observable().extend({
                fetch: {
                    undefined: {},
                    disable: ko.pureComputed(() => {
                        return !this.animalPrefix()
                                 || this.animalPrefix.isInvalid()
                                 || this.animalKeepSuffix();
                    }),
                    fn: (signal) => {
                        return fetch("ajax_service.py", {
                            method: "POST",
                            body: getFormData({
                                function: "get_next_free_eartag",
                                eartag_prefix: this.animalPrefix(),
                            }),
                            signal,
                        });
                    },
                },
            });

            // extract next free suffix from next free eartag
            this.animalNextFreeEartag.subscribe((v) => {
                this.animalSuffix("");
                this.animalNextFreeEartagError("");
                if (v) {
                    if (v.success === true) {
                        this.animalSuffix(v.eartag.split("-")[1]);
                    } else {
                        // warning (e.g. "There are not enough IDs available for this prefix")
                        this.animalNextFreeEartagError(v.message);
                    }
                }
            });

            /* cage assign id */

            this.subactionCageAssignId = ko.observable(false);
            this.subactionCageAssignId.subscribe(() => {
                this.selected(true);
            });

            this.cagePrefixFromList = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionCageAssignId()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.cagePrefix = ko.observable()
                .extend({normalize: _.trim})
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionCageAssignId()) {
                            return !v || isInvalidCagePrefix(v);
                        }
                        return false;
                    },
                });
            this.cagePrefix.subscribe(() => {
                this.subactionCageAssignId(true);
            });

            this.cageSuffix = ko.observable()
                .extend({normalize: _.trim})
                .extend({
                    invalid: (v) => {
                        if (this.selected() && this.subactionCageAssignId()) {
                            return !v || isInvalidCageSuffix(v);
                        }
                        return false;
                    },
                });

            // copy over the selected prefix from dropdown to text field
            this.cagePrefixFromList.subscribe(() => {
                this.cagePrefix(this.cagePrefixFromList());
            });

            // load next free cage suffix according to selected prefix
            // returns an object of all types where type is key and number is value.
            // eg: {'Breeding': '01454', 'Experiment': '00001', 'Stock': '12523'}
            this.cageNextFreeSuffix = ko.observable().extend({
                fetch: {
                    undefined: {},
                    disable: ko.pureComputed(() => {
                        return !this.cagePrefix()
                                 || this.cagePrefix.isInvalid();
                    }),
                    fn: (signal) => {
                        return fetch("ajax_service.py", {
                            method: "POST",
                            body: getFormData({
                                function: "get_next_free_cagenumber_suffix",
                                cage_type: "__all__",
                                prefix: this.cagePrefix(),
                            }),
                            signal,
                        });
                    },
                },
            });

            // load next free cage suffix into selectedCageSuffix
            // depending on the cage types of the selected animals
            this.cageNextFreeSuffix.subscribe((v) => {
                const cagetypesFromSelectedAnimals: string[] = [];
                const suffixes = [];
                let minSuffix;

                if (v) {
                    _.forEach(qs.context().animals, (a) => {
                        if (!cagetypesFromSelectedAnimals.includes(a.cagetype)) {
                            cagetypesFromSelectedAnimals.push(a.cagetype);
                        }
                    });
                    _.forEach(qs.context().pups, (p) => {
                        if (!cagetypesFromSelectedAnimals.includes(p.cagetype)) {
                            cagetypesFromSelectedAnimals.push(p.cagetype);
                        }
                    });

                    if (cagetypesFromSelectedAnimals.includes("Breeding")) {
                         suffixes.push(v.Breeding);
                    }
                    if (cagetypesFromSelectedAnimals.includes("Stock")) {
                         suffixes.push(v.Stock);
                    }
                    if (cagetypesFromSelectedAnimals.includes("Experiment")) {
                         suffixes.push(v.Experiment);
                    }
                    // display the lowest suffix in case of different cage types
                    minSuffix = suffixes.sort()[0];
                    this.cageSuffix(minSuffix);
                } else {
                    this.cageSuffix("");
                }
            });

            this.subactionAnimalSetResponsible = ko.observable();
            this.subactionAnimalSetResponsible.subscribe(() => {
               this.selected(true);
            });

            this.animalResponsibleId = ko.observable();
            this.animalResponsibleId.subscribe(() => {
                this.subactionAnimalSetResponsible(true);
            });

            /* cage set location */

            this.subactionCageSetLocation = ko.observable();
            this.subactionCageSetLocation.subscribe(() => {
               this.selected(true);
            });

            this.cageSelectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionCageSetLocation()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                        return (v && !v.rack_id) ? getTranslation("Please select a rack") : false;
                    }
                    return false;
                },
            });
            this.cageSelectedLocation.subscribe(() => {
                this.subactionCageSetLocation(true);
            });
            this.cagePreselectLocation = ko.observable();
            this.cageUnselectLocation = ko.observable();
            this.cageRackId = ko.computed(() => {
                return this.cageSelectedLocation() ? this.cageSelectedLocation().rack_id : undefined;
            });
            this.cagePositions = ko.observable();
            this.cageConfirmSanitaryStatus = ko.observable();

            /* move to new cage */

            this.subactionNewCageMoveAnimals = ko.observable();
            this.subactionNewCageMoveAnimals.subscribe(() => {
               this.selected(true);
            });

            this.newCageSelectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                        return (v && !v.rack_id) ? getTranslation("Please select a rack") : false;
                    }
                    return false;
                },
            });
            this.newCageSelectedLocation.subscribe(() => {
                this.subactionNewCageMoveAnimals(true);
            });
            this.newCagePreselectLocation = ko.observable();
            this.newCageUnselectLocation = ko.observable();
            this.newCageRackId = ko.computed(() => {
                return this.newCageSelectedLocation() ? this.newCageSelectedLocation().rack_id : undefined;
            });
            this.newCagePositions = ko.observable();
            this.newCageConfirmSanitaryStatus = ko.observable();

            this.newCagePrefix = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.newCageType = ko.observable();
            this.newCageCategoryId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals()) {
                        return !v;
                    }
                    return false;
                },
            });

            this.newCageOpenManyNewCages = ko.observable(false);
            this.newCagePerCage = ko.observable(0);
            this.newCageKeepAnimalsTogether = ko.observable(false);
            this.newCageFillMethod = ko.observable("cage_fillevenly");

            this.newCageOpenManyNewCages.subscribe(() => {
                this.subactionNewCageMoveAnimals(true);
            });

            this.newCagePerCage.extend({
                invalid: (v) => {
                    if (this.selected() && this.subactionNewCageMoveAnimals() &&
                            this.newCageOpenManyNewCages() && !this.newCageKeepAnimalsTogether()) {
                        if (!v || v <= 0) {
                            return true;
                        }
                        if (!(String(v).match(/^\d+$/))) {
                            return getTranslation("Invalid number");
                        }
                    }
                    return false;
                },
            });

            // activate 'open many cages' checkbox when 'animals per cage' is entered
            this.newCagePerCage.subscribe(() => {
                this.newCageOpenManyNewCages(true);
            });

            /* cage set responsible */

            this.subactionCageSetResponsible = ko.observable();
            this.subactionCageSetResponsible.subscribe(() => {
               this.selected(true);
            });

            this.cageResponsibleId = ko.observable();
            this.cageResponsibleId.subscribe(() => {
                this.subactionCageSetResponsible(true);
            });

            // disable 'set cage responsible' if the option 'move to new cage' is available
            // but not activated, because it will set the responsible of the new cage
            this.enableCageSetResponsible = ko.computed(() => {
                return this.subactionNewCageMoveAnimals();
            });

            // clear 'set cage responsible' checkbox whenever it get's deactivated
            this.enableCageSetResponsible.subscribe((v) => {
                if (!v) {
                    this.subactionCageSetResponsible(false);
                }
            });

            /* export strains / projects */

            this.subactionExportStrains = ko.observable(false);
            this.subactionExportStrains.subscribe(() => {
               this.selected(true);
            });

            this.subactionExportProjects = ko.observable(false);
            this.subactionExportProjects.subscribe(() => {
               this.selected(true);
            });

            // values from work request details
            if (seed?.workrequest_details?.behavior_name == "exp_to_scientist") {
                this.selected(true);
                this.ownerId(seed?.workrequest_details?.new_owner_id);

                if (seed.subactions.includes("animal_set_responsible") && seed.workrequest_details?.change_responsible) {
                    this.subactionAnimalSetResponsible(true);
                    this.availableResponsibles.subscribeOnce(() => {
                        setTimeout(() => {
                            this.animalResponsibleId(seed?.workrequest_details?.new_responsible_id);
                        }, 0);
                    });
                }
                if (seed.subactions.includes("cage_set_responsible") && seed.workrequest_details?.change_responsible) {
                    this.subactionCageSetResponsible(true);
                    this.availableResponsibles.subscribeOnce(() => {
                        setTimeout(() => {
                            this.cageResponsibleId(seed?.workrequest_details?.new_responsible_id);
                        }, 0);
                    });
                }
                if (seed.subactions.includes("cage_set_location") && seed.workrequest_details?.new_location) {
                    this.subactionCageSetLocation(true);
                    this.cagePreselectLocation(seed.workrequest_details?.new_location);
                }
                if (seed.subactions.includes("new_cage_move_animals") && seed.workrequest_details?.new_location) {
                    this.subactionNewCageMoveAnimals(true);
                    this.newCagePreselectLocation(seed.workrequest_details?.new_location);
                }
            }

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !(this.ownerId.isInvalid() ||
                         this.animalPrefixFromList.isInvalid() ||
                         this.animalPrefix.isInvalid() ||
                         this.animalSuffix.isInvalid() ||
                         this.cagePrefixFromList.isInvalid() ||
                         this.cagePrefix.isInvalid() ||
                         this.cageSuffix.isInvalid() ||
                         this.cageSelectedLocation.isInvalid() ||
                         this.newCageSelectedLocation.isInvalid() ||
                         this.newCagePrefix.isInvalid() ||
                         this.newCageCategoryId.isInvalid() ||
                         this.newCagePerCage.isInvalid());
            });
        }

        public serialize = () => {
            return {
                owner_id: this.ownerId(),
                comment: this.comment(),
                subactions: {
                    ...(this.subactionAnimalAssignId() && {
                        animal_set_id: {
                            animal_prefix: this.animalPrefix(),
                            keep_animal_suffix: this.animalKeepSuffix(),
                            animal_suffix: this.animalSuffix(),
                        },
                    }),
                    ...(this.subactionAnimalSetResponsible() && {
                        animal_set_responsible: {
                            animal_responsible_id: this.animalResponsibleId(),
                        },
                    }),
                    ...(this.subactionCageSetLocation() && {
                        cage_set_location: {
                            rack_id: this.cageRackId(),
                            cage_positions: this.cagePositions(),
                            confirm_sanitary_status: this.cageConfirmSanitaryStatus(),
                        },
                    }),
                    ...(this.subactionCageAssignId() && {
                        cage_set_id: {
                            cage_prefix: this.cagePrefix(),
                            cage_suffix: this.cageSuffix(),
                        },
                    }),
                    ...(this.subactionNewCageMoveAnimals() && {
                        new_cage_move_animals: {
                            rack_id: this.newCageRackId(),
                            cage_prefix: this.newCagePrefix(),
                            cage_type: this.newCageType(),
                            cage_category_id: this.newCageCategoryId(),
                            cage_positions: this.newCagePositions(),
                            open_many_new_cages: this.newCageOpenManyNewCages(),
                            animals_per_cage: this.newCagePerCage(),
                            keep_animals_together: this.newCageKeepAnimalsTogether(),
                            cage_fillmethod: this.newCageFillMethod(),
                            confirm_sanitary_status: this.newCageConfirmSanitaryStatus(),
                        },
                    }),
                    ...(this.subactionCageSetResponsible() && {
                        cage_set_responsible: {
                            cage_responsible_id: this.cageResponsibleId(),
                        },
                    }),
                    ...(this.subactionExportStrains() && {
                        export_strains: {},
                    }),
                    ...(this.subactionExportProjects() && {
                        export_projects: {},
                    }),
                },
            };
        };

    }),

    export_to_institution_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public institutionId: CheckExtended<Observable<number>>;
        public comment: Observable<string>;

        constructor() {
            super(qs);

            this.institutionId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.institutionId.subscribe(() => {
                this.selected(true);
            });

            this.comment = ko.observable();
            this.comment.subscribe(() => {
               this.selected(true);
            });

            // values from work request details
            if (seed?.workrequest_details?.behavior_name === "exp_to_facility") {
                this.selected(true);
                this.institutionId(seed?.workrequest_details?.facility_id || undefined);
                this.comment(seed?.workrequest_details?.export_comment || undefined);
            }

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }


                return !this.institutionId.isInvalid();
            });
        }

        public serialize = () => {
            return {
                institution_id: this.institutionId(),
                comment: this.comment(),
            };
        };

    }),

    set_license_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public strainIds: Computed<number[]>;
        public speciesIds: Computed<number[]>;
        public noSpeciesSelected: Computed<boolean>;
        public multipleSpeciesSelected: Computed<boolean>;
        public commonSpeciesId: Computed<number>;
        public licenseId: CheckExtended<Observable<number>>;
        public classificationId: CheckExtended<Observable<number>>;

        public availableLicenses: FetchExtended<ObservableArray<{
            id: number;
            name: string;
            valid_from: string;
            license_type_id: number;
            budget_name?: string;
        }>>;
        public availableClassifications: FetchExtended<ObservableArray<{
            id: number;
            licence_id: number;
            name: string;
            severity_level_id: number;
            animal_number: number;
            used: number;
        }>>;

        public licenseAssignDate: CheckExtended<Observable<string>>;
        public overuseLicense: Observable<boolean>;

        constructor() {
            super(qs);

            this.strainIds = ko.computed(() => {
                return _.chain(
                    _.concat((qs.context().animals).map((animalData) => {
                        return animalData.strain_id || 0;
                    }), (qs.context().pups).map((pupData) => {
                        return pupData.strain_id || 0;
                    }))).uniq().value();
            });

            this.speciesIds = ko.computed(() => {
                return _.chain(
                    _.concat((qs.context().animals).map((animalData) => {
                        return animalData.species_id;
                    }), (qs.context().pups).map((pupData) => {
                        return pupData.species_id;
                    }))).uniq().value();
            });

            this.noSpeciesSelected = ko.computed(() => {
                return !!(this.speciesIds().length === 1 && !this.speciesIds()[0]);
            });

            this.multipleSpeciesSelected = ko.computed(() => {
                return (this.speciesIds().length > 1);
            });

            this.commonSpeciesId = ko.computed(() => {
                if (this.speciesIds().length === 1 && this.speciesIds()[0]) {
                    return this.speciesIds()[0];
                }
            });

            this.licenseId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v && getTranslation("Please select a license");
                    }
                    return false;
                },
            });
            this.availableLicenses = ko.observableArray().extend({
                fetch: {
                    undefined: [],
                    disable: ko.pureComputed(() => !this.commonSpeciesId()),
                    fn: (signal) => {
                        return fetch("ajax_service.py", {
                            method: "POST",
                            body: getFormData({
                                function: "get_licenses_for_setting",
                                species_id: String(this.commonSpeciesId()),
                                strain_id: JSON.stringify(this.strainIds()),
                            }),
                            signal,
                        });
                    },
                },
            });

            this.classificationId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v && getTranslation("Please select a classification");
                    }
                    return false;
                },
            });
            this.availableClassifications = ko.observableArray().extend({
                fetch: {
                    undefined: [],
                    disable: ko.pureComputed(() => !this.commonSpeciesId() || !this.licenseId()),
                    fn: (signal) => {
                        return fetch("ajax_service.py", {
                            method: "POST",
                            body: getFormData({
                                function: "get_license_classifications_for_setting",
                                license_id: String(this.licenseId()),
                                species_id: String(this.commonSpeciesId()),
                                strain_id: JSON.stringify(this.strainIds()),
                            }),
                            signal,
                        });
                    },
                },
            });

            this.licenseAssignDate = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        if (v && isInvalidCalendarDate(v)) {
                            return getTranslation("Invalid date");
                        }
                    }
                    return false;
                },
            });

            this.overuseLicense = ko.observable(false);

            // values from work request details
            if (seed?.workrequest_details?.behavior_name === "exp_to_scientist" || seed?.workrequest_details?.behavior_name === "exp_to_facility") {
                if (seed?.workrequest_details?.license_id || seed?.workrequest_details?.classification_id) {
                    this.selected(true);

                    this.availableLicenses.subscribeOnce(() => {
                        setTimeout(() => {
                            this.licenseId(seed?.workrequest_details?.license_id);
                        }, 0);
                    });
                    this.availableClassifications.subscribeOnce(() => {
                        setTimeout(() => {
                            this.classificationId(seed?.workrequest_details?.classification_id);
                        }, 0);
                    });
                }
            }

            this.enabled = ko.pureComputed(() => {
                const allLiveAnimals = qs.context().animals
                    .map((data) => { return data.state; })
                    .concat(qs.context().pups.map((data) => { return data.state; }))
                    .every((state) => state == "live");

                return !!this.commonSpeciesId()
                    && (allLiveAnimals || session.userPermissions.animal_not_live_set_license);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.licenseAssignDate.errorMessage()) {
                    this.errors.push(this.licenseAssignDate.errorMessage());
                }

                return !(this.licenseId.isInvalid() ||
                         this.classificationId.isInvalid() ||
                         this.licenseAssignDate.isInvalid());
            });
        }

        public serialize = () => {
            return {
                classification_id: this.classificationId(),
                assign_date: this.licenseAssignDate() || undefined,
                confirmed_overuse: this.overuseLicense(),
            };
        };
    }),

    perform_workrequest_action: (qs, seed) => new class extends Action {

        public workrequestId = seed.workrequest_handling.workrequest_id;
        public requireConclusion = false;

        public closeWorkrequest: Observable<boolean>;

        public serialize = () => ({
            workrequest_id: seed.workrequest_handling.workrequest_id,
            close_workrequest: seed.workrequest_handling.close_workrequest,
        });

        constructor() {
            super(qs);
            this.selected(!!seed.workrequest_handling.workrequest_id);
        }

    },
};


class QuickselectExportViewModel {

    public reloadRequired: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<{ context: Seed.Context; content: Seed.Content }>>>;
    public context: PureComputed<Seed.Context | undefined>;
    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    public actions: PureComputed<{ [key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]> }>;
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    private readonly params: Params;
    private readonly dialog: KnockoutPopup;
    private scenery: PureComputed<{ loading: boolean } |
        { conclusion: QuickselectExportViewModel } |
        { context: QuickselectExportViewModel; actions: QuickselectExportViewModel } |
        { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.params = params;
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        // get initial data
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch("quickselect_export.py", {
                    method: "POST",
                    body: getFormData({
                        action: "get_options",
                        data: JSON.stringify({
                            actions: this.params.actions,
                            context: {
                                animal_ids: this.params.animalIds || [],
                                pup_ids: this.params.pupIds || [],
                            },
                            workrequest_id: this.params.workrequestId,
                            close_workrequest: this.params.closeWorkrequest,
                        }),
                    }), signal,
                });
            },
        });

        this.context = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed?.context;
            } else {
                return undefined;
            }
        });

        // initialize the actions with their seeded data
        this.actions = ko.pureComputed(() => {
            const actions: {[key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]>} = {};
            const seed = this.seed();
            if (seed?.success && seed?.content) {
                Object.keys(seed.content).forEach((actionName: keyof Seed.Content) => {
                    if (actionName in actionModels) {
                        // @ts-expect-error: TODO: Typing is too complicated here. I did not get it right.
                        // Waiting for "inherit" from implementation in TypeScript.
                        actions[actionName] = actionModels[actionName](this, seed.content[actionName]);
                    }
                });
            }
            return actions;
        });


        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {

            if (this.seed.fetchInProgress()) {
                return {loading: true};
            }

            if (this.conclusion().length) {
                return {conclusion: this};
            }

            if (this.seed() && this.seed().success) {
                return {context: this, actions: this};
            }

            return {error: true};

        });

    }

     public showAnimalDetails = (animalId: number) => {
        frames.detailPopup.open(getUrl("mousedetail.py", {
            animalid: animalId,
        }));
    };

    public showPupDetails = (pupId: number) => {
        frames.detailPopup.open(getUrl("pupdetail.py", {
            animalid: pupId,
        }));
    };

    public close = () => {
        this.dialog.close();
        if (typeof this.params.reloadCallback === "function" && this.reloadRequired()) {
            this.params.reloadCallback();
        }
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.context().animals.length < 1 && this.context().pups.length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {

        this.errors.removeAll();

        const data = {
            context: {animal_ids: _.map(this.context().animals, "animalid"), pup_ids: _.map(this.context().pups, "pupid")},
            content: _
                .chain(this.actions())
                .pickBy((a) => {
                    return a.selected();
                })
                .mapValues((a) => {
                    return a.serialize();
                })
                .value(),
        };

        this.applyInProgress(true);
        this.reloadRequired(true);
        _.forEach(this.actions(), (action) => {
            action.errors.removeAll();
        });

        fetch("quickselect_export.py", {
            method: "POST",
            body: getFormData({
                action: "post_data",
                data: JSON.stringify(data),
            },
        )})
            .then((response) => response.json())
            .then((response: Response) => {

                if (response.success) {

                    this.conclude(response);

                    const autoClose = !_.some(response?.content, (action_data, action: keyof Response["content"]) => {
                        return this.actions()[action] && this.actions()[action].requireConclusion;
                    });

                    if (autoClose) {
                        // no action requires a conclusion, so we immediately close it
                        this.close();
                    }

                } else {
                    // client error
                    _.forEach(response.content, (messages, name: keyof Response["content"]) => {
                        if (this.actions()[name] && this.actions()[name].errors) {
                            _.forEach(messages, (m) => {
                                this.actions()[name].errors.push(m);
                            });
                        } else {
                            _.forEach(messages, (m) => {
                                this.errors.push(m);
                            });
                        }
                    });
                }
            })
            .catch(() => {
                this.errors.push(getTranslation("General quickselect error."));
            })
            .finally(() => this.applyInProgress(false));

    };

    private conclude = (value: Response) => {

        // clear old results
        this.conclusion.removeAll();

        const notUpdatedPupCageIds = value?.content?.export_to_scientist_action?.not_updated_pup_cage_ids;

        if (notUpdatedPupCageIds?.length) {
            this.conclusion.push({
                text:
                    getTranslation("Attention: Active litters with several possible mothers exist in cages") + "." +
                    getTranslation("Ownership of pups not automatically changed. Please use the pup list to change the ownership of these pups.") +
                    " " + _.template(getTranslation("Show cages (<%- count %>)."))({count: notUpdatedPupCageIds.length}),
                click: () => {
                    this.reloadRequired(false);
                    window.top.mainMenu.filterSubtab("cages", {cageid: notUpdatedPupCageIds});
                },
            });
        }

        if (!this.conclusion().length) {
            // no conclusion was needed, so we close it
            this.close();
        }
    };
}


// dialog starter
export const showQuickselectExport = dialogStarter(QuickselectExportViewModel, template, {
    name: "QuickselectExport",
    width: 600,
    cssARequire: [
        ":table.css",
        ":quick_select.css",
        ":usercolors.css"],
    anchor: {
        top: 5,
        right: 5,
    },
    closeOthers: true,
    title: getTranslation("Quick Select - Export animals"),
});
