import * as ko from "knockout";
import {Subscription} from "knockout";

type SubscriptionOnceCallback<T, TTarget> = (this: TTarget, val: T) => void;

declare module "knockout" {

    export interface SubscribableFunctions {
        subscribeOnce?<T, TTarget>(callback?: SubscriptionOnceCallback<T, TTarget>, callbackTarget?: TTarget, event?: string): Subscription;
    }

    export interface Subscribable<T> {
        subscribeOnce(callback: (newValue: T) => void, target?: any, event?: "change"): Subscription;
    }

}

// extension to subscribe to an observable only once
ko.subscribable.fn.subscribeOnce = function <T, TTarget>(callback: SubscriptionOnceCallback<T, TTarget>, callbackTarget: TTarget, event: string): Subscription {
    const subscription = this.subscribe(function (newValue: T) {
        subscription.dispose();
        callback.call(callbackTarget, newValue);
    }, callbackTarget, event);
    return subscription;
};
