interface SessionValues {
    sessionId?: string;
    pyratVersion: {
        version: string;
        long: string;
        commit: string;
    };
    pyratConf: { [key: string]: any };
    localesConf: {
        decimalSymbol: string;
        timeShorthand: string;
        weekendDays: number[];
    };
    userPermissions: { [key: string]: boolean };
    userId: number;
    userName: string;
}

export let session: SessionValues = window.top?.pyratFrontend?.session;

export const setSessionValues = (sessionConstants: SessionValues) => {
    if (window !== window.top) {
        throw "not int top context";
    }
    else if (window.top.pyratFrontend.session) {
        throw "session is already set";
    }
    session = sessionConstants;
    window.top.pyratFrontend.session = sessionConstants;
};
