import {AllBindings} from "knockout";
import * as ko from "knockout";

declare module "knockout" {
    export interface BindingHandlers {
        maxLength?: {
            init(element: HTMLTextAreaElement, valueAccessor: () => number, allBindings: AllBindings): void;
        };
    }

}

// Since maxlength attribute on textarea elements is a HTML5 feature, here is a ko
// binding to fill the gap. It works for value and textInput bindings and cuts the
// text after the given number of characters.
ko.bindingHandlers.maxLength = {
    init: function (element, valueAccessor, allBindings) {
        const maxLength = valueAccessor();
        const valueBinding = allBindings.get("value");
        const textInputBinding = allBindings.get("textInput");

        element.maxLength = maxLength;

        if (ko.isObservable(valueBinding)) {
            valueBinding.subscribe((newValue) => {
                if (newValue && newValue.length > maxLength) {
                    valueBinding(newValue.substr(0, maxLength));
                }
            });
        }

        if (ko.isObservable(textInputBinding)) {
            textInputBinding.subscribe((newValue) => {
                if (newValue && newValue.length > maxLength) {
                    textInputBinding(newValue.substr(0, maxLength));
                }
            });
        }
    },
};
