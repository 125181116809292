import * as ko from "knockout";
import {Observable} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {AjaxResponse, EmptyObject} from "../lib/utils";
import template from "./setCohortId.html";

interface AnimalReference {
    animalId: number;
}

interface PupReference {
    pupId: number;
}

interface Params {
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

class SetCohortIdViewModel {

    // params
    private readonly dialog;
    private readonly params;

    // state
    private readonly cohortId: Observable<string>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly updateInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<{
        cohort_id: string;
        eartag_or_id: string | number;
    }>>>;

    constructor(params: Params & (AnimalReference | PupReference), dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.params = params;

        this.cohortId = ko.observable("");
        this.reloadRequired = ko.observable(false);
        this.updateInProgress = ko.observable(false);
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const form = getFormData({});
                if ("animalId" in params) {
                    form.append("function", "get_animal_cohort_id");
                    form.append("animal_id", String(params.animalId));
                } else if ("pupId" in params) {
                    form.append("function", "get_pup_cohort_id");
                    form.append("pup_id", String(params.pupId));
                }
                return fetch("cohort_id_service.py", {method: "POST", body: form, signal});
            },
        });

        this.seed.subscribe((v) => {
            if (v.success) {
                this.cohortId(v.cohort_id);
                this.dialog.setTitle(getTranslation("Set cohort ID for %s.").replace("%s", String(v.eartag_or_id)));
            }
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.params.closeCallback) {
                this.params.closeCallback();
            }

            if (this.params.reloadCallback && this.reloadRequired()) {
                this.params.reloadCallback();
            }
        });
    }

    public applyLabel = () => {
        this.reloadRequired(true);
        this.updateInProgress(true);

        const form = getFormData({
            cohort_id: this.cohortId(),
        });
        if ("animalId" in this.params) {
            form.append("function", "set_animal_cohort_id");
            form.append("animal_id", String(this.params.animalId));
        } else if ("pupId" in this.params) {
            form.append("function", "set_pup_cohort_id");
            form.append("pup_id", String(this.params.pupId));
        }

        return fetch("cohort_id_service.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<EmptyObject>) => {
                if (response.success === true) {
                    this.dialog.close();
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                notifications.showNotification(getTranslation("General error."), "error");
            })
            .finally(() => this.updateInProgress(false));
    };

}

export const showSetCohortId = dialogStarter(SetCohortIdViewModel, template, params => ({
    name: "SetCohortId",
    width: 300,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    title: getTranslation("Set cohort ID."),
}));
