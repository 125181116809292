/**
 * Show a popup to set the cage label.
 *
 * @param cageId: Database ID of the cage.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setCageLabel.html";

interface Params {
    cageId: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    cage_label: string;
}

class SetCageLabelViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly cageId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly cageLabel: Observable<string>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorMessages: ko.PureComputed<string[]>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.cageId = params.cageId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.cageLabel = ko.observable();

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("set_cage_label.py", {cage_id: this.cageId}), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                setTimeout(() => {
                    this.cageLabel(seed.cage_label);
                }, 0);
            }
        });

        this.errorMessages = ko.pureComputed(() => {
            const res: string[] = [];

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        const formData = getFormData({
            cage_id: this.cageId.toString(),
            cage_label: this.cageLabel(),
        });

        fetch("set_cage_label.py", {method: "POST", body: formData})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetCageLabel = dialogStarter(SetCageLabelViewModel, template, params => ({
    name: "SetCageLabel",
    width: 320,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: params.title,
}));
