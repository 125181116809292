import * as ko from "knockout";
import * as _ from "lodash";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {PureComputed} from "knockout";
import {showTankDetails} from "../dialogs";
import {LocationItem} from "../knockout/components/locationPicker/locationPicker";
import {PreselectLocationItem} from "../knockout/components/locationPicker/locationPicker";
import {FetchExtended} from "../knockout/extensions/fetch";
import {setSessionItem} from "../lib/browserStorage";
import {getSessionItem} from "../lib/browserStorage";
import {getTranslation} from "../lib/localize";
import {session} from "../lib/pyratSession";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import "./buildingMap.scss";
import {notifications} from "../lib/pyratTop";
import {frames} from "../lib/pyratTop";

interface WorkRequest {
    id: number;
}

interface MedicalCondition {
    id: number;
    name: string;
}

interface Cage {
    cageid: number;
    animal_medical_conditions: MedicalCondition[];
    requests: WorkRequest[];
}

interface Tank {
    tank_id: number;
    medical_conditions: MedicalCondition[];
    requests: WorkRequest[];
}

interface Cell {
    occupied: boolean;
    position: string;
    cages: Cage[];
    tanks: Tank[];
}

interface Detail {
    value: string;
    label: string;
}

interface RackPosition {
    width: number;
}

type RackRow = RackPosition[];

interface Rack {
    rack_id: number;
}

interface Location {
    id: string;
    db_id: number;
    building_id: number;
    area_id: number;
    room_id: number;
    rack_id: number;
    parent_id: string;
    type: "building" | "area" | "room" | "rack";
    name: string;
}

interface Arguments {
    locations: Location[];
    location_id: string;
}

class BuildingMapDetails {

    public available: Detail[] = [
        {value: "owner_full_name", label: getTranslation("Owner")},
        {value: "label", label: getTranslation("Label")},
        {value: "responsible_full_name", label: getTranslation("Responsible")},
        {value: "strain_name", label: getTranslation("Line / Strain")},
        {value: "projects", label: getTranslation("Project")},
        {value: "licence_number", label: getTranslation("License number")},
        {value: "number_of_animals", label: getTranslation("Number of animals")},
        {value: "open_date", label: getTranslation("Open date")},
        {value: "birth_date", label: getTranslation("Birth date")},
        {value: "requests", label: getTranslation("Work requests")},
    ];
    public availableToAdd: PureComputed<Detail[]>;
    public show: ObservableArray<string>;
    public additional: Observable<Detail>;

    constructor() {

        this.availableToAdd = ko.pureComputed(() => {
            return _.filter(this.available, (i) => {
                return !_.includes(this.show(), i.value);
            });
        });

        this.show = ko.observableArray([]).extend({localStorage: "building_map.details_to_show"});
        this.additional = ko.observable();
        this.additional.subscribe((detail: Detail) => {
            if (detail) {
                this.show.push(detail.value);
            }
        });
    }

    public removeDetail = (value: string) => {
        this.show.remove(value);
    };
}

class BuildingMapHighlights {

    public available = [
        {
            value: "has_work_request",
            label: getTranslation("with work request"),
        },
        {
            value: "with_medical_condition",
            label: getTranslation("with condition"),
        },
    ];

    public active: Observable<string>;

    public isHighlightedCell = () => {
        return false;
    };

    public isHighlightedCage = (rack: Rack, cage: Cage) => {

        if (this.active() === "with_medical_condition") {
            return !!_.size(cage.animal_medical_conditions);
        }

        if (this.active() === "has_work_request") {
            return !!_.size(cage.requests);
        }

        return false;
    };

    public isHighlightedTank = (rack: Rack, tank: Tank) => {

        if (this.active() === "with_medical_condition") {
            return !!_.size(tank.medical_conditions);
        }

        if (this.active() === "has_work_request") {
            return !!_.size(tank.requests);
        }

        return false;
    };

    constructor() {
        this.active = ko.observable().extend({ sessionStorage: "building_map.active_highlighter" });
    }
}

// noinspection DuplicatedCode
class BuildingMapActions {

    public actionsInProgress: Observable<number>;
    public active: Observable<"open" | "reveal" | "requests" | "move" | "swap">;
    public selectedCells: ObservableArray<Cell>;
    public selectedCages: ObservableArray<Cage>;
    public selectedTanks: ObservableArray<Tank>;
    public selectionCount: PureComputed<number>;
    public reloadRacks: _.DebouncedFunc<() => void>;

    public available = [
        {value: "open", label: getTranslation("Open details")},
        {value: "reveal", label: getTranslation("Reveal in list")},
        {value: "requests", label: getTranslation("Show requests")},
    ];

    public clearSelection = () => {
        this.selectedCells([]);
        this.selectedCages([]);
        this.selectedTanks([]);
    };

    private map: BuildingMap;

    constructor(map: BuildingMap) {

        this.map = map;

        if (session.userPermissions.cage_set_location || session.userPermissions.tank_set_location) {
            this.available.push({value: "move", label: getTranslation("Move")});
            this.available.push({value: "swap", label: getTranslation("Swap")});
        }

        this.actionsInProgress = ko.observable(0);
        this.active = ko.observable();
        this.active.subscribe(() => {
            this.clearSelection();
        });

        this.reloadRacks = _.throttle(() => {
            this.map.racks.fetchForceReload();
        });
        this.actionsInProgress.subscribe((numberOfActiveActions) => {
            if (numberOfActiveActions <= 0) {
                this.reloadRacks();
            }
        });

        this.selectedCells = ko.observableArray([]);
        this.selectedCages = ko.observableArray([]);
        this.selectedTanks = ko.observableArray([]);
        this.selectionCount = ko.pureComputed(() => {
            return (this.selectedCells().length
                + this.selectedCages().length
                + this.selectedTanks().length);
        });

    }

    /**
     * action helpers
     **/

    public revealSelectionInCagesList = () => {
        const cageIds = _.map(this.selectedCages(), "cageid");
        window.top.mainMenu.filterSubtab("cages", {"cageid": cageIds});
    };

    public revealSelectionInAnimalsList = () => {
        const cageIds = _.map(this.selectedCages(), "cageid");
        window.top.mainMenu.filterSubtab("animals", {
            cage_id: cageIds,
            state: "live",
        });
    };

    public revealSelectionInTanksList = () => {
        const tankIds = _.map(this.selectedTanks(), "tank_id");
        window.top.mainMenu.filterSubtab("tanks", {"tank_id": tankIds});
    };

    /**
     * visual handlers
     **/
    public clickedOutside = () => {
        this.clearSelection();
        return false;
    };

    public clickedCell = (rack: Rack, cell: Cell) => {

        if (this.map.racks.fetchInProgress()) {
            return false;
        } else if (this.active() === "move") {
            _.forEach(this.selectedCages(), (cage: Cage) => {
                this.actionsInProgress(this.actionsInProgress() + 1);
                this.selectedCages.remove(cage);
                fetch("building_map.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            move_cage: {
                                cage_id: cage.cageid,
                                target_rack_id: rack.rack_id,
                                target_rack_position: cell.position,
                                confirmed_sanitary_status: false,
                            },
                        }),
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.confirm && response.confirm === "confirm_sanitary_status") {
                            notifications.showConfirm(response.message, () => {
                                this.actionsInProgress(this.actionsInProgress() + 1);
                                fetch("building_map.py", {
                                    method: "POST",
                                    body: getFormData({
                                        request: JSON.stringify({
                                            move_cage: {
                                                cage_id: cage.cageid,
                                                target_rack_id: rack.rack_id,
                                                target_rack_position: cell.position,
                                                confirmed_sanitary_status: true,
                                            },
                                        }),
                                    }),
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        if (!response.success) {
                                            notifications.showNotification(response.message, "error");
                                        }
                                    })
                                    .finally(() => {
                                        this.actionsInProgress(this.actionsInProgress() - 1);
                                    });
                            });
                        } else if (!response.success) {
                            notifications.showNotification(response.message, "error");
                        }
                    })
                    .finally(() => {
                        this.actionsInProgress(this.actionsInProgress() - 1);
                    });
            });
            _.forEach(this.selectedTanks(), (tank) => {
                this.actionsInProgress(this.actionsInProgress() + 1);
                this.selectedTanks.remove(tank);
                fetch("building_map.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            move_tank: {
                                tank_id: tank.tank_id,
                                target_rack_id: rack.rack_id,
                                target_rack_position: cell.position,
                            },
                        }),
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (!response.success) {
                            notifications.showNotification(response.message, "error");
                        }
                    })
                    .finally(() => {
                        this.actionsInProgress(this.actionsInProgress() - 1);
                    });
            });
        }
    };

    public clickedCage = (rack: Rack, cage: Cage) => {

        if (this.map.racks.fetchInProgress()) {
            return false;

        } else if (this.active() === "open") {
            frames.openListDetailPopup(getUrl("cagedetail.py", {
                cageid: cage.cageid,
            }));
        } else if (this.active() === "reveal") {
            if (this.selectedCages.indexOf(cage) !== -1) {
                this.selectedCages.remove(cage);
            } else {
                this.selectedCages.push(cage);
            }

        } else if (this.active() === "move") {
            if (this.selectedCages.indexOf(cage) !== -1) {
                this.selectedCages.remove(cage);
            } else if (this.selectionCount() === 0) {
                this.clearSelection();
                this.selectedCages.push(cage);
            }

        } else if (this.active() === "swap") {
            if (this.selectedCages.indexOf(cage) !== -1) {
                this.selectedCages.remove(cage);
            } else if (this.selectionCount() === 0) {
                this.clearSelection();
                this.selectedCages.push(cage);
            } else if (this.selectionCount() === 1) {
                this.actionsInProgress(this.actionsInProgress() + 1);
                fetch("building_map.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            swap_cages: {
                                cage_ids: [this.selectedCages()[0].cageid, cage.cageid],
                            },
                        }),
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (!response.success) {
                            notifications.showNotification(response.message, "error");
                        }
                    })
                    .finally(() => {
                        this.actionsInProgress(this.actionsInProgress() - 1);
                        this.clearSelection();
                    });
            }

        } else if (this.active() === "requests") {
            if (_.size(cage.requests) === 1) {
                frames.openListDetailPopup(getUrl("requestdetail.py",{
                    incidentid: cage.requests[0].id,
                }));
            } else if (_.size(cage.requests) > 1) {
                window.top.mainMenu.filterSubtab("work_requests", {
                    id: _.map(cage.requests, "id").join(","),
                    status_id_or_unresolved: -1,
                });
            }

        }

        return false;
    };

    public clickedTank = (rack: Rack, tank: Tank) => {

        if (this.map.racks.fetchInProgress()) {
            return false;

        } else if (this.active() === "open") {
            showTankDetails({tankId: tank.tank_id});

        } else if (this.active() === "reveal") {
            if (this.selectedTanks.indexOf(tank) !== -1) {
                this.selectedTanks.remove(tank);
            } else {
                this.selectedTanks.push(tank);
            }

        } else if (this.active() === "move") {
            if (this.selectedTanks.indexOf(tank) !== -1) {
                this.selectedTanks.remove(tank);
            } else if (this.selectionCount() === 0) {
                this.clearSelection();
                this.selectedTanks.push(tank);
            }

        } else if (this.active() === "swap") {
            if (this.selectedTanks.indexOf(tank) !== -1) {
                this.selectedTanks.remove(tank);
            } else if (this.selectionCount() === 0) {
                this.clearSelection();
                this.selectedTanks.push(tank);
            } else if (this.selectionCount() === 1) {
                this.actionsInProgress(this.actionsInProgress() + 1);
                fetch("building_map.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            swap_tanks: {
                                tank_ids: [this.selectedTanks()[0].tank_id, tank.tank_id],
                            },
                        }),
                    }),
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (!response.success) {
                            notifications.showNotification(response.message, "error");
                        }
                    })
                    .finally(() => {
                        this.actionsInProgress(this.actionsInProgress() - 1);
                        this.clearSelection();
                    });
            }

        } else if (this.active() === "requests") {
            if (_.size(tank.requests) === 1) {
                frames.openListDetailPopup(getUrl("requestdetail.py",{
                    incidentid: tank.requests[0].id,
                }));
            } else if (_.size(tank.requests) > 1) {
                window.top.mainMenu.filterSubtab("work_requests", {
                    id: _.map(tank.requests, "id").join(","),
                    status_id_or_unresolved: -1,
                });
            }

        }

        return false;
    };

    public isSelectedCell = () => {
        return false;
    };

    public isSelectedCage = (rack: Rack, cage: Cage) => {

        if (this.active() === "move" || this.active() === "swap") {
            return this.selectedCages.indexOf(cage) !== -1;
        } else if (this.active() === "reveal") {
            return this.selectedCages.indexOf(cage) !== -1;
        }

        return false;
    };

    public isSelectedTank = (rack: Rack, tank: Tank) => {

        if (this.active() === "move" || this.active() === "swap") {
            return this.selectedTanks.indexOf(tank) !== -1;
        } else if (this.active() === "reveal") {
            return this.selectedTanks.indexOf(tank) !== -1;
        }

        return false;
    };

    public isOptionCell = (rack: Rack, cell: Cell) => {

        if (this.active() === "move") {
            return this.selectionCount() && !cell.occupied;
        }

        return false;
    };

    public isOptionCage = (rack: Rack, cage: Cage) => {

        if (this.active() === "swap") {
            return !_.includes(this.selectedCages(), cage) && this.selectedCages().length;
        }

        return false;
    };

    public isOptionTank = (rack: Rack, tank: Tank) => {

        if (this.active() === "swap") {
            return !_.includes(this.selectedTanks(), tank) && this.selectedTanks().length;
        }

        return false;
    };

    public isClickableCell = (rack: Rack, cell: Cell) => {

        if (this.map.racks.fetchInProgress()) {
            return false;
        }

        if (this.active() === "move") {
            return this.selectionCount() && _.trim(cell.position).length && !_.size(cell.cages) && !_.size(cell.tanks);
        }

        return false;
    };

    public isClickableCage = (rack: Rack, cage: Cage) => {

        if (this.map.racks.fetchInProgress()) {
            return false;
        } else if (this.active() === "open") {
            return true;
        } else if (this.active() === "reveal") {
            return true;
        } else if (this.active() === "move") {
            return this.selectionCount() === 0;
        } else if (this.active() === "swap") {
            return true;
        } else if (this.active() === "requests") {
            return !!_.size(cage.requests);
        }

        return false;
    };

    public isClickableTank = (rack: Rack, tank: Tank) => {

        if (this.map.racks.fetchInProgress()) {
            return false;
        } else if (this.active() === "open") {
            return true;
        } else if (this.active() === "reveal") {
            return true;
        } else if (this.active() === "move") {
            return this.selectionCount() === 0;
        } else if (this.active() === "swap") {
            return true;
        } else if (this.active() === "requests") {
            return !!_.size(tank.requests);
        }

        return false;
    };
}

class BuildingMap {

    public scale: Observable<number>;
    public locations: ObservableArray<Location>;
    public currentLocationId: Observable<string>;
    public preselectInProgress: Observable<boolean>;
    public preselectLocation: PreselectLocationItem | undefined;
    public selectedLocation: Observable<LocationItem>;
    public selectedRackIds: PureComputed<number[]>;
    public stickyRackIds: ObservableArray<number>;
    public selectedRacks: PureComputed<Location[]>;
    public stickyRacks: PureComputed<Location[]>;
    public rackIdsString: PureComputed<string>;
    public racks: FetchExtended<Observable<Rack[]>>;

    public details: BuildingMapDetails;
    public highlights: BuildingMapHighlights;
    public actions: BuildingMapActions;

    public addLocation = (location: Location) => {
        this.stickyRackIds.push(location.rack_id);
    };

    public removeLocation = (location: Location) => {
        this.stickyRackIds.remove(location.rack_id);
    };

    public maxRowWidth = (positions: RackRow[]) => {
        return _.max(_.map(positions, (row) => {
            return _.reduce(row, (m, p) => {
                return m + p.width;
            }, 0);
        }));
    };

    constructor(locations: Location[], preselectLocationId?: string) {

        this.scale = ko.observable(100).extend({localStorage: "building_map.scale_factor"});

        this.locations = ko.observableArray(locations);

        const preselectLocation = _.find(locations, {id: preselectLocationId});
        if (preselectLocation) {
            this.preselectLocation = {
                type: preselectLocation.type,
                id: preselectLocation.db_id,
            };
        }

        this.preselectInProgress = ko.observable(true);
        getSessionItem("building_map.selected_location")
            .then((location: LocationItem) => {
                if (location && !this.preselectLocation) {
                    this.preselectLocation = {
                        type: location.type,
                        id: location.db_id,
                    };
                }
                this.preselectInProgress(false);
            });


        this.selectedLocation = ko.observable();
        this.selectedLocation.subscribe(v => setSessionItem("building_map.selected_location", v));
        this.selectedRackIds = ko.pureComputed(() => {
            let rackIds: number[] = [];
            const selectedLocation = this.selectedLocation();
            if (selectedLocation) {
                if (selectedLocation.type === "rack") {
                    rackIds = [selectedLocation.rack_id];
                }
                if (selectedLocation.type === "room") {
                    rackIds = _.map(_.filter(this.locations(), {"parent_id": selectedLocation.id}), "rack_id");
                }
            }
            return _.without(rackIds, ...this.stickyRackIds());
        });
        this.selectedRacks = ko.pureComputed(() => {
            return this.selectedRackIds().map((rackId) => _.find(this.locations(), {"rack_id": rackId}));
        });

        this.stickyRackIds = ko.observableArray().extend({sessionStorage: "building_map.sticky_rack_ids"});
        this.stickyRacks = ko.pureComputed(() => {
            return this.stickyRackIds().map((rackId) => _.find(this.locations(), {"rack_id": rackId}));
        });


        // That this is a string is a "hack" to make sure knockout is able to
        // detect if the value has _not_ changed. Knockout cannot compare complex
        // data types like number[], so we use a string instead, to avoid
        // rack detail data reloads where actually nothing changed.
        this.rackIdsString = ko.computed(() => _.union(this.selectedRackIds(), this.stickyRackIds()).sort().join(","));

        this.racks = ko.observable([]).extend({
            fetch: {
                undefined: [],
                fn: (signal) => {
                    if (this.rackIdsString()) {
                        const rackIds = this.rackIdsString().split(",").map((v) => parseInt(v, 10));
                        return fetch("building_map.py", {
                            method: "POST",
                            body: getFormData({
                                request: JSON.stringify({ get_rack_details: rackIds }),
                            }),
                            signal,
                        });
                    }
                },
                cleanup: (v) => {
                    return v.success ? v.get_rack_details : [];
                },
            },
        });

        this.details = new BuildingMapDetails();
        this.highlights = new BuildingMapHighlights();
        this.actions = new BuildingMapActions(this);

    }

}

export const initBuildingMap = _.once((args: Arguments): void => {
    const map = new BuildingMap(args.locations, args.location_id);
    ko.applyBindings(map);

});
