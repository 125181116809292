/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Export formats for well plates.
 */
export enum WellplateExportFormat {
    STANDARD_CSV = 'standard_csv',
    TRANSNETYX_XLSX = 'transnetyx_xlsx',
}
