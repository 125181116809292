import {dialogStarter} from "../knockout/dialogStarter";
import {KnockoutPopup, PopupCallback} from "../lib/popups";
import {CommentWidgetParams} from "../knockout/components/commentWidget";
import {getTranslation} from "../lib/localize";
import {omit} from "lodash";

interface Params extends CommentWidgetParams {
    onClose?: PopupCallback;
}

class CommentsViewModel {

    private widgetParams: CommentWidgetParams;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.widgetParams = omit(params, "onClose");
        dialog.setTitle(getTranslation("Comments"));
    }

}

export const showComments = dialogStarter(CommentsViewModel, `
    <div data-bind="component: {name: 'ko-comment-widget', params: widgetParams}" style="padding: 0 10px 10px"></div>
`, params => ({
    name: "Comments",
    width: 700,
    anchor: {top: 60, left: undefined},
    modal: true,
    handle: "right top",
    closeOthers: true,
    onClose: params.onClose,
}));
