import * as ko from "knockout";
import {Computed, Observable, ObservableArray, PureComputed} from "knockout";
import * as _ from "lodash";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./tankCrossingDetails.html";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {TranslationTemplates, getTranslation} from "../lib/localize";
import {AjaxResponse, isInvalidCalendarDate} from "../lib/utils";
import {showTankAnimalImport} from "./tankAnimalImport";
import {session} from "../lib/pyratSession";


interface TankCrossingDetailParams {
    crossingId: number;
    positioningElement?: HTMLElement;
    requestReload?: () => void;
}

interface PrinterType {
    value?: string;
    label: string;
}

interface Strain {
    id: number;
    name_with_id: string;
    species_id: number;
}

interface Crossing {
    crossing_id: number;
    status: string;
    completed: boolean;
    responsible_id: number;
    strain_species_id: number;
    license_id: number;
    classification_id: number;
    // TODO: add missing fields
}


class TankCrossingDetailsViewModel {

    public readonly dialog: KnockoutPopup;
    public licenseDenom: string;

    public noLabelPrinter: PrinterType = {label: getTranslation("Do not print a label.")};
    public newWindowPrinter: PrinterType = {label: getTranslation("Show in new window / tab")};

    public requestReload: () => void;
    public errors: ObservableArray<string>;
    public submitInProgress: Observable<boolean>;
    public anythingInProgress: PureComputed<boolean>;

    public crossingId: Observable<number>;
    public selectedStrain: ObservableArray<Strain>;
    public strainId: CheckExtended<Observable<number | undefined>>;
    public responsibleId: CheckExtended<Observable<number | undefined>>;
    public licenseId: Observable<number>;
    public classificationId: CheckExtended<Observable<number>>;
    public description: Observable<string>;
    public printer: Observable<PrinterType>;
    public dateOfRecord: CheckExtended<Observable<string | undefined>>;
    public crossingTanks: CheckExtended<Observable<number | undefined>>;
    public raisedTanks: CheckExtended<Observable<number | undefined>>;
    public firstNumber: CheckExtended<Observable<number>>;
    public seed: FetchExtended<Observable<AjaxResponse<{
        crossing_detail: Crossing;
        tank_strains: Strain[];
    }>>>;
    public availableLicenses: FetchExtended<ObservableArray<{
        id: number;
        name: string;
        valid_from: string;
        license_type_id: number;
        budget_name?: string;
    }>>;
    public availableClassifications: FetchExtended<ObservableArray<{
        id: number;
        licence_id: number;
        name: string;
        severity_level_id: number;
        animal_number: number;
        used: number;
    }>>;

    public crossingDetails: Computed<Crossing>;
    public availableLabelTargets: Computed<PrinterType[]>;

    public crossingStatus = () => {
        return this.crossingDetails()?.status;
    };

    public isInStatus = (...args: string[]) => {
        return _.includes(args, this.crossingStatus());
    };

    public isCompleted = () => {
        return this.crossingDetails()?.completed;
    };

    public canSetUp = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid()
            && (this.printer() === this.noLabelPrinter || this.firstNumber.isValid());
    };

    public canRaise = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public canUpdate = () => {
        return this.strainId.isValid()
            && this.classificationId.isValid()
            && this.dateOfRecord.isValid()
            && this.responsibleId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public canDiscard = () => {
        return this.strainId.isValid()
            && this.responsibleId.isValid()
            && this.classificationId.isValid()
            && this.crossingTanks.isValid()
            && this.raisedTanks.isValid();
    };

    public updateCrossing = (action: string, successCallback: () => void) => {
        this.errors.removeAll();
        this.submitInProgress(true);
        const form = getFormData({
            request: JSON.stringify({
                update_crossing: {
                    action: action,
                    crossing_id: this.crossingId(),
                    crossing_tanks: this.crossingTanks(),
                    raised_tanks: this.raisedTanks(),
                    date_of_record: session.userPermissions.tank_crossing_modify_date ? this.dateOfRecord() : undefined,
                    strain_id: this.strainId(),
                    description: this.description(),
                    responsible_id: this.responsibleId(),
                    classification_id: this.classificationId(),
                },
            }),
        });
        fetch("tank_list.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                this.submitInProgress(false);
                if (response.success) {
                    if (typeof this.requestReload === "function") {
                        this.requestReload();
                    }
                    if (_.isFunction(successCallback)) {
                        successCallback();
                    } else {
                        this.dialog.close();
                    }
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.errors.push(getTranslation("General unexpected error occurred."));
            });
    };

    public openLabelInWindow = () => {
        window.open(getUrl("papercard.py", {
            kind: "tank_crossing_card",
            subjects: this.crossingId(),
            offset: this.firstNumber() - 1,
        }));
        this.dialog.close();
    };

    public sendLabelToPrinter = () => {
        this.submitInProgress(true);
        const form = getFormData({
            request: JSON.stringify({
                print_crossing_label: {
                    crossing_id: this.crossingId(),
                    printer: this.printer().value,
                    offset: this.firstNumber() - 1,
                },
            }),
        });
        fetch("tank_list.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                this.submitInProgress(false);
                if (response.success) {
                    notifications.showNotification(getTranslation("Tank labels were sent to the printer."), "success");
                    this.dialog.close();
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.errors.push(getTranslation("General unexpected error occurred."));
            });
    };

    public setUpCrossing = () => {
        if (this.printer() === this.noLabelPrinter) {
            this.updateCrossing("set_up", undefined);
        } else if (this.printer() === this.newWindowPrinter) {
            this.updateCrossing("set_up", this.openLabelInWindow);
        } else {
            this.updateCrossing("set_up", this.sendLabelToPrinter);
        }
    };

    public raiseCrossing = () => {
        showTankAnimalImport({crossingId: this.crossingId()});
    };

    constructor(params: TankCrossingDetailParams, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.licenseDenom = TranslationTemplates.license({cap: true, trans: true});

        /* params */
        this.requestReload = params.requestReload;
        this.crossingId = ko.observable(params.crossingId);
        this.crossingId.extend({notify: "always"});
        this.submitInProgress = ko.observable(false);
        this.anythingInProgress = ko.pureComputed(() => {
            return this.submitInProgress() ||
                    this.seed.fetchInProgress() ||
                    this.availableLicenses.fetchInProgress() ||
                    this.availableClassifications.fetchInProgress();
        });
        this.errors = ko.observableArray([]);

        this.selectedStrain = ko.observableArray();
        this.strainId = ko.observable(undefined).extend(
            {
                invalid: value => !_.identity(value),
            }
        );
        this.responsibleId = ko.observable(undefined).extend({
                invalid: value => !_.identity(value),
            }
        );
        this.licenseId = ko.observable();
        this.classificationId = ko.observable().extend({
            invalid: value => {
                if (this.licenseId() && !value) {
                    return getTranslation("Please select a classification");
                }

                return false;
            },
        });
        this.description = ko.observable("");
        this.printer = ko.observable(this.noLabelPrinter);

        this.dateOfRecord = ko.observable().extend({
            invalid: v => !(v && !isInvalidCalendarDate(v)),
        });

        this.crossingTanks = ko.observable().extend({
            normalize: v => v && parseInt(v, 10),
            invalid: v => !(v > 0),
        });

        this.raisedTanks = ko.observable().extend({
            normalize: v => v ? parseInt(v, 10) : null,
            invalid: v => !(_.isNaN(v) || v > -1),
        });
        this.firstNumber = ko.observable(1).extend({
            normalize: v => v && parseInt(String(v), 10),
            invalid: v => !(v > 0),
        });

        this.availableLicenses = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.strainId() ||
                                               !this.selectedStrain() ||
                                               !this.selectedStrain().length ||
                                               !this.selectedStrain()[0].species_id),
                fn: (signal) => {
                    return fetch("ajax_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_licenses_for_setting",
                            species_id: this.selectedStrain()[0].species_id,
                            strain_id: this.strainId(),
                        }),
                        signal,
                    });
                },
            },
        });
        this.availableClassifications = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.licenseId() ||
                                               !this.strainId() ||
                                               !this.selectedStrain() ||
                                               !this.selectedStrain().length ||
                                               !this.selectedStrain()[0].species_id),
                fn: (signal) => {
                    return fetch("ajax_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_license_classifications_for_setting",
                            license_id: String(this.licenseId()),
                            species_id: this.selectedStrain()[0].species_id,
                            strain_id: this.strainId(),
                        }),
                        signal,
                    });
                },
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.crossingId()) {
                    return fetch("tank_list.py", {
                        method: "POST",
                        body: getFormData({
                            request: JSON.stringify({
                                crossing_detail: {crossing_id: this.crossingId()},
                                get_crossing_responsible: this.crossingId(),
                                get_tank_strains: true,
                                get_printers: true,
                                get_crossing_default_printer: this.crossingId(),
                            }),
                        }),
                        signal,
                    });
                }
            },
        });

        this.crossingDetails = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed.crossing_detail;
            }
        });

        this.availableLabelTargets = ko.pureComputed(() => {
            return _.union(
                [this.noLabelPrinter, this.newWindowPrinter],
                _.map(_.result(this.seed(), "printers", []), function (printerName) {
                    return {
                        value: printerName,
                        label: _.template(getTranslation("Print using '<%- arguments[0] %>'."))(printerName),
                    };
                }));
        });


        // set default values
        this.crossingDetails.subscribe((details) => {
            const parentResponsibleIds = _.map(_.get(details, ["tanks", "parents"]), "responsible_id");
            let responsibleId: number = details?.responsible_id;

            // if responsibleId is null, check for parent responsible ids being equal and preset if true
            if (!responsibleId && _.uniq(parentResponsibleIds).length === 1) {
                responsibleId = _.head(parentResponsibleIds);
            }

            _.defer(() => {
                this.dateOfRecord(_.result(details, "date_of_record"));
                this.strainId(_.result(details, "strain_id"));
                this.responsibleId(responsibleId);
                this.licenseId(_.result(details, "license_id"));
                this.classificationId(_.result(details, "classification_id"));
                this.description(_.result(details, "description"));
                this.printer(_.result(this.seed(), "crossing_default_printer"));
                this.raisedTanks(_.result(details, "really_raised_tanks"));
                if (_.result(details, "crossing_tanks")) {
                    // number of crossing tanks already known
                    this.crossingTanks(_.result(details, "crossing_tanks"));
                } else {
                    // number of crossing is calculated as the number of parents divided by two
                    this.crossingTanks(Math.floor(
                        _.reduce(_.get(details, ["tanks", "parents"]), function (m, t) {
                            return m + t.number_of_male + t.number_of_female + t.number_of_unknown;
                        }, 0) / 2)
                    );
                }
            });
        });

    }
}

// dialog starter
export const showTankCrossingDetails = dialogStarter(TankCrossingDetailsViewModel, template, params => ({
    name: "TankCrossingDetails",
    title: _.template(getTranslation("Crossing #<%- crossingId %>"))(params),
    width: 400,
    cssARequire: [":popup_details_common_styles.css"],
    anchor: params.positioningElement,
    handle: "left center",
    closeOthers: true,
}));
