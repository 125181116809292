/**
 * Show a pop-up for animal related breeding performance details.
 *
 * @param animalId: Database ID of the animal (parent) for which to show breeding performance details.
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {frames} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {formatIsoDate} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {pyratFrontend} from "../pyratFrontend";
import template from "./breedingPerformanceDetails.html";

interface Params {
    animalId: number;
    birthDateTo?: string;
    birthDateFrom?: string;
    closeCallback?: () => void;
}

interface Seed {
    eartag: string;
    sex: string;
    strain_name_with_id: string;
    litter_count: number;
    animal_litter_count: number;
    pup_litter_count: number;
    mixed_litter_count: number;
    born_total_count: number;
    born_male_count: number;
    born_male_mono_parent_count: number;
    born_male_poly_parent_count: number;
    born_female_count: number;
    born_female_mono_parent_count: number;
    born_female_poly_parent_count: number;
    born_unknown_count: number;
    born_unknown_mono_parent_count: number;
    born_unknown_poly_parent_count: number;
    weaned_total_count: number;
    weaned_male_count: number;
    weaned_male_mono_parent_count: number;
    weaned_male_poly_parent_count: number;
    weaned_female_count: number;
    weaned_female_mono_parent_count: number;
    weaned_female_poly_parent_count: number;
    unweaned_total_count: number;
    average_litters_per_female: string;
    standard_deviation_litters_per_female: string;
    average_birth_litter_size: string;
    standard_deviation_birth_litter_size: string;
    average_wean_litter_size: string;
    standard_deviation_wean_litter_size: string;
    mortality_count: number;
    mortality_percent: string;
    mortality_unexpected_count: number;
    mortality_unexpected_percent: string;
    mortality_reduced_count: number;
    mortality_reduced_percent: string;
    mortality_other_count: number;
    mortality_other_percent: string;
    litters_with_weaned_animals: {
        id: number;
        birth_id: number;
        cageid: number;
        cagenumber: string;
        dateborn: string;
        datesacrificed: string;
        eartag: string;
        sex: string;
    }[][];
}

class BreedingPerformanceDetailsViewModel {

    private readonly dialog: KnockoutPopup;
    private readonly closeCallback: () => void;

    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    public readonly animalId: number;
    public readonly birthDateTo: string;
    public readonly birthDateFrom: string;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;
        this.animalId = params.animalId;
        this.birthDateTo = params.birthDateTo;
        this.birthDateFrom = params.birthDateFrom;
        this.closeCallback = params.closeCallback;

        if (this.birthDateFrom && this.birthDateTo) {
            this.dialog.setTitle(this.dialog.params.title + " (" + formatIsoDate(this.birthDateFrom) + " - "
                + formatIsoDate(this.birthDateTo) + ")");
        } else if (this.birthDateFrom) {
            this.dialog.setTitle(this.dialog.params.title + " (>= " + formatIsoDate(this.birthDateFrom) + ")");
        } else if (this.birthDateTo) {
            this.dialog.setTitle(this.dialog.params.title + " (<=" + formatIsoDate(this.birthDateTo) + ")");
        }

        /**
         * Get and handle data for dialog (popup).
         */
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("breeding_performance_detail.py", {
                    action: "get_data",
                    animal_id: this.animalId,
                    birth_date_to: this.birthDateTo,
                    birth_date_from: this.birthDateFrom,
                }), {signal});
            },
        });

        /**
         * Add callback that is called after popup is closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    /**
     * Export animal breeding performance data as csv/xls file
     */
    public exportDetails = () => {
        pyratFrontend.virtualSaveFromFetch(
            fetch(getUrl("breeding_performance_detail.py", {
                action: "export",
                animal_id: this.animalId,
                birth_date_to: this.birthDateTo,
                birth_date_from: this.birthDateFrom,
            }))
        );
    };

    public printDetails = () => {
        window.open(getUrl("breeding_performance_detail.py", {
            action: "print",
            animal_id: this.animalId,
            birth_date_to: this.birthDateTo,
            birth_date_from: this.birthDateFrom,
        }), "_blank");
    };

    public showAnimalDetails = (animalId: number) => {
        frames.detailPopup.open(getUrl("mousedetail.py", {animalid: animalId}));
    };

    public showCageDetails = (cageId: number) => {
        frames.detailPopup.open(getUrl("cagedetail.py", {cageid: cageId}));
    };
}

export const showBreedingPerformanceDetails = dialogStarter(BreedingPerformanceDetailsViewModel, template, {
    name: "BreedingPerformanceDetails",
    width: 590,
    closeOthers: true,
    cssARequire: [":popup.css", ":table.css", ":cage_mouse_popup.css"],
    anchor: {top: 120, left: undefined},
    title: getTranslation("Breeding performance"),
});
