import {onceDocumentLoaded} from "../utils";


// Period of time to wait between steps.
const waitPeriod = 2000; // ms

export class ProcedureShortcuts {


    /** Execute the shortcut functions.
     *
     * This method calls each job in the given data array, waits for it to run
     * the given callback method, waits additional one `waitPeriod` and then runs the next
     * job or quits if its last one.
     *
     * In most most cases there is only one job, anyways: Its always required
     * to wrap the job details in another array.
     *
     * Example:
     *
     *      // fist open the cage list and details for cage 60, when the job
     *      // returns, open the animal list and details for animal 100
     *      procedureShortcuts.execute(
     *          [
     *              ['openCageListAndDetails', 60],
     *              ['openAnimalListAndDetails', 100]
     *          ]
     *      )
     *
     * @param data - The functions to start and its arguments (without callback).
     * This is an array, containing one or more other arrays. The inner arrays
     * are the actual job descriptions and consist of the function name found in
     * ``this.shortcutFunctions`` and the functions arguments, except of the fist
     * argument (callback). See example above.
     */
    public execute = (data: [keyof ProcedureShortcuts["shortcutFunctions"], ...any][]): void => {

        onceDocumentLoaded(document, () => setTimeout(() => {
                if (Array.isArray(data) && data.length > 0) {
                    const [task, ...rest] = data;
                    if (Array.isArray(task) && task.length > 0) {
                        const [name, ...args] = task;
                        if (name in this.shortcutFunctions) {
                            this.shortcutFunctions[name].apply(this, [() => this.execute(rest), ...args]);
                        }
                    }
                }
            }, waitPeriod)
        );

    };

    /** Wait for an iframe to be loaded.
     *
     * @param selector - A selector that describes how to find the questionable frame.
     * @param callback - Function that gets called after the frame finished loading.
     */
    private waitForIFrame = (selector: string, callback: () => void) => {
        const iFrame = document.querySelector(selector) as HTMLIFrameElement;
        iFrame.addEventListener("load", () => {
            setTimeout(() => {
                callback();
            }, waitPeriod);
        }, {once: true});
    };

    /** The actual available shortcut functions.
     *
     * Each function in this object may be called via execute. This first argument is
     * always a callback function, that needs to be called after the functions finishes (as
     * late as possible).
     */
    private shortcutFunctions = {

        // function to open the pup list and the detail dialog of the given pup
        openPupListAndDetails(callback: () => void, pupId: number) {
            window.mainMenu.subtabClick("pups");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].animalList.showPupDetails(pupId, pupId);
                this.waitForIFrame("#detailframe", callback);
            });
        },

        // function to open the animal list and the detail dialog of the given animal
        openAnimalListAndDetails(callback: () => void, animalId: number) {
            window.mainMenu.subtabClick("animals");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].animalList.showAnimalDetails(animalId, animalId);
                this.waitForIFrame("#detailframe", callback);
            });
        },

        // function to open the cage list and the detail dialog of the given cage
        openCageListAndDetails(callback: () => void, cageId: number) {
            window.mainMenu.subtabClick("cages");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].cageList.showCageDetails(cageId);
                this.waitForIFrame("#detailframe", callback);
            });
        },

        // function to open the tank list and detail dialog of the given tank id
        openTankListAndDetails(callback: () => void, tankId: number) {
            window.mainMenu.subtabClick("tanks");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].tankList.showDetailsDialog({rowid: tankId});
                this.waitForIFrame("#detailframe", callback);
            });
        },

        // function to open the embryo list and the detail dialog of the given embryo
        openEmbryoListAndDetails(callback: () => void, groupkey: string) {
            window.mainMenu.subtabClick("embryos");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].embryoList.showEmbryoGroupDetails(groupkey, callback);
            });
        },

        // function to open the order request list and detail dialog for the given order request id
        openOrderRequestListAndDetails(callback: () => void, orderRequestId: number) {
            window.mainMenu.subtabClick("order_requests");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].orderRequestList.showOrderRequestDetailsDialog(orderRequestId, callback);
            });
        },

        // function to open the work request list and detail dialog for the given work request id
        openWorkRequestListAndDetail(callback: () => void, workRequestId: number) {
            window.mainMenu.subtabClick("work_requests");
            this.waitForIFrame("#listiframe", () => {
                // @ts-expect-error: We cannot ensure if this is really there, as long as it is legacy js.
                window.frames[1].workRequestList.showDetailsDialog(workRequestId);
                this.waitForIFrame("#detailframe", callback);
            });
        },
    };

}
