import * as ko from "knockout";
import {Observable} from "knockout";

interface ScrollToElementParams {
    trigger: Observable<boolean>;
    containerSelector: string;
    position?: string;
}

declare module "knockout" {
    export interface BindingHandlers {
        scrollToElement?: {
            update(element: HTMLElement, valueAccessor: () => ScrollToElementParams): void;
        };
    }

}

/**
 * Scroll container element so that element becomes visible.
 *
 * ScrollIntoView will potentially scroll multiple (nesting) elements to make an element as visible
 * as possible. This binding helper doesn't do so. It will only scroll that container element. If that
 * container is scrolled out of view by itself, this helper will not change that.
 *
 * You have to pass on object consisting of:
 * @param trigger - whenever this observable is set to === true (not trueish) will will scroll. It will be set to false immediately after.
 * @param containerSelector - querySelector to use to find the container element in the parents of triggering element
 * @param position - optional, should the container be scrolled so the element will be at "top", "middle" or "bottom". Will be "middle" if not passed
 *
 * example:
 *  js:
 *      function Model () {
 *          this.name = ko.observable('my text');
 *          this.intoView = ko.observable();
 *      }
 *
 *  html:
 *      <div class="my-container">
 *          [...] <!-- may be more elements -->
 *          <span data-bind="text: name, scrollToElement: { trigger: intoView, containerSelector: '.my-container' }"></span>
 *          <!-- or if you want to scroll the element to top instead of middle: -->
 *          <span data-bind="text: name, scrollToElement: { trigger: intoView, containerSelector: '.my-container', position: 'top' }"></span>
 *          [...] <!-- may be more elements -->
 *      </div>
 *
 *  later in js:
 *      // trigger by setting observable to true
 *      this.intoView(true);
 *      this.intoView(); // will already be false again
 */
ko.bindingHandlers.scrollToElement = {
    update: function (element, valueAccessor) {
        const value = valueAccessor();
        let scrollToPosition: number;

        if (value.trigger() === true) {
            const containerEl = element.closest(value.containerSelector) as HTMLElement;
            const posTop = element.offsetTop - containerEl.offsetTop;
            const posMiddle = posTop - (containerEl.offsetHeight / 2) + (element.offsetHeight / 2);
            const posBottom = posTop - (containerEl.offsetHeight) + (element.offsetHeight);

            if (value.position === "top") scrollToPosition = posTop;
            else if (value.position === "bottom") scrollToPosition = posBottom;
            else scrollToPosition = posMiddle;

            if (typeof containerEl.scrollTo === "function") {
                containerEl.scrollTo({top: scrollToPosition, behavior: "smooth"});
            } else {
                // IE goes in here 🗑
                containerEl.scrollTop = scrollToPosition;
            }
            value.trigger(false);
        }
    },
};
