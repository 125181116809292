/**
 * Show a pop-up to set user status.
 *
 * @param userId: Database ID of the user for which to set status.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to un-highlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setUserStatus.html";
import {session} from "../lib/pyratSession";

interface Params {
    userId: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    status: string;
    username: string;
}

class SetUserStatusViewModel {

    private readonly userId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;
    private readonly reloadRequired: Observable<boolean>;
    private readonly applyInProgress: Observable<boolean>;

    public status: Observable<string>;
    public readonly dialog: KnockoutPopup;
    public readonly canApply: PureComputed<boolean>;
    public readonly deleteSelf: PureComputed<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;

        this.userId = params.userId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.status = ko.observable();
        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("set_user_status.py", {user_id: this.userId}), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.status(seed.status);
                this.dialog.setTitle(getTranslation("Set status for %s").replace("%s", seed.username));
            }
        });

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && !this.seed.fetchInProgress()
                && this.status() !== this.seed()?.status;
        });

        /**
         * Show warning to user if he is about to "delete" himself from the system.
         * (set own status to "inactive")
         */
        this.deleteSelf = ko.pureComputed(() => {
            return this.userId === session.userId
                && this.status() === "inactive"
                && this.seed()?.status === "active";
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        fetch("set_user_status.py", {
            method: "POST",
            body: getFormData({
                user_id: this.userId,
                status: this.status(),
            })})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetUserStatus = dialogStarter(SetUserStatusViewModel, template, params => ({
    name: "SetUserStatus",
    width: 300,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":popup.css"],
    title: getTranslation("Set status"),
}));
