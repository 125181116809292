import * as dialogs from "./dialogs";
import * as browserStorage from "./lib/browserStorage";
import * as charts from "./lib/charts";
import * as flatpickr from "./lib/flatpickr";
import * as popups from "./lib/popups";
import * as postLinks from "./lib/postLinks";
import {setSessionValues} from "./lib/pyratSession";
import * as top from "./lib/pyratTop";
import {session} from "./lib/pyratSession";
import * as sites from "./sites";


export const pyratFrontend = {

    // Top context functions
    top,

    // Session value access
    session,
    setSessionValues,

    // Functions
    browserStorage,
    charts,
    dialogs,
    flatpickr,
    popups,
    sites,
    virtualPostRequest: postLinks.virtualPostRequest,
    virtualSaveFromFetch: postLinks.virtualSaveFromFetch,
};
