/**
 * Show a popup to set the eartag for animal or pup.
 *
 * @param animalId: Database ID of the animal.
 *
 * @param pupId: Database ID of the pup.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import * as _ from "lodash";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {isInvalidEartagPrefix} from "../lib/utils";
import {isInvalidEartagSuffix} from "../lib/utils";
import template from "./setEartag.html";

interface Params {
    animalId?: number;
    pupId?: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    animal_prefix: string;
    animal_suffix: string;
}

class SetEartagViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly animalId: number;
    private readonly pupId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    private readonly animalPrefix: CheckExtended<Observable<string>>;
    private readonly animalSuffix: CheckExtended<Observable<string>>;
    private readonly animalNextFreeEartag: FetchExtended<Observable<string>>;
    private readonly animalNextFreeEartagError: Observable<string>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorMessages: ko.PureComputed<string[]>;
    private readonly canSubmit: PureComputed<boolean>;
    private readonly submitInProgress: Observable<boolean>;
    private readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.animalId = params.animalId;
        this.pupId = params.pupId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.animalPrefix = ko.observable()
            .extend({normalize: _.trim})
            .extend({
                invalid: (v) => {
                    return !v || isInvalidEartagPrefix(v);
                },
            });

        this.animalSuffix = ko.observable()
            .extend({normalize: _.trim})
            .extend({
                invalid: (v) => {
                    return !v || isInvalidEartagSuffix(v);
                },
            });

        this.animalNextFreeEartagError = ko.observable();

        // load next free suffix according to selected prefix
        // do not send the ajax call until config.PREFEARTAGSIZE characters are given
        this.animalNextFreeEartag = ko.observable().extend({
            fetch: {
                undefined: "",
                disable: ko.pureComputed(() => {
                    return !this.animalPrefix()
                             || this.animalPrefix.isInvalid();
                }),
                fn: (signal) => {
                    return fetch("ajax_service.py", {
                        method: "POST",
                        body: getFormData({
                            function: "get_next_free_eartag",
                            eartag_prefix: this.animalPrefix(),
                        }),
                        signal,
                    });
                },
                cleanup: (data) => {
                    this.animalNextFreeEartagError("");

                    if (data === undefined) {
                        // something went wrong, simply exit
                        return;
                    }

                    if (data.success) {
                        return data.eartag;
                    } else {
                        // warning (e.g. "There are not enough IDs available for this prefix")
                        this.animalNextFreeEartagError(data.message);
                    }
                },
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.animalId) {
                    return fetch(getUrl("set_eartag.py", {animal_id: this.animalId}), {signal});
                } else if (this.pupId) {
                    return fetch(getUrl("set_eartag.py", {pup_id: this.pupId}), {signal});
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.animalPrefix(seed.animal_prefix);
                this.animalSuffix(seed.animal_suffix);
            }
        });

        this.errorMessages = ko.pureComputed(() => {
            const res = _.compact([
                this.animalPrefix.errorMessage(),
                this.animalSuffix.errorMessage(),
            ]);

            // extend the list with server side error messages
            return res.concat(this.errors() || []);
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress() ||
                     this.animalPrefix.isInvalid() ||
                     this.animalSuffix.isInvalid());
        });

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public useNextFreeEartag = () => {
        if (this.animalNextFreeEartag()) {
            // extract next free suffix from next free eartag
            const suffix = this.animalNextFreeEartag().split("-")[1];
            this.animalSuffix(suffix);
        }
    };

    public cancel = () => {
        this.dialog.close();
    };

    private getFormData = () => {
        const formData = getFormData({
            animal_prefix: this.animalPrefix(),
            animal_suffix: this.animalSuffix(),
        });
        if (this.animalId) {
            formData.append("animal_id", this.animalId.toString());
        } else if (this.pupId) {
            formData.append("pup_id", this.pupId.toString());
        }

        return formData;
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        fetch("set_eartag.py", {method: "POST", body: this.getFormData()})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetEartag = dialogStarter(SetEartagViewModel, template, params => ({
    name: "SetEartag",
    width: 300,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: params.title,
}));
