import {
    ArcElement,
    CategoryScale,
    Chart,
    ChartType,
    DoughnutController,
    Filler,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale,
    Tooltip,
    TooltipItem,
    TooltipModel,
} from "chart.js";
import "chartjs-adapter-moment";
import "./charts.scss";
import styles from "./basicStyles.module.scss";
import { assert } from "./assert";
import { getMomentJsDateFormat } from "./flatpickr";
import { el, getReadableByteSizeObject } from "./utils";


Chart.register(ArcElement);
Chart.register(CategoryScale);
Chart.register(DoughnutController);
Chart.register(Filler);
Chart.register(Legend);
Chart.register(LineController);
Chart.register(LineElement);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(TimeScale);
Chart.register(Tooltip);


declare module "chart.js" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface PluginOptionsByType<TType extends ChartType> {
        customText?: {
            text: string;
            color?: string;
            shadowColor?: string | false;
            margin?: number;
            corner?: "top-left" | "top-right" | "bottom-right" | "bottom-left";
        }[];
    }
}

type dataFormat = { [key: string]: number } | { [key: number]: number } | number[];

Chart.register({
    id: "chartjs-plugin-customText",
    afterDraw(chart) {
        const options = chart.config.options.plugins.customText;
        const ctx = chart.ctx;
        (options || []).forEach(o => {
            if (o?.text) {
                ctx.save();

                o = {
                    color: "grey",
                    shadowColor: "white",
                    corner: "top-left",
                    margin: 1,
                    ...o,
                };

                ctx.fillStyle = o.color;
                ctx.font = ctx.font.replace("bold", "");

                if (o.shadowColor) {
                    ctx.shadowColor = o.shadowColor;
                    ctx.shadowBlur = 4;
                }

                if (o.corner === "top-left") {
                    ctx.textBaseline = "top";
                    ctx.fillText(o.text, o.margin, o.margin);
                } else if (o.corner === "top-right") {
                    ctx.textAlign = "right";
                    ctx.textBaseline = "top";
                    ctx.fillText(o.text, chart.width - o.margin, o.margin);
                } else if (o.corner === "bottom-right") {
                    ctx.textAlign = "right";
                    ctx.textBaseline = "bottom";
                    ctx.fillText(o.text, chart.width - o.margin, chart.height - o.margin);
                } else if (o.corner === "bottom-left") {
                    ctx.textBaseline = "bottom";
                    ctx.fillText(o.text, o.margin, chart.height - o.margin);
                }
                ctx.restore();
            }
        });
    },
});


/* Define some default styling.
 *
 * If this one day becomes an issue, we should provide a set of functions, or a plugin to
 * apply styling where needed, instead of overwriting the defaults. In most cases it
 * should be fine.
 */
Chart.defaults.elements.line.borderWidth = 1.5;
Chart.defaults.elements.point.borderWidth = 3;
Chart.defaults.elements.point.hitRadius = 10;
Chart.defaults.elements.point.hoverRadius = 5;
Chart.defaults.elements.point.radius = 1;


// For use with colorschemes plugin: https://github.com/nagix/chartjs-plugin-colorschemes
export const colorSchemes = {
    mixed: [
        "#7391ab",
        "#f69865",
        "#80c296",
        "#fb7477",
        "#d1b9e4",
        "#fad275",
        "#8ec8cc",
    ],
    blues: [
        "#005d91",
        "#0e6b9a",
        "#187aa3",
        "#1f89ac",
        "#2598b4",
        "#2aa8bd",
        "#2eb8c6",
        "#32c8cf",
        "#35d8d8",
    ],
    basic: [
        styles.basicColor900,
        styles.basicColor700,
        styles.basicColor600,
        styles.basicColor500,
        styles.basicColor300,
        styles.basicColor100,
    ],
    second: [
        styles.secondColor900,
        styles.secondColor700,
        styles.secondColor600,
        styles.secondColor500,
        styles.secondColor300,
        styles.secondColor100,
    ],
};

Chart.defaults.elements.line.cubicInterpolationMode = "monotone";


function HtmlTooltip(context: { chart: Chart; tooltip: TooltipModel<"line"> }): void {

    const tooltipModel = context.tooltip;

    // Tooltip Element
    let tooltipEl = document.getElementById("ce-sparkline-tooltip");

    // Create the element on first render
    if (!tooltipEl) {
        tooltipEl = el("div", { id: "ce-sparkline-tooltip" });
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = "0";

    } else {

        // Set Text
        if (tooltipModel.body) {
            const headElement = el("thead");
            const bodyElement = el("thead");
            const tableElement = el("table", {}, headElement, bodyElement);
            const bodyLines = tooltipModel.body.map((bodyItem) => bodyItem.lines);


            (tooltipModel.title || []).forEach(function(title) {
                headElement.appendChild(el("tr", {}, el("th", {}, title)));
            });

            bodyLines.forEach(function(body) {
                headElement.appendChild(el("tr", {}, el("td", {}, String(body))));
            });

            tooltipEl.innerHTML = "";
            tooltipEl.appendChild(tableElement);
        }

        // `this` will be the overall tooltip
        const position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = "1";
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px";
        tooltipEl.style.top = (position.top + window.pageYOffset + tooltipModel.caretY - tooltipModel.height) + "px";
        tooltipEl.style.padding = "10px";
        tooltipEl.style.pointerEvents = "none";

    }

}


/** Custom Element to show a sparkline (inline graph)
 *
 * @param data-values: Array of values to use for the yAxis. Or Object with `labels: values` for both axes.
 * This is the bare minimum for this Element to work.
 *
 * @param data-custom-text: Custom text to show i the top left corner.
 *
 * @param data-xAxis-type: Data type of the labels ("time" or unset).
 *
 * @param data-tooltip-data-type: Data type of the values for the tooltip ("ByteSize" or unset).
 *
 * @param data-xaxis-min: See yAxis.
 * @param data-xaxis-max: See yAxis.
 * @param data-yaxis-min: The minimum boundary to show on the yAxis.
 * @param data-yaxis-max: The maximum boundary to show on the yAxis.
 *
 * @param data-xaxis-suggested-min: See yAxis.
 * @param data-xaxis-suggested-max: See yAxis.
 * @param data-yaxis-suggested-min: If no value lower than this is given, show at least this minimum value.
 * @param data-yaxis-suggested-max: If no value greater than this is given, show at least this maximum value.
 *
 * @param data-colorscheme: Key of colorSchemes (defaults to "blues") to define line color.
 */
customElements.get("ce-sparkline") || customElements.define("ce-sparkline", (

    class extends HTMLElement {

        static readonly observedAttributes = [
            "data-values",
            "data-top-left-text",
            "data-top-right-text",
            "data-bottom-right-text",
            "data-bottom-left-text",
            "data-tooltip-data-type",
            "data-xaxis-type",
            "data-xaxis-min",
            "data-xaxis-max",
            "data-xaxis-suggested-min",
            "data-xaxis-suggested-max",
            "data-yaxis-min",
            "data-yaxis-max",
            "data-yaxis-suggested-min",
            "data-yaxis-suggested-max",
            "data-colorscheme",
        ];

        public chart: Chart;

        constructor() {
            super();
        }

        public connectedCallback(): void {
            window.addEventListener("load", () => this.draw());
        }

        // noinspection JSUnusedGlobalSymbols
        public attributeChangedCallback(): void {
            if (document.readyState === "complete") {
                setTimeout(() => this.draw(), 0);
            }
        }

        private draw(): void {
            this.innerHTML = "";

            let values: number[] = [];
            let labels: number[] | string[] = [];
            try {
                if (this.dataset.values) {
                    const data: dataFormat = JSON.parse(this.dataset.values);
                    if (Array.isArray(data)) {
                        values = data;
                    } else if (typeof data === "object") {
                        labels = Object.keys(data);
                        values = Object.values(data);
                    }
                }
            } catch (e) {
                throw(`Invalid value given (${e}): ${this.dataset.values}`);
            }

            const xAxisType = this.dataset.xaxisType || "category";
            assert(["category", "time"].includes(xAxisType), `Unknown data-xAxis-type: ${xAxisType}`);

            if (values.length) {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");
                const colorScheme = colorSchemes?.[this.dataset.colorscheme as keyof typeof colorSchemes || "blues"];
                this.chart = new Chart(context, {
                    type: "line",
                    data: {
                        // @ts-expect-error: Labels may be strings
                        labels: labels,
                        datasets: [
                            {
                                data: values,
                                pointRadius: 0,
                                borderColor: `${colorScheme[0]}AA`,
                            },
                        ],
                    },
                    options: {
                        plugins: {
                            customText: [
                                { text: this.dataset.topLeftText, corner: "top-left", color: colorScheme[0] },
                                { text: this.dataset.topRightText, corner: "top-right", color: colorScheme[0] },
                                { text: this.dataset.bottomRightText, corner: "bottom-right", color: colorScheme[0] },
                                { text: this.dataset.bottomLeftText, corner: "bottom-left", color: colorScheme[0] },
                            ],
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                // Disable the on-canvas tooltip
                                enabled: false,
                                // Use an HTML tooltip instead
                                external: HtmlTooltip,
                                callbacks: {
                                    label: (tooltipItem: TooltipItem<"line">) => {
                                        if (this.dataset.tooltipDataType === "ByteSize") {
                                            return getReadableByteSizeObject(tooltipItem.raw as number).string;
                                        }
                                        return tooltipItem.formattedValue;
                                    },
                                },
                            },
                        },
                        animation: {
                            duration: 0,
                        },
                        scales: {
                            y: {
                                display: false,
                                // @ts-expect-error: suggestedMin / suggestedMax may be string
                                ticks: {
                                    ...(this.dataset.xaxisMin ?
                                        { min: parseFloat(this.dataset.xaxisMin) } : {}),
                                    ...(this.dataset.xaxisMax ?
                                        { max: parseFloat(this.dataset.xaxisMax) } : {}),
                                    ...(this.dataset.xaxisSuggestedMin ?
                                        { suggestedMin: this.dataset.xaxisSuggestedMin } : {}),
                                    ...(this.dataset.xaxisSuggestedMax ?
                                        { suggestedMax: this.dataset.xaxisSuggestedMax } : {}),
                                },
                            },
                            x: {
                                type: xAxisType as "category" | "time",
                                display: false,
                                ...(xAxisType === "time" ? {
                                    time: {
                                        tooltipFormat: getMomentJsDateFormat(),
                                    },
                                } : {}),
                                ticks: {
                                    display: false,
                                    ...(this.dataset.yaxisMin ?
                                        { min: this.dataset.yaxisMin } : {}),
                                    ...(this.dataset.yaxisMax ?
                                        { max: this.dataset.yaxisMax } : {}),
                                    ...(this.dataset.yaxisSuggestedMin ?
                                        { suggestedMin: this.dataset.yaxisSuggestedMin } : {}),
                                    ...(this.dataset.yaxisSuggestedMax ?
                                        { suggestedMax: this.dataset.yaxisSuggestedMax } : {}),
                                },
                            },
                        },
                        maintainAspectRatio: false,
                    },
                });

                this.appendChild(canvas);
            }
        }
    }

));
