import {Subscribable} from "knockout";
import * as _ from "lodash";
import * as ko from "knockout";
import {PureComputed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import {getFormattedCurrentDate} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import {frames} from "../lib/pyratTop";
import template from "./quickselectStud.html";


// The definition of Seed is quite complex here, so we use a namespace knowing it is not recommended.
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Seed {

    interface Context {
        animals: {
            animalid: number;
            eartag: string;
        }[];
    }

    interface Content {
        add_to_selection_action?: AddToSelectionAction;
        show_in_animal_list_action?: ShowInAnimalListAction;
        set_mating_action?: SetMatingAction;
        set_inactive_action?: SetInactiveAction;
        set_plugs_action?: SetPlugsAction;
        set_status_action?: SetStatusAction;
        remove_stud_action?: RemoveStudAction;
        export_to_excel_action?: ExportToExcelAction;
    }

    interface AddToSelectionAction {
        have_work_requests: boolean;
        animals_in_selection: number;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ShowInAnimalListAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetMatingAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetInactiveAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetPlugsAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetStatusAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RemoveStudAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ExportToExcelAction {
    }
}

interface Response {
    success: boolean;
    context: {
        animal_ids: number[];
    };
    content: {
        add_to_selection_action?: number[];
        show_in_animal_list_action?: any;
        export_to_excel_action?: any;
    };
}

interface Params {
    animalIds: number[];
    actions?: string[];
    reloadCallback?: () => void;
}

abstract class Action {

    public qs: QuickselectStudViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: QuickselectStudViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);
    }

    public serialize = () => {
        return {};
    };
}


// TODO: Waiting for "inherit" from in TypeScript to replace "Action" return value with implementation.
// noinspection JSPotentiallyInvalidUsageOfThis
const actionModels: { [key in keyof Seed.Content]: (qs: QuickselectStudViewModel, seed: Seed.Content[key]) => Action } = {

    add_to_selection_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public extendAnimalSelection: Observable<boolean>;

        constructor() {
            super(qs);

            this.extendAnimalSelection = ko.observable(false);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                return !(!session.pyratConf.MULTIPLE_WR && seed.have_work_requests);
            });
        }

        public serialize = () => {
            return {
                extend_selection: this.extendAnimalSelection(),
            };
        };
    }),

    show_in_animal_list_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    set_mating_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public matingSystem: Observable<string>;
        public maleFemaleRatio: Observable<string>;

        constructor() {
            super(qs);

            this.matingSystem = ko.observable();
            this.maleFemaleRatio = ko.observable();
        }

        public serialize = () => {
            return {
                mating_system: this.matingSystem(),
                male_female_ratio: this.maleFemaleRatio(),
            };
        };
    }),

    set_inactive_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);
        }
    }),

    set_plugs_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public numberOfPlugs: Observable<number>;
        public plugDate: CheckExtended<Observable<string>>;

        constructor() {
            super(qs);

            this.numberOfPlugs = ko.observable(1);

            this.numberOfPlugs.subscribe(() => {
               this.selected(true);
            });

            this.plugDate = ko.observable(getFormattedCurrentDate()).extend({
                invalid: (v) => {
                    this.errors.removeAll();

                    if (this.selected()) {
                        if (!v) {
                            return true;
                        }
                        if (v && isInvalidCalendarDate(v)) {
                            this.errors.push(getTranslation("Invalid date"));
                            return true;
                        }
                    }
                    return false;
                },
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.plugDate.isInvalid();
            });
        }

        public serialize = () => {
            return {
                number_of_plugs: this.numberOfPlugs(),
                plug_date: this.plugDate(),
            };
        };
    }),

    set_status_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public studStatus: Observable<string>;

        constructor() {
            super(qs);

            this.studStatus = ko.observable();
        }

        public serialize = () => {
            return {
                stud_status: this.studStatus(),
            };
        };
    }),

    remove_stud_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }
    }),

    export_to_excel_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }
    }),
};


class QuickselectStudViewModel {

    public reloadRequired: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<{ context: Seed.Context; content: Seed.Content }>>>;
    public context: PureComputed<Seed.Context | undefined>;
    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    public actions: PureComputed<{ [key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]> }>;
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    private readonly params: Params;
    private readonly dialog: KnockoutPopup;
    private scenery: PureComputed<{ loading: boolean } |
        { conclusion: QuickselectStudViewModel } |
        { context: QuickselectStudViewModel; actions: QuickselectStudViewModel } |
        { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.params = params;
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        // get initial data
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("quickselect_stud.py", {
                    "data": JSON.stringify({
                        actions: this.params.actions,
                        context: {animal_ids: this.params.animalIds},
                    }),
                }), {signal});
            },
        });

        this.dialog.setTitle(
            getTranslation("Quick Select") + " (" +
            getTranslation("%d animals selected").replace("%d", String(this.params.animalIds.length)) + ")"
        );

        this.context = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed?.context;
            } else {
                return undefined;
            }
        });

        // initialize the actions with their seeded data
        this.actions = ko.pureComputed(() => {
            const actions: {[key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]>} = {};
            const seed = this.seed();
            if (seed?.success && seed?.content) {
                Object.keys(seed.content).forEach((actionName: keyof Seed.Content) => {
                    if (actionName in actionModels) {
                        // @ts-expect-error: TODO: Typing is too complicated here. I did not get it right.
                        // Waiting for "inherit" from implementation in TypeScript.
                        actions[actionName] = actionModels[actionName](this, seed.content[actionName]);
                    }
                });
            }
            return actions;
        });


        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {

            if (this.seed.fetchInProgress()) {
                return {loading: true};
            }

            if (this.conclusion().length) {
                return {conclusion: this};
            }

            if (this.seed() && this.seed().success) {
                return {context: this, actions: this};
            }

            return {error: true};

        });

    }

    public close = () => {
        this.dialog.close();
        if (typeof this.params.reloadCallback === "function" && this.reloadRequired()) {
            this.params.reloadCallback();
        }
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.context().animals.length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {

        this.errors.removeAll();

        const data = {
            context: {animal_ids: _.map(this.context().animals, "animalid")},
            content: _
                .chain(this.actions())
                .pickBy((a) => {
                    return a.selected();
                })
                .mapValues((a) => {
                    return a.serialize();
                })
                .value(),
        };

        this.applyInProgress(true);
        this.reloadRequired(true);
        _.forEach(this.actions(), (action) => {
            action.errors.removeAll();
        });

        fetch("quickselect_stud.py", {method: "POST", body: getFormData({data: JSON.stringify(data)})})
            .then((response) => response.json())
            .then((response: Response) => {

                if (response.success) {

                    this.conclude(response);

                    const autoClose = !_.some(response?.content, (action_data, action: keyof Response["content"]) => {
                        return this.actions()[action] && this.actions()[action].requireConclusion;
                    });

                    if (autoClose) {
                        // no action requires a conclusion, so we immediately close it
                        this.close();
                    }

                } else {
                    // client error
                    _.forEach(response.content, (messages, name: keyof Response["content"]) => {
                        if (this.actions()[name] && this.actions()[name].errors) {
                            _.forEach(messages, (m) => {
                                this.actions()[name].errors.push(m);
                            });
                        } else {
                            _.forEach(messages, (m) => {
                                this.errors.push(m);
                            });
                        }
                    });
                }
            })
            .catch(() => {
                this.errors.push(getTranslation("General quickselect error."));
            })
            .finally(() => this.applyInProgress(false));

    };

    private conclude = (value: Response) => {

        // clear old results
        this.conclusion.removeAll();

        const animalIds = value?.context?.animal_ids;
        const addToSelectionRequest = value?.content?.add_to_selection_action;
        const showInAnimalListRequest = value?.content?.show_in_animal_list_action;
        const exportRequest = value?.content?.export_to_excel_action;

        if (animalIds) {
            this.conclusion.push({
                text: getTranslation("Show studs") + " (" + String(animalIds.length) + ").",
                click: () => {
                    this.reloadRequired(false);
                    window.top.mainMenu.filterSubtab("studmales", {animalid: animalIds});
                },
            });
        }

        if (addToSelectionRequest) {
            this.conclusion.push({
                text: getTranslation("Immediately open a new request"),
                click: () => {
                    frames.detailPopup.open(getUrl("new_request.py"));
                },
            });
        }

        if (showInAnimalListRequest) {
            // no conclusion needed, this action redirects immediately to the animal list
            this.conclusion.removeAll();
            this.reloadRequired(false);
            window.top.mainMenu.subtabClick("animals");
        }

        if (exportRequest) {
            frames.openListAjaxPopup({
                method: "POST",
                url: "columnselect.py",
                name: "ColumnSelect",
                data: {
                    view_name: "studlist",
                    export_args: JSON.stringify({
                        animalid: animalIds,
                        page_start: 0,
                        page_size: animalIds.length,
                    }),
                },
                title: getTranslation("Select columns"),
                width: "auto",
                height: "auto",
                anchor: {top: 25, right: 25},
                closeOnEscape: false,
            });
        }

        if (!this.conclusion().length) {
            // no conclusion was needed, so we close it
            this.close();
        }
    };
}


// dialog starter
export const showQuickselectStud = dialogStarter(QuickselectStudViewModel, template, {
    name: "QuickselectStud",
    width: 600,
    cssARequire: [
        ":table.css",
        ":quick_select.css",
        ":usercolors.css"],
    anchor: {
        top: 5,
        right: 5,
    },
    closeOthers: true,
    title: getTranslation("Quick Select"),
});
