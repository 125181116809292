import * as ko from "knockout";
import {BackgroundTaskStatusComponent} from "./backgroundTaskStatus";
import {CageHardwareWidgetComponent} from "./cageHardwareWidget";
import {CalendarPopupComponent} from "./calendarPopup";
import {ClickToEditComponent} from "./clickToEdit";
import {ClickToSelectComponent} from "./clickToSelect";
import {ColorPickerComponent} from "./colorPicker";
import {CommentWidgetComponent} from "./commentWidget";
import {DocumentsComponent} from "./documents";
import {GenerationWidgetComponent} from "./generationWidget";
import {ProcedureWidgetComponent} from "./procedureWidget";
import {ProgressComponent} from "./koProgress";
import {RepeatedInstancesComponent} from "./repeatedInstances";
import {SelectemComponent} from "./selectem";
import {SliderComponent} from "./slider";
import "./locationPicker";

// register knockout components
ko.components.register("ko-background-task-status", new BackgroundTaskStatusComponent());
ko.components.register("ko-calendar-popup", new CalendarPopupComponent());
ko.components.register("ko-cage-hardware-widget", new CageHardwareWidgetComponent());
ko.components.register("ko-comment-widget", new CommentWidgetComponent());
ko.components.register("ko-click-to-edit", new ClickToEditComponent());
ko.components.register("ko-click-to-select", new ClickToSelectComponent());
ko.components.register("ko-color-picker", new ColorPickerComponent());
ko.components.register("ko-documents", new DocumentsComponent());
ko.components.register("ko-generation-widget", new GenerationWidgetComponent());
ko.components.register("ko-procedure-widget", new ProcedureWidgetComponent());
ko.components.register("ko-progress", new ProgressComponent());
ko.components.register("ko-repeated-instances", new RepeatedInstancesComponent());
ko.components.register("ko-selectem", new SelectemComponent());
ko.components.register("ko-slider", new SliderComponent());
