/**
 * Show a popup to set the location for a cage.
 *
 * @param cageId: Database ID of the cage.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of popup).
 *
 * @param title: Title for dialog.
 *
 * @param reloadCallback: Function to call when data has been applied and popup is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the popup is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Computed, Observable} from "knockout";
import {PureComputed} from "knockout";
import {LocationItem, PreselectLocationItem} from "../knockout/components/locationPicker/locationPicker";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {AjaxResponse} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import template from "./setCageLocation.html";

interface Params {
    cageId: number;
    eventTarget?: HTMLElement;
    title: string;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    select_rack_id: number;
}

class SetCageLocationViewModel {
    private readonly dialog: KnockoutPopup;

    // params
    private readonly cageId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;

    // state
    public preselectLocation: PreselectLocationItem;
    public selectedLocation: CheckExtended<Observable<LocationItem>>;
    public rackId: Computed<number>;
    public cagePosition: Observable<string>;
    public confirmSanitaryStatus: Observable<boolean>;
    public errors: ko.ObservableArray<string>;
    public canSubmit: PureComputed<boolean>;
    public submitInProgress: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<Seed>>>;


    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.cageId = params.cageId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.selectedLocation = ko.observable();
        this.preselectLocation = null;

        this.selectedLocation.extend({
            invalid: (v) => {
                return !!(session.pyratConf.MANDATORY_LOCATION && !v);
            },
        });

        this.rackId = ko.computed(() => {
            return this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
        });

        this.cagePosition = ko.observable("");

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("set_cage_location.py", {cage_id: this.cageId}), {signal});
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                if (seed.select_rack_id) {
                   this.preselectLocation = {type: "rack", id: seed.select_rack_id};
                }
            }
        });

        this.canSubmit = ko.pureComputed(() => {
            return !(this.submitInProgress() ||
                     this.seed.fetchInProgress() ||
                     this.selectedLocation.isInvalid());
        });

        this.confirmSanitaryStatus = ko.observable(false);

        this.submitInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public submit = () => {
        this.submitInProgress(true);
        this.errors([]);

        const formData = getFormData({
            cage_id: this.cageId.toString(),
            rack_id: this.rackId() || "",
            cage_position: this.cagePosition() || "",
            confirm_sanitary_status: this.confirmSanitaryStatus() ? 1 : 0,
        });

        fetch("set_cage_location.py", {method: "POST", body: formData})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    notifications.showNotification(response.message, "success");
                } else if (response.confirm && response.confirm === "confirm_sanitary_status") {
                    notifications.showConfirm(
                        response.message,
                        () => {
                            // post again, this time with confirmed sanitary status
                            this.confirmSanitaryStatus(true);
                            this.submit();
                        },
                        {
                            onCancel: () => {
                                this.submitInProgress(false);
                            },
                        }
                    );
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetCageLocation = dialogStarter(SetCageLocationViewModel, template, params => ({
    name: "SetCageLocation",
    width: 325,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: params.title,
}));
