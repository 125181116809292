import * as ko from "knockout";
import {Computed, Observable, ObservableArray} from "knockout";
import * as _ from "lodash";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import template from "./severityAssessmentSheet.html";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import {isDateLowerThanDate} from "../lib/utils";
import {getFormattedCurrentDate} from "../lib/utils";
import {isInvalidCalendarDate} from "../lib/utils";
import {notifications} from "../lib/pyratTop";


interface Params {
    scoresheetId?: number;
    animalId?: number | number[];
    pupId?: number | number[];
    birthId?: number | number[];
    strainId?: number | number[];
    classificationId?: number;
    templateId?: number;
    templateForm?: string;
    reloadCallback?: () => void;
    quickSelectOpenCallback?: () => ScoreSheetData;
    quickSelectApplyCallback?: () => void;
}

interface Seed {
    time_points: {
        id: number;
        name: string;
    }[];
    scoresheet_data: ScoreSheetData;
    timepoint_count_by_timepoint_id?: Record<number, number>;
}

interface ScoreSheetData {
    id: number;
    birth_id: number;
    examination_date: string;
    examination_time: string;
    template_form: string;
    template_type: string;
    timepoint_id: number;
    timepoint_count: number;
    items: {
        id?: number;
        sign_id: number;
        sign_name: string;
        category_name: string;
        score: number;
        present?: boolean;
        comment?: string;
    }[];
}

interface ScoreSheetItem {
    id?: number;
    signId: number;
    signName: string;
    categoryName: string;
    score: number;
    present: Observable<boolean>;
    comment: Observable<string>;
}

interface GroupedScoreSheetItem {
    categoryName: string;
    categoryScoreResult: Computed<number>;
    items: ScoreSheetItem[];
}

interface SubmitData {
    action?: string;
    examination_date: string;
    examination_time?: string;
    timepoint_id?: number;
    timepoint_count?: number;
    items: {
        id?: number;
        sign_id: number;
        present: number;
        comment: string;
    }[];
    scoresheet_id?: number;
    animal_id?: number | number[];
    pup_id?: number | number[];
    birth_id?: number | number[];
    strain_id?: number | number[];
    classification_id?: number;
    template_id?: number;
    template_form?: string;
}

/**
 * Popup dialog for tank details
 */
class SeverityAssessmentSheetViewModel {

    private readonly scoresheetId: number;
    private readonly animalId: number | number[];
    private readonly pupId: number | number[];
    private readonly birthId: number | number[];
    private readonly strainId: number | number[];
    private readonly classificationId: number;
    private readonly templateId: number;
    private readonly templateForm: string;
    private readonly reloadCallback: () => void;
    private readonly quickSelectOpenCallback: () => ScoreSheetData;
    private readonly quickSelectApplyCallback: (submitData: SubmitData) => void;
    private readonly dialog: KnockoutPopup;

    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed>>>;
    public readonly scoreSheetData: Observable<ScoreSheetData>;
    public readonly timePointId: Observable<number>;
    public readonly timePointCount: CheckExtended<Observable<number>>;
    private readonly timePointCountByTimePointId: Observable<Record<number, number>>;
    public readonly timePointVisible: Computed<boolean>;
    public readonly examinationDate: CheckExtended<Observable<string>>;
    public readonly examinationTime: CheckExtended<Observable<string>>;
    public readonly examinationTimeVisible: Computed<boolean>;
    public readonly nothingAbnormalDetected: CheckExtended<Observable<boolean>>;
    public readonly scoreSheetItems: ObservableArray<ScoreSheetItem>;
    public readonly groupedScoreSheetItems: Computed<GroupedScoreSheetItem[]>;
    public readonly totalScoreResult: Computed<number>;

    public readonly editable: Computed<boolean>;
    private readonly submitInProgress: Observable<boolean>;

    constructor({scoresheetId, animalId, pupId, birthId, strainId, classificationId,
                    templateId, templateForm, reloadCallback, quickSelectOpenCallback, quickSelectApplyCallback}: Params,
                dialog: KnockoutPopup) {

        this.scoresheetId = scoresheetId;
        this.animalId = animalId;
        this.pupId = pupId;
        this.birthId = birthId;
        this.strainId = strainId;
        this.classificationId = classificationId;
        this.templateId = templateId;
        this.templateForm = templateForm;
        this.reloadCallback = reloadCallback;
        this.quickSelectOpenCallback = quickSelectOpenCallback;
        this.quickSelectApplyCallback = quickSelectApplyCallback;

        this.editable = ko.pureComputed(function () {
            return (!scoresheetId && session.userPermissions.severity_assessment_scoresheet_create) ||
                   (scoresheetId && session.userPermissions.severity_assessment_scoresheet_update);
        });

        this.dialog = dialog;
        this.dialog.setTitle(
            scoresheetId
                ? (this.editable()
                    ? getTranslation("Edit assessment sheet")
                    : getTranslation("View assessment sheet"))
                : getTranslation("New assessment sheet")
        );

        this.submitInProgress = ko.observable(false);

        this.scoreSheetData = ko.observable();
        this.scoreSheetItems = ko.observableArray();
        this.timePointId = ko.observable();
        this.timePointCount = ko.observable().extend({
            invalid: (v) => {
                if (this.timePointVisible()) {
                    return !v || !(_.isNumber((v || 1)) && (v || 1) >= 1);
                }
                return false;
            },
        });
        this.timePointCountByTimePointId = ko.observable();

        this.examinationDate = ko.observable().extend({
            invalid: (v) => {
                return !(v && !isInvalidCalendarDate(v)
                           && !isDateLowerThanDate(getFormattedCurrentDate(), v) || _.isUndefined(v));
            },
        });

        this.examinationTime = ko.observable().extend({
            invalid: (v) => {
                if (this.examinationTimeVisible()) {
                    if (!v) {
                        return true;
                    }
                    return !(v.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]/));
                }
                return false;
            },
        });

        this.nothingAbnormalDetected = ko.observable().extend({
            invalid: (v) => {
                const anyPresentItems = _.some(this.scoreSheetItems(), (item) => { return item.present(); });

                if (!v && !anyPresentItems) {
                    return getTranslation("Please select 'Nothing abnormal detected' if no deviations from normal state were detected.");
                } else if (v && anyPresentItems) {
                    return true;
                }
                return false;
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => fetch("severity_assessment_sheet.py", {
                method: "POST",
                body: getFormData({
                    data: JSON.stringify({
                            action: "get_dialog_options",
                            scoresheet_id: scoresheetId || undefined,
                            animal_id: animalId || undefined,
                            pup_id: pupId || undefined,
                            birth_id: birthId || undefined,
                            template_id: templateId || undefined,
                            template_form: templateForm || undefined,
                        },
                    ),
                }),
                signal,
            }),
        });

        this.seed.subscribe((response) => {
            if (response && response.success) {
                if (response.scoresheet_data) {
                    this.scoreSheetData(response.scoresheet_data);
                }
                if (response.timepoint_count_by_timepoint_id) {
                    this.timePointCountByTimePointId(response.timepoint_count_by_timepoint_id);
                }
            }
        });

        this.scoreSheetData.subscribe((data) => {
            this.examinationDate(data.examination_date);
            this.examinationTime(data.examination_time);
            this.timePointId(data.timepoint_id);
            this.timePointCount(data.timepoint_count);

            this.scoreSheetItems(_.map(data.items || [], (row) => {
                return {
                    id: row.id,
                    signId: row.sign_id,
                    signName: row.sign_name,
                    categoryName: row.category_name,
                    score: row.score,
                    present: ko.observable(!!row.present),
                    comment: ko.observable(row.comment || null),
                };
            }));

            // this is used when the dialog is opened from quickselect
            // load quickselect data (when popup is opened for 'Edit')
            if (typeof this.quickSelectOpenCallback === "function") {
                const quickSelectData = this.quickSelectOpenCallback();

                this.examinationDate(quickSelectData.examination_date);
                this.examinationTime(quickSelectData.examination_time);
                this.timePointId(quickSelectData.timepoint_id);
                this.timePointCount(quickSelectData.timepoint_count);

                _.forEach(this.scoreSheetItems() || [], (item, i) => {
                    item.present(!!quickSelectData.items[i].present);
                    item.comment(quickSelectData.items[i].comment || null);
                });
                const anyPresentItems = _.some(this.scoreSheetItems(), (item) => { return item.present(); });
                this.nothingAbnormalDetected(!anyPresentItems);
            }

            if (scoresheetId) {
                // preselect the 'nothing anormal detected' checkbox on existing sheet.
                // don't preselect on a new assessment sheet
                // This is meant as an additional check when adding a new assessment sheet
                // to make sure an empty sheet is not added by mistake (see #15715)
                const anyPresentItems = _.some(this.scoreSheetItems(), (item) => { return item.present(); });
                this.nothingAbnormalDetected(!anyPresentItems);
            }
        });

        /**
         * Determine whether examination time is visible on create/edit dialog or not
         *
         * Rules:
         * Examination time is visible for classification assessment.
         * (not for strain assessment)
         */
        this.examinationTimeVisible = ko.pureComputed(() => {
            if (scoresheetId) {
                return this.scoreSheetData() && this.scoreSheetData().template_form === "classification";
            }
            // new scoresheet that's classification assessment
            return templateForm === "classification";

        });

        /**
         * Determine whether timepoint & counter is visible on create/edit dialog or not
         *
         * Rules:
         * Time point and counter are available for strain assessment.
         * They are not relevant for classification assessment.
         */
        this.timePointVisible = ko.pureComputed(() => {
            if (scoresheetId) {
                return this.scoreSheetData() && this.scoreSheetData().template_form === "strain_animal" ||
                    this.scoreSheetData() && this.scoreSheetData().template_form === "strain_litter";
            }
            // new scoresheet that's strain assessment
            return templateForm === "strain_animal" || templateForm === "strain_litter";
        });

        /**
         * Preselect the counter depending on the selected timepoint (on a new assessment sheet)
         */
        this.timePointId.subscribe((v) => {
            if (!scoresheetId && this.timePointCountByTimePointId()) {
                this.timePointCount(_.get(this.timePointCountByTimePointId(), v) || 1);
            }
        });

        /**
         * Group scoresheet items by category (and preserve the original order)
         */
        this.groupedScoreSheetItems = ko.computed(() => {
            const res: any[] = [];
            _.forEach(this.scoreSheetItems(), (item) => {
                 if (res.length && _.last(res).categoryName === item.categoryName) {
                    _.last(res).items.push(item);
                } else  {
                    res.push({"categoryName": item.categoryName, "items": [item]});
                }
            });

            _.forEach(res, function (category){
                category.categoryScoreResult = ko.computed(() => {
                    return _.max(_.map(category.items, (item) => {
                        return item.present() ? item.score : null;
                    }));
                });
             });

            return res;
        });

        /**
         * Calculate the total score
         *
         * Rules:
         * The total score is the sum of all category scores.
         * The category score is the highest score of all ticked clinical signs of the category.
         */
        this.totalScoreResult = ko.computed(() => {
            return _.sum(_.map(this.groupedScoreSheetItems(), function(category) {
                return category.categoryScoreResult();
            }));
        });
    }

    public cancel = () => {
        this.dialog.close();
    };

    public errors = ko.pureComputed(() => {
        const errors = [];
        if (this.nothingAbnormalDetected.errorMessage()) {
            errors.push(this.nothingAbnormalDetected.errorMessage());
        }
        return errors;
    });

    public canSubmit = ko.pureComputed(() => {
        return !(this.seed.fetchInProgress() ||
            this.submitInProgress() ||
            this.examinationDate.isInvalid() ||
            this.examinationTime.isInvalid() ||
            this.timePointCount.isInvalid() ||
            this.nothingAbnormalDetected.isInvalid());
    });

    private getRequestData = () => {
        const requestData: SubmitData = {
            examination_date: this.examinationDate(),
            examination_time: this.examinationTimeVisible() ? this.examinationTime() : undefined,
            timepoint_id: this.timePointVisible()? this.timePointId() : undefined,
            timepoint_count: this.timePointVisible()? this.timePointCount() : undefined,
            items: _.map(this.scoreSheetItems(), function (item) {
                return {
                    id: item.id,
                    sign_id: item.signId,
                    present: item.present() ? 1 : 0,
                    comment: item.comment(),
                };
            }),
        };

        if (this.scoresheetId) {
            // existing scoresheet
            requestData["action"] = "update";
            requestData["scoresheet_id"] = this.scoresheetId;
        } else {
            // new scoresheet
            requestData["action"] = "insert";
            requestData["template_id"] = this.templateId;
            requestData["template_form"] = this.templateForm;
            requestData["strain_id"] = this.strainId;
            requestData["classification_id"] = this.classificationId;
            requestData["animal_id"] = this.animalId;
            requestData["pup_id"] = this.pupId;
            requestData["birth_id"] = this.birthId;
        }

        return requestData;
    };


    public submit = () => {
        // quickselect apply function
        if (typeof this.quickSelectApplyCallback === "function") {
            this.quickSelectApplyCallback(this.getRequestData());
            this.dialog.close();
            return;
        }

        this.submitInProgress(true);

        const form = getFormData({data: JSON.stringify(this.getRequestData())});
        fetch("severity_assessment_sheet.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                    if (this.scoresheetId) {
                        notifications.showNotification(
                            getTranslation("Editing assessment sheet was successful."), "success");
                    } else {
                        notifications.showNotification(
                            getTranslation("Adding assessment sheet was successful."), "success");
                    }
                } else {
                    notifications.showNotification(response.message, "error");
                }
            }).catch(() => {
                this.submitInProgress(false);
                notifications.showNotification(getTranslation("Action failed. The data could not be saved. Please try again."), "error");
            });
    };
}

// dialog starter
export const showSeverityAssessmentSheet = dialogStarter(SeverityAssessmentSheetViewModel, template, {
    name: "SeverityAssessmentSheet",
    width: 500,
    cssARequire: [":table.css",
                  ":severity_assessment.css"],
    anchor: {top: 120, left: undefined},
    escalate: false,
    closeOthers: true,
});
