import { Chart, ChartConfiguration } from "chart.js";
import * as ko from "knockout";
import * as _ from "lodash";

interface ChartElement extends HTMLElement {
    chart: Chart;
}

declare module "knockout" {

    export interface BindingHandlers {
        chart?: {
            init(element: ChartElement, valueAccessor: ko.Observable<ChartConfiguration>): void;
            update(element: ChartElement, valueAccessor: ko.Observable<ChartConfiguration>): void;
        };
    }

}

const defaultConfiguration: Partial<ChartConfiguration> = {
    options: {
        animation: {
            duration: 0,
        },
        maintainAspectRatio: false,
    },
};

const drawChart = (element: HTMLElement, configuration: ChartConfiguration) => {
    element.innerHTML = "";
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    new Chart(context, configuration);
    element.appendChild(canvas);
};

/** Chart.Js knockout binding.
 *
 * Usage like this: <div data-bind="chart: ChartConfigurationObject"></div>
 */
ko.bindingHandlers.chart = {
    init: (element, valueAccessor) => {
        drawChart(element, _.defaultsDeep(ko.unwrap(valueAccessor()), defaultConfiguration));
    },
    update: (element, valueAccessor) => {
        drawChart(element, _.defaultsDeep(ko.unwrap(valueAccessor()), defaultConfiguration));
    },
};
