import {dialogStarter} from "../knockout/dialogStarter";
import {session} from "../lib/pyratSession";
import {getUrl} from "../lib/utils";
import template from "./projectDetails.html";
import createBudgetTemplate from "./projectDetailsCreateBudget.html";
import {KnockoutPopup} from "../lib/popups";
import {getTranslation} from "../lib/localize";
import {FetchExtended} from "../knockout/extensions/fetch";
import {AjaxResponse, isInvalidCalendarDate} from "../lib/utils";
import * as ko from "knockout";
import * as _ from "lodash";
import {CheckExtended} from "../knockout/extensions/invalid";
import {CommentWidgetSeed} from "../knockout/components/commentWidget";
import {DocumentsWidgetSeed} from "../knockout/components/documents";
import {getFormData} from "../lib/utils";
import "../../scss/tab_menu.scss";
import {frames} from "../lib/pyratTop";
import {notifications} from "../lib/pyratTop";


interface Params {
    projectId: number | null;
}

interface Budget {
    id: number;
    name: string;
}

interface Seed {
    owners: {
        userid: number;
        fullname: string;
    }[];
    categories: {
        id: number;
        name: string;
    }[];
    budgets: Budget[];
    project_details?: {
        id: number;
        budget_id: number;
        category_id: number;
        description: string;
        expiration_date: string;
        name: string;
        owner_id: number;
        owner_fullname: string;
        permitted_users: {
            user_id: number;
            user_fullname: string;
        }[];
    };
    comment_widget_seed?: CommentWidgetSeed;
    document_widget_seed?: DocumentsWidgetSeed;
}

class CreateBudgetModel {

    public projectDetails;
    public dialog;
    public newBudgetName: ko.Observable<string>;

    constructor(projectDetails: ProjectDetailsViewModel, dialog: KnockoutPopup) {
        this.projectDetails = projectDetails;
        this.dialog = dialog;
        this.newBudgetName = ko.observable().extend({
            trim: true,
            invalid: function(v) {
                return !v;
            },
        });
    }

    public createBudget = () => {
        this.projectDetails.applyInProgress(true);
        const form = getFormData({
            request: JSON.stringify({
                "create_budget": this.newBudgetName(),
            }),
        });
        fetch("project_detail.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                this.projectDetails.budgets(response.budgets || undefined);
                this.projectDetails.budgetId(response.budget_id || undefined);
                if (!response.success) {
                    notifications.showNotification(response.message);  // no 'error', only inform about name already existing
                }
                this.dialog.close();
            })
            .catch(() => {
                notifications.showNotification(getTranslation("An error occured. Please try again."), "error");
            })
            .finally(() => {
                this.projectDetails.applyInProgress(false);
            });
    };
}

const showCreateBudget = dialogStarter(CreateBudgetModel, createBudgetTemplate, {
    title: getTranslation("Create new budget"),
    width: 360,
    height: "auto",
    modal: true,
    anchor: {
        top: 210,
        right: 40,
        left: undefined,
        bottom: undefined,
    },
});

class ProjectDetailsViewModel {

    private readonly params;
    private readonly dialog;
    private readonly createAllowed: boolean;
    private readonly updateAllowed: boolean;

    private readonly seed: FetchExtended<ko.Observable<AjaxResponse<Seed>>>;
    private readonly projectName: CheckExtended<ko.Observable<string>>;
    private readonly projectLeaderId: CheckExtended<ko.Observable<number>>;
    private readonly categoryId: ko.Observable<number>;
    private readonly expirationDate: CheckExtended<ko.Observable<string>>;
    private readonly description: ko.Observable<string>;

    public budgets: ko.ObservableArray<Budget>;
    public budgetId: ko.Observable<number>;
    public showCreateBudget: () => void;

    private readonly permittedUsers: ko.Computed<any[]>;
    private readonly isPermittedToAll: ko.Computed<boolean>;
    private readonly newPermittedUserId: ko.Observable<number>;
    private readonly otherUsers: ko.Computed<any[]>;
    private readonly commentWidgetSeed: ko.Observable<CommentWidgetSeed>;

    public  readonly applyInProgress: ko.Observable<boolean>;
    private readonly selectedTab: ko.Observable<"permissions" | "comments" | "documents">;
    private readonly setPermissionInProgress: ko.Observable<boolean>;
    private readonly errors: ko.ObservableArray<string>;
    private readonly errorSameProjectMessage: ko.Observable<string>;
    private readonly errorSameProjectHref: ko.Observable<string>;
    private readonly reloadRequired: ko.Observable<boolean>;

    constructor(params: Params, dialog: KnockoutPopup) {
        this.params = params;
        this.dialog = dialog;

        this.createAllowed = session.userPermissions.project_create;
        this.updateAllowed = session.userPermissions.project_update;

        if (params.projectId) {
            dialog.setTitle(getTranslation("Project details"));
        } else {
            dialog.setTitle(getTranslation("Create new project"));
        }

        dialog.addOnClose(() => {
            if (this.reloadRequired()) {
                frames.reloadListIframe();
            }
        });

        /* initial data */
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                const request: { [key: string]: any } = {
                    "get_project_details": "1",
                };
                if (this.params.projectId) {
                    request["project_id"] = this.params.projectId;
                }
                const form = getFormData({request: JSON.stringify(request)});
                return fetch("project_detail.py", {method: "POST", body: form, signal});
            },
        });

        /* content */
        this.projectName = ko.observable().extend({
            trim: true,
            invalid: function(v) {
                return !v;
            },
        });

        this.projectLeaderId = ko.observable().extend({
            invalid: function (v) { return _.isUndefined(v); },
        });

        this.categoryId = ko.observable();

        this.expirationDate = ko.observable().extend({
            invalid: function (v) { return v ? isInvalidCalendarDate(v): false; },
        });

        this.budgets = ko.observableArray();
        this.budgetId = ko.observable();
        this.showCreateBudget = _.partial(showCreateBudget, this);

        this.description = ko.observable();

        this.permittedUsers = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed && seed.success) {
                return _.filter(seed.project_details.permitted_users, (ownerData) => {
                    // do not show project leader in the list
                    return ownerData.user_id !== this.projectLeaderId();
                });
            }
            return [];
        });
        this.isPermittedToAll = ko.pureComputed(() => {
            return _.some(this.permittedUsers(), function (ownerData) {
                return ownerData.user_id === 0;
            });
        });

        this.newPermittedUserId = ko.observable();
        this.otherUsers = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed && seed.success) {
                return [{
                    userid: 0,
                    fullname: getTranslation("All"),
                }].concat(_.filter(seed.owners, (ownerData) => {
                    // offer all users that are not yet permitted users and who are not the project leader
                    return ownerData.userid !== this.projectLeaderId() &&
                            _.every(this.permittedUsers(), function (u) { return ownerData.userid !== u.user_id; });
                }));
            }
            return [];
        });

        this.commentWidgetSeed = ko.observable();

        this.seed.subscribeOnce((seed: AjaxResponse<Seed>) => {
            if (seed.success) {
                setTimeout(() => {
                    this.budgets(seed.budgets || []);
                    this.projectLeaderId(seed.project_details.owner_id);

                    if (this.params.projectId) {
                        this.projectName(seed.project_details.name);
                        this.categoryId(seed.project_details.category_id);
                        this.expirationDate(seed.project_details.expiration_date);
                        this.budgetId(seed.project_details.budget_id);
                        this.description(seed.project_details.description);
                        this.commentWidgetSeed(seed.comment_widget_seed || undefined);
                    }
                }, 0);
            }
        });

        /* internals */
        this.selectedTab = ko.observable("permissions");
        this.applyInProgress = ko.observable(false);
        this.setPermissionInProgress = ko.observable(false);
        this.errors = ko.observableArray([]);
        this.errorSameProjectMessage = ko.observable();
        this.errorSameProjectHref = ko.observable();
        this.reloadRequired = ko.observable(false);
    }

    private reseedComments = (seed: CommentWidgetSeed) => {
        this.commentWidgetSeed(seed);
        this.reloadRequired(true);
    };

    private showStrainDetail = (strainId: number) => {
        frames.detailPopup.open(getUrl("edit_strain.py", {
            strainid: strainId,
        }));
        this.dialog.close();
    };

    private canEdit = () => {
        if (!this.params.projectId && this.createAllowed) {
            return true;
        } else if (this.params.projectId && this.updateAllowed) {
            return true;
        }
        return false;
    };

    private canSubmit = ko.pureComputed(() => {
        if (!this.canEdit()) {
            return false;
        } else if (this.applyInProgress()) {
            return false;
        } else if (this.projectName.isInvalid() ||
                   this.projectLeaderId.isInvalid() ||
                   this.expirationDate.isInvalid()) {
            return false;
        }
        return true;
    });


    private cancel = () => {
        this.dialog.close();
    };

    private createProject = () => {
        this.applyInProgress(true);
        this.errors([]);
        this.errorSameProjectMessage(null);
        this.errorSameProjectHref(null);
        const form = getFormData({
            request: JSON.stringify({
                create_project: {
                    name: this.projectName(),
                    owner_id: this.projectLeaderId(),
                    category_id: this.categoryId(),
                    expiration_date: this.expirationDate(),
                    budget_id: this.budgetId(),
                    description: this.description(),
                },
            }),
        });
        fetch("project_detail.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // close popup and show success message
                    this.reloadRequired(true);
                    this.dialog.close();
                    notifications.showNotification(getTranslation("Project created"), "success");
                } else {
                    if (response.same_project && response.same_project.owner_status === "active" &&
                            response.same_project.owner_email) {

                        this.errorSameProjectMessage(getTranslation("Send email to %s").replace("%s", response.same_project.owner_fullname));
                        this.errorSameProjectHref("mailto:" + response.same_project.owner_email);
                    }
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.applyInProgress(false));
    };

    private updateProject = () => {
        this.applyInProgress(true);
        this.errors([]);
        this.errorSameProjectMessage(null);
        this.errorSameProjectHref(null);
        const form = getFormData({
            request: JSON.stringify({
                update_project: {
                    project_id: this.params.projectId,
                    name: this.projectName(),
                    owner_id: this.projectLeaderId(),
                    category_id: this.categoryId(),
                    expiration_date: this.expirationDate(),
                    budget_id: this.budgetId(),
                    description: this.description(),
                },
            }),
        });
        fetch("project_detail.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // reload popup and show success message
                    this.reloadRequired(true);
                    this.seed.fetchForceReload();
                    notifications.showNotification(getTranslation("Project updated"), "success");
                } else {
                    if (response.same_project && response.same_project.owner_status === "active" &&
                            response.same_project.owner_email) {

                        this.errorSameProjectMessage(getTranslation("Send email to %s").replace("%s", response.same_project.owner_fullname));
                        this.errorSameProjectHref("mailto:" + response.same_project.owner_email);
                    }
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.applyInProgress(false));
    };

    private setPermission = (data: {add_user_id: number} | {delete_user_id: number}) => {
        const request = _.extend({
                 "project_id": this.params.projectId,
            }, data);

        this.setPermissionInProgress(true);
        this.errors([]);
        const form = getFormData({request: JSON.stringify(request)});
        fetch("project_detail.py", {method: "POST", body: form})
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    // reload popup and show update message
                    this.reloadRequired(true);
                    this.seed.fetchForceReload();
                    notifications.showNotification(response.message, "success");
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => this.errors.push(getTranslation("Action failed. The data could not be saved. Please try again.")))
            .finally(() => this.setPermissionInProgress(false));
    };

    /**
     * Set requirement for reloading the list frame after detail popup was closed
     */
    public setReloadRequired = () => {
        this.reloadRequired(true);
    };
}

export const showProjectDetails = dialogStarter(ProjectDetailsViewModel, template, {
    name: "ProjectDetails",
    width: 590,
    anchor: {top: 20, right: 20},
    cssARequire: [":popup.css", ":table.css"],
    closeOthers: true,
});
