/**
 * Show a pop-up to set lab ID for given subject (id).
 *
 * @param pupId: Database ID of the pup for which to set lab ID.
 +
 * @param animalId: Database ID of the animal for which to set lab ID.
 *
 * @param eventTarget: HTMLElement anchor for dialog (position of pop-up).
 *
 * @param reloadCallback: Function to call when data has been applied and pop-up is closed
 *                        (e.g. to reload a list or detail page to display new data).
 *
 * @param closeCallback: Function to call whenever the pop-up is closed, whether data was applied or not
 *                       (e.g. to unhighlight a row in listview table).
 */

import * as ko from "knockout";
import {Observable} from "knockout";
import {PureComputed} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setLabID.html";
import {CheckExtended} from "../knockout/extensions/invalid";

interface Params {
    pupId?: number;
    animalId?: number;
    eventTarget?: HTMLElement;
    closeCallback?: () => void;
    reloadCallback?: () => void;
}

interface Seed {
    labid: string;
    eartag_or_id: string | number;
}

class SetLabIDViewModel {
    public readonly allowEdit: boolean;
    public readonly canApply: PureComputed<boolean>;
    public readonly seed: FetchExtended<Observable<AjaxResponse<Seed | undefined>>>;

    private readonly pupId: number;
    private readonly animalId: number;
    private readonly closeCallback: () => void;
    private readonly reloadCallback: () => void;
    private readonly dialog: KnockoutPopup;
    private readonly reloadRequired: Observable<boolean>;
    private readonly applyInProgress: Observable<boolean>;
    private readonly labId: CheckExtended<Observable<string>>;


    constructor(params: Params, dialog: KnockoutPopup) {
        this.dialog = dialog;

        this.pupId = params.pupId;
        this.animalId = params.animalId;
        this.closeCallback = params.closeCallback;
        this.reloadCallback = params.reloadCallback;

        this.allowEdit = session.userPermissions.animal_set_labid
            && Boolean(this.pupId || this.animalId);

        this.reloadRequired = ko.observable(false);
        this.applyInProgress = ko.observable(false);

        this.labId = ko.observable().extend({
            invalid: (value) => {
                return !!value && !value.match(new RegExp("^[^,]{0,80}$"))
                    ? getTranslation("The lab id may consist of up to 80 characters and not contain a comma.")
                    : false;
            },
        });

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                if (this.pupId) {
                    return fetch(getUrl("set_lab_id.py", {pup_id: this.pupId}), {signal});
                } else if (this.animalId) {
                    return fetch(getUrl("set_lab_id.py", {animal_id: this.animalId}), {signal});
                }
            },
        });

        this.seed.subscribe((seed) => {
            if (seed?.success) {
                this.labId(seed.labid);
                this.dialog.setTitle(getTranslation("Set lab ID for %s").replace("%s", String(seed.eartag_or_id)));
            }
        });

        this.canApply = ko.pureComputed(() => {
            return !this.applyInProgress()
                && !this.seed.fetchInProgress()
                && this.labId.isValid()
                && this.labId() !== this.seed()?.labid;
        });

        /**
         * Add a new callback, called after the popup was closed.
         */
        this.dialog.addOnClose(() => {
            if (this.closeCallback) {
                this.closeCallback();
            }

            if (this.reloadRequired() && typeof this.reloadCallback === "function") {
                this.reloadCallback();
            }
        });
    }

    public submit = () => {
        this.applyInProgress(true);
        this.reloadRequired(true);

        let formData;
        if (this.pupId) {
            formData = getFormData({pup_id: this.pupId, lab_id: this.labId()});
        } else if (this.animalId){
            formData = getFormData({animal_id: this.animalId, lab_id: this.labId()});
        }

        fetch("set_lab_id.py", {method: "POST", body: formData})
            .then(response => response.json()).then((response: AjaxResponse<any>) => {
                this.applyInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.applyInProgress(false);
                notifications.showNotification(
                    getTranslation("Action failed. The data could not be saved. Please try again."), "error"
                );
            });
    };
}

export const showSetLabID = dialogStarter(SetLabIDViewModel, template, params => ({
    name: "SetLabID",
    width: 300,
    anchor: params.eventTarget,
    escalate: false,
    closeOthers: true,
    cssARequire: [":table.css"],
    title: getTranslation("Set lab ID"),
}));
