import * as ko from "knockout";

import {dialogStarter} from "../knockout/dialogStarter";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";


interface Seed {
    label: string;
}

class SetStrawLabelModel {

    private readonly dialog: KnockoutPopup;
    private readonly cryotankContentId: number;
    private readonly reloadCallback: () => void;

    public readonly strawLabel: ko.Observable<string>;
    public readonly fetchInProgress: ko.Observable<boolean>;
    public readonly updateInProgress: ko.Observable<boolean>;
    public readonly seed: ko.Observable<Seed>;

    constructor(params: { cryotankContentId: number; eventTarget?: HTMLElement; reloadCallback: () => void }, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.reloadCallback = params.reloadCallback;
        this.cryotankContentId = params.cryotankContentId;
        this.strawLabel = ko.observable("");
        this.fetchInProgress = ko.observable(true);
        this.updateInProgress = ko.observable(false);

        const form = getFormData({
            get_straw_label: JSON.stringify(this.cryotankContentId),
        });
        fetch("cryotank.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<Seed>) => {
                if (response.success) {
                    this.strawLabel(response.label);
                }
            })
            .finally(() => this.fetchInProgress(false));
    }

    public applyLabel = () => {
        this.updateInProgress(true);
        const form = getFormData({
            set_straw_label: JSON.stringify({
                cryotank_content_id: this.cryotankContentId,
                straw_label: this.strawLabel(),
            }),
        });
        fetch("cryotank.py", {method: "POST", body: form})
            .then(response => response.json())
            .then((response: AjaxResponse<Seed>) => {
                if (response.success === true) {
                    this.dialog.close();
                    if (this.reloadCallback) {
                        this.reloadCallback();
                    }
                } else {
                    notifications.showModal(response.message);
                }
            })
            .finally(() => this.updateInProgress(false));
    };

}

export const showSetStrawLabel = dialogStarter(SetStrawLabelModel, `
    <div class="set_straw_label_popup">
        <form class="flexible_container" style="margin: 10px;">
            <input maxlength="32"
                   class="flex_inside_main_box"
                   data-bind="textInput: strawLabel,
                              attr: {placeholder: getTranslation('Label')},
                              disable: fetchInProgress() || updateInProgress(),
                              hasFocus: !(fetchInProgress() || updateInProgress())">

            <input type="submit"
                   class="confirm"
                   data-bind="value: getTranslation('Apply'),
                              disable: fetchInProgress() || updateInProgress(),
                              click: applyLabel">
        </form>
    </div>
`, params => ({
    name: "SetStrawLabel",
    width: 300,
    title: getTranslation("Set straw label"),
    cssARequire: [":popup.css"],
    anchor: params.eventTarget,
    closeOthers: true,
}));
