/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LicenseForSetting } from '../models/LicenseForSetting';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicensesService {

    /**
     * Get Licenses For Setting
     * Get the list of licenses for setting a license.
     * @returns LicenseForSetting Successful Response
     * @throws ApiError
     */
    public static getLicensesForSetting({
        speciesId,
        strainId,
    }: {
        speciesId: number,
        strainId?: Array<number>,
    }): CancelablePromise<Array<LicenseForSetting>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/licenses/for_setting',
            query: {
                'species_id': speciesId,
                'strain_id': strainId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
