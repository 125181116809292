import * as ko from "knockout";
import {Observable} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {KnockoutPopup} from "../lib/popups";
import {notifications} from "../lib/pyratTop";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import template from "./setBirthComment.html";

interface Params {
    birthId: number;
    eventTarget?: HTMLElement;
    reloadCallback?: () => void;
}

interface Seed {
    comment: string;
}

class SetBirthCommentViewModel {

    private readonly dialog: KnockoutPopup;

    //params
    public birthId: number;
    public reloadCallback: () => void;

    // state
    public comment: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<Seed>>>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.birthId = params.birthId;
        this.reloadCallback = params.reloadCallback;

        this.comment = ko.observable("");
        this.submitInProgress = ko.observable(false);

        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch("set_birth_comment.py", {
                    method: "POST",
                    body: getFormData({
                        request: JSON.stringify({
                            action: "get_dialog_options",
                            birth_id: this.birthId,
                        }),
                    }),
                    signal,
                });
            },
        });

         this.seed.subscribe((response) => {
            if (response && response.success) {
                this.comment(response.comment);
            }
        });
    }

    public submit = () => {
        this.submitInProgress(true);

        return fetch("set_birth_comment.py", {
            method: "POST",
            body: getFormData({
                request: JSON.stringify({
                    action: "update",
                    birth_id: this.birthId,
                    comment: this.comment() || undefined,
                }),
            }),
        })
            .then(response => response.json())
            .then((response: AjaxResponse<any>) => {
                this.submitInProgress(false);
                if (response.success) {
                    this.dialog.close();
                    notifications.showNotification(response.message, "success");

                    if (typeof this.reloadCallback === "function") {
                        this.reloadCallback();
                    }
                } else {
                    notifications.showNotification(response.message, "error");
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.dialog.close();
            });
    };

}

export const showSetBirthComment = dialogStarter(SetBirthCommentViewModel, template, params => ({
    name: "SetBirthComment",
    width: 300,
    handle: "right top",
    anchor: params.eventTarget,
    escalate: false,
    cssARequire: [":popup.css"],
    title: params.eventTarget.title,
}));
