import * as ko from "knockout";
import { Observable, PureComputed } from "knockout";
import { filter, first, last, map, mergeWith, min } from "lodash";
import { getMomentJsDateFormat } from "../../lib/flatpickr";
import { session } from "../../lib/pyratSession";
import { StatusRecord } from "./report";
import { ChartConfiguration, ChartDataset, Scale } from "chart.js";
import { formatTimestampDate, getReadableByteSizeObject } from "../../lib/utils";
import { getTranslation } from "../../lib/localize";
import { SliderOptions } from "../../knockout/components/slider";
import { PipsMode } from "nouislider";
import { colorSchemes } from "../../lib/charts";


class PyratStatusTrend {

    public sliderOptions: SliderOptions;
    public minDate = 0;  // isoDatetime
    public maxDate = 0;  // isoDatetime
    public startDate: Observable<number>;  // isoDatetime
    public endDate: Observable<number>;  // isoDatetime
    public allSubjectsChartConfiguration: PureComputed<ChartConfiguration>;
    public activeSubjectsChartConfiguration: PureComputed<ChartConfiguration>;
    public documentsChartConfiguration: PureComputed<ChartConfiguration>;
    public logsSpaceChartConfiguration: PureComputed<ChartConfiguration>;
    public systemSpaceChartConfiguration: PureComputed<ChartConfiguration>;
    public uploadsSpaceChartConfiguration: PureComputed<ChartConfiguration>;
    public databaseSpaceChartConfiguration: PureComputed<ChartConfiguration>;
    public memoryChartConfiguration: PureComputed<ChartConfiguration>;
    public visibleDays: PureComputed<number>;
    private startPeriod = 1000 * 60 * 60 * 24 * 120;  // milliseconds
    private dateFormatter = {
        to: (value: number): string => formatTimestampDate(value),
        from: (value: string): number => Date.parse(value),
    };

    constructor(statusRecords: StatusRecord[]) {

        if (statusRecords.length) {
            this.minDate = this.dateFormatter.from(last(statusRecords)["finish_date_time"]);
            this.maxDate = this.dateFormatter.from(first(statusRecords)["finish_date_time"]);
        }

        this.startDate = ko.observable(this.minDate < this.maxDate - this.startPeriod ? this.maxDate - this.startPeriod : this.minDate);
        this.endDate = ko.observable(this.maxDate);

        this.visibleDays = ko.pureComputed(() => Math.floor((this.endDate() - this.startDate()) / 1000 / (60 * 60 * 24)));

        this.sliderOptions = {
            start: [this.startDate(), this.endDate()],
            connect: true,
            tooltips: [this.dateFormatter, this.dateFormatter],
            margin: 1000 * 60 * 60 * 24 * 7,  // one week in milliseconds
            pips: {
                mode: PipsMode.Steps,
                density: 3,
                format: this.dateFormatter,
            },
            range: {
                "min": [this.minDate],
                "max": [this.maxDate],
            },
        };

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.allSubjectsChartConfiguration = ko.pureComputed(() => {

            const datasets: ChartDataset[] = [];

            if (session.pyratConf.TERRESTRIAL) {
                datasets.push({
                    label: getTranslation("Animals"),
                    data: this.pluck(statusRecords, "number_of_animals"),
                    pointRadius: 0,
                    fill: true,
                });
                datasets.push({
                    label: getTranslation("Pups"),
                    data: this.pluck(statusRecords, "number_of_pups"),
                    pointRadius: 0,
                    fill: true,
                });
                datasets.push({
                    label: getTranslation("Cages"),
                    data: this.pluck(statusRecords, "number_of_cages"),
                    pointRadius: 0,
                    fill: true,
                });
            }

            if (session.pyratConf.AQUATIC) {
                datasets.push({
                    label: getTranslation("Tanks"),
                    data: this.pluck(statusRecords, "number_of_tanks"),
                    pointRadius: 0,
                    fill: true,
                });
            }

            datasets.push({
                label: getTranslation("Lines / Strains"),
                data: this.pluck(statusRecords, "number_of_strains"),
                pointRadius: 0,
                fill: true,
            });
            datasets.push({
                label: getTranslation("Licenses"),
                data: this.pluck(statusRecords, "number_of_licenses"),
                pointRadius: 0,
                fill: true,
                hidden: true,
            });

            if (session.pyratConf.TERRESTRIAL) {
                datasets.push({
                    label: getTranslation("Severity assessment items"),
                    data: this.pluck(statusRecords, "number_of_severity_assessment_items"),
                    pointRadius: 0,
                    fill: true,
                    hidden: true,
                });
            }

            for (const dataset of datasets) {
                dataset["borderColor"] = colorSchemes.mixed[datasets.indexOf(dataset)];
                dataset["backgroundColor"] = `${colorSchemes.mixed[datasets.indexOf(dataset)]}DD`;
            }

            return {

                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets,
                },
                options: {
                    scales: {
                        y0: {
                            stacked: true,
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },

                        },
                        y1: {
                            display: false,
                            position: "right",
                            grid: {
                                display: false,
                            },

                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },

                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },

                },
            };

        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.activeSubjectsChartConfiguration = ko.pureComputed(() => {

            const datasets: ChartDataset[] = [];

            if (session.pyratConf.TERRESTRIAL) {
                datasets.push({
                    label: getTranslation("Live animals"),
                    data: this.pluck(statusRecords, "number_of_live_animals"),
                    pointRadius: 0,
                    fill: true,
                });
                datasets.push({
                    label: getTranslation("Live pups"),
                    data: this.pluck(statusRecords, "number_of_live_pups"),
                    pointRadius: 0,
                    fill: true,
                });
                datasets.push({
                    label: getTranslation("Open cages"),
                    data: this.pluck(statusRecords, "number_of_open_cages"),
                    pointRadius: 0,
                    fill: true,
                });
            }

            if (session.pyratConf.AQUATIC) {
                datasets.push({
                    label: getTranslation("Open tanks"),
                    data: this.pluck(statusRecords, "number_of_open_tanks"),
                    pointRadius: 0,
                    fill: true,
                });
            }

            datasets.push({
                label: getTranslation("License classifications"),
                data: this.pluck(statusRecords, "number_of_license_classifications"),
                pointRadius: 0,
                fill: true,
                hidden: true,
            });

            for (const dataset of datasets) {
                dataset["borderColor"] = colorSchemes.mixed[datasets.indexOf(dataset)];
                dataset["backgroundColor"] = `${colorSchemes.mixed[datasets.indexOf(dataset)]}DD`;
            }

            return {
                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets,
                },
                options: {
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                    scales: {
                        y0: {
                            stacked: true,
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        y1: {
                            position: "right",
                            grid: {
                                display: false,
                            },

                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },
                    },
                },
            };
        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.documentsChartConfiguration = ko.pureComputed(() => {

            return {
                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            yAxisID: "size",
                            label: getTranslation("Used space"),
                            data: this.pluck(statusRecords, "size_of_uploads"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,
                        },
                        {
                            yAxisID: "number",
                            label: getTranslation("Documents"),
                            data: this.pluck(statusRecords, "number_of_uploads"),
                            pointRadius: 0,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                            backgroundColor: `${colorSchemes.mixed[1]}DD`,
                        },
                        {
                            yAxisID: "size",
                            label: getTranslation("Available space"),
                            data: mergeWith(
                                this.pluck(statusRecords, "max_size_of_uploads"),
                                this.pluck(statusRecords, "uploads_disk_space_total"),
                                (objValue, srcValue) => min([objValue, srcValue]),
                            ),
                            fill: false,
                            pointRadius: 0,
                            borderColor: "black",
                        },
                    ],
                },
                options: {
                    scales: {
                        size: {
                            ticks: {
                                stepSize: 1024 * 1024 * 1024,  // 1 GB
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },
                            },
                            title: {
                                display: true,
                                text: getTranslation("Used space"),
                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        number: {
                            position: "right",
                            ticks: {
                                autoSkipPadding: 20,
                            },
                            title: {
                                display: true,
                                text: getTranslation("Number of documents"),
                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },
                    },
                    plugins: {
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                        legend: {
                            display: true,
                            align: "start",
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };
        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.systemSpaceChartConfiguration = ko.pureComputed(() => {

            return {

                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            label: getTranslation("Used for system"),
                            data: this.pluck(statusRecords, "system_disk_space_used"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,
                        },
                        {
                            label: getTranslation("Free for system"),
                            data: this.pluck(statusRecords, "system_disk_space_free"),
                            pointRadius: 0,
                            borderWidth: 3,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                max: last(statusRecords)?.system_disk_space_total,
                                stepSize: 1024 * 1024 * 1024 * 5,  // 5 GB
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },

                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };

        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.logsSpaceChartConfiguration = ko.pureComputed(() => {

            return {

                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            label: getTranslation("Used for logs"),
                            data: this.pluck(statusRecords, "logs_disk_space_used"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,

                        },
                        {
                            label: getTranslation("Free for logs"),
                            data: this.pluck(statusRecords, "logs_disk_space_free"),
                            pointRadius: 0,
                            borderWidth: 3,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                max: last(statusRecords)?.logs_disk_space_total,
                                stepSize: 1024 * 1024 * 1024 * 5,  // 5 GB
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },

                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },

                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },

                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };
        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.uploadsSpaceChartConfiguration = ko.pureComputed(() => {

            return {

                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            label: getTranslation("Used for uploads"),
                            data: this.pluck(statusRecords, "uploads_disk_space_used"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,
                        },
                        {
                            label: getTranslation("Free for uploads"),
                            data: this.pluck(statusRecords, "uploads_disk_space_free"),
                            pointRadius: 0,
                            borderWidth: 3,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                max: last(statusRecords)?.uploads_disk_space_total,
                                stepSize: 1024 * 1024 * 1024 * 5,  // 5 GB
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },

                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },

                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },


                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };
        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.databaseSpaceChartConfiguration = ko.pureComputed(() => {

            return {
                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            label: getTranslation("Used for database"),
                            data: this.pluck(statusRecords, "mysql_disk_space_used"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,

                        },
                        {
                            label: getTranslation("Free for database"),
                            data: this.pluck(statusRecords, "mysql_disk_space_free"),
                            pointRadius: 0,
                            borderWidth: 3,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                        },
                    ],
                },
                options: {
                    scales: {
                        y: {
                            ticks: {
                                max: last(statusRecords)?.mysql_disk_space_total,
                                stepSize: 1024 * 1024 * 1024 * 5,  // 5 GB
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },

                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },

                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },

                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };
        });

        // @ts-expect-error: Type 'string' is not assignable to type 'keyof ChartTypeRegistry'.
        this.memoryChartConfiguration = ko.pureComputed(() => {

            return {

                type: "line",
                data: {
                    labels: this.pluck(statusRecords, "finish_date_time"),
                    datasets: [
                        {
                            yAxisID: "used",
                            label: getTranslation("Used memory"),
                            data: this.pluck(statusRecords, "memory_used"),
                            pointRadius: 0,
                            fill: true,
                            borderColor: colorSchemes.mixed[0],
                            backgroundColor: `${colorSchemes.mixed[0]}DD`,
                        },
                        {
                            yAxisID: "free",
                            label: getTranslation("Free memory"),
                            data: this.pluck(statusRecords, "memory_free"),
                            pointRadius: 0,
                            borderWidth: 3,
                            fill: false,
                            borderColor: colorSchemes.mixed[1],
                        },
                    ],
                },
                options: {
                    scales: {
                        used: {
                            scaleLabel: {
                                display: true,
                                labelString: getTranslation("Used memory"),
                            },
                            stacked: true,
                            ticks: {
                                min: 0,
                                max: last(statusRecords)?.memory_total,
                                stepSize: 1024 * 1024 * 1024 / 2,
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },
                            },
                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        free: {
                            scaleLabel: {
                                display: true,
                                labelString: getTranslation("Free memory"),
                            },
                            ticks: {
                                min: 0,
                                max: last(statusRecords)?.memory_total,
                                stepSize: 1024 * 1024 * 1024 / 2,
                                autoSkipPadding: 20,
                                callback: (value: number) => {
                                    return getReadableByteSizeObject(value).string;
                                },
                            },
                            position: "right",
                            grid: {
                                display: false,
                            },

                            afterSetDimensions: (axes: Scale) => {
                                axes.maxWidth = 0;
                            },
                        },
                        x: {
                            type: "time",
                            time: {
                                minUnit: "week",
                                tooltipFormat: getMomentJsDateFormat(),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: true,
                            align: "start",
                        },
                        tooltip: {
                            mode: "index",
                            position: "nearest",
                            intersect: false,
                        },
                    },
                    layout: {
                        padding: {
                            left: 100,
                            right: 100,
                        },
                    },
                },
            };
        });
    }

    public onSetDateRange = (values: number[]) => {
        this.startDate(values[0]);
        this.endDate(values[1]);
    };

    private pluck(statusRecords: StatusRecord[], field: string) {
        return map(filter(statusRecords,
                (item: StatusRecord) =>
                    this.dateFormatter.from(item["finish_date_time"]) >= this.startDate() &&
                    this.dateFormatter.from(item["finish_date_time"]) <= this.endDate()),
            field);
    }
}


export const initPyratStatusTrend = (statusRecords?: StatusRecord[]): void => {
    ko.applyBindings(new PyratStatusTrend(statusRecords));
};
