/**
 * Create own error type for assert.
 */
export class AssertionError extends Error {
    constructor(...args: any[]) {
        super(...args);

        // Maintains proper stack trace for where our error was thrown
        Error.captureStackTrace?.(this, AssertionError);

        // define custom values
        this.name = "AssertionError";
    }
}

/**
 * Check assertion, throw Error if false.
 *
 * @param condition: Should be somthing that can be true or false.
 * @param message: Used as error message if condition is false.
 *
 * Example:
 *      assert(typeof animalId === 'number', 'animalId is not a number');
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function assert(condition: any, message: string): asserts condition {
    if (!condition) {
        throw new AssertionError(message);
    }
}
