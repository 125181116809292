import {Computed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";
import {getTranslation} from "../lib/localize";
import {notifications} from "../lib/pyratTop";
import {getUrl} from "../lib/utils";
import {getFormData} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";

interface ImporterOption {
    name: string;
    label: string;
    mime: string;
}

interface ImportRow {
    catalog_id: number;
    catalog_name: string;
    product_type: string;
    reference_number: string;
    species_id: number;
    species_name: string;
    strain_id: number;
    strain_name_with_id: string;
    description: string;
    sex: string;
    catalog_age_weight: string;
    import_age: number;
    import_weight: number;
    billing_period_id: number;
    billing_period_name: string;
    prices: {
        price_category_id: number;
        price_category_name: string;
        price: string;
    }[];
    errors: string[];
}

interface Arguments {
    importers: {
        order: number;
        visible: boolean;
        name: string;
        label: string;
        mime: string;
        ending: string;
    }[];
    general_error?: string;
    importer_errors?: string[];
    import_results?: ImportRow[];
}

interface SuccessResult {
    catalog_item_ids: number[];
    price_ids: number[];
}


class OrderRequestCatalogImport {

    public seed: Arguments;

    public generalError: string;
    public importerErrors: string[];

    public importers: ImporterOption[];
    public importerName: Observable<string>;
    public importerMimeType: Computed<string>;
    public importResults: ObservableArray<ImportRow>;

    public submitError: Observable<string>;
    public submitInProgress: Observable<boolean>;
    public canSubmit: Computed<boolean>;
    public successResult: Observable<SuccessResult>;

    constructor(args: Arguments) {

        this.seed = args;

        this.generalError = this.seed.general_error;
        this.importerErrors = this.seed.importer_errors;

        this.importers = _.map(args.importers, (importer) => {
            return {
                name: importer.name,
                label: _.template("<%= label %> (.<%= ending %>)")(importer),
                mime: importer.mime,
            };
        });

        this.importerName = ko.observable();
        this.importerMimeType = ko.computed(() => {
            return (_.find(this.importers, {name: this.importerName()}) || {}).mime;
        });

        this.importResults = ko.observableArray(args.import_results);

        this.submitError = ko.observable();
        this.submitInProgress = ko.observable(false);

        this.canSubmit = ko.computed(() => {
            if (_.size(this.importerErrors)) {
                return false;
            }
            // loop through all rows and check if there are validation errors
            return !_.some(this.importResults(), (row) => {return _.size(row.errors);});
        });

        this.successResult = ko.observable();
    }

    public sendCsvExample = () => {
        location.href = getUrl("order_request_catalog_import.py", {get_csv_example: 1});
    };

    public submitImport = () => {
        this.submitInProgress(true);
        this.submitError("");

        fetch("order_request_catalog_import.py", {
            method: "POST",
            body: getFormData({
                import_data: JSON.stringify(this.seed.import_results),
            }),
        })
            .then((response) => response.json())
            .then((response: AjaxResponse<any>) => {
                if (response.success) {
                    this.importResults([]);
                    notifications.showNotification(getTranslation("Changes saved"), "success");

                    // show number of imported catalog items and prices
                    this.successResult({
                        catalog_item_ids: response.catalog_item_ids,
                        price_ids: response.price_ids,
                    });
                } else {
                    // validation errors
                    this.submitInProgress(false);
                    this.submitError(response.message);
                }
            })
            .catch(() => {
                this.submitInProgress(false);
                this.submitError(getTranslation("Action failed. The data could not be saved. Please try again."));
            });
    };
}


export const initOrderRequestCatalogImport = (args: Arguments): void => {
    ko.applyBindings(new OrderRequestCatalogImport(args));
};
