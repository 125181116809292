/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LicenseForSetting = {
    budget_name: string;
    group: LicenseForSetting.group;
    id: number;
    license_type_id: number;
    name: string;
    valid_from?: string;
};

export namespace LicenseForSetting {

    export enum group {
        AVAILABLE = 'available',
        EXCEEDED = 'exceeded',
        EXPIRED = 'expired',
    }


}

