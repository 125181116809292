import * as ko from "knockout";
import {Computed, Observable, ObservableArray} from "knockout";
import * as _ from "lodash";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {getUrl} from "../lib/utils";
import {formatDate} from "../lib/flatpickr";
import {parseDate} from "../lib/flatpickr";
import template from "./tankAnimalImport.html";
import {LocationItem} from "../knockout/components/locationPicker/locationPicker";
import {PreselectLocationItem} from "../knockout/components/locationPicker/locationPicker";
import {TankPosition} from "../knockout/components/locationPicker/tankPicker";
import {showTankPicker} from "../knockout/components/locationPicker/tankPickerDialog";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {getFormData} from "../lib/utils";
import {addDays, isInvalidCalendarDate, normalizeDate} from "../lib/utils";
import {frames} from "../lib/pyratTop";
import {notifications} from "../lib/pyratTop";
import "./tankAnimalImport.scss";


interface TankAnimalImportParams {
    crossingId?: number;
    strainId?: number;
}

interface TankAnimalImportSeed {
    genetic_backgrounds: {
        id: number;
        name: string;
    }[];
    origins: {
        id: number;
        name: string;
    }[];
    owners: {
        userid: number;
        fullname: string;
    }[];
    parents: {
        strain_name_with_id: number;
        owner_id: number;
        responsible_id: number;
        projects: {
            id: number;
            category: string;
            name: string;
            project_label: string;
            status: string;
        }[];
        origin_id: number;
        species_id: number;
        genetic_background_id: number;
        generation: number;
        classification_id: number;
        licence_id: number;
        tank_rack_id: number;
        tank_position: number;
        location_rack_name: number;
    }[];
    strain_id: number;
    strains: {
        id: number;
        name: string;
        name_id: number;
        name_with_id: string;
        species_id: number;
        owner_user_id: string;
        owner_username: string;
    }[];
    species: {
        id: number;
        name: string;
        selected: boolean;
    }[];
    common_parent_location: PreselectLocationItem;
    responsible_id: number;
    current_alias_id: number;
    license_id: number;
    classification_id: number;
}

interface Tank {
    occupied: Computed<boolean>;
    tankComment: Observable<string>;
    tankPosition: Observable<string>;
    tankType: Observable<string>;
    ageLevel: Observable<string>;
    males: CheckExtended<Observable<string>>;
    females: CheckExtended<Observable<string>>;
    unknowns: CheckExtended<Observable<string>>;
    aliveCount?: CheckExtended<Computed<number>>;
}

interface SubmitData {
    generation: string;
    species_id: number;
    strain_id?: number;
    strain_name?: string;
    owner_id: number;
    responsible_id: number;
    date_of_birth: string;
    crossing_id: number;
    origin_id: number;
    date_of_release: string;
    confirmed_license_overuse: boolean;
    rack_id: number;
    project_id: number[];
    genetic_background_id: number;
    tanks: unknown[];
    license_classification_id: number;
    license_assign_date: string;
}

class TankAnimalImportViewModel {

    private readonly dialog: KnockoutPopup;

    public errors: ObservableArray<string>;
    public submitInProgress: Observable<boolean>;

    public crossingId: Observable<number>;
    public ageLevel: Observable<string>;
    public species: CheckExtended<Observable<{ id: number }>>;
    public strainId: CheckExtended<Observable<number>>;
    public strainType: Observable<string>;
    public customStrain: Observable<string>;
    public projectIds: Observable<number[]>;
    public owner: CheckExtended<Observable<{ userid: number }>>;
    public responsible: CheckExtended<Observable<{ userid: number }>>;
    public origin: CheckExtended<Observable<{ id: number; name: string }>>;
    public licenseId: CheckExtended<Observable<number>>;
    public selectedClassifications: ObservableArray<number>;
    public classificationId: CheckExtended<Observable<number>>;
    public licenseAssignDate: CheckExtended<Observable<string>>;
    public overuseLicense: Observable<boolean>;
    public geneticBackground: Observable<{ id: number; name: string }>;
    public generation: Observable<string>;
    public location: Observable<LocationItem>;
    public preselectLocation: PreselectLocationItem;
    public dateOfBirth: CheckExtended<Observable<string>>;
    public dateOfRelease: CheckExtended<Observable<string>>;

    public seed: FetchExtended<Observable<TankAnimalImportSeed>>;
    public availableResponsibles: FetchExtended<Observable<{ userid: number }>>;
    public availableLicenses: FetchExtended<ObservableArray<{
        id: number;
        name: string;
        valid_from: string;
        license_type_id: number;
        budget_name: string;
    }>>;
    public availableClassifications: FetchExtended<ObservableArray<{
        id: number;
        licence_id: number;
        name: string;
        severity_level_id: number;
        animal_number: number;
        used: number;
    }>>;
    public occupiedPositions: FetchExtended<Observable<string[]>>;

    public tanks: ObservableArray<Tank>;

    public addTank = () => {

        const position = ko.observable("").extend({rateLimit: {timeout: 500, method: "notifyWhenChangesStop"}});
        const lastTank = _.last(this.tanks());
        const newTank: Tank = {
            occupied: ko.pureComputed(() => {
                return _.includes(this.occupiedPositions(), position());
            }),
            tankComment: ko.observable(undefined),
            tankPosition: position,
            tankType: ko.observable(lastTank ? lastTank.tankType() : "stock"),
            ageLevel: ko.observable(lastTank ? lastTank.ageLevel() : "larva"),
            males: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
            females: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
            unknowns: ko.observable().extend({
                invalid: (v) => {
                    return !(((_.isNumber(v) && String(v).match(/^\d+$/)) || _.isUndefined(v)) && (v || 0) >= 0);
                },
            }),
        };

        newTank.aliveCount = ko.pureComputed(function () {
            return ((parseInt(newTank.males(), 10) || 0) +
                (parseInt(newTank.females(), 10) || 0) +
                (parseInt(newTank.unknowns(), 10) || 0));
        }).extend({
            invalid: v => !(v > 0),
        });

        this.tanks.push(newTank);
    };

    public removeTank = (item: Tank) => {
        this.tanks(_.without(this.tanks(), item));
    };

    public showPositionPicker = (tank: Tank, event: MouseEvent) => {
        showTankPicker({
            location: this.location(),
            clickEvent: event,
            onApply: (position: TankPosition) => {
                tank.tankPosition(position.tank_position);
            },
        });
    };

    public serialize = () => {

        const data: SubmitData = {
            crossing_id: this.crossingId(),
            species_id: (this.species() || {}).id,
            project_id: session.pyratConf.MULTIPLE_PROJECTS ? (this.projectIds() || []) : this.projectIds(),
            owner_id: (this.owner() || {}).userid,
            responsible_id: (this.responsible() || {}).userid,
            origin_id: (this.origin() || {}).id,
            license_classification_id: this.classificationId(),
            license_assign_date: this.licenseAssignDate(),
            confirmed_license_overuse: this.overuseLicense() || false,
            genetic_background_id: (this.geneticBackground() || {}).id,
            generation: this.generation(),
            rack_id: (this.location() || {}).db_id,
            date_of_birth: this.dateOfBirth(),
            date_of_release: this.dateOfRelease(),
            tanks: _.map(this.tanks(), function (tank) {
                return {
                    comment: tank.tankComment(),
                    tank_position: tank.tankPosition(),
                    tank_type: tank.tankType(),
                    age_level: tank.ageLevel(),
                    number_of_male: (tank.males() || 0),
                    number_of_female: (tank.females() || 0),
                    number_of_unknown: (tank.unknowns() || 0),
                };
            }),
        };

        if (this.strainType() === "known") {
            data["strain_id"] = this.strainId();
        } else if (this.strainType() === "new") {
            data["strain_name"] = this.customStrain();
        }

        return data;

    };

    public submit = () => {
        this.errors.removeAll();
        this.submitInProgress(true);
        const form = getFormData({data: JSON.stringify({import: this.serialize()})});
        fetch("tank_animal_import.py", {method: "POST", body: form})
            .then((response) => response.json())
            .then((response) => {
                if (response.success) {
                    frames.reloadListIframe();
                    notifications.showNotification(
                        getTranslation("New tank(s) successfully added"),
                        "success",
                        {
                            buttons: [
                                notifications.button(getTranslation("Show"), "", (n: Noty) => {
                                    window.top.mainMenu.filterSubtab("tanks", {tank_id: JSON.stringify(response.imported)});
                                    n.close();
                                }),
                            ],
                        }
                    );

                    this.dialog.close();
                } else {
                    this.errors.push(response.message);
                }
            })
            .catch(() => {
                this.errors.push(getTranslation("Import error."));
            })
            .finally(() => this.submitInProgress(false));
    };

    // summary

    public malesTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.males(), 10) || 0);
        }, 0);
    };

    public femalesTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.females(), 10) || 0);
        }, 0);
    };

    public unknownsTotal = () => {
        return _.reduce(this.tanks(), function (m, r) {
            return m + (parseInt(r.unknowns(), 10) || 0);
        }, 0);
    };


    // validate

    public valid = () => {

        if (this.licenseId.isInvalid()) {
            return false;
        }

        if (this.classificationId.isInvalid()) {
            return false;
        }

        if (this.licenseAssignDate.isInvalid()) {
            return false;
        }

        if (this.dateOfRelease.isInvalid()) return false;

        if (this.dateOfBirth.isInvalid()) return false;

        if (this.species.isInvalid()) return false;

        if (this.origin.isInvalid()) return false;

        if (this.owner.isInvalid()) return false;

        if (this.strainId.isInvalid()) return false;

        if (this.responsible.isInvalid()) return false;

        if (!_.every(_.map(this.tanks(), function (tank) {
            return tank.males.isValid()
                && tank.females.isValid()
                && tank.unknowns.isValid()
                && tank.aliveCount.isValid();
        }))) return false;

        // noinspection RedundantIfStatementJS
        if (!this.location()) return false;

        return true;
    };


    constructor(params: TankAnimalImportParams = {}, dialog: KnockoutPopup) {

        this.dialog = dialog;
        this.errors = ko.observableArray([]);
        this.submitInProgress = ko.observable(false);

        this.tanks = ko.observableArray([]);
        this.crossingId = ko.isObservable(params.crossingId) ? params.crossingId : ko.observable(params.crossingId);

        this.ageLevel = ko.observable(undefined);
        this.species = ko.observable(undefined);
        this.projectIds = ko.observable(undefined);
        this.owner = ko.observable(undefined);
        this.responsible = ko.observable(undefined);
        this.origin = ko.observable(undefined);
        this.geneticBackground = ko.observable(undefined);
        this.generation = ko.observable(undefined);
        this.location = ko.observable(undefined);
        this.preselectLocation = undefined;
        this.dateOfBirth = ko.observable(undefined);
        this.dateOfRelease = ko.observable(undefined);

        this.licenseId = ko.observable(undefined).extend({
            invalid: (v) => {
                if (session.pyratConf.AQUATIC_MANDATORY_LICENSE) {
                    return !v;
                } else {
                    return false;
                }
            },
        });

        this.selectedClassifications = ko.observableArray([]);
        this.classificationId = ko.observable().extend({
            invalid: (v) => {
                return Boolean(!v && this.licenseId());
            },
        });
        this.licenseAssignDate = ko.observable().extend({
            normalize: normalizeDate,
            invalid: v => !_.isUndefined(v) && isInvalidCalendarDate(v),
        });
        this.overuseLicense = ko.observable(false);


        // special strain creation handling
        this.strainId = ko.observable(undefined);
        this.strainType = ko.observable("known");
        this.customStrain = ko.observable("").extend({
            invalid: v => !(v.length),
        });

        this.dateOfBirth.subscribe((v) => {
            const today = new Date();

            if (v && !isInvalidCalendarDate(v)) {
                const parsedDate = parseDate(v);

                if (parsedDate > today) {
                    notifications.showConfirm(
                        getTranslation("Date of birth in the future. Proceed anyway?"),
                        function () {
                            return true;
                        },
                        {
                            onCancel: () => {
                                this.dateOfBirth(undefined);
                                this.dateOfRelease(undefined);
                            },
                        }
                    );
                }

                this.dateOfRelease(formatDate(addDays(parsedDate, session.pyratConf.AQUATIC_DATE_OF_RELEASE_OFFSET)));
            }
        });

        this.strainId.extend({
            invalid: () => {

                if (session.pyratConf.AQUATIC_MANDATORY_STRAIN) {
                    if (this.strainType() === "known") {
                        return _.isUndefined(this.strainId());
                    } else if (this.strainType() === "new") {
                        return !_.size(this.customStrain());
                    }
                }

                return false;
            },
        });

        this.responsible.extend({
            invalid: (v: any) => !(session.pyratConf.AQUATIC_MANDATORY_RESPONSIBLE ? !_.isUndefined(v) : true),
        });
        this.dateOfBirth.extend({
            invalid: (v: any) => !(v && !isInvalidCalendarDate(v)),
        });
        this.dateOfRelease.extend({
            invalid: (v: any) => !(v && !isInvalidCalendarDate(v)),
        });
        this.species.extend({
            invalid: (v: any) => _.isUndefined(v),
        });
        this.origin.extend({
            invalid: (v: any) => !(session.pyratConf.IMPORT_ORIGIN_REQUIRED ? !_.isUndefined(v) : true),
        });
        this.owner.extend({
            invalid: (v: any) => _.isUndefined(v),
        });

        // get independent values
        this.seed = ko.observable().extend({
            fetch: {
                undefined: {},
                fn: (signal) => fetch("tank_animal_import.py", {
                    method: "POST",
                    body: getFormData({
                        data: JSON.stringify({
                            "crossing_id": this.crossingId(),
                            "request": [
                                "species",
                                "strains",
                                "projects",
                                "owners",
                                "origins",
                                "genetic_backgrounds",
                                "age_levels",
                                "tank_types",
                                "crossing",
                                "strain_id",
                                "current_alias",
                            ],
                        }),
                    }),
                    signal,
                }),
            },
        });


        // get dependent values

        this.availableResponsibles = ko.observable().extend({
            fetch: {
                undefined: [],
                disable: ko.computed(() => !this.owner()),
                fn: () => fetch(getUrl("ajax/user_service.py", {
                    "function": "get_responsibles_of_user",
                    userid: this.owner().userid,
                })),
            },
        });

        this.availableLicenses = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.species()),
                fn: () => fetch(getUrl("ajax_service.py", {
                    "function": "get_licenses_for_setting",
                    species_id: this.species().id,
                    strain_id: this.strainType() === "known" ? this.strainId() || 0 : -1,
                })),
            },
        });

        this.availableClassifications = ko.observableArray().extend({
            fetch: {
                undefined: [],
                disable: ko.pureComputed(() => !this.licenseId() || !this.species()),
                fn: () => fetch(getUrl("ajax_service.py", {
                    "function": "get_license_classifications_for_setting",
                    license_id: this.licenseId(),
                    species_id: this.species().id,
                    strain_id: this.strainType() === "known" ? this.strainId() || 0 : -1,
                })),
            },
        });

        // select default values
        this.seed.subscribe((seed) => {

            /* Owner */
            _.defer(() => {
                // use current selected alias as default owner
                if (seed.current_alias_id) {
                    this.owner(seed.owners.find(({ userid }) => userid === seed.current_alias_id));
                }
            });

            /* Responsible */
            _.defer(() => {
                this.availableResponsibles.subscribeOnce((responsibles) => {
                    // @ts-expect-error: object as predicate is missing in type definition
                    this.responsible(_.find(responsibles, {userid: seed.responsible_id}));
                });
            });

            /* Species */
            _.defer(() => {

                this.species(_.find(seed.species, {selected: true}));
            });

            /* Line / Strain (from param) */
            _.defer(() => {
                if (params.strainId) {
                    const knownStrain = _.find(seed.strains, {id: params.strainId});
                    if (knownStrain) {
                        this.species(_.find(seed.species, {id: knownStrain.species_id}));
                        this.strainType("known");
                        this.strainId(knownStrain.id);
                    }
                }
            });

            /* Line / Strain (from crossing) */
            _.defer(() => {

                const preselectedStrain = _.find(seed.strains, {id: seed.strain_id});
                if (preselectedStrain) {
                    this.species(_.find(seed.species, {id: preselectedStrain.species_id}));
                    this.strainType("known");
                    this.strainId(preselectedStrain.id);
                }

            });

            /* License and classification from crossing */
            _.defer(() => {
                this.availableLicenses.subscribeOnce(() => {
                    this.licenseId(seed.license_id);
                });

                this.availableClassifications.subscribeOnce(() => {
                    this.classificationId(seed.classification_id);
                });
            });

            // obtain parent animal details on animal import after crossing
            if (seed.parents && seed.parents.length) {

                const firstParent = _.head(seed.parents);

                /* Owner */
                _.defer(() => {

                    this.owner(_.find(seed.owners, {userid: firstParent.owner_id}));
                });

                /* Project */
                _.defer(() => {

                    this.projectIds(_.map(firstParent.projects, function (project) {
                        return project.id;
                    }));
                });

                /* Origin */
                _.defer(() => {

                    // default value "Born in house"
                    this.origin(_.find(seed.origins, {id: 1}));
                });

                /* Species */
                _.defer(() => {
                    // If a strain_id is defined in the crossing (default), we obtained the
                    // matching species, otherwise we try to take the one from the first parent.
                    if (!seed.strain_id) {
                        this.species(_.find(seed.species, {id: firstParent.species_id}));
                    }
                });

                /* Genetic Background */
                _.defer(() => {

                    this.geneticBackground(_.find(seed.genetic_backgrounds, {id: firstParent.genetic_background_id}));
                });

                /* Licence */
                _.defer(() => {

                    this.availableLicenses.subscribeOnce(() => {
                        // If no license is specified in the crossing then preselect the license of the first parent
                        if (!seed.license_id && firstParent.licence_id) {
                            this.licenseId(firstParent.licence_id);
                        }
                    });
                });

                /* Classification */
                _.defer(() => {

                    this.availableClassifications.subscribeOnce(() => {
                        // If no classification is specified in the crossing then preselect the classification of the first parent
                        if (!seed.classification_id && firstParent.classification_id) {
                            this.classificationId(firstParent.classification_id);
                        }
                    });
                });

                /* Location */
                this.preselectLocation = seed.common_parent_location;
            }
        });

        this.occupiedPositions = ko.observable().extend({
                fetch: {
                    fn: (signal) => {
                        const positions = _.filter(_.invokeMap(this.tanks(), "tankPosition"), _.identity);
                        if (this.location() && positions.length) {
                            return fetch("tank_list.py", {
                                method: "POST",
                                body: getFormData({
                                    request: JSON.stringify({
                                        "is_position_occupied": {
                                            "status": "open",
                                            "tank_position": positions,
                                            "rack_id": this.location().db_id,
                                        },
                                    }),
                                }),
                                signal,
                            });
                        }
                    },
                    cleanup: (v) => v.success ? _.uniq(v.occupied_positions) : undefined,
                },
            }
        );

        // add a first single tank as initialization
        this.addTank();

    }
}

// dialog starter
export const showTankAnimalImport = dialogStarter(TankAnimalImportViewModel, template, {
    name: "TankAnimalImport",
    closeOthers: true,
    title: getTranslation("Tank Import"),
    width: 600,
    cssARequire: [":popup.css", ":table.css"],
    anchor: {top: 20, left: undefined},
});
