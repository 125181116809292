import {Computed} from "knockout";
import {ObservableArray} from "knockout";
import {Observable} from "knockout";
import * as ko from "knockout";
import * as _ from "lodash";
import * as d3 from "d3";
import * as dagreD3 from "dagre-d3-es";
import * as $ from "jquery";
import {showTankDetails} from "../dialogs";
import {FetchExtended} from "../knockout/extensions/fetch";
import {setSessionItem} from "../lib/browserStorage";
import {getSessionItem} from "../lib/browserStorage";
import {getTranslation} from "../lib/localize";
import {session} from "../lib/pyratSession";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {el} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import "./colonyPedigree.scss";
import {frames} from "../lib/pyratTop";

interface Arguments {
    kind?: string;
    label?: string;
    strain_autocomplete: any;
}

interface Node {
    id: number | "children" | "siblings";
}

type Edge = [Node["id"], Node["id"]];

interface Animal {
    sex: "m" | "f" | "?";
    animal_access_permitted: boolean;
    eartag: string;
    strain_name_with_id: string;
    cagenumber: string;
    dateborn: string;
    datesacrificed: string;
    mutations: {
        mutationname: string;
        mutationgrade?: string;
    }[];
    parents: {
        parent_id: number;
        parent_eartag: string;
        parent_sex: "m" | "f" | "?";
    }[];
}

interface AnimalNode extends Animal, Node {
    animals?: Animal[];
}

interface Tank {
    parent_tank_ids: number[] | null;
    tank_id: number;
    tank_position: string;
    tank_label: string;
    status: "open" | "closed" | "exported" | "joined";
    strain_name_with_id: string;
    number_of_male: number;
    number_of_female: number;
    number_of_unknown: number;
    generation: string;
    tank_access_permitted: boolean;
}

interface TankNode extends Tank, Node {
    tanks?: Tank[];
}

interface Strain extends Node {
    name_with_id: string;
    owner_fullname: string;
    project_label: string;
    severity_level: string;
    created: string;
    num_animals_live: number;
    num_tank_animals_alive: number;
    num_animals: number;
    num_tank_animals_released: number;
    num_pups_live: number;
    num_pups: number;
    num_cages_total: number;
    num_tanks: number;
    mutations: {
        name: string;
    }[];
}

interface StrainNode extends Strain, Node {
    parents?: Strain[];
    children?: Strain[];
}

interface Pedigree {
    root: number | "children" | "siblings";
    nodes: Node[];
    edges: Edge[];
    kind: string;
}

class PedigreeKindModel {
    public manager: ColonyPedigree;
    public nodeLabel: (pedigree_data: Pedigree, node: Node) => HTMLElement;
    public nodeClass: (pedigree_data: Pedigree, node: Node) => string;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    constructor(manager: ColonyPedigree) {
    }

    public nodeId = (node: Node) => {
        return node.id;
    };
}

class PedigreeKind {
    public available: boolean;
    public label: string;
    public placeholder: string;
    public params: { [key: string]: any };
    public request: () => { [key: string]: string | number | null };
    public model: typeof PedigreeKindModel;
}

class AnimalPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: AnimalNode) => {
        const classNames = [];

        if (node.id === "children" || node.id === "siblings") {
            classNames.push("list_node");
        } else {
            if (node.sex === "m") {
                classNames.push("animal_male_sex");
            } else if (node.sex === "f") {
                classNames.push("animal_female_sex");
            } else {
                classNames.push("animal_unknown_sex");
            }

            if (node.animal_access_permitted) {
                classNames.push("animal_access_permitted");
            } else {
                classNames.push("animal_access_denied");
            }

            if (node.datesacrificed) {
                classNames.push("animal_dead");
            } else {
                classNames.push("animal_alive");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }

        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: AnimalNode) => {

        const c = $("<div>");
        const actions = $("<div>", {"class": "actions"});

        if (node.id === "children" || node.id === "siblings") {

            c.addClass("list_node").addClass(node.id + "_node");
            c.css({maxWidth: "200px"});

            if (node.id === "siblings") {
                c.append($("<h1>", {text: getTranslation("Siblings")}));
            } else if (node.id === "children") {
                c.append($("<h1>", {text: getTranslation("Children")}));
            }

            _.forEach(_.chain(node.animals)
                .sortBy("birth_id")
                .groupBy("birth_id")
                .value(), (birth_animals) => {

                const birthGroup = $("<div>", {"class": "birth"});

                _.forEach(birth_animals, (animal, index) => {

                    birthGroup.append(
                        $("<span>", {text: animal.eartag, "class": "animal"})
                            .addClass({"m": "male", "f": "female", "?": "unknown"}?.[animal.sex])
                            .addClass(animal.datesacrificed ? "animal_dead" : "animal_alive")
                            .on("click", () => {
                                this.manager.subject(this.manager.kinds.animal_pedigree);
                                this.manager.subjectLabel(animal.eartag);
                                this.manager.generateRequest();
                            }));

                    if (index + 1 < birth_animals.length) {
                        birthGroup.append($("<span>", {"class": "comma", "text": ","})).append(" ");
                    }
                });

                c.append(birthGroup);

            });

        } else {

            c.addClass("animal_node");

            c.css({
                // sizes must be defined in js for calculations
                minWidth: _.size(node.eartag) * 12 + 60,
            });

            if (node.sex === "m") {
                c.append($("<span>", {"class": "gender_symbol icon_button icon_male"}));
            } else if (node.sex === "f") {
                c.append($("<span>", {"class": "gender_symbol icon_button icon_female"}));
            } else {
                c.append($("<span>", {"class": "gender_symbol icon_button icon_unknown"}));
            }

            /* Animal Id */
            c.append($("<h1>", {
                title: node.eartag,
                text: node.eartag,
                "class": "eartag",
            }));

            /* Strain name */
            c.append($("<div>", {"class": "strain"})
                .append($("<span>", {text: node.strain_name_with_id}))
            );

            /* Mutations */
            _.forEach(node.mutations, (mutation) => {
                c.append($("<div>", {"class": "mutation"})
                    .append($("<label>", {text: mutation.mutationname}))
                    .append($("<span>", {text: mutation.mutationgrade})));

            });

            /* Cage */
            c.append($("<div>", {"class": "cagenumber"})
                .append($("<span>", {"class": "icon_button icon_cage only_icon_img"}))
                .append($("<span>", {text: node.cagenumber})));

            /* Birth date */
            c.append($("<div>", {"class": "day_of_birth"})
                .append($("<span>", {"class": "icon_button icon_born only_icon_img"}))
                .append($("<span>", {text: node.dateborn})));

            /* Death date */
            if (node.datesacrificed) {
                c.append($("<div>", {"class": "day_of_death"})
                    .append($("<span>", {"class": "icon_button icon_death only_icon_img"}))
                    .append($("<span>", {text: node.datesacrificed})));
            }

            /* Link pedigree from here */
            if (node.parents && node.parents.length && node.id !== pedigree_data.root) {
                actions.append(
                    $("<span>", {"class": "icon_button icon_pedigree only_icon_img right"})
                        .on("click", () => {
                            this.manager.subject(this.manager.kinds.animal_pedigree);
                            this.manager.subjectLabel(node.eartag);
                            this.manager.generateRequest();
                        })
                );
            }

            /* Link animal details popup */
            if (node.animal_access_permitted === true) {
                actions.append(
                    $("<span>", {"class": "icon_button icon_eye only_icon_img right"})
                        .on("click", () => {
                            frames.detailPopup.open(getUrl("mousedetail.py", {
                                animalid: node.id,
                            }));
                        })
                );
            }

            if (actions.children().length) {
                c.append(actions);
            }

        }

        return c.get(0);
    };

}

class TankPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: TankNode) => {
        const classNames = [];

        if (node.id === "children" || node.id === "siblings") {
            classNames.push("list_node");
        } else {

            if (node.tank_access_permitted) {
                classNames.push("tank_access_permitted");
            } else {
                classNames.push("tank_access_denied");
            }

            if (node.status == "open") {
                classNames.push("tank_open");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }

        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: TankNode) => {

        // TODO: Convert to JSX, once this is available.
        const c = el("div");
        const actions = el("div", {classList: ["actions"]});

        if (node.id === "children" || node.id === "siblings") {

            c.classList.add("list_node");
            c.classList.add(node.id + "_node");
            c.style.maxWidth = "200px";

            if (node.id === "siblings") {
                c.append(el("h1", {}, getTranslation("Siblings")));
            } else if (node.id === "children") {
                c.append(el("h1", {}, getTranslation("Children")));
            }

            node?.tanks?.forEach((tank, index) => {

                const tankElement = el("span", {classList: ["tank"]}, String(tank.tank_id));

                if (tank.status !== "open") {
                    tankElement.classList.add("tank_not_open");
                }

                if (tank.tank_label) {
                    tankElement.innerText += ` (${tank.tank_label})`;
                }

                tankElement.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.tank_pedigree);
                    this.manager.subjectLabel(String(tank.tank_id));
                    this.manager.generateRequest();
                });

                c.append(tankElement);
                if (index + 1 < node.tanks.length) {
                    c.append(el("span", {classList: ["comma"]}, ", "));
                }

            });

        } else {

            c.classList.add("tank_node");

            // sizes must be defined in js for calculations
            c.style.minWidth = String(node.tank_id).length * 12 + 60 + "px";

            /* Animal Id */
            const heading = el("h1", {classList: ["tank_id"]}, String(node.tank_id));
            c.append(heading);

            /* Strain name */
            if (node.tank_label) {
                heading.append(el("span", {classList: ["tank_label"]}, node.tank_label));
            }

            c.append(el("table", {classList: ["animal_counts"]},
                el("tr", {},
                    el("th", {classList: ["icon_button", "icon_male"]}),
                    el("td", {}, String(node.number_of_male)),
                    el("th", {classList: ["icon_button", "icon_female"]}),
                    el("td", {}, String(node.number_of_female)),
                    el("th", {classList: ["icon_button", "icon_unknown"]}),
                    el("td", {}, String(node.number_of_unknown)),
                ),
            ));

            /* Strain name */
            if (node.strain_name_with_id) {
                c.append(el("div", {classList: ["strain"]},
                    el("span", {}, node.strain_name_with_id),
                ));
            }

            /* Generation */
            if (node.generation) {
                c.append(el("div", {classList: ["generation"]},
                    el("label", {}, getTranslation("Generation") + ": "),
                    el("span", {}, node.generation),
                ));
            }

            /* Link pedigree from here */
            if (node.parent_tank_ids?.length && node.tank_id !== pedigree_data.root) {
                const actionElement = el("span", {classList: ["icon_button", "icon_pedigree", "only_icon_img", "right"]});
                actionElement.addEventListener("click", () => {
                    this.manager.subject(this.manager.kinds.tank_pedigree);
                    this.manager.subjectLabel(String(node.tank_id));
                    this.manager.generateRequest();
                });
                actions.appendChild(actionElement);
            }

            /* Link animal details popup */
            if (node.tank_access_permitted === true) {
                const actionElement = el("span", {classList: ["icon_button", "icon_eye", "only_icon_img", "right"]});
                actionElement.addEventListener("click", () => {
                    showTankDetails({tankId: node.id as number});
                });
                actions.appendChild(actionElement);
            }

            if (actions.childNodes.length) {
                c.append(actions);
            }

        }

        return c;
    };

}

class StrainPedigreeModel extends PedigreeKindModel {

    constructor(manager: ColonyPedigree) {
        super(manager);
        this.manager = manager;
    }

    public nodeClass = (pedigree_data: Pedigree, node: StrainNode) => {
        const classNames = [];

        if (node.id === "children") {
            classNames.push("list_node");
        } else {
            if (node.num_animals_live > 0 || node.num_pups_live > 0 || node.num_tank_animals_alive > 0) {
                classNames.push("strain_used_live");
            } else {
                classNames.push("strain_used_dead");
            }

            if (node.id === pedigree_data.root) {
                classNames.push("root_node");
            }
        }

        return classNames.join(" ");
    };

    public nodeLabel = (pedigree_data: Pedigree, node: StrainNode) => {

        const nodeDiv = $("<div>").css({minWidth: "200px"});  // sizes must be defined in js for calculations
        const actions = $("<div>", {"class": "actions"});
        const childGroup = $("<div>", {"class": "children"});

        if (node.id === "children") {

            _.forEach(_.sortBy(node.children, "name"), (strain, index) => {
                childGroup.append(
                    $("<span>", {text: strain.name_with_id, title: strain.name_with_id, "class": "child subtle-link"})
                        .on("click", () => {
                            this.manager.subject(this.manager.kinds.strain_pedigree);
                            this.manager.subjectLabel(String(strain.id));
                            this.manager.generateRequest();
                        })
                );

                if (index + 1 < node.children.length) {
                    childGroup.append($("<span>", {"class": "comma", "text": ","})).append(" ");
                }
            });

            nodeDiv
                .css({maxWidth: "250px"})
                .addClass("list_node " + node.id + "_node")
                .append($("<h1>", {text: getTranslation("Children")}))
                .append(childGroup);

        } else {

            nodeDiv.addClass("strain_node");

            /* strain_name */
            nodeDiv.append($("<h1>", {
                title: node.name_with_id,
                text: node.name_with_id,
                "class": "strain_name",
            }));

            /* owner_fullname */
            nodeDiv.append(
                $("<div>", {"class": "owner_name"})
                    .append($("<span>", {text: node.owner_fullname}))
            );

            /* project label */
            if (node.project_label) {
                nodeDiv.append(
                    $("<div>", {"class": "project_label"})
                        .append($("<label>", {text: getTranslation("Project") + ": "}))
                        .append($("<span>", {text: node.project_label}))
                );
            }

            /* severity level */
            if (node.severity_level) {
                nodeDiv.append(
                    $("<div>", {"class": "severity_level"})
                        .append($("<label>", {text: getTranslation("Severity level") + ": "}))
                        .append($("<span>", {text: node.severity_level}))
                );
            }

            /* creation date */
            if (node.created) {
                nodeDiv.append(
                    $("<div>", {"class": "creation_date"})
                        .append($("<label>", {text: getTranslation("Creation date") + ": "}))
                        .append($("<span>", {text: node.created}))
                );
            }

            /* mutations */
            if (node.mutations.length > 0) {
                nodeDiv.append($("<h2>", {text: getTranslation("Mutations") + ":", "class": "mutations"}));
                nodeDiv.append($("<ul>", {"class": "mutations"}).append(
                    _.map(node.mutations, (mutation) => {
                        return $("<li>", {text: mutation.name, "class": "mutations"});
                    })
                ));
            }

            /* num animals alive/total */
            nodeDiv.append(
                $("<div>", {"class": "animal_count"})
                    .append($("<label>", {text: getTranslation("Animals alive") + ": "}))
                    .append($("<span>", {text: node.num_animals_live + node.num_tank_animals_alive}))
                    .append(" (")
                    .append($("<label>", {text: getTranslation("total") + ": "}))
                    .append($("<span>", {text: node.num_animals + node.num_tank_animals_released}))
                    .append(")"));

            if (session.pyratConf.TERRESTRIAL) {
                /* num pups */
                nodeDiv.append(
                    $("<div>", {"class": "pup_count"})
                        .append($("<label>", {text: getTranslation("Pups alive") + ": "}))
                        .append($("<span>", {text: node.num_pups_live}))
                        .append(" (")
                        .append($("<label>", {text: getTranslation("total") + ": "}))
                        .append($("<span>", {text: node.num_pups}))
                        .append(")"));

                /* num cages */
                nodeDiv.append(
                    $("<div>", {"class": "cage_count"})
                        .append($("<label>", {text: getTranslation("Open cages") + ": "}))
                        .append($("<span>", {text: node.num_cages_total}))
                );
            }

            /* num cages */
            if (session.pyratConf.AQUATIC) {
                nodeDiv.append(
                    $("<div>", {"class": "tank_tanks"})
                        .append($("<label>", {text: getTranslation("Open tanks") + ": "}))
                        .append($("<span>", {text: node.num_tanks}))
                );
            }

            /* detail */
            actions.append(
                $("<span>", {"class": "icon_button icon_eye only_icon_img right"})
                    .on("click", () => {
                        frames.detailPopup.open(getUrl("edit_strain.py",{
                            strainid: node.id,
                        }));
                    })
            );

            if (node.id !== pedigree_data.root && node.parents && node.parents.length) {
                actions.append(
                    $("<span>", {"class": "icon_button icon_pedigree only_icon_img right"})
                        .on("click", () => {
                            this.manager.subject(this.manager.kinds.strain_pedigree);
                            this.manager.subjectLabel(String(node.id));
                            this.manager.generateRequest();
                        })
                );
            }

            if (actions.children().length) {
                nodeDiv.append(actions);
            }
        }

        return nodeDiv.get(0);
    };
}

class ColonyPedigree {

    public graphRoot: d3.Selection<HTMLDivElement, any, HTMLElement, any>;
    public kinds: { [key: string]: PedigreeKind };
    public error: Observable<boolean>;
    public message: Observable<string>;
    public depth: Observable<number>;
    public subject: Observable<PedigreeKind>;
    public subjectLabel: Observable<string>;
    public history: ObservableArray<[PedigreeKind, string]>;
    public requestData: Observable<{ [key: string]: number | string | null }>;
    public seed: FetchExtended<Observable<AjaxResponse<{ pedigree: Pedigree } | undefined>>>;
    public model: Computed<PedigreeKindModel>;
    public rankDir: Observable<"LR" | "TB" | "RL" | "BT">;
    public density: Observable<number>;
    public scale: Observable<number>;
    public border: number;
    public graph: Computed<d3.Selection<d3.BaseType, any, HTMLElement, any>>;

    constructor(args: Arguments) {

        this.kinds = {
            animal_pedigree: {
                available: session.pyratConf.TERRESTRIAL,
                label: getTranslation("Animal"),
                placeholder: getTranslation("ID"),
                model: AnimalPedigreeModel,
                params: {
                    nearby: ko.observable(undefined),
                },
                request: () => {
                    return {
                        "kind": "animal_pedigree",
                        "label": this?.subjectLabel(),
                        "nearby": this.kinds.animal_pedigree.params.nearby() || null,
                    };
                },
            },
            tank_pedigree: {
                available: session.pyratConf.AQUATIC,
                label: getTranslation("Tank"),
                placeholder: getTranslation("ID"),
                model: TankPedigreeModel,
                params: {
                    nearby: ko.observable(undefined),
                },
                request: () => {
                    return {
                        "kind": "tank_pedigree",
                        "label": this?.subjectLabel(),
                        "nearby": this.kinds.animal_pedigree.params.nearby() || null,
                    };
                },
            },
            strain_pedigree: {
                available: session.userPermissions.pedigree_view_strain,
                label: getTranslation("Line / Strain"),
                placeholder: getTranslation("Name"),
                model: StrainPedigreeModel,
                params: {
                    autocomplete: args.strain_autocomplete,
                },
                request: () => {
                    return {
                        kind: "strain_pedigree",
                        label: parseInt(this?.subjectLabel(), 10),
                    };
                },
            },
        };

        this.graphRoot = d3.selectAll<HTMLDivElement, any>("#colony_pedigree #graph");
        this.error = ko.observable(false);
        this.message = ko.observable(getTranslation("Define a starting point to draw a pedigree"));

        this.subject = ko.observable(this.kinds[args.kind]);
        getSessionItem("pedigree_history_last_subject").then((value: string) => {
            this.subject(this.kinds[args.kind] ||
                this.kinds[value] ||
                this.kinds.animal_pedigree);
        });

        this.subjectLabel = ko.observable(args.label);
        getSessionItem("pedigree_history_last_subject_label").then((value: string) => {
            this.subjectLabel(args.label || value);
        });

        this.depth = ko.observable(4);
        this.requestData = ko.observable();

        if (this.kinds[args.kind] && args.label) {
            _.defer(() => {
                this.generateRequest();
            });
        }

        this.history = ko.observableArray([]);
        this.requestData.subscribe(() => {
            this.history.push([this.subject(), this.subjectLabel()]);
            setSessionItem("pedigree_history_last_subject", _.findKey(this.kinds, this.subject()));
            setSessionItem("pedigree_history_last_subject_label", this.subjectLabel());
        });

        this.seed = ko.observable().extend({
            rateLimit: 0,
            fetch: {
                disable: ko.computed(() => !this.requestData()),
                fn: (signal) => {
                    this.error(false);
                    this.message(undefined);
                    const response = fetch("pedigree.py", {
                        method: "POST",
                        body: getFormData({
                            json: "true",
                            ...this.requestData(),
                        }),
                        signal,
                    });
                    response.catch(() => {
                        this.error(true);
                        this.message(getTranslation("Unknown error when generating the pedigree"));
                    });
                    return response;
                },
            },
        });

        this.seed.subscribe((seed) => {
            if (seed.success === false) {
                this.error(true);
                this.message(seed.message);
            }
        });

        this.rankDir = ko.observable("LR");  // LR = left-right, TD = top-down
        this.density = ko.observable(50);
        this.scale = ko.observable(100);
        this.border = 20;

        this.scale.subscribe(() => {
            $("#scale_value_info").stop(true, true).show(0).delay(1000).fadeOut(500);
        });

        this.density.subscribe(() => {
            $("#density_value_info").stop(true, true).show(0).delay(1000).fadeOut(500);
        });

        this.graph = ko.computed(() => {

            // remove all old graphs
            this.graphRoot.selectAll("svg > *").remove();

            const seed = this.seed();
            if (seed?.success && seed?.pedigree) {

                const render = dagreD3.render();
                const graph = new dagreD3.graphlib.Graph().setGraph({
                    marginx: this.border,
                    marginy: this.border,
                    rankdir: this.rankDir(),
                    nodesep: this.density(),
                    ranksep: (Math.pow(this.density(), 2 * 1.06) + 1000) * 0.01,
                });
                const svg = this.graphRoot.select("svg");
                const inner = svg.append("g");

                svg.classed(seed.pedigree.kind, true);

                // add all the nodes
                const model = new this.kinds[seed.pedigree.kind].model(this);
                _.forEach(seed.pedigree.nodes, (node) => {
                    graph.setNode(String(model.nodeId(node)), {
                        "label": model.nodeLabel(seed.pedigree, node),
                        "class": model.nodeClass(seed.pedigree, node),
                    });
                });

                // define all the edges
                _.forEach(seed.pedigree.edges, (edge: Edge) => {
                    graph.setEdge(String(edge[0]), String(edge[1]), {
                        // possible curve options in https://github.com/d3/d3-shape#curves
                        curve: {
                            LR: d3.curveMonotoneX,
                            RL: d3.curveMonotoneX,
                            TB: d3.curveBasis,
                            BT: d3.curveBasis,
                        }[this.rankDir()],
                    });
                });

                // run the renderer
                render(inner, graph);

                // resize the svg element and the popup according to the graph (within certain limits)
                inner.attr("transform", "scale(" + this.scale() / 100 + ")");
                svg.attr("height", (graph.graph().height * this.scale() / 100) + 10);
                svg.attr("width", (graph.graph().width * (this.scale() / 100)));

                return svg;
            }

        });

    }

    public generateRequest = () => {
        this.requestData(_.extend(this.subject().request(), {"depth": this.depth()}));
    };

    public stepBack = () => {
        const step = this.history()[this.history().length - 2];
        this.subject(step[0]);
        this.subjectLabel(step[1]);
        this.generateRequest();
        this.history.splice(this.history().length - 2, 2);
    };

    public print = () => {
        window.print();
    };

    public scaleToFit = () => {
        if (this.graph()) {

            if (this.scale() !== 100) {
                this.scale(100);

            } else {

                // reset to 100 % to know if it is bigger
                this.scale(100);

                // calculate the scaling factor for bot dimensions
                const boxSize = this.graphRoot.node().getBoundingClientRect();
                // @ts-expect-error: getBBox is nor typed
                const svgSize = this.graphRoot.select("svg").node().getBBox();
                const boxHeight = boxSize.height - boxSize.top;
                const svnHeight = svgSize.height + (svgSize.y * 2);
                const boxWidth = boxSize.width - boxSize.left;
                const svnWidth = svgSize.width + (svgSize.x * 2);
                const heightScale = boxHeight / svnHeight;
                const widthScale = boxWidth / svnWidth;

                // set the lower of both scaling factor but never scale up
                if (heightScale >= widthScale && widthScale < 1) {
                    this.scale(Math.floor((widthScale * 100) / 5) * 5);
                } else if (widthScale >= heightScale && heightScale < 1) {
                    this.scale(Math.floor((heightScale * 100) / 5) * 5);
                }

            }
        }
    };


}


export const initColonyPedigree = (args: Arguments): void => {
    ko.applyBindings(new ColonyPedigree(args));
};
