import {showOrderRequestDetails} from "../dialogs";
import {showProjectDetails} from "../dialogs";
import "../lib/actionLog.scss";
import {LocaleKey as flatpickrLocaleKey} from "../lib/flatpickr";
import {localize as localizeFlatpickr} from "../lib/flatpickr";
import {DropdownPopup} from "../lib/popups";
import {procedureShortcuts} from "../lib/pyratTop";
import {frames} from "../lib/pyratTop";
import {hideTabs, loadLogoutFrame, logoWink, showTabs} from "../lib/pyratTop/frames";
import {ProcedureShortcuts} from "../lib/pyratTop/procedureShortcuts";
import SessionPoll from "../lib/sessionPoll";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";

interface Arguments {
    sessionTimeout: number;
    language: string;
    archiveMode: boolean;
    hideTabs: boolean;
    defaultSubTab: string;
    tokenData: [keyof ProcedureShortcuts["shortcutFunctions"], ...any][];
    flatpickrLocale: flatpickrLocaleKey;
    flatpickrDateFormat: string;
    flatpickrFirstDayOfWeek: number;
}

class ActionLog {

    container: HTMLElement;
    lastTimestamp: number;

    constructor(container: HTMLElement) {

        this.container = container;

        // export button
        container.parentElement.querySelector("a.action_log_export").addEventListener("click", (e) => {
            e.preventDefault();
            window.top.open(getUrl("action_log.py", {
                export: 1,
            }), "_new");
        });

        // print button
        container.parentElement.querySelector("a.action_log_print").addEventListener("click", (e) => {
            e.preventDefault();
            window.top.open(getUrl("action_log.py", {
                print: 1,
            }), "_new");
        });

    }

    public clearGlobalSelection = () => {
        fetch("clear_global_selection.py", {method: "POST", body: getFormData()}).then(() => this.reload());
    };

    /**
     * Fetch new action log events and display them.
     */
    public reload = () => {
        fetch(getUrl("action_log.py", {since: this.lastTimestamp}))
            .then((response) => {
                if (response.ok) {
                    return response.text();
                }
            })
            .then((html) => {
                this.container.innerHTML = html;
                this.establishClickListeners(this.container);

                const lastTimestampElement = this.container.querySelector("input[name=action_log_last_timestamp]");
                if (lastTimestampElement) {
                    this.lastTimestamp = parseInt(lastTimestampElement.textContent, 10);
                    this.container.removeChild(lastTimestampElement);
                }
            });
    };

    private establishClickListeners(container: HTMLElement) {

        // details
        container.querySelectorAll("a.cagenumber").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            frames.detailPopup.open(getUrl("cagedetail.py", {
                cageid: parseInt((e.target as HTMLElement).getAttribute("cage-id"), 10),
            }));
        }));

        container.querySelectorAll("a.animal-eartag").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            frames.detailPopup.open(getUrl("mousedetail.py", {
                animalid: parseInt((e.target as HTMLElement).getAttribute("animal-id"), 10),
            }));
        }));

        container.querySelectorAll("a.pup-eartag").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            frames.detailPopup.open(getUrl("pupdetail.py", {
                animalid: parseInt((e.target as HTMLElement).getAttribute("pup-id"), 10),
            }));
        }));

        container.querySelectorAll("a.order-request-number").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            showOrderRequestDetails({
                orderRequestId: parseInt((e.target as HTMLElement).getAttribute("order-request-id"), 10),
            });
        }));

        container.querySelectorAll("a.service-request-number").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            frames.detailPopup.open(getUrl("service_request_detail.py", {
                request_id: parseInt((e.target as HTMLElement).getAttribute("service-request-id"), 10),
            }));
        }));

        container.querySelectorAll("a[project-id]").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            showProjectDetails({
                projectId: parseInt((e.target as HTMLElement).getAttribute("project-id"), 10),
            });
        }));

        container.querySelectorAll("a.clear-global-selection").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            this.clearGlobalSelection();
        }));

        // lists
        container.querySelectorAll("a.cages").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.filterSubtab("cages", {cagenumber: (e.target as HTMLElement).getAttribute("cage-numbers")});
        }));

        container.querySelectorAll("a.animals").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.filterSubtab("animals", {eartag: (e.target as HTMLElement).getAttribute("animal-eartags")});
        }));

        container.querySelectorAll("a.pups").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.filterSubtab("pups", {
                pupid: (e.target as HTMLElement).getAttribute("pup-ids").split(",").map((v) => parseInt(v, 10)),
            });
        }));

        container.querySelectorAll("a.tanks").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.filterSubtab("tanks", {
                tank_id: (e.target as HTMLElement).getAttribute("tank-ids").split(",").map((v) => parseInt(v, 10)),
            });
        }));

        container.querySelectorAll("a.stud-males").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.subtabClick("studmales", {arguments: "Yes", eartag: (e.target as HTMLElement).getAttribute("stud-male-eartags")});
        }));

        container.querySelectorAll("a.order-requests").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.subtabClick("order_requests", {id: (e.target as HTMLElement).getAttribute("order-request-ids")});
        }));

        container.querySelectorAll("a.service-requests").forEach((e) => e.addEventListener("click", (e) => {
            e.preventDefault();
            window.top.mainMenu.subtabClick("service_requests", {id: (e.target as HTMLElement).getAttribute("service-request-ids")});
        }));

    }
}


export const initMainPage = (args: Arguments): void => {

    // If this is not loaded in the top frame, redirect it.
    if (window != window.top) {
        window.top.location.href = window.location.href;
    }

    new SessionPoll(args.sessionTimeout);

    // @ts-expect-error: TODO: Move menu Dropdown handling to pyratFrontend.
    window.alias_logout_box = new DropdownPopup(document.getElementById("user_container"), {
        onOpen: loadLogoutFrame,
        hideOnMouseLeave: false,
    });

    // @ts-expect-error: TODO: Move ActionLog to pyratFrontend.
    window.actionLog = new ActionLog(document.querySelector("div.action_log .action_log_events"));

    // @ts-expect-error: TODO: Move menu Dropdown handling to pyratFrontend.
    window.actionLogDropdown = new DropdownPopup(document.getElementById("history_container"), {
        // @ts-expect-error: TODO: Move ActionLog to pyratFrontend.
        onOpen: window.actionLog.reload,
        hideOnMouseLeave: false,
    });

    // @ts-expect-error: TODO: Move menu Dropdown handling to pyratFrontend.
    window.archiveModeDropdown = new DropdownPopup(document.getElementById("archive_mode_container"), {
        hideOnMouseLeave: false,
    });

    // This is not nice, but this way to handle the detail popup,
    // as a single reused instance, should be removed eventually.
    // TODO: Remove any usage of the detailPopup Frame
    frames.initDetailPopup();

    setInterval(() => {
        const rnd = Math.round(Math.random() * 5000);
        setTimeout(logoWink, rnd);
    }, 6000);
    document.getElementById("pyrat_logo").addEventListener("mouseenter", logoWink);

    if (args.hideTabs) {
        hideTabs();
    } else {
        showTabs();
    }

    // @ts-expect-error: TODO: Move newtablayer to pyratFrontend.top.frames.
    window.tab_initialize();

    if (args.defaultSubTab && !args.hideTabs) {
        window.mainMenu.subtabClick(args.defaultSubTab);
        procedureShortcuts.execute(args.tokenData);
    }

    // Show archive mode status tab
    if (args.archiveMode) {
        document.getElementById("archive_mode_container").style.display = "block";
    }

    localizeFlatpickr(args.flatpickrLocale, args.flatpickrDateFormat, args.flatpickrFirstDayOfWeek);
};
