import * as ko from "knockout";
import {Computed} from "knockout";
import {Observable} from "knockout";
import {components} from "knockout";
import {getTranslation} from "../../../lib/localize";
import {CageItem} from "./cagePicker";
import {CageId} from "./cagePicker";
import {LocationCagePickerParams} from "./locationCagePicker";
import {PreselectLocationItem} from "./locationPicker";
import {LocationItem} from "./locationPicker";
import {LocationIdString} from "./locationPicker";


/**
 * Location Cage Picker Popup Component
 *
 * Wrapping location-cage-picker in popup dialog using dialog bindingHandler.
 * All params of location-cage-picker will be passed through - read picker doc string.
 *
 * @param inputClassName - Additional class name(s) that will be assigned to the input
 * element that triggers the popup to open.
 *
 * @param inputId - String that should be used as id attribute on the input element
 * that triggers the popup to open. This can be usefull if used in forms with label
 * elements.
 *
 * @param afterApply - Function that will be executed when location picker selection
 * is applied/confirmed.
 */
interface LocationCagePickerPopupParams extends LocationCagePickerParams {
    inputClassName?: string;
    inputId?: string;
    afterApply?: () => void;
}

class LocationCagePickerPopupViewModel {

    public componentElement: HTMLElement;
    public dialogPosition: HTMLElement;
    public dialogVisible: Observable<boolean>;
    public initialized: Observable<false | "loading" | true>;
    public pickerClassName: string;
    public inputClassName: string;
    public inputId: string;
    public isConfirmed: boolean;
    public disabledPicker: any;
    public disabledAttr: Observable<string | boolean>;
    public dialogArgs: { width: number; title: string; close: any; height: string };
    public rememberSelectionTrigger: Observable;
    public restoreSelectionTrigger: Observable;
    public parentLocation: LocationIdString;
    public selectedLocation: Observable<LocationItem>;
    public selectedCage: Observable<CageItem>;
    public preselectLocation: PreselectLocationItem;
    public preselectCage: CageId;
    public unselectLocationTrigger: Observable;
    public unselectCageTrigger: Observable;
    public cleanupTrigger: Observable;
    public selectedLocationTitle: Observable<string>;
    public selectedCageTitle: Observable<string>;
    public selectedCageText: Computed<any>;
    public afterApply: () => void;
    public confirmable: Computed<boolean>;

    constructor(params: LocationCagePickerPopupParams, componentElement: HTMLElement) {

        this.componentElement = componentElement;
        this.dialogArgs = {
            title: getTranslation("Select cage"),
            height: "auto",
            width: 600,
            close: this.closeCallback,
        };

        this.dialogVisible = ko.observable();
        this.dialogPosition = componentElement;
        this.initialized = ko.observable();
        this.pickerClassName = params.pickerClassName;
        this.inputClassName = params.inputClassName;
        this.inputId = params.inputId;
        this.isConfirmed = false;
        this.disabledPicker = params.disabled;
        this.disabledAttr = ko.observable(this.disabledPicker ? "disabled" : false);
        this.rememberSelectionTrigger = ko.observable();
        this.restoreSelectionTrigger = ko.observable();
        this.parentLocation = params.parentLocation;
        this.selectedLocation = params.selectedLocation;
        this.selectedCage = params.selectedCage;
        this.preselectLocation = params.preselectLocation;
        this.preselectCage = params.preselectCage;
        this.unselectLocationTrigger = params.unselectLocationTrigger;
        this.unselectCageTrigger = params.unselectCageTrigger;
        this.cleanupTrigger = ko.isObservable(params.cleanupTrigger) ? params.cleanupTrigger : ko.observable();
        this.selectedLocationTitle = ko.isObservable(params.selectedLocationTitle) ? params.selectedLocationTitle : ko.observable();
        this.selectedCageTitle = ko.isObservable(params.selectedCageTitle) ? params.selectedCageTitle : ko.observable();
        this.selectedCageText = ko.pureComputed(() => {
            return this.initialized() === "loading" ? getTranslation("Loading") :
                this.selectedCage() ? this.selectedCage().cagenumber :
                    this.disabledPicker ? getTranslation("No location set") : getTranslation("Click here to set cage");
        });
        this.selectedCage.subscribe(() => {
            const x = this.selectedCage();
            let s = "";

            if (x instanceof Object) {
                s = this.selectedLocationTitle();
                s += "\n" + getTranslation("Cage") + ": " + x.cagenumber;
            }
            this.selectedCageTitle(s);
        });

        this.confirmable = ko.pureComputed(() => {
            return this.selectedLocation() instanceof Object
                && this.selectedCage() instanceof Object;
        });

        this.dialogVisible.subscribe((newValue) => {
            // jQuery UI triggers a focus event on the opener element
            // (usualy that textarea element that shows the location string).
            // That element listens on the focusin event to show the
            // location picker.
            // We can avoid this by disabling the opener element - so jQuery UI
            // won't trigger it's focus event.
            // https://bugs.webkit.org/show_bug.cgi?id=47182
            this.disabledAttr(newValue ? "disabled" : false);
            window.setTimeout(() => {
                // Firefox < ~85 needs this to be wrapped in setTimeout(),
                // other Browser need it to be outside.
                // TODO: Removed after 2022-04.
                this.disabledAttr(newValue ? "disabled" : false);
            }, 0);
        });

        if (ko.isObservable(params.initialized)) {
            params.initialized(this.initialized());
            this.initialized.subscribe((value) => {
                params.initialized(value);
            });
        }

        this.afterApply = () => {
            if (typeof params.afterApply === "function") {
                params.afterApply();
            }
        };

    }

    public showPopup = () => {
        if (this.disabledPicker) {
            return;
        }
        if (!this.dialogVisible()) {
            this.isConfirmed = false;
            this.dialogVisible(true);
        }
        this.cleanupTrigger.valueHasMutated();
        this.rememberSelectionTrigger.valueHasMutated();
    };

    public hidePopup = () => {
        if (this.dialogVisible()) {
            this.dialogVisible(false);
        }
    };

    public confirmSelect = () => {
        this.isConfirmed = true;

        this.afterApply();
        this.hidePopup();
    };

    private closeCallback = () => {
        if (!this.isConfirmed) {
            this.restoreSelectionTrigger.valueHasMutated();
        }
        //lose focus of cage location (textarea), so location-picker-popup shows of on next click
        const blurEvent = document.createEvent("HTMLEvents");
        blurEvent.initEvent("blur", true, false);
        this.componentElement.dispatchEvent(blurEvent);

    };
}

export class LocationCagePickerPopupComponent {

    constructor() {
        return {
            viewModel: {
                createViewModel: (params: LocationCagePickerPopupParams, componentInfo: components.ComponentInfo) => {
                    let viewModelElement: ChildNode;
                    const componentElement = componentInfo.element as HTMLElement;
                    if (componentElement.nodeName === "#comment") {
                        viewModelElement = componentElement.nextElementSibling;
                    } else {
                        viewModelElement = componentElement.firstChild;
                    }
                    return new LocationCagePickerPopupViewModel(params, viewModelElement as HTMLElement);
                },
            },
            template: `
                <input type="text"
                       readonly="readonly"
                       data-bind="event: {focusin: showPopup},
                                  value: selectedCageText,
                                  attr: {title: selectedCageTitle,
                                         id: inputId,
                                         disabled: disabledAttr},
                                  css: inputClassName"/>
                <div class="location-cage-picker-popup"
                     data-bind="dialog: dialogArgs,
                                dialogVisible: dialogVisible,
                                dialogPosition: dialogPosition">
                    <ko-location-cage-picker params="pickerClassName: pickerClassName,
                                                     _withinPopup: true,
                                                     initialized: initialized,
                                                     selectedLocation: selectedLocation,
                                                     selectedCage: selectedCage,
                                                     parentLocation: parentLocation,
                                                     preselectLocation: preselectLocation,
                                                     preselectCage: preselectCage,
                                                     unselectLocationTrigger: unselectLocationTrigger,
                                                     unselectCageTrigger: unselectCageTrigger,
                                                     cleanupTrigger: cleanupTrigger,
                                                     selectedLocationTitle: selectedLocationTitle,
                                                     selectedCageTitle: selectedCageTitle,
                                                     rememberSelectionTrigger: rememberSelectionTrigger,
                                                     restoreSelectionTrigger: restoreSelectionTrigger"></ko-location-cage-picker>
                    <div class="popup_footer">
                        <input type="reset"
                               data-bind="value: getTranslation('Cancel'),
                                          click: hidePopup"/>
                        <input type="submit"
                               class="confirm"
                               data-bind="value: getTranslation('Apply'),
                                          click: confirmSelect,
                                          enable: confirmable"/>
                    </div>
                </div>
            `,
        };
    }

}
