import * as Noty from "noty";
import "noty/src/noty.scss";
import {getTranslation} from "../localize";
import "./notifications.scss";
import {hasParentWithMatchingSelector} from "../utils";


type Type = "confirm" | Noty.Type;

interface NotificationOptions {
    buttons?: Noty.Button[];
}

interface ModalOptions {
    type?: Type;
    buttons?: Noty.Button[];
    onClose?: () => void;
}

interface ConfirmOptions {
    type?: Type;
    onCancel?: () => void;
    okText?: string;
    cancelText?: string;
}

export class Notifications {

    constructor() {
        Noty.overrideDefaults({
            type: "information",
            layout: "bottomLeft",
            theme: "pyrat",
            queue: "default",
            force: true,
            closeWith: ["click"],
        });
    }

    button = Noty.button;

    /**
     * Register a click handler to close all "default" notifications when clicking
     * somewhere in the window. Unfortunately, this is required to be called once
     * for all windows - also in frames.
     *
     * @param w Window element to act on.
     */
    registerNotificationMessageCleaner(w: Window): void {
        w.addEventListener("click", function (event) {
            // ignore clicks to the message box itself to allow buttons to act
            if (event.target instanceof HTMLElement
                || (typeof event.target === "object"
                    && Object.getPrototypeOf(event.target.constructor).name === "HTMLElement")
            ) {
                if (!hasParentWithMatchingSelector(event.target as Element, ".noty_bar")) {
                    Noty.closeAll("default");
                }
            }
        });
    }

    /**
     * Show a bottom left notification, which disappears on next click.
     *
     * This is a replacement for the old "notificationMessage".
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in the stylesheet).
     * @param buttons List of buttons to show. Buttons are created with the button method of this class.
     */
    showNotification(text: string, type: Type = "information",
                     {
                         buttons = [],
                     }: NotificationOptions = {}): Noty {
        const n = new Noty({
            // @ts-expect-error: Noty actually accetps also custom classes, but this is not in the TypeScript definition.
            type,
            buttons,
            text,
        });
        n.show();
        return n;
    }


    /**
     * Show a bottom left notification, which requires a click on a little cross to close.
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in the stylesheet).
     * @param buttons List of buttons to show. Buttons are created with the button method of this class.
     */
    showStickyNotification(text: string, type: Type = "information",
                           {
                               buttons = [],
                           }: NotificationOptions = {}): Noty {
        const n = new Noty({
            // @ts-expect-error: Noty actually accetps also custom classes, but this is not in the TypeScript definition.
            type,
            queue: "confirm",
            closeWith: ["button"],
            buttons,
            text,
        });
        n.show();
        return n;
    }

    /**
     * Show a centered modal popup with a predefined closing a Ok button.
     *
     * This is a replacement for the old "alertPopup".
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in the stylesheet).
     * @param buttons List of buttons to show. Buttons are created with the button method of this class.
     * @param onClose Callback, called once the popup is closed.
     */
    showModal(text: string,
              {
                  type = "information",
                  buttons = [
                      Noty.button(getTranslation("OK"), "confirm", (n: Noty) => {
                          n.close();
                      }),
                  ],
                  onClose,
              }: ModalOptions = {}): Noty {
        const n = new Noty({
            // @ts-expect-error: Noty actually accetps also custom classes, but this is not in the TypeScript definition.
            type,
            layout: "center",
            modal: true,
            queue: "popup",
            closeWith: [],
            callbacks: {
                onClose,
            },
            buttons,
            text,
        });
        n.show();
        return n;
    }

    /**
     * Show a centered modal popup with a Cancel and an Ok button.
     *
     * This is a replacement for the old "confirmPopup".
     *
     * @param text The message to show.
     * @param type Type class of the notification (defined in the stylesheet).
     * @param onOk Callback, called in case the user clicks Ok button.
     * @param onCancel Callback, called in case the user clicks Cancel button.
     * @param okText Caption to show on the Ok button.
     * @param cancelText Caption to show on the Cancel button.
     */
    showConfirm(text: string,
                onOk: () => void,
                {
                    type,
                    onCancel,
                    okText = getTranslation("OK"),
                    cancelText = getTranslation("Cancel"),
                }: ConfirmOptions = {}): Noty {
        return this.showModal(
            text,
            {
                type,
                buttons: [
                    Noty.button(cancelText, "", (n: Noty) => {
                        n.close();
                        if (typeof onCancel == "function") {
                            onCancel();
                        }
                    }),
                    Noty.button(okText, "confirm", (n: Noty) => {
                        n.close();
                        if (typeof onOk == "function") {
                            onOk();
                        }
                    }),
                ],
            },
        );
    }

}
