import {Computed, Subscribable} from "knockout";
import * as _ from "lodash";
import * as ko from "knockout";
import {PureComputed} from "knockout";
import {Observable} from "knockout";
import {ObservableArray} from "knockout";
import {dialogStarter} from "../knockout/dialogStarter";
import {FetchExtended} from "../knockout/extensions/fetch";
import {CheckExtended} from "../knockout/extensions/invalid";
import {getTranslation} from "../lib/localize";
import {KnockoutPopup} from "../lib/popups";
import {session} from "../lib/pyratSession";
import {getFormData} from "../lib/utils";
import {getUrl} from "../lib/utils";
import {AjaxResponse} from "../lib/utils";
import {frames, notifications} from "../lib/pyratTop";
import template from "./quickselectCage.html";
import {LocationItem, PreselectLocationItem} from "../knockout/components/locationPicker/locationPicker";
import {CommentWidgetSeed} from "../knockout/components/commentWidget";
import {NewComment} from "../knockout/components/commentWidget";


// The definition of Seed is quite complex here, so we use a namespace knowing it is not recommended.
// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Seed {

    interface Context {
        cages: {
            cageid: number;
            cagenumber: string;
            cagetype: "Stock" | "Breeding" | "Experiment";
            final_state_period: number;
            owner_userid: number;
            status: string;
        }[];
    }

    interface Content {
        add_to_selection_action?: AddToSelectionAction;
        show_in_animal_list_action?: ShowInAnimalListAction;
        show_in_pup_list_action?: ShowInPupListAction;
        convert_to_experiment_action?: ConvertToExperimentAction;
        set_location_action?: SetLocationAction;
        set_owner_action?: SetOwnerAction;
        set_responsible_action?: SetResponsibleAction;
        add_projects_action?: AddProjectsAction;
        remove_projects_action?: RemoveProjectsAction;
        set_project_action?: SetProjectAction;
        set_cage_category_action?: SetCageCategoryAction;
        add_comment_action?: AddCommentAction;
        set_color_action?: SetColorAction;
        set_user_color_action?: SetUserColorAction;
        set_cage_label_action?: SetCageLabelAction;
        set_cage_label_range_action?: SetCageLabelRangeAction;
        close_empty_cage_action?: CloseEmptyCageAction;
        print_cage_cards_action?: PrintCageCardsAction;
        mark_printed_action?: MarkPrintedAction;
        export_to_excel_action?: ExportToExcelAction;
        archive_action?: ArchiveAction;
        restore_action?: RestoreAction;
    }

    interface AddToSelectionAction {
        have_work_requests: boolean;
        cages_in_selection: number;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ShowInAnimalListAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ShowInPupListAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ConvertToExperimentAction {
    }

    interface SetLocationAction {
        select_rack_id: number | null;
    }

    interface SetOwnerAction {
        owners: {
            id: number;
            label: string;
        }[];
    }

    interface SetResponsibleAction {
        responsibles: {
            id: number;
            label: string;
        }[];
    }

    interface Project {
        id: number;
        status: string;
        project_label: string;
        owner_fullname: string;
        project_label_with_owner: string;
    }

    interface ProjectsSeed {
        add_projects: Project[];
        remove_projects: Project[];
    }

    interface AddProjectsAction {
        projects: ProjectsSeed;
    }

    interface RemoveProjectsAction {
        projects: ProjectsSeed;
    }

    interface SetProjectAction {
        projects: ProjectsSeed;
    }

    interface SetCageCategoryAction {
        cage_categories: {
            id: number;
            name: string;
        }[];
    }

    interface AddCommentAction {
        comment_widget_data: CommentWidgetSeed;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetColorAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetUserColorAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetCageLabelAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface SetCageLabelRangeAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface CloseEmptyCageAction {
    }

    interface PrintCageCardsAction {
        printers: string[];
        default_printer?: string;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface MarkPrintedAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ExportToExcelAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ArchiveAction {
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RestoreAction {
    }
}

interface Response {
    success: boolean;
    context: {
        cage_ids: number[];
    };
    content: {
        add_to_selection_action?: number[];
        show_in_animal_list_action?: any;
        show_in_pup_list_action?: any;
        print_cage_cards_action?: any;
        export_to_excel_action?: any;
    };
}

interface Params {
    cageIds: number[];
    viewName: "cagelist" | "stockcagelist";
    actions?: string[];
    reloadCallback?: () => void;
}

abstract class Action {

    public qs: QuickselectCageViewModel;
    public requireConclusion = true;
    public selected: Subscribable<boolean>;
    public enabled: Subscribable<boolean>;
    public valid: Subscribable<boolean>;
    public possible: Subscribable<boolean>;
    public errors: ObservableArray<string>;

    protected constructor(qs: QuickselectCageViewModel) {
        this.qs = qs;
        this.selected = ko.observable(false);
        this.enabled = ko.observable(true);
        this.valid = ko.observable(true);
        this.errors = ko.observableArray([]);
        this.possible = ko.observable(true);
    }

    public serialize = () => {
        return {};
    };
}


// TODO: Waiting for "inherit" from in TypeScript to replace "Action" return value with implementation.
// noinspection JSPotentiallyInvalidUsageOfThis
const actionModels: { [key in keyof Seed.Content]: (qs: QuickselectCageViewModel, seed: Seed.Content[key]) => Action } = {

    add_to_selection_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public extendCageSelection: Observable<boolean>;

        constructor() {
            super(qs);

            this.extendCageSelection = ko.observable(false);
        }

        public serialize = () => {
            return {
                extend_selection: this.extendCageSelection(),
            };
        };
    }),

    show_in_animal_list_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    show_in_pup_list_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    convert_to_experiment_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            // all selected cages must be stock cages
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context().cages, (c) => {
                    return c.cagetype === "Stock";
                });
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    set_location_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public preselectLocation: PreselectLocationItem;
        public selectedLocation: CheckExtended<Observable<LocationItem>>;
        public afterApply: () => void;
        public rackId: Computed<number>;
        public confirmSanitaryStatus: Observable<string>;
        public cagePositions: Observable<string>;

        constructor() {
            super(qs);

            this.preselectLocation = {type: "rack", id: seed.select_rack_id};

            this.selectedLocation = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        if (session.pyratConf.MANDATORY_LOCATION && !v) {
                            return true;
                        }
                    }
                    return false;
                },
            });

            this.afterApply = () => {
                this.selected(true);
            };

            this.rackId = ko.computed(() => {
                return this.selectedLocation() ? this.selectedLocation().rack_id : undefined;
            });

            this.confirmSanitaryStatus = ko.observable();
            this.cagePositions = ko.observable();

            this.cagePositions.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.selectedLocation.isInvalid();
            });
        }

        public serialize = () => {
            return {
                rack_id: this.rackId(),
                cage_positions: this.cagePositions(),
                confirm_sanitary_status: this.confirmSanitaryStatus(),
            };
        };
    }),

    set_owner_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public ownerId: CheckExtended<Observable<number>>;

        constructor() {
            super(qs);

            this.ownerId = ko.observable().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !v;
                    }
                    return false;
                },
            });
            this.ownerId.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.ownerId.isInvalid();
            });
        }

        public serialize = () => {
            return {
                owner_id: this.ownerId(),
            };
        };
    }),

    set_responsible_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public responsibleId: Observable<number>;

        constructor() {
            super(qs);

            // all selected cages must be of the same owner
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context().cages, (c) => {
                    return qs.context().cages[0].owner_userid === c.owner_userid;
                });
            });

            this.responsibleId = ko.observable();
            this.responsibleId.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => {
            return {
                responsible_id: this.responsibleId(),
            };
        };
    }),

    add_projects_action: (qs, seed) => new class extends Action {

        public seed = seed;
        public projectIds: CheckExtended<ObservableArray<number>>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectIds = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !(v && v.length);
                    }
                    return false;
                },
            });

            this.projectIds.subscribe((v) => {
                if (v && v.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.projectIds.isInvalid();
            });
        }

        public serialize = () => ({
            project_ids: this.projectIds(),
        });

    },

    remove_projects_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public projectIds: CheckExtended<ObservableArray<number>>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectIds = ko.observableArray().extend({
                invalid: (v) => {
                    if (this.selected()) {
                        return !(v && v.length);
                    }
                    return false;
                },
            });

            this.projectIds.subscribe((v) => {
                if (v && v.length) {
                    this.selected(true);
                }
            });

            this.valid = ko.computed(() => {
                this.errors.removeAll();

                return !this.projectIds.isInvalid();
            });

            this.serialize = () => ({
                project_ids: this.projectIds(),
            });
        }

    }),

    set_project_action: (qs, seed) => new(class extends Action {

        public seed = seed;
        public projectId: Observable<number>;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                return !session.pyratConf.MULTIPLE_PROJECTS;
            });

            this.projectId = ko.observable();
            this.projectId.subscribe(() => {
                this.selected(true);
            });

            this.serialize = () => ({
                project_id: this.projectId(),
            });
        }

    }),

    set_cage_category_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageCategoryId: Observable<number>;

        constructor() {
            super(qs);

            this.cageCategoryId = ko.observable();
        }

        public serialize = () => {
            return {
                cage_category_id: this.cageCategoryId(),
            };
        };
    }),

    add_comment_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public comment: Observable<NewComment>;

        constructor() {
            super(qs);

            this.comment = ko.observable();
            this.comment.subscribe(() => {
                this.selected(true);
            });

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected()
                    && !this.comment()?.comment?.length
                    && !this.comment()?.attributes?.length
                ) {
                    this.errors.push(getTranslation("The comment field is empty."));
                    return false;
                }

                return true;
            });

        }

        public serialize = () => {
            return this.comment();
        };
    }),

    set_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return {color_key: this.selectedColor() || null};
        };

    }),

    set_user_color_action: (qs, seed) => new (class extends Action {

        public seed = seed;
        public selectedColor: Observable<string>;

        constructor() {
            super(qs);
            this.selectedColor = ko.observable();
        }

        public changeColorCallback = () => {
            this.selected(true);
        };

        public serialize = () => {
            return {user_color_key: this.selectedColor() || null};
        };

    }),

    set_cage_label_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageLabel: Observable<string>;

        constructor() {
            super(qs);

            this.cageLabel = ko.observable();
            this.cageLabel.subscribe(() => {
                this.selected(true);
            });
        }

        public serialize = () => {
            return {
                cage_label: this.cageLabel(),
            };
        };
    }),

    set_cage_label_range_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        public cageLabelFrom: Observable<string>;
        public cageLabelTo: Observable<string>;

        constructor() {
            super(qs);

            this.cageLabelFrom = ko.observable();
            this.cageLabelFrom.subscribe(() => {
                this.selected(true);
            });

            this.cageLabelTo = ko.observable();
            this.cageLabelTo.subscribe(() => {
                this.selected(true);
            });

        }

        public serialize = () => {
            return {
                cage_label_from: this.cageLabelFrom(),
                cage_label_to: this.cageLabelTo(),
            };
        };
    }),

    close_empty_cage_action: (qs, seed) => new (class extends Action {

        public seed = seed;

        constructor() {
            super(qs);

            // all selected cages must be empty
            this.enabled = ko.pureComputed(() => {
                return _.every(qs.context().cages, (c) => {
                    return c.status === "empty";
                });
            });
        }
    }),

    print_cage_cards_action: (qs, seed) => new class extends Action {

        public seed = seed;

        public printer: Observable<string>;
        public maxCagesPrintWarning: Computed<string>;

        constructor() {
            super(qs);

            this.printer = ko.observable(seed.default_printer || undefined);
            this.printer.subscribe(() => {
                this.selected(true);
            });

            this.maxCagesPrintWarning = ko.computed(() => {
                if (this.selected() && qs.context().cages.length > parseInt(session.pyratConf.MAX_CAGES_PRINT, 10)) {
                    return getTranslation("Too many cards to be printed at once. Only the first %s cards are printed.")
                        .replace("%s", session.pyratConf.MAX_CAGES_PRINT);
                }
            });
        }

        public serialize = () => ({
            printer: this.printer(),
        });

    },

    mark_printed_action: (qs) => new (class extends Action {

        constructor() {
            super(qs);
        }
    }),

    export_to_excel_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);

            this.valid = ko.computed(() => {

                this.errors.removeAll();

                if (this.selected() &&
                        _.some(qs.actions(), (action) => { return action !== this && action.selected(); })) {
                    this.errors.push(getTranslation("This cannot be combined with other actions"));
                    return false;
                }

                return true;
            });
        }
    }),

    archive_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);

            this.enabled = ko.pureComputed(() => {
                let isEnabled = false;
                const finalCageStates = ["closed", "empty"];

                // enable archive action checkbox
                // -> all selected cages need to be in final state (closed, empty)
                if (qs.context().cages.length) {
                    isEnabled = qs.context().cages.every((cage) => {
                        return finalCageStates.includes(cage.status)
                            && cage.final_state_period >= session.pyratConf.ARCHIVE_FINAL_STATE_PERIOD_MIN;
                    });
                }

                return isEnabled;
            });

        }
    }),

    restore_action: (qs) => new (class extends Action {

        public requireConclusion = false;

        constructor() {
            super(qs);
        }
    }),

};


class QuickselectCageViewModel {

    public reloadRequired: Observable<boolean>;
    public seed: FetchExtended<Observable<AjaxResponse<{ context: Seed.Context; content: Seed.Content }>>>;
    public context: PureComputed<Seed.Context | undefined>;
    public conclusion: ObservableArray<{ text: string; click: () => void }>;
    public actions: PureComputed<{ [key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]> }>;
    public applyInProgress: Observable<boolean>;
    public errors: ObservableArray<string>;
    private readonly params: Params;
    private readonly dialog: KnockoutPopup;
    private scenery: PureComputed<{ loading: boolean } |
        { conclusion: QuickselectCageViewModel } |
        { context: QuickselectCageViewModel; actions: QuickselectCageViewModel } |
        { error: boolean }>;

    constructor(params: Params, dialog: KnockoutPopup) {

        this.params = params;
        this.dialog = dialog;
        this.reloadRequired = ko.observable(false);

        // get initial data
        this.seed = ko.observable().extend({
            fetch: (signal) => {
                return fetch(getUrl("quickselect_cage.py", {
                    "data": JSON.stringify({
                        actions: this.params.actions,
                        context: {cage_ids: this.params.cageIds},
                    }),
                }), {signal});
            },
        });

        this.dialog.setTitle(
            getTranslation("Quick Select") + " (" +
            getTranslation("%d cages selected").replace("%d", String(this.params.cageIds.length)) + ")"
        );

        this.context = ko.pureComputed(() => {
            const seed = this.seed();
            if (seed?.success) {
                return seed?.context;
            } else {
                return undefined;
            }
        });

        // initialize the actions with their seeded data
        this.actions = ko.pureComputed(() => {
            const actions: {[key in keyof typeof actionModels]: ReturnType<typeof actionModels[key]>} = {};
            const seed = this.seed();
            if (seed?.success && seed?.content) {
                Object.keys(seed.content).forEach((actionName: keyof Seed.Content) => {
                    if (actionName in actionModels) {
                        // @ts-expect-error: TODO: Typing is too complicated here. I did not get it right.
                        // Waiting for "inherit" from implementation in TypeScript.
                        actions[actionName] = actionModels[actionName](this, seed.content[actionName]);
                    }
                });
            }
            return actions;
        });


        // result handling

        this.conclusion = ko.observableArray([]);
        this.errors = ko.observableArray([]);
        this.applyInProgress = ko.observable(false);

        // routing
        this.scenery = ko.pureComputed(() => {

            if (this.seed.fetchInProgress()) {
                return {loading: true};
            }

            if (this.conclusion().length) {
                return {conclusion: this};
            }

            if (this.seed() && this.seed().success) {
                return {context: this, actions: this};
            }

            return {error: true};

        });

    }

    public close = () => {
        this.dialog.close();
        if (typeof this.params.reloadCallback === "function" && this.reloadRequired()) {
            this.params.reloadCallback();
        }
    };

    public canApply = () => {

        if (this.applyInProgress()) {
            return false;
        } else if (this.context().cages.length < 1) {
            return false;
        } else if (!_.some(_.invokeMap(this.actions(), "selected"))) {
            // check if any action is selected
            return false;
        }

        // check if all selected actions are valid
        return _.every(this.actions(), (val) => {
            return val.selected() && val.valid ? Boolean(val.valid()) : true;
        });

    };

    public applyQuickselect = () => {

        this.errors.removeAll();

        const data = {
            context: {cage_ids: this.params.cageIds},
            content: _
                .chain(this.actions())
                .pickBy((a) => {
                    return a.selected();
                })
                .mapValues((a) => {
                    return a.serialize();
                })
                .value(),
        };

        this.applyInProgress(true);
        this.reloadRequired(true);
        _.forEach(this.actions(), (action) => {
            action.errors.removeAll();
        });

        fetch("quickselect_cage.py", {method: "POST", body: getFormData({data: JSON.stringify(data)})})
            .then((response) => response.json())
            .then((response: Response) => {

                if (response.success) {

                    this.conclude(response);

                    const autoClose = !_.some(response?.content, (action_data, action: keyof Response["content"]) => {
                        return this.actions()[action] && this.actions()[action].requireConclusion;
                    });

                    if (autoClose) {
                        // no action requires a conclusion, so we immediately close it
                        this.close();
                    }

                } else {
                    // client error
                    _.forEach(response.content, (messages, name: keyof Response["content"]) => {
                        if (this.actions()[name] && this.actions()[name].errors) {
                            _.forEach(messages, (m) => {
                                this.actions()[name].errors.push(m);
                            });
                        } else {
                            _.forEach(messages, (m) => {
                                this.errors.push(m);
                            });
                        }
                    });
                }
            })
            .catch(() => {
                this.errors.push(getTranslation("General quickselect error."));
            })
            .finally(() => this.applyInProgress(false));

    };

    private conclude = (value: Response) => {

        // clear old results
        this.conclusion.removeAll();

        const cageIds = value?.context?.cage_ids;
        const addToSelectionRequest = value?.content?.add_to_selection_action;
        const showInAnimalListRequest = value?.content?.show_in_animal_list_action;
        const showInPupListRequest = value?.content?.show_in_pup_list_action;
        const printRequest = value?.content?.print_cage_cards_action;
        const exportRequest = value?.content?.export_to_excel_action;

        if (showInAnimalListRequest) {
            // no conclusion needed, this action redirects immediately to the animal list
            this.conclusion.removeAll();
            this.reloadRequired(false);
            window.top.mainMenu.subtabClick("animals");
        }

        if (showInPupListRequest) {
            // no conclusion needed, this action redirects immediately to the pup list
            this.conclusion.removeAll();
            this.reloadRequired(false);
            window.top.mainMenu.subtabClick("pups");
        }

        if (exportRequest && cageIds) {
            // no conclusion needed, this action opens column select for export
            frames.openListAjaxPopup({
                method: "POST",
                url: "columnselect.py",
                name: "ColumnSelect",
                data: {
                    view_name: this.params.viewName,
                    export_args: JSON.stringify({
                        cageid: cageIds,
                        page_start: 0,
                        page_size: cageIds.length,
                    }),
                },
                title: getTranslation("Select columns"),
                width: "auto",
                height: "auto",
                anchor: {top: 25, right: 25},
                closeOnEscape: false,
            });
        }

        if (addToSelectionRequest) {
            this.conclusion.push({
                text: getTranslation("Immediately open a new request"),
                click: () => {
                    frames.detailPopup.open(getUrl("new_request.py"));
                },
            });
        }

        if (cageIds) {
            this.conclusion.push({
                text: getTranslation("Show cages") + " (" + String(cageIds.length) + ").",
                click: () => {
                    this.reloadRequired(false);
                    window.top.mainMenu.filterSubtab(
                        this.params.viewName === "cagelist" ? "cages" : "stock_cage",
                        {cageid: cageIds},
                    );
                },
            });

            if (printRequest) {
                this.conclusion.push({
                    text: getTranslation("Print") + ": " + getTranslation("Show in new window / tab"),
                    click: () => this.openPdfInBrowser(cageIds),
                });
                this.openPdfInBrowser(cageIds);
            } else {
                this.conclusion.push({
                    text: getTranslation("Print cage cards"),
                    click: () => {
                        this.checkCagesAndPrinter(cageIds);
                        this.reloadRequired(false);
                        this.close();
                    },
                });

                this.conclusion.push({
                    text: getTranslation("Print cage cards and mark them as printed"),
                    click: () => {
                        this.checkCagesAndPrinter(cageIds);
                        this.markCageCardsAsPrinted(cageIds);
                        this.reloadRequired(false);
                        this.close();
                    },
                });
            }
        }

        this.conclusion.push({
            text: getTranslation("Open Quick Select to perform another task"),
            click: () => {
                this.conclusion.removeAll();
                this.seed.fetchForceReload();
            },
        });
    };

    private checkCagesAndPrinter = (cageIds: number[]) => {
        fetch("print_cages.py", {
            method: "POST",
            body: getFormData({cageids: cageIds.join(","), check: 1}),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.success || notifications.showConfirm(response.message, () => true)) {
                    if (Object.hasOwn(response, "select_a_printer")) {
                        this.printCageCards(cageIds, response.select_a_printer);
                    } else {
                        // open PDF preview file in browser
                        this.openPdfInBrowser(cageIds);
                    }
                }
            })
            .catch(() => notifications.showNotification(
                getTranslation("Can't print. Internal error has ocurred."),
            ));
    };

    private openPdfInBrowser = (cageIds: number[]) => {
        window.open(getUrl("papercard.py", {
            "kind": "cage_card",
            "subjects": cageIds.slice(0, parseInt(session.pyratConf.MAX_CAGES_PRINT, 10)).join(","),
        }));
    };

    private printCageCards = (cageIds: number[], selectPrinter: boolean) => {
        if (selectPrinter) {
            // pyrat_conf is set up to use printer_chooser
            frames.openListAjaxPopup({
                method: "GET",
                url: "print_cages.py",
                data: {
                    cageids: cageIds.join(","),
                    select_a_printer: "1",
                },
                title: getTranslation("Choose printer"),
            });
        } else {
            // printer is already selected or just one printer is set up
            fetch(getUrl("print_cages.py", {cageids: cageIds.join(",")}))
                .then((response) => response.json())
                .then((response) => {
                    if (response.success) {
                        notifications.showNotification(getTranslation("Cards printed"), "success");
                    } else {
                        notifications.showNotification(getTranslation("Can't print. Internal error has ocurred."), "error");
                    }
                })
                .catch(() => notifications.showNotification(
                    getTranslation("Can't print. Internal error has ocurred."),
                ));
        }
    };

    private markCageCardsAsPrinted = (cageIds: number[]) => {
        fetch("quickselect_cage.py", {
            method: "POST",
            body: getFormData({
                data: JSON.stringify({
                    "context": {"cage_ids": cageIds},
                    "content": {"mark_printed_action": {}},
                }),
            }),
        }).catch(() => {
            this.errors.push(getTranslation("General quickselect error."));
        });
    };
}


// dialog starter
export const showQuickselectCage = dialogStarter(QuickselectCageViewModel, template, {
    name: "QuickselectCage",
    width: 600,
    cssARequire: [
        ":table.css",
        ":quick_select.css",
        ":usercolors.css"],
    anchor: {
        top: 5,
        right: 5,
    },
    closeOthers: true,
    title: getTranslation("Quick Select"),
});
